import { action, makeObservable, observable } from 'mobx';

import { AgreementFileTemplateStore } from './agreement-file-template.store';
import { MethodsRequest } from '../../constants';
import { API } from '../../core';
import { ErrorAPI } from '../../helpers';
import { AgreementFileTemplate } from '../../interfaces';
import { AllStore } from '../common/all.store';
import { TableStore } from '../common/table.store';

export class AgreementFileTemplatesStore {
	@observable all = new AllStore({
		url: 'agreement-file-templates',
		getListItemStore: this.getListItemStore,
	});

	@observable table = new TableStore({
		url: 'agreement-file-templates',
		getListItemStore: this.getListItemStore,
	});

	constructor() {
		makeObservable(this);
	}

	@action.bound
	async uploadFiles(files: FileList) {
		try {
			await API.request('agreement-file-templates', {
				method: MethodsRequest.Post,
				body: API.getFormData({ files: files }),
			});
		} catch (e) {
			ErrorAPI(`uploadFiles`, e);
		}
	}

	@action.bound
	public getListItemStore(value: AgreementFileTemplate): AgreementFileTemplateStore {
		return new AgreementFileTemplateStore(value);
	}
}
