import { Checkbox, Col, DatePicker, Divider, Form, FormInstance, Input, Row, Select } from 'antd';
import FormItem from 'antd/es/form/FormItem';
import { observer } from 'mobx-react-lite';

import { useStores } from '../../../../../../../stores';

interface InformationTabProps {
	form: FormInstance;
}

export const InformationTab = observer(({ form }: InformationTabProps) => {
	const {
		usersStore,
		itemsStore: { selected: currentTaskItem },
	} = useStores();

	const responsible_id = Form.useWatch('responsible_id', form);

	return (
		<>
			<Form.Item name={'parent_id'} hidden>
				<Input />
			</Form.Item>

			<Form.Item name={'item_type_id'} hidden>
				<Input type={'hidden'} />
			</Form.Item>

			<Form.Item name={'item_object_type_id'} hidden>
				<Input type={'hidden'} />
			</Form.Item>

			<Row gutter={15}>
				<Col span={12}>
					<Form.Item label={'Ответственный по задаче'} name={'creator_id'}>
						<Select
							showSearch
							filterOption={(inputValue, option) => {
								return String(option?.label ?? '')
									.toLowerCase()
									.includes(inputValue.toLowerCase());
							}}
							placeholder={'Выберите ответсвенного'}
							options={usersStore.selectBoxVolume}
							allowClear
						/>
					</Form.Item>
				</Col>

				<Col span={12}>
					<Form.Item label={'Исполнитель задачи'} name={'responsible_id'}>
						<Select
							showSearch
							filterOption={(inputValue, option) => {
								return String(option?.label ?? '')
									.toLowerCase()
									.includes(inputValue.toLowerCase());
							}}
							placeholder={'Выберите исполнителя'}
							options={usersStore.selectBoxVolume}
							allowClear
						/>
					</Form.Item>
				</Col>
			</Row>

			<Row gutter={15}>
				<Col span={12}>
					<FormItem shouldUpdate>
						{() => {
							const volumeStartDate = currentTaskItem?.parent_volume?.start_date;
							const volumeEndDate = currentTaskItem?.parent_volume?.end_date;

							return (
								<Form.Item
									label={'Начало работ'}
									name={'start_date'}
									rules={[
										{ required: true },
										{
											validator: async () => {
												if (volumeStartDate) {
													return Promise.resolve();
												} else {
													return Promise.resolve();
												}
											},
										},
									]}
								>
									<DatePicker
										format={'DD MMMM YYYY'}
										disabledDate={(current) => {
											if (volumeStartDate && current && volumeEndDate) {
												return (current && current <= volumeStartDate) || current >= volumeEndDate.endOf('day');
											} else {
												return false;
											}
										}}
									/>
								</Form.Item>
							);
						}}
					</FormItem>
				</Col>

				<Col span={12}>
					<FormItem shouldUpdate style={{ margin: 0 }}>
						{() => {
							const fieldStartDate = form.getFieldValue('start_date');
							const volumeEndDate = currentTaskItem?.parent_volume?.end_date;

							return (
								<Form.Item label={'Окончание работ'} name={'end_date'} rules={[{ required: true }]}>
									<DatePicker
										picker={'date'}
										format={'DD MMMM YYYY'}
										disabledDate={(current) => {
											if (fieldStartDate && current && volumeEndDate) {
												return (current && current <= fieldStartDate) || current > volumeEndDate.endOf('day');
											} else {
												return false;
											}
										}}
									/>
								</Form.Item>
							);
						}}
					</FormItem>
				</Col>
			</Row>

			<Row gutter={15}>
				<Col span={24}>
					<Form.Item label={'Завершающаяя задача'} name={'is_finish'} valuePropName={'checked'}>
						<Checkbox />
					</Form.Item>
				</Col>
			</Row>

			<Row gutter={15}>
				<Col span={24}>
					<Form.Item label={'Название задачи'} name={'title'} rules={[{ required: true }]}>
						<Input />
					</Form.Item>
				</Col>
			</Row>

			<Row gutter={15}>
				<Col span={24}>
					<Form.Item label={'Описание задачи'} name={'content'} rules={[{ required: true }]}>
						<Input.TextArea autoSize={{ minRows: 3 }} />
					</Form.Item>
				</Col>
			</Row>

			{!responsible_id && (
				<>
					<Divider />

					<Row>
						<Col span={12}>
							<Form.Item label={'Итоговая дата назначения'} name={'deadline_execute_date'}>
								<DatePicker disabled format={'DD MMMM YYYY'} />
							</Form.Item>
						</Col>
					</Row>
				</>
			)}
		</>
	);
});
