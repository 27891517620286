import { ChangeEvent, useRef } from 'react';

import { Button, Col, Descriptions, Row } from 'antd';
import { observer } from 'mobx-react-lite';

import { ContainerInputFile } from './Files.styled';
import { useStores } from '../../../../../stores';
import { FileButtons } from '../../../../common';

export const Files = observer(() => {
	const { userDashboardStore } = useStores();
	const { objects } = userDashboardStore;
	const { viewDetail } = objects;
	const { data } = viewDetail;

	const refUploadTech = useRef<HTMLInputElement>(null);

	const handleOnUploadTechFile = async (e: ChangeEvent<HTMLInputElement>) => {
		const files = e.currentTarget.files;

		if (!files) {
			return false;
		}
	};

	return (
		<>
			<ContainerInputFile type="file" ref={refUploadTech} multiple onChange={handleOnUploadTechFile} />
			<Row gutter={15}>
				<Col span={12}>
					<Descriptions
						title={'Файлы технической документации'}
						layout={'vertical'}
						column={1}
						extra={[
							<Button
								key={'upload-tech'}
								onClick={() => {
									if (refUploadTech.current) {
										refUploadTech.current.click();
									}
								}}
							>
								Загрузить
							</Button>,
						]}
					>
						<Descriptions.Item>
							<div style={{ display: 'flex', width: '100%', flexDirection: 'column' }}>
								{data.techFiles.list.map((file) => {
									return <FileButtons key={file.id} file={file} readOnly />;
								})}
							</div>
						</Descriptions.Item>
					</Descriptions>
				</Col>

				<Col span={12}>
					<Descriptions
						layout={'vertical'}
						column={1}
						title={'Файлы административной документации'}
						extra={[<Button key={'upload-admin'}>Загрузить</Button>]}
					>
						<Descriptions.Item>
							<div style={{ display: 'flex', width: '100%', flexDirection: 'column' }}>
								{data.adminFiles.list.map((file) => {
									return <FileButtons key={file.id} file={file} readOnly />;
								})}
							</div>
						</Descriptions.Item>
					</Descriptions>
				</Col>

				<Col span={12}>
					<Descriptions
						layout={'vertical'}
						column={1}
						title={'Файлы на проектирование (ТЗ)'}
						extra={[<Button key={'upload-tech-task'}>Загрузить</Button>]}
					>
						<Descriptions.Item>
							<div style={{ display: 'flex', width: '100%', flexDirection: 'column' }}>
								{data.techTaskFiles.list.map((file) => {
									return <FileButtons key={file.id} file={file} readOnly />;
								})}
							</div>
						</Descriptions.Item>
					</Descriptions>
				</Col>
			</Row>
		</>
	);
});
