import { FormInstance } from 'antd';
import { observer } from 'mobx-react-lite';

import { ContractorsList } from './ContractorsList';

interface ContractorsTabProps {
	form: FormInstance;
}

export const ContractorsTab = observer(({ form }: ContractorsTabProps) => {
	return <ContractorsList form={form} />;
});
