import { observable } from 'mobx';

import { ObjectsStore } from '../objects/objects.store';
import { TasksStore } from '../task/tasks.store';
import { VolumesStore } from '../volume/volumes.store';

export class UserDashboardStore {
	@observable objects = new ObjectsStore();
	@observable volumes = new VolumesStore();
	@observable tasks = new TasksStore();
}
