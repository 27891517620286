import { Table } from 'antd';
import { observer } from 'mobx-react-lite';

import { useColumns } from './AgreementFileTemplatesTable.columns';
import { useStores } from '../../../../../stores';

interface AgreementFileTemplatesTableProps {}

export const AgreementFileTemplatesTable = observer(({}: AgreementFileTemplatesTableProps) => {
	const { agreementFileTemplatesStore } = useStores();

	return (
		<>
			<Table
				rowKey={'id'}
				loading={agreementFileTemplatesStore.table.isLoading}
				columns={useColumns()}
				dataSource={agreementFileTemplatesStore.table.list}
				pagination={{
					current: agreementFileTemplatesStore.table.pagination.current_page,
					total: agreementFileTemplatesStore.table.pagination.total,
					onChange: (page) => agreementFileTemplatesStore.table.pagination.setCurrentPage(page),
				}}
			/>
		</>
	);
});
