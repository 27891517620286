import { useEffect } from 'react';

import { Col, Row } from 'antd';
import { observer } from 'mobx-react-lite';

import { PermissionCard } from './PermissionCard';
import { useStores } from '../../../../stores';

export const PermissionCards = observer(() => {
	const { permissionsStore } = useStores();

	useEffect(() => {
		(async () => {
			await permissionsStore.getList();
		})();
	}, []);

	return (
		<Row gutter={15} style={{ rowGap: 15 }}>
			{permissionsStore.list.map((permission) => {
				return (
					<Col key={permission.id} span={8}>
						<PermissionCard permissionStore={permission} />
					</Col>
				);
			})}
		</Row>
	);
});
