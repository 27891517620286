import { Descriptions } from 'antd';
import { observer } from 'mobx-react-lite';

import { ItemStore, useStores } from '../../../../../stores';
import { HistoryReport } from '../HistoryReport/HistoryReport';

interface CompleteBlockProps {
	itemStore: ItemStore;
}

export const CompleteBlock = observer(({ itemStore }: CompleteBlockProps) => {
	const { authStore } = useStores();

	if (itemStore.is_delete || !itemStore.isComplete) {
		return null;
	}

	if ([itemStore.responsible_id, itemStore.creator_id].includes(authStore.current.id)) {
		return (
			<>
				<div style={{ height: 25 }} />

				<Descriptions
					title={'Завершен'}
					column={1}
					bordered
					labelStyle={{ width: 350 }}
					extra={[itemStore.reports.length > 0 && <HistoryReport key={'history-button'} itemStore={itemStore} />]}
				>
					<Descriptions.Item label={'Отправлено на утверждение'}>
						{itemStore.lastReport.createdAtFormatted}
					</Descriptions.Item>

					<Descriptions.Item label={'Утверждено'}>{itemStore.actualDateFormatted}</Descriptions.Item>

					<Descriptions.Item label={'Информация'}>{itemStore.lastReport.comment}</Descriptions.Item>

					{itemStore.lastReport.files.length > 0 && (
						<>
							<Descriptions.Item label={'Загруженные файлы'}>
								{itemStore.lastReport.files.map((file) => (
									<div key={file.id}>
										<a href={file.fullPath} target={'_blank'} rel="noreferrer">
											{file.name}
										</a>
									</div>
								))}
							</Descriptions.Item>
						</>
					)}
				</Descriptions>
			</>
		);
	}

	return null;
});
