import { useEffect } from 'react';

import { FileOutlined, FileTextOutlined, SaveOutlined, UnorderedListOutlined } from '@ant-design/icons';
import { Alert, Button, Form, Modal, Space, Tabs } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { observer } from 'mobx-react-lite';

import { FilesTab } from './Tabs/FilesTab/FilesTab';
import { InformationVolumeTab } from './Tabs/InformationVolumeTab/InformationVolumeTab';
import { TasksTab } from './Tabs/TasksTab/TasksTab';
import { VOLUME_TABS } from '../../../../constants';
import { getButtonName, getTitle } from '../../../../helpers';
import { VolumeI, WsPayload, WsTypeEvent } from '../../../../interfaces';
import { useStores } from '../../../../stores';

export const VolumeModal = observer(() => {
	const { objectsStore, websocketStore } = useStores();
	const { selected: currentObject } = objectsStore;
	const { volumes } = currentObject;
	const { selected: currentVolume } = volumes;

	// Формв
	const [form] = useForm();

	// Поля формы
	const fields = {
		id: currentVolume.id,
		object_id: currentVolume.object_id,
		creator_id: currentVolume.creator_id,
		responsible_id: currentVolume.responsible_id,
		name_volume: currentVolume.name_volume,
		cipher: currentVolume.cipher,
		start_date: currentVolume.start_date,
		end_date: currentVolume.end_date,
		actual_end_date: currentVolume.formattedWorkActualEndDate,
		deviation: currentVolume.deviation,
		confirmation_by_responsible: currentVolume.confirmation_by_responsible,
		confirmation_by_creator: currentVolume.confirmation_by_creator,
	};

	// Монтирование и обновление компонента
	useEffect(() => {
		form.setFieldsValue({
			...fields,
		});
	}, [currentVolume.id, currentVolume.object_id]);

	// Создание или Обновление
	const handleOnSend = async (values: VolumeI) => {
		try {
			if (currentVolume.isNewStore) {
				// Создание тома
				await currentVolume.create(values);
				// Добавление тома в объект
				currentObject.volumes.add(currentVolume);
				// Закрываем окно
				handleOnClose();
			} else {
				// Обновляем
				await currentVolume.update(values);
				// Очищаем загрузку файлоы
				form.resetFields(['files']);
				// Обновляем наш объект
				await currentObject.get();
			}

			// Отправляем в сокет данные по тому
			websocketStore.sendMessage<WsPayload>({
				event: WsTypeEvent.volume,
				payload: {
					type: WsTypeEvent.volume,
					id: values.id,
					title: values.name_volume,
					responsible_id: values.responsible_id,
				},
			});
		} catch {
			/* empty */
		}
	};

	const handleOnSave = () => form.submit();

	const handleOnClose = () => {
		form.resetFields();
		handleOnCloseModal();
	};

	// При закрытии окна
	useEffect(() => {
		if (!volumes.modal.isShow) {
			volumes.clearSelected();
			objectsStore.clearSelected();
		}
	}, [volumes.modal.isShow]);

	const handleOnCloseModal = () => {
		volumes.modal.close();
		volumes.clearSelected();
		objectsStore.clearSelected();
	};

	const tabs = [
		{
			key: VOLUME_TABS.INFORMATION,
			label: 'Информация тома',
			icon: <FileOutlined />,
			children: <InformationVolumeTab form={form} objectStore={currentObject} volumeStore={currentVolume} />,
		},
		{
			key: VOLUME_TABS.TASKS,
			label: 'Задачи',
			disabled: currentVolume.isNewStore,
			children: <TasksTab />,
		},
		{
			key: VOLUME_TABS.FILES,
			label: 'Файлы',
			disabled: currentVolume.isNewStore,
			children: <FilesTab form={form} />,
		},
	];

	return (
		<Modal
			forceRender
			open={volumes.modal.isShow}
			centered
			width={1000}
			styles={{
				body: { height: 600, overflowX: 'hidden', overflowY: 'auto', display: 'flex', flexDirection: 'column' },
			}}
			title={getTitle('тома', currentVolume.isNewStore, currentVolume.cipher)}
			onCancel={handleOnCloseModal}
			footer={[
				<Space size={15} key={'space'}>
					{currentVolume.isError && (
						<Alert
							type={'error'}
							showIcon
							closable
							message={'Ошибка. Есть незаполненные поля.'}
							style={{ height: 32 }}
						/>
					)}

					<Button key={'cancel'} disabled={currentVolume.isLoading} onClick={handleOnClose}>
						Отменить
					</Button>
					<Button
						key={'create'}
						icon={<SaveOutlined />}
						type={'primary'}
						loading={currentVolume.isLoading}
						onClick={handleOnSave}
					>
						{getButtonName(currentVolume.isNewStore)}
					</Button>
				</Space>,
			]}
		>
			<Form form={form} layout={'vertical'} onFinish={handleOnSend}>
				<Tabs
					defaultActiveKey={VOLUME_TABS.INFORMATION}
					items={[<FileTextOutlined key={1} />, <UnorderedListOutlined key={2} />, <FileOutlined key={3} />].map(
						(Icon, index) => {
							const currentTab = tabs[index];

							return {
								...currentTab,
								label: (
									<span>
										{Icon} {currentTab.label}
									</span>
								),
							};
						},
					)}
				/>
			</Form>
		</Modal>
	);
});
