import { useEffect } from 'react';

import { notification } from 'antd';
import { observer } from 'mobx-react-lite';
import { useParams } from 'react-router-dom';

import {
	BackgroundBase,
	ContractorPageBodyUpload,
	ContractorPageContainer,
	ContractorPageDescription,
	ContractorPageFiles,
	ContractorPageTitle,
	ContractorPageUploadedBlock,
} from './ContractorPage.styled';
import { useStores } from '../../../../stores';
import { DragZoneUpload } from '../../../common';

interface ContractorPageProps {}

export const ContractorPage = observer(({}: ContractorPageProps) => {
	const { authStore, agreementPrivateContractorStore } = useStores();

	const { hash } = useParams<{ hash: string }>();

	useEffect(() => {
		(async () => {
			// Сразу выходим если вдруг были залогинены
			await authStore.logout();

			// Записываем hash
			if (hash) {
				agreementPrivateContractorStore.setHashLink(hash);
			}

			// Получаем информацию о контрагента
			await agreementPrivateContractorStore.getByHash();
		})();
	}, []);

	const handleOnUpload = async (files: FileList | null, callbackFn) => {
		if (!files) {
			return;
		}

		try {
			await agreementPrivateContractorStore.uploadFiles(files, agreementPrivateContractorStore.id);

			// Уведомление об успешной загрузке
			notification.success({
				message: 'Успешно загружены',
			});

			// Обноавляем стейт
			await agreementPrivateContractorStore.getByHash();
		} catch (e) {
			console.error(e);
		} finally {
			callbackFn();
		}
	};

	return (
		<BackgroundBase>
			<ContractorPageContainer
				initial={{
					opacity: 0,
				}}
				animate={{
					opacity: 1,
				}}
			>
				<ContractorPageTitle>
					Личная страница контрагента компании : {agreementPrivateContractorStore.contractor.company_name}
				</ContractorPageTitle>

				<ContractorPageDescription>
					Добро пожаловать <span>{agreementPrivateContractorStore.contractor.ceo}</span>
				</ContractorPageDescription>

				<ContractorPageDescription>Файлы для ознакомления с ТЗ</ContractorPageDescription>

				<ContractorPageFiles>
					{agreementPrivateContractorStore.agreement.item.files.map((file) => {
						return <a href={file.fullPath}>{file.name}</a>;
					})}
				</ContractorPageFiles>

				{agreementPrivateContractorStore.template_file.id && (
					<>
						<ContractorPageDescription>Типовой договор</ContractorPageDescription>

						<ContractorPageFiles>
							<a href={agreementPrivateContractorStore.template_file.fullPath}>
								{agreementPrivateContractorStore.template_file.origin_name}
							</a>
						</ContractorPageFiles>
					</>
				)}

				{agreementPrivateContractorStore.comment && (
					<>
						<ContractorPageDescription>Комментарий от компании</ContractorPageDescription>

						<ContractorPageFiles>{agreementPrivateContractorStore.comment}</ContractorPageFiles>
					</>
				)}

				<DragZoneUpload onUpload={(files, callbackStop) => handleOnUpload(files, callbackStop)} hidden={true}>
					<ContractorPageBodyUpload>Переместите файлы для загрузки</ContractorPageBodyUpload>
				</DragZoneUpload>

				{agreementPrivateContractorStore.files.length > 0 && (
					<ContractorPageUploadedBlock>
						<span>Загруженные файлы</span>

						{agreementPrivateContractorStore.files.map((file) => {
							return <a href={file.fullPath}>{file.origin_name}</a>;
						})}
					</ContractorPageUploadedBlock>
				)}
			</ContractorPageContainer>
		</BackgroundBase>
	);
});
