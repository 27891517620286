import { makeObservable } from 'mobx';

import { ObjectFileStore } from './object-file.store';
import { ObjectFileI } from '../../interfaces/object/object-file.interface';

export class ObjectAdminFileStore extends ObjectFileStore {
	PATH = 'object-admin-files';

	constructor(initialData: ObjectFileI | null) {
		super(initialData);
		makeObservable(this);
	}
}
