import { ChangeEvent, useState } from 'react';

import { SendOutlined } from '@ant-design/icons';
import { Button, Input } from 'antd';
import { observer } from 'mobx-react-lite';

import { Actions, Container, Field } from './WriteMessage.styled';
import { useStores } from '../../../../../../stores';

export const WriteMessage = observer(() => {
	const {
		authStore,
		dialogsStore: { selected: currentDialog },
	} = useStores();

	const [message, setMessage] = useState<string>('');

	const handleOnSendMessage = async () => {
		currentDialog?.sendMessage({
			from_user_id: authStore.current.id,
			to_user_id: currentDialog.toUserID,
			message: message,
		});

		setMessage('');
	};

	const handleOnChangeMessage = (e: ChangeEvent<HTMLTextAreaElement>) => {
		const { value } = e.currentTarget;

		setMessage(value);
	};

	return (
		<Container>
			<Field>
				<Input.TextArea autoSize={{ minRows: 6, maxRows: 6 }} value={message} onChange={handleOnChangeMessage} />
			</Field>

			<Actions>
				<Button type={'primary'} icon={<SendOutlined />} onClick={handleOnSendMessage}>
					Отправить
				</Button>
			</Actions>
		</Container>
	);
});
