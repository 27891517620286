import dayjs, { Dayjs } from 'dayjs';
import { action, observable } from 'mobx';

import { ItemTriggerPayload } from '../../interfaces/item';

export class ItemTriggerPayloadStore implements ItemTriggerPayload {
	@observable title = '';
	@observable content = '';
	@observable start_date: undefined | Dayjs = undefined;
	@observable end_date: undefined | Dayjs = undefined;
	@observable responsible_id: undefined | number = undefined;

	constructor(initialData: ItemTriggerPayload | null) {
		if (initialData) {
			this.fillStore(initialData);
		}
	}

	@action
	fillStore(data: ItemTriggerPayload) {
		const { title, content, start_date, end_date, responsible_id } = data;

		this.title = title || '';
		this.content = content || '';
		this.start_date = start_date ? dayjs(start_date) : undefined;
		this.end_date = end_date ? dayjs(end_date) : undefined;
		this.responsible_id = responsible_id ? Number(responsible_id) : undefined;
	}
}
