import {
	EditOutlined,
	EyeOutlined,
	HolderOutlined,
	InfoCircleOutlined,
	IssuesCloseOutlined,
	SearchOutlined,
} from '@ant-design/icons';
import { DndContext, DragEndEvent } from '@dnd-kit/core';
import { SensorDescriptor, SensorOptions } from '@dnd-kit/core/dist/sensors/types';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { Avatar, Button, Dropdown, Input, MenuProps, Popover, Table, Tag, Tooltip, Typography } from 'antd';
import { Observer } from 'mobx-react-lite';

import { ItemTaskRowTable } from './ItemTaskRowTable';
import { ItemDropDownKey, NameDays } from '../../../../../../../constants';
import { getInformationUser, IconEditItem, numWords } from '../../../../../../../helpers';
import { MenuInfoAntD } from '../../../../../../../interfaces';
import { ItemsStore, useStores } from '../../../../../../../stores';
import { ItemListStore } from '../../../../../../../stores/item/item-list.store';
import { COLORS } from '../../../../../../../stylesheets';
import { AvatarExtra } from '../../../../../../layouts/CabinetLayout/Sider/UserBlock/UserBlock.styled';

export const taskExpandedRowRender = (
	itemVolume: ItemListStore,
	itemsStore: ItemsStore,
	sensors: SensorDescriptor<SensorOptions>[],
) => {
	const { authStore } = useStores();

	const items: MenuProps['items'] = [
		{
			key: ItemDropDownKey.ViewTask,
			label: (
				<Button type={'link'} icon={<EyeOutlined />}>
					Просмотр
				</Button>
			),
		},
		{
			key: ItemDropDownKey.Edit,
			label: (
				<Button type={'link'} icon={<EditOutlined />}>
					Редактировать
				</Button>
			),
		},
	];

	const handleOnClick = async (e: MenuInfoAntD, row: ItemListStore) => {
		if (e.key === ItemDropDownKey.Edit) {
			// Очищаем
			itemsStore.clearSelected();

			// Устанавливаем ID
			itemsStore.selected.setId(row.id);

			// Получаем данные
			await itemsStore.selected.get();

			itemsStore.selected.drawer.show();
			return;
		}

		// Просмотр объекта
		else if (e.key === ItemDropDownKey.ViewTask) {
			// Очищаем
			itemsStore.clearSelected();

			// Устанавливаем ID
			itemsStore.selected.setId(row.id);

			// Получаем данные
			await itemsStore.selected.get();

			// Открываем окно
			itemsStore.selected.viewer.show();
		}
	};

	// const columns: TableColumnsType<ItemListStore>

	const columns: any = [
		{
			key: 'sort-dnd',
		},
		{
			title: 'Название задачи',
			dataIndex: 'title',
			align: 'center',
			filterDropdown: ({ selectedKeys, confirm }) => {
				return (
					<Input
						autoFocus
						placeholder={'Минимум 3 символа'}
						name={'title'}
						allowClear
						value={selectedKeys[0] as string}
						onBlur={() => confirm()}
					/>
				);
			},
			filterIcon: () => {
				return <SearchOutlined />;
			},
			render: (_, row) => {
				return (
					<Observer>
						{() => {
							return (
								<>
									<IconEditItem item={row} />
									{row.is_finish && (
										<Tooltip title={`Закрывающая задача`}>
											<Tag icon={<IssuesCloseOutlined />} />
										</Tooltip>
									)}{' '}
									{row.title}
								</>
							);
						}}
					</Observer>
				);
			},
		},
		{
			title: 'Статус',
			align: 'center',
			width: 150,
			render: (_, record) => {
				return (
					<Typography.Title style={{ color: COLORS.BLUE_100, fontSize: 12 }} ellipsis={true}>
						{record.local_status.status}
					</Typography.Title>
				);
			},
		},
		{
			title: 'Участники',
			align: 'center',
			render: (_, row) => {
				return (
					<Observer>
						{() => {
							return (
								<Avatar.Group>
									{row.creator.isExist && (
										<Popover title={'Ответственный'} content={getInformationUser(row.creator)}>
											<AvatarExtra style={{ background: COLORS.BLUE_80 }}>{row.creator.initials}</AvatarExtra>
										</Popover>
									)}

									{row.responsible.isExist && (
										<Popover title={'Исполнитель'} content={getInformationUser(row.responsible)}>
											<AvatarExtra style={{ background: COLORS.BLUE_100 }}>{row.responsible.initials}</AvatarExtra>
										</Popover>
									)}
								</Avatar.Group>
							);
						}}
					</Observer>
				);
			},
		},
		{
			title: (
				<>
					Триггеры{' '}
					<Tooltip title={'Показывает неотработаные триггеры'}>
						<InfoCircleOutlined />
					</Tooltip>
				</>
			),
			align: 'center',
			render: (_, row) => {
				return row.trigger_workflows.length;
			},
		},
		{
			title: 'Начало / Заершение',
			align: 'center',
			render: (_, row) => {
				return (
					<Observer>
						{() => {
							return (
								<span>
									{row.start_date?.format('DD MMMM YYYY')} - {row.end_date?.format('DD MMMM YYYY')}
								</span>
							);
						}}
					</Observer>
				);
			},
		},
		{
			title: 'Отклонение',
			align: 'center',
			render: (_, row) => {
				return (
					<Observer>
						{() => {
							return (
								<span>
									{row.deviation_days} {numWords(row.deviation_days, NameDays)}
								</span>
							);
						}}
					</Observer>
				);
			},
		},
		{
			title: 'Действия',
			align: 'center',
			width: 100,
			render: (_, row) => {
				return (
					<Dropdown
						menu={{
							onClick: (e) => handleOnClick(e, row),
							items,
						}}
						trigger={['click']}
					>
						<Button
							icon={<HolderOutlined />}
							type={'default'}
							style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', margin: '0 auto' }}
						/>
					</Dropdown>
				);
			},
		},
	];

	const onDragEnd = async (eventDragEnd: DragEndEvent) => {
		if (eventDragEnd.active?.id && eventDragEnd.over?.id) {
			if (typeof eventDragEnd.active?.id === 'number' && typeof eventDragEnd.over?.id === 'number') {
				const { active, over, delta } = eventDragEnd;

				// Разница между текущим положением и новым
				const position = over?.data.current?.sortable?.index - active.data.current?.sortable?.index;

				// Внизу или вверх
				const isBefore = delta.y < 0;

				// На какое количество изменяется позиция
				const countPosition = Math.abs(position);

				// Отправляем данные на обновление
				await itemVolume.childrenTable.changePosition({
					activeId: eventDragEnd.active?.id,
					overId: eventDragEnd.over.id,
					isBefore,
					countPosition,
				});
			}
		}
	};

	return (
		<Observer>
			{() => {
				return (
					<DndContext sensors={sensors} modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
						<SortableContext
							items={itemVolume.childrenTable.list.map((i) => i.id)}
							strategy={verticalListSortingStrategy}
						>
							<Table
								rowKey={'id'}
								columns={columns}
								dataSource={itemVolume.childrenTable.list}
								components={{
									body: {
										row: ItemTaskRowTable,
									},
								}}
								pagination={{
									current: itemVolume.childrenTable.pagination.current_page,
									total: itemVolume.childrenTable.pagination.total,
									onChange: (page) => itemVolume.childrenTable.pagination.setCurrentPage(page),
									style: {
										marginTop: 45,
									},
								}}
								onRow={(row) => {
									return {
										onDoubleClick: async () => {
											// Открываем окно для редактирования
											itemsStore.selected.drawer.show();

											// Устаналиваем ID
											itemsStore.selected.setId(row.id);

											// Получаем информацию по объекту
											await itemsStore.selected.get();
										},
									};
								}}
								rowClassName={(record) => {
									return record.hidden ? `tr_task_hidden` : '';
								}}
							/>
						</SortableContext>
					</DndContext>
				);
			}}
		</Observer>
	);
};
