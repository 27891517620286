import { ReactNode, useEffect } from 'react';

import { Pagination, Radio, RadioChangeEvent } from 'antd';

import { CardWidgetContainer } from './CardWidget.styled';
import { FILTER_CARD_WIDGET } from '../../../constants';
import { TableStore } from '../../../stores/common/table.store';
import { COLORS } from '../../../stylesheets';

interface CardWidgetProps {
	title: string;
	children?: ReactNode;
	tableStore: TableStore<any, any>;
}

export const CardWidget = ({ title, children, tableStore }: CardWidgetProps) => {
	const { pagination } = tableStore;

	useEffect(() => {
		(async () => {
			tableStore.addFilter(FILTER_CARD_WIDGET.RESPONSIBLE, 1);
		})();
	}, []);

	const handleOnChangePage = async (page: number) => {
		await pagination.setCurrentPage(page);
	};

	const handleOnChangeFilter = async (e: RadioChangeEvent) => {
		const { value } = e.target;

		if (value === FILTER_CARD_WIDGET.CREATOR) {
			tableStore.deleteFilter(FILTER_CARD_WIDGET.RESPONSIBLE);
			tableStore.addFilter(FILTER_CARD_WIDGET.CREATOR, 1);
		} else if (value === FILTER_CARD_WIDGET.RESPONSIBLE) {
			tableStore.deleteFilter(FILTER_CARD_WIDGET.CREATOR);
			tableStore.addFilter(FILTER_CARD_WIDGET.RESPONSIBLE, 1);
		}

		await pagination.setCurrentPage(1);
	};

	return (
		<CardWidgetContainer
			extra={
				<>
					<Radio.Group
						defaultValue={FILTER_CARD_WIDGET.RESPONSIBLE}
						optionType={'button'}
						buttonStyle={'solid'}
						options={[
							{
								label: 'Исходящие',
								value: FILTER_CARD_WIDGET.CREATOR,
							},
							{
								label: 'Входящие',
								value: FILTER_CARD_WIDGET.RESPONSIBLE,
							},
						]}
						onChange={handleOnChangeFilter}
					/>
				</>
			}
			headStyle={{ background: COLORS.SILVER_20 }}
			title={title}
			style={{ height: '100%', boxShadow: `0px 5px 10px 2px rgba(34, 60, 80, 0.05)` }}
		>
			{children}

			<Pagination
				style={{ margin: 'auto auto 0' }}
				total={pagination.total}
				current={pagination.current_page}
				pageSize={pagination.per_page}
				onChange={handleOnChangePage}
			/>
		</CardWidgetContainer>
	);
};
