import { CheckCircleOutlined, EditOutlined, HolderOutlined } from '@ant-design/icons';
import { Button, Dropdown, MenuProps, Popover, Tag, Typography } from 'antd';
import { ColumnsType } from 'antd/es/table';

import { actions } from '../../../../constants';
import { useStores } from '../../../../stores';
import { ContractorStore } from '../../../../stores/contractors/contractor.store';

export const useColumns = (): ColumnsType<ContractorStore> => {
	const { contractorsStore } = useStores();

	const handleOnEditContractor = async (contractor: ContractorStore) => {
		contractorsStore.drawer.show();

		contractorsStore.selected.setId(contractor.id);

		await contractorsStore.selected.get();
	};

	const items: MenuProps['items'] = [
		{
			key: actions.edit,
			label: (
				<Button type={'link'} icon={<EditOutlined />}>
					Редактировать
				</Button>
			),
		},
	];

	return [
		{
			width: 250,
			dataIndex: 'company_name',
			title: 'Название компании',
			align: 'center',
		},
		{
			width: 250,
			dataIndex: 'ceo',
			title: 'Директор',
			align: 'center',
		},
		{
			width: 250,
			dataIndex: 'inn',
			title: 'ИНН',
			align: 'center',
			render: (value) => {
				return (
					<Typography.Paragraph copyable style={{ margin: 0, letterSpacing: 1.4 }}>
						{value}
					</Typography.Paragraph>
				);
			},
		},
		{
			width: 250,
			dataIndex: 'legal_address',
			title: 'Юридический адрес',
			align: 'center',
		},
		{
			title: 'Телефон',
			align: 'center',
			width: 100,
			render: (_, record) => {
				if (record.phones.length === 0) {
					return <Tag>Не указан</Tag>;
				}

				const contentPhones = record.phones.map((phoneStore) => {
					return (
						<div key={phoneStore.id} style={{ display: 'flex', justifyContent: 'space-between' }}>
							<Typography.Text style={{ marginRight: 10 }}>
								{phoneStore.name} <span></span>
							</Typography.Text>
							<Typography.Link copyable href={`tel:${phoneStore.phone}`}>
								{phoneStore.phone}
							</Typography.Link>
						</div>
					);
				});

				return (
					<Popover trigger={'click'} title={'Телефоны:'} content={contentPhones}>
						<Button type={'dashed'}>Показать</Button>
					</Popover>
				);
			},
		},
		{
			width: 250,
			dataIndex: 'verified',
			title: 'Верифицирован',
			align: 'center',
			render: (_, record) => {
				return record.verified ? (
					<span style={{ display: 'inline-flex', alignItems: 'center', columnGap: 15 }}>
						<CheckCircleOutlined /> <Tag>{record.active_passed?.format('DD MMMM YYYY')}</Tag>
					</span>
				) : (
					<Tag>Не верифицирован</Tag>
				);
			},
		},

		{
			width: 250,
			title: 'Действия',
			align: 'center',
			render: (_, record) => {
				return (
					<Dropdown
						trigger={['click']}
						placement={'bottom'}
						menu={{
							items,
							onClick: ({ key }) => {
								if (key === actions.edit) {
									handleOnEditContractor(record);
									return;
								}
							},
						}}
					>
						<Button
							icon={<HolderOutlined />}
							type={'default'}
							style={{ display: 'inline-flex', justifyContent: 'center', alignItems: 'center' }}
						/>
					</Dropdown>
				);
			},
		},
	];
};
