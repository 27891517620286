import { SearchOutlined } from '@ant-design/icons';
import { Button, Checkbox, DatePicker, Input, Tag, Tooltip } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { Observer } from 'mobx-react-lite';

import { ItemObjectTypesMapping, ItemObjectTypesMappingShort } from '../../../../../constants';
import { ItemTypes, ItemTypesDrawerMapping } from '../../../../../constants/item-types';
import { useStores } from '../../../../../stores';
import { UserItemStore } from '../../../../../stores/user-items';
import { COLORS } from '../../../../../stylesheets';

export const useColumnsTaskExchange = (): ColumnsType<UserItemStore> => {
	const { userItemsStore, itemLocalStatusesStore, itemTypesStore, itemObjectTypesStore } = useStores();

	const getType = (row: UserItemStore) => {
		return ItemTypesDrawerMapping[row.item_type_id];
	};

	const getColorType = (row: UserItemStore) => {
		switch (row.item_type_id) {
			case ItemTypes.object:
				return 'cyan';
			case ItemTypes.volume:
				return 'geekblue';
			case ItemTypes.tasks:
				return 'purple';
			default:
				return 'magenta';
		}
	};

	const getObjectType = (row: UserItemStore) => {
		if (row.item_type_id === ItemTypes.object && row.item_object_type_id) {
			return ItemObjectTypesMappingShort[row.item_object_type_id];
		}

		if (row?.item_object_type_id && [ItemTypes.volume, ItemTypes.tasks].includes(row.item_type_id)) {
			return ItemObjectTypesMappingShort[row.item_object_type_id];
		}
	};

	const getObjectTypeTitle = (row: UserItemStore) => {
		if (row.item_type_id === ItemTypes.object && row.item_object_type_id) {
			return ItemObjectTypesMapping[row.item_object_type_id];
		}

		if (row?.parent_object?.item_object_type_id && [ItemTypes.volume, ItemTypes.tasks].includes(row.item_type_id)) {
			return ItemObjectTypesMapping[row.parent_object.item_object_type_id];
		}
	};

	const getCipher = (row: UserItemStore) => {
		if ([ItemTypes.object, ItemTypes.volume].includes(row.item_type_id)) {
			return row.cipher;
		}

		return '-';
	};

	return [
		{
			title: 'Тип',
			align: 'center',
			width: 100,
			render: (_, row) => <Tag color={getColorType(row)}>{getType(row)}</Tag>,
			filterDropdown: ({ setSelectedKeys, confirm, selectedKeys }) => {
				return (
					<div>
						{itemTypesStore.list.map((itemTypeStore) => {
							return (
								<div key={itemTypeStore.id}>
									<Checkbox
										value={itemTypeStore.id}
										checked={selectedKeys.includes(itemTypeStore.id)}
										onChange={(e) => {
											const { value } = e.target;
											const findIndex = selectedKeys.findIndex((item) => item === value);

											// Записываем или удаляем выбранный вариант
											if (findIndex === -1) {
												const complete = [...selectedKeys, ...[value]] as Array<number>;
												setSelectedKeys(complete);

												// Итоговый вариант записываем в фильтры
												userItemsStore.table.addFilter('item_type_id', complete);
											} else {
												selectedKeys.splice(findIndex, 1);
												setSelectedKeys([...selectedKeys]);

												// Итоговый вариант записываем в фильтры
												userItemsStore.table.addFilter('item_type_id', selectedKeys as Array<number>);
											}
										}}
									>
										{itemTypeStore.name}
									</Checkbox>
								</div>
							);
						})}

						<Button
							type={'primary'}
							style={{ width: '100%', marginTop: 20 }}
							onClick={async () => {
								confirm();
							}}
						>
							Найти
						</Button>
					</div>
				);
			},
		},
		{
			title: 'Тип сетей',
			align: 'center',
			width: 140,
			render: (_, row) => (
				<Tooltip placement={'top'} title={getObjectTypeTitle(row)}>
					<Tag color={COLORS.BLUE_100}>{getObjectType(row)}</Tag>
				</Tooltip>
			),
		},
		{
			title: 'Заявок',
			align: 'center',
			width: 140,
			render: (_, row) => row.task_requests_count,
		},
		{
			title: 'Название',
			align: 'center',
			ellipsis: true,
			render: (_, row) => row.title,
			filterIcon: () => {
				return <SearchOutlined />;
			},
			filterDropdown: ({ selectedKeys, setSelectedKeys, confirm }) => {
				return (
					<Input
						autoFocus
						placeholder={'Минимум 3 символа'}
						name={'title'}
						allowClear
						value={selectedKeys[0] as string}
						onChange={async (e) => {
							const { value, name } = e.target;
							setSelectedKeys(value ? [value] : []);
							userItemsStore.table.addFilter(name, value);

							if (value.length > 0 && value.length < 3) {
								return false;
							}
						}}
						onBlur={() => confirm()}
					/>
				);
			},
		},
		{
			title: 'Начало / Заершение',
			align: 'center',
			width: 300,
			render: (_, row) => {
				return (
					<Observer>
						{() => {
							return (
								<span>
									{row.start_date?.format('DD MMMM YYYY')} - {row.end_date?.format('DD MMMM YYYY')}
								</span>
							);
						}}
					</Observer>
				);
			},
			filterDropdown: ({ setSelectedKeys, confirm }) => {
				return (
					<div>
						<DatePicker.RangePicker
							picker={'date'}
							format={'DD MMM YYYY'}
							onChange={(e) => {
								if (Array.isArray(e)) {
									const [dateStart, dateEnd] = e;

									if (dateStart && dateEnd) {
										setSelectedKeys([dateStart.toISOString(), dateEnd.toISOString()]);

										userItemsStore.table.addFilter('date_range', [dateStart.toISOString(), dateEnd.toISOString()]);
									}
								} else {
									setSelectedKeys([]);
									userItemsStore.table.addFilter('date_range', '');
								}
							}}
						/>

						<Button
							type={'primary'}
							style={{ width: '100%', marginTop: 20 }}
							onClick={async () => {
								confirm();
							}}
						>
							Найти
						</Button>
					</div>
				);
			},
		},
		{
			title: 'Прием заявок',
			align: 'center',
			width: 300,
			render: (_, row) => {
				return (
					<Observer>
						{() => {
							return <span>{row.deadline_execute_date?.format('DD MMMM YYYY')}</span>;
						}}
					</Observer>
				);
			},
		},
	];
};
