import { Menu } from 'antd';
import styled from 'styled-components';

export const MenuExtra = styled(Menu)`
	margin-bottom: 25px;
	border: 0 !important;

	.ant-menu-item-group-title {
		padding-bottom: 0;
	}
`;
