import { useEffect } from 'react';

import { Button, Modal, Spin, Typography } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { observer } from 'mobx-react-lite';

import { Container } from './ItemProjectDocumentationModal.styled';
import { getButtonName, getTitle } from '../../../../helpers';
import { useStores } from '../../../../stores';
import { ItemProjectDocumentationForm } from '../../Forms/ItemProjectDocumentationForm/ItemProjectDocumentationForm';

export const ItemProjectDocumentationModal = observer(() => {
	const { itemProjectDocumentsStore, rolesStore } = useStores();
	const { detail } = itemProjectDocumentsStore;

	const [form] = useForm();

	useEffect(() => {
		(async () => {
			//  Получаем все роли
			await rolesStore.all.getList();
		})();
	}, []);

	const handleOnClose = () => {
		detail.modal.close();
		itemProjectDocumentsStore.clearDetail();
	};

	const handleOnDelete = async () => {
		try {
			await detail.data.delete(true);

			handleOnClose();

			await itemProjectDocumentsStore.table.getList();
		} catch (e) {
			console.error(`Error in method handleOnDelete: `, e);
		}
	};

	return (
		<>
			<Modal
				forceRender
				open={detail.modal.isShow}
				title={
					<Typography.Title
						level={5}
						ellipsis={{
							rows: 1,
							tooltip: detail.data.name,
						}}
						style={{ paddingRight: 20 }}
					>
						{getTitle('проектной документации', detail.data.isNewStore, detail.data.name)}
					</Typography.Title>
				}
				width={1200}
				styles={{
					body: {
						height: 700,
						overflowY: 'auto',
						overflowX: 'hidden',
					},
				}}
				onCancel={handleOnClose}
				footer={[
					<Button key={'cancel'} loading={detail.data.isLoading} onClick={handleOnClose}>
						Отмена
					</Button>,

					detail.data.isNotNewStore && (
						<Button
							key={'delete-confirm'}
							type={'primary'}
							danger
							onClick={() =>
								Modal.confirm({
									title: `Удаление проектной документации : ${detail.data.name}`,
									content:
										'Внимание! Вы удаляете проектную документацию. Если Вы точно уверены что она Вам ненужна, нажмите кнопку Удалить безвозвратно.',
									centered: true,
									width: 700,
									okText: 'Удалить безвозвратно',
									okButtonProps: {
										type: 'primary',
										danger: true,
										loading: detail.data.isLoading,
									},
									onOk: async (close) => {
										await handleOnDelete();
									},
									footer: (_, { OkBtn, CancelBtn }) => {
										return (
											<>
												<CancelBtn />
												<OkBtn />
											</>
										);
									},
								})
							}
						>
							Удалить
						</Button>
					),
					<Button key={'action'} type={'primary'} onClick={form.submit} loading={detail.data.isLoading}>
						{getButtonName(detail.data.isNewStore)}
					</Button>,
				]}
			>
				<Spin spinning={detail.data.isDataLoadedProcess} tip={`Загрузка`}>
					<Container>
						<ItemProjectDocumentationForm form={form} />
					</Container>
				</Spin>
			</Modal>
		</>
	);
});
