import React from 'react';

import { App, ConfigProvider } from 'antd';
import ruRU from 'antd/locale/ru_RU';
import 'antd/dist/reset.css';
import 'dayjs/locale/ru';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import { AppSvep } from './App';
import {
	GenerateVolumesModal,
	ItemViewTreeModal,
	ObjectDetailModal,
	ObjectModal,
	TaskDetailModal,
	VolumeDetailModal,
	VolumeModal,
} from './components';
import { TaskModal } from './components/ui/Modals/TaskModal/TaskModal';
import { COLORS, GlobalStyled } from './stylesheets';

dayjs.extend(utc);
dayjs.locale('ru');

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
	/*<React.StrictMode>*/
	<ConfigProvider
		locale={ruRU}
		theme={{
			token: {
				colorPrimary: COLORS.BLUE_100,
				fontFamily: 'Brutal Type',
				colorLink: COLORS.BLUE_100,
				colorLinkHover: COLORS.BLUE_80,
				colorLinkActive: COLORS.BLUE_100,
			},
			components: {},
		}}
	>
		<BrowserRouter>
			<App>
				<AppSvep />
			</App>
			<GlobalStyled />

			{/**
			 * Drawers
			 */}

			{/*<CountUsers />*/}

			<ObjectModal />
			<VolumeModal />
			<TaskModal />
			<GenerateVolumesModal />

			{/**
       Детальные просмотры
       */}
			<TaskDetailModal />
			<VolumeDetailModal />
			<ObjectDetailModal />

			<ItemViewTreeModal />
		</BrowserRouter>
	</ConfigProvider>,
	/*</React.StrictMode>,*/
);
