import dayjs from 'dayjs';
import { action, makeObservable, observable } from 'mobx';

import { ObjectContractorFileStore } from './object-contractor-file.store';
import { ObjectContractorsI } from '../../interfaces';
import { CrudStore } from '../common/crud.store';
import { ContractorStore } from '../contractors/contractor.store';

export class ObjectContractorStore extends CrudStore<ObjectContractorsI> implements ObjectContractorsI {
	@observable object_id = 0;
	@observable contractor_id = 0;
	@observable subject_contract = '';
	@observable contractor = new ContractorStore(null);
	@observable files: ObjectContractorFileStore[] = [];
	@observable created_at = dayjs();
	@observable updated_at = dayjs();

	constructor(initialData: ObjectContractorsI | null) {
		super();
		makeObservable(this);
		if (initialData) {
			this.fillStore(initialData);
		}
	}

	@action.bound
	deleteFile(id: number) {
		this.files = (this.files || []).filter((file) => file.id !== id);
	}

	@action
	fillStore(data: ObjectContractorsI) {
		const { id, object_id, contractor_id, subject_contract, contractor, files, created_at, updated_at } = data;

		this.id = id;
		this.object_id = object_id;
		this.contractor_id = contractor_id;
		this.subject_contract = subject_contract;
		this.contractor = new ContractorStore(contractor);
		this.files = (files || []).map((file) => new ObjectContractorFileStore(file));
		this.created_at = dayjs(created_at);
		this.updated_at = dayjs(updated_at);
	}
}
