import styled from 'styled-components';

import { fontMixin } from '../../../../../stylesheets';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	row-gap: 15px;
	margin-top: auto;
	justify-content: center;
`;

export const Version = styled.div`
	display: flex;
	justify-content: center;
	${fontMixin(12, 300)}
`;
