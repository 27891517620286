import { PlusOutlined } from '@ant-design/icons';
import { Button, Col, Form, FormInstance, Row } from 'antd';
import { observer } from 'mobx-react-lite';

import { VolumeList } from './VolumeList';

interface VolumesProps {
	form: FormInstance;
}

export const Volumes = observer(({ form }: VolumesProps) => {
	return (
		<>
			<Form.List name={'item_volume_generates'}>
				{(fields, { add, remove }) => {
					return (
						<>
							<Row>
								<Col span={8} offset={8}>
									<Form.Item>
										<Button type="primary" onClick={() => add()} block icon={<PlusOutlined />}>
											Добавить том
										</Button>
									</Form.Item>
								</Col>
							</Row>

							<VolumeList fields={fields} form={form} onRemoveItem={remove} />
						</>
					);
				}}
			</Form.List>
		</>
	);
});
