import dayjs from 'dayjs';
import { action, makeObservable, observable } from 'mobx';

import { PermissionI } from '../../interfaces/permission';
import { CrudStore } from '../common/crud.store';

export class PermissionStore extends CrudStore<PermissionI> implements PermissionI {
	PATH = 'permissions';

	@observable parent_id = 0;
	@observable name = '';
	@observable description = '';
	@observable guard_name = '';
	@observable created_at = dayjs();
	@observable updated_at = dayjs();
	@observable children: Array<PermissionStore> = [];

	constructor(initialData: PermissionI | null) {
		super();
		makeObservable(this);

		if (initialData) {
			this.fillStore(initialData);
		}
	}

	@action.bound
	deleteChildById(id: number) {
		this.children = this.children.filter((permission) => permission.id !== id);
	}

	@action
	fillStore(data: PermissionI) {
		const { id, parent_id, name, description, guard_name, created_at, updated_at, children } = data;

		this.id = id;
		this.parent_id = parent_id;
		this.name = name;
		this.description = description;
		this.guard_name = guard_name;
		this.created_at = dayjs(created_at);
		this.updated_at = dayjs(updated_at);
		this.children = (children || []).map((permission) => new PermissionStore(permission));
	}
}
