import { useEffect, useState } from 'react';

import { Modal, notification } from 'antd';
import { observer } from 'mobx-react-lite';

import { numWords } from '../../../../helpers';
import { VolumeI, WsPayload, WsTypeEvent } from '../../../../interfaces';
import { useStores } from '../../../../stores';
import { VolumeStore } from '../../../../stores/volume/volume.store';

export const GenerateVolumesModal = observer(() => {
	const { generateVolumesStore, objectsStore, websocketStore } = useStores();
	const { selected: currentObject } = objectsStore;
	const [checked, setChecked] = useState<Array<number>>(generateVolumesStore.allKeyID);

	useEffect(() => {
		setChecked(generateVolumesStore.allKeyID);
	}, [generateVolumesStore.list]);

	// Закрытие модального окна
	const handleOnClose = () => {
		generateVolumesStore.modal.close();
	};

	// Обработчик для выбранного пункта
	//const handleOnChecked = (values: Array<number>) => setChecked(values);

	// Генерация томов
	const handleOnCreate = async () => {
		generateVolumesStore.setIsLoading(true);

		let sort = 1;
		let countCreateVolume = 0;

		for (let i = 0; i < checked.length; i++) {
			const findVolume = generateVolumesStore.list.find((item) => item.id === checked[i]);

			if (findVolume) {
				const selectedVolume = new VolumeStore(null);
				await selectedVolume.create(
					{
						sort: sort++,
						object_id: currentObject.id,
						name_volume: findVolume.note,
						cipher: findVolume.nomination,
						responsible_id: findVolume.responsible_id,
						start_date: currentObject.start_date,
						end_date: currentObject.end_date,
					} as VolumeI,
					false,
				);

				objectsStore.selected.volumes.add(selectedVolume);
				objectsStore.selected.volumes_count += 1;
				countCreateVolume += 1;

				// Отправляем созданный или обновленный объект
				websocketStore.sendMessage<WsPayload>({
					event: WsTypeEvent.volume,
					payload: {
						type: WsTypeEvent.volume,
						id: selectedVolume.id,
						title: `${currentObject.cipher}-${findVolume.nomination}`,
						responsible_id: selectedVolume.responsible_id,
					},
				});
			}
		}

		notification.success({
			message: `Успешно добавлено ${countCreateVolume} ${numWords(countCreateVolume, ['том', 'тома', 'томов'])}`,
		});

		generateVolumesStore.setIsLoading(false);
		generateVolumesStore.modal.close();
	};

	return (
		<Modal
			open={generateVolumesStore.modal.isShow}
			title={`Генерация томов (${currentObject.project_documentation.name})`}
			width={1200}
			styles={{
				body: {
					height: 700,
					overflowY: 'auto',
				},
			}}
			okText={'Сгенерировать'}
			onCancel={handleOnClose}
			onOk={handleOnCreate}
		/>
	);
});
