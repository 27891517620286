import { Badge, Popover } from 'antd';
import { observer } from 'mobx-react-lite';

import { Container } from './StatusConnection.styled';
import { UserListOnline } from './UserListOnline';
import { useStores } from '../../../stores';

export const StatusConnection = observer(() => {
	const { websocketStore, userOnlineStore } = useStores();

	const getContentBlock = () => {
		return (
			<>
				<Popover trigger="click" title={'Кто онлайн'} content={<UserListOnline />}>
					Вы онлайн из {userOnlineStore.count} сотрудников.
				</Popover>
			</>
		);
	};

	return (
		<Container>
			{websocketStore.isConnection && <Badge status={'success'} text={getContentBlock()} />}
			{!websocketStore.isConnection && <Badge status={'error'} text={`Подключение к системе...`} />}
		</Container>
	);
});
