import {
	CopyOutlined,
	EditOutlined,
	EyeOutlined,
	HolderOutlined,
	PlusOutlined,
	SearchOutlined,
} from '@ant-design/icons';
import { Avatar, Button, Dropdown, Input, MenuProps, Popover, Tag, Tooltip, Typography } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { Observer } from 'mobx-react-lite';

import { ItemDropDownKey } from '../../../../../constants';
import { formattedPrice, getInformationUser, IconEditItem } from '../../../../../helpers';
import { MenuInfoAntD } from '../../../../../interfaces';
import { useStores } from '../../../../../stores';
import { ItemListStore } from '../../../../../stores/item/item-list.store';
import { COLORS } from '../../../../../stylesheets';
import { AvatarExtra } from '../../../../layouts/CabinetLayout/Sider/UserBlock/UserBlock.styled';

export const useItemsTableColumns = (): ColumnsType<ItemListStore> => {
	const { authStore, itemsStore, itemProjectGenerateStore, itemDisabledStore } = useStores();

	const items: MenuProps['items'] = [
		{
			key: ItemDropDownKey.View,
			label: (
				<Button type={'link'} icon={<EyeOutlined />}>
					Просмотр
				</Button>
			),
		},
		{
			key: ItemDropDownKey.Edit,
			label: (
				<Button type={'link'} icon={<EditOutlined />}>
					Редактировать
				</Button>
			),
		},
		{
			key: ItemDropDownKey.CreateVolume,
			label: (
				<Button type={'link'} icon={<PlusOutlined />}>
					Создать том
				</Button>
			),
		},
		{
			key: ItemDropDownKey.GenerateVolume,
			label: (
				<Button type={'link'} icon={<CopyOutlined />}>
					Генерация томов
				</Button>
			),
		},
	];

	const handleOnClick = async (e: MenuInfoAntD, row: ItemListStore) => {
		if (e.key === ItemDropDownKey.Edit) {
			// Очищаем выбранный объект
			itemsStore.clearSelected();

			// Устанавливаем ID объекта
			itemsStore.selected.setId(row.id);

			// Получаем информацию
			await itemsStore.selected.get();

			// Открваем окно
			itemsStore.selected.drawer.show();

			return;
		}

		// Создание тома
		else if (e.key === ItemDropDownKey.CreateVolume) {
			// Очищаем выбранный объект
			itemsStore.clearSelected();

			// Устанавливаем тип объекта
			itemsStore.selected.setTypeVolume();

			// Устаналиваем родителя
			itemsStore.selected.createParentObjectWrapper();
			itemsStore.selected.parent_object?.setId(row.id);
			itemsStore.selected.parent_object?.setChildrenShow(row.childrenShow);

			// Получаем информацию по родителю
			await itemsStore.selected.parent_object?.get();

			// Устанавливаем тип сетей
			if (itemsStore.selected.parent_object?.item_object_type_id) {
				itemsStore.selected.setItemObjectType(itemsStore.selected.parent_object?.item_object_type_id);
			}

			// Открываем окно создания
			itemsStore.selected.drawer.show();

			return;
		}

		// Просмотр объекта
		else if (e.key === ItemDropDownKey.View) {
			// Очищаем выбранный объект
			itemsStore.clearSelected();

			// Устанавливаем ID объекта
			itemsStore.selected.setId(row.id);

			// Получаем информацию
			await itemsStore.selected.get();

			// Показвываем детальную информацию
			itemsStore.selected.viewer.show();

			return;
		}

		// Генерация томов
		else if (e.key === ItemDropDownKey.GenerateVolume) {
			// Очищаем
			itemProjectGenerateStore.clearSelectedObject();

			// Устанавливаем ID объекта
			itemProjectGenerateStore.selectedObject.setId(row.id);

			// Открываем окно
			itemProjectGenerateStore.modal.open();

			// Получаем информацию
			await itemProjectGenerateStore.selectedObject.get();
		}
	};

	const columns: any = [
		{
			title: 'Шифр',
			align: 'center',
			dataIndex: 'cipher',
			render: (value, row) => (
				<Observer>
					{() => {
						return (
							<Typography.Title
								style={{
									color: COLORS.BLUE_100,
									fontSize: 12,
									margin: 0,
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
									columnGap: 10,
								}}
								ellipsis={true}
							>
								<IconEditItem item={row} />

								{value}
							</Typography.Title>
						);
					}}
				</Observer>
			),
			filterIcon: () => {
				return <SearchOutlined />;
			},
			filterDropdown: ({ selectedKeys, setSelectedKeys, confirm }) => {
				return (
					<Input
						autoFocus
						placeholder={'Минимум 3 символа'}
						name={'cipher'}
						allowClear
						value={selectedKeys[0] as string}
						onChange={async (e) => {
							const { value, name } = e.target;
							setSelectedKeys(value ? [value] : []);
							itemsStore.table.addFilter(name, value);

							if (value.length > 0 && value.length < 3) {
								return false;
							}
						}}
						onBlur={() => confirm()}
					/>
				);
			},
		},
		{
			title: 'Титул проекта',
			align: 'center',
			dataIndex: 'title',
			ellipsis: {
				showTitle: false,
			},
			render: (value) => {
				return (
					<Tooltip placement={'topLeft'} title={value} trigger={'hover'}>
						{value}
					</Tooltip>
				);
			},
			filterDropdown: ({ selectedKeys, setSelectedKeys, confirm }) => {
				return (
					<Input
						autoFocus
						placeholder={'Минимум 3 символа'}
						name={'title'}
						allowClear
						value={selectedKeys[0] as string}
						onChange={async (e) => {
							const { value, name } = e.target;
							setSelectedKeys(value ? [value] : []);
							itemsStore.table.addFilter(name, value);

							if (value.length > 0 && value.length < 3) {
								return false;
							}
						}}
						onBlur={() => confirm()}
					/>
				);
			},
			filterIcon: () => {
				return <SearchOutlined />;
			},
		},
		{
			title: 'Статус',
			align: 'center',
			width: 150,
			render: (_, record) => {
				return (
					<Typography.Title style={{ color: COLORS.BLUE_100, fontSize: 12 }} ellipsis={true}>
						{record.local_status.status}
					</Typography.Title>
				);
			},
		},
		{
			title: 'Участники',
			align: 'center',
			render: (_, row) => {
				return (
					<Avatar.Group>
						{row.creator.isExist && (
							<Popover title={'Ответственный'} content={getInformationUser(row.creator)}>
								<AvatarExtra style={{ background: COLORS.BLUE_80 }}>{row.creator.initials}</AvatarExtra>
							</Popover>
						)}

						{row.responsible.isExist && (
							<Popover
								title={'Исполнитель'}
								content={
									row.responsible.isContractor
										? getInformationUser(row.responsible)
										: getInformationUser(row.responsible)
								}
							>
								<AvatarExtra style={{ background: COLORS.BLUE_100 }}>{row.responsible.initials}</AvatarExtra>
							</Popover>
						)}
					</Avatar.Group>
				);
			},
		},
		{
			title: 'Начало / Завершение',
			align: 'center',
			render: (_, row) => {
				return (
					<span>
						{row.startDateFormatted} - {row.endDateFormatted}
					</span>
				);
			},
		},
		{
			title: 'Цена',
			align: 'center',
			dataIndex: 'price',
			render: (value) => formattedPrice(value),
			filterDropdown: ({ selectedKeys, setSelectedKeys, confirm }) => {
				return (
					<Input
						autoFocus
						placeholder={'Минимум 3 символа'}
						name={'price'}
						allowClear
						value={selectedKeys[0] as string}
						onChange={async (e) => {
							const { value, name } = e.target;
							setSelectedKeys(value ? [value] : []);
							itemsStore.table.addFilter(name, value);

							if (value.length > 0 && value.length < 3) {
								return false;
							}
						}}
						onBlur={() => confirm()}
					/>
				);
			},
			filterIcon: () => {
				return <SearchOutlined />;
			},
		},
		{
			title: 'Количество томов',
			align: 'center',
			render: (_, row) => {
				return row.children_count;
			},
		},
		{
			title: 'Номер договора',
			align: 'center',
			dataIndex: 'contract_number',
			width: 200,
			render: (value) => {
				return value ? (
					<Typography.Title style={{ color: COLORS.BLUE_100, fontSize: 14 }} ellipsis={true}>
						{value}
					</Typography.Title>
				) : (
					<Tag>Не указан</Tag>
				);
			},
			filterDropdown: ({ selectedKeys, confirm }) => {
				return (
					<Input
						autoFocus
						placeholder={'Минимум 3 символа'}
						name={'contract_number'}
						allowClear
						value={selectedKeys[0] as string}
						onBlur={() => confirm()}
					/>
				);
			},
			filterIcon: () => {
				return <SearchOutlined />;
			},
		},
		{
			title: 'Действия',
			align: 'center',
			width: 100,
			render: (_, row) => {
				if (row.is_delete) {
					return (
						<Button
							type={'link'}
							icon={<EyeOutlined />}
							onClick={() => {
								//itemsStore.setSelected(row);
								//itemsStore.selected.viewer.show();
							}}
						/>
					);
				}

				return (
					<Dropdown
						menu={{
							onClick: (e) => handleOnClick(e, row),
							items,
						}}
						trigger={['click']}
					>
						<Button
							icon={<HolderOutlined />}
							type={'default'}
							style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', margin: '0 auto' }}
						/>
					</Dropdown>
				);
			},
		},
	];

	return columns;
};
