import { Table } from 'antd';
import { observer } from 'mobx-react-lite';

import { useColumns } from './RolesTable.columns';
import { useStores } from '../../../../stores';

export const RolesTable = observer(() => {
	const { rolesStore } = useStores();

	return (
		<Table
			rowKey={'id'}
			columns={useColumns()}
			dataSource={rolesStore.table.list}
			loading={rolesStore.table.isLoading}
			pagination={{
				total: rolesStore.table.pagination.total,
				pageSize: rolesStore.table.pagination.per_page,
				onChange: (page) => rolesStore.table.pagination.setCurrentPage(page),
			}}
		/>
	);
});
