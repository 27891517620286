import { Checkbox, DatePicker, Form, Input, Select, Tooltip } from 'antd';
import { RangePickerProps } from 'antd/es/date-picker';
import { ColumnsType } from 'antd/es/table';

import { DATE_FORMAT, Forms } from '../../../../../constants';
import { useStores } from '../../../../../stores';
import { ItemVolumeGenerateStore } from '../../../../../stores/item-volume-generate';

export const useColumns = (
	itemVolumeGenerateStores: ItemVolumeGenerateStore[],
): ColumnsType<ItemVolumeGenerateStore> => {
	const { itemProjectGenerateStore, employeesStore } = useStores();
	const { selectedObject } = itemProjectGenerateStore;

	const disabledDate: RangePickerProps['disabledDate'] = (current) => {
		if (selectedObject.start_date && selectedObject.end_date) {
			return selectedObject.start_date.isAfter(current) || selectedObject.end_date.isBefore(current);
		}

		return false;
	};

	console.log(itemProjectGenerateStore);

	return [
		{
			title: 'Завершающий',
			dataIndex: 'is_finish',
			align: 'center',
			render: (value, _, index) => {
				return (
					<Form.Item name={[Forms.ProjectGenerate.BaseForm, index, 'is_finish']} valuePropName={'checked'}>
						<Checkbox />
					</Form.Item>
				);
			},
		},
		{
			title: 'Обозначение',
			align: 'center',
			width: 200,
			dataIndex: 'number_section',
			render: (value, _, index) => {
				return (
					<>
						<Form.Item
							name={[Forms.ProjectGenerate.BaseForm, index, Forms.ProjectGenerate.Fields.NumberSection]}
							hidden
						>
							<Input type="text" />
						</Form.Item>

						{value}
					</>
				);
			},
		},
		{
			title: 'Наименование',
			align: 'center',
			ellipsis: true,
			width: 250,
			dataIndex: 'nomination',
			render: (value, _, index) => {
				return (
					<>
						<Form.Item name={[Forms.ProjectGenerate.BaseForm, index, Forms.ProjectGenerate.Fields.Nomination]} hidden>
							<Input type="text" />
						</Form.Item>

						<Tooltip placement={'bottomLeft'} title={value}>
							{value}
						</Tooltip>
					</>
				);
			},
		},
		{
			title: 'Дата окончания',
			align: 'center',
			width: 350,
			render: (_, record, index) => {
				return (
					<Form.Item
						name={[Forms.ProjectGenerate.BaseForm, index, Forms.ProjectGenerate.Fields.VolumeDates]}
						rules={[{ required: true, message: 'Введите дату начала и окончания тома' }]}
						style={{ marginBottom: 0 }}
					>
						<DatePicker.RangePicker disabledDate={disabledDate} format={DATE_FORMAT.FULL} />
					</Form.Item>
				);
			},
		},
		{
			title: 'Исполнитель',
			align: 'center',
			width: 200,
			render: (_, itemVolumeTaskStore, index) => {
				const role_id = itemVolumeGenerateStores[index]?.payload?.role_id || 0;

				return (
					<>
						<Form.Item
							name={[Forms.ProjectGenerate.BaseForm, index, Forms.ProjectGenerate.Fields.ResponsibleId]}
							rules={[{ required: true, message: 'Выберите исполнителя' }]}
						>
							<Select
								style={{ width: 180 }}
								placeholder={'Выберите исполнителя'}
								showSearch
								filterOption={(inputValue, option) => {
									return String(option?.children ?? '')
										.toLowerCase()
										.includes(inputValue.toLowerCase());
								}}
							>
								{employeesStore.all.list
									.filter((employee) => employee.role.id === Number(role_id))
									.map((resultItem) => {
										return (
											<Select.Option key={resultItem.id} value={resultItem.id}>
												{resultItem.shortFullName} ({resultItem.roleName})
											</Select.Option>
										);
									})}
							</Select>
						</Form.Item>
					</>
				);
			},
		},
		{
			title: 'Примечание',
			align: 'center',
			dataIndex: 'note',
			ellipsis: true,
			render: (value, _, index) => {
				return (
					<>
						<Form.Item name={[Forms.ProjectGenerate.BaseForm, index, Forms.ProjectGenerate.Fields.Note]} hidden>
							<Input type="text" />
						</Form.Item>
						<Tooltip placement={'bottomLeft'} title={value}>
							{value}
						</Tooltip>
					</>
				);
			},
		},
	];
};
