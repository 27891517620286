import { EditOutlined, HolderOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Dropdown, MenuProps, Popover, Tag, Typography } from 'antd';
import { ColumnsType } from 'antd/es/table';

import { actions } from '../../../../constants';
import { useStores } from '../../../../stores';
import { EmployeeStore } from '../../../../stores/employees/employee.store';
import { AvatarExtra } from '../../../layouts/CabinetLayout/Sider/UserBlock/UserBlock.styled';

export const useColumns = (): ColumnsType<EmployeeStore> => {
	const { employeesStore } = useStores();

	const handleOnEditEmployee = (record: EmployeeStore) => {
		employeesStore.setSelected(record);
		employeesStore.drawer.show();
	};

	const items: MenuProps['items'] = [
		{
			key: actions.edit,
			label: (
				<Button type={'link'} icon={<EditOutlined />}>
					Редактировать
				</Button>
			),
		},
	];

	return [
		{
			title: 'ID',
			dataIndex: 'id',
			align: 'center',
			width: 40,
		},
		{
			dataIndex: 'avatar',
			align: 'center',
			width: 40,
			render: (_, row) => {
				return <AvatarExtra icon={<UserOutlined />} src={`${row.avatarFullPath}`} size={50} />;
			},
		},
		{
			title: 'ФИО',
			align: 'center',
			width: 250,
			render: (_, record) => {
				return record.shortFullName;
			},
		},
		{
			title: 'Роль (Должность)',
			align: 'center',
			width: 250,
			render: (_, record) => {
				return record.roleName;
			},
		},
		{
			title: 'Телефон',
			align: 'center',
			width: 100,
			render: (_, record) => {
				if (record.phones.length === 0) {
					return <Tag>Не указан</Tag>;
				}

				const contentPhones = record.phones.map((phoneStore) => {
					return (
						<div key={phoneStore.id} style={{ display: 'flex', justifyContent: 'space-between' }}>
							<Typography.Text style={{ marginRight: 10 }}>
								{phoneStore.name} <span></span>
							</Typography.Text>
							<Typography.Link href={`tel:${phoneStore.phone}`}>{phoneStore.phone}</Typography.Link>
						</div>
					);
				});

				return (
					<Popover trigger={'click'} title={'Телефоны:'} content={contentPhones}>
						<Button type={'dashed'}>Показать</Button>
					</Popover>
				);
			},
		},
		{
			title: 'День рождения',
			align: 'center',
			width: 100,
			render: (_, record) => {
				return record.formattedBirthday;
			},
		},
		{
			title: 'Последний вход',
			align: 'center',
			width: '100px',
			render: (_, record) => {
				return record.formattedLastEnter;
			},
		},
		{
			title: 'Действия',
			align: 'center',
			width: 100,
			render: (_, record) => {
				return (
					<>
						<Dropdown
							trigger={['click']}
							menu={{
								items,
								onClick: async ({ key }) => {
									if (key === actions.edit) {
										await handleOnEditEmployee(record);
										return;
									}
								},
							}}
						>
							<Button
								icon={<HolderOutlined />}
								type={'default'}
								style={{ display: 'inline-flex', justifyContent: 'center', alignItems: 'center' }}
							/>
						</Dropdown>
					</>
				);
			},
		},
	];
};
