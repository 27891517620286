import { UserOutlined, VideoCameraOutlined } from '@ant-design/icons';
import { observer } from 'mobx-react-lite';

import { UserListOnlineButton, UserListOnlineContainer, UserListOnlineRow } from './UserListOnline.styled';
import { useStores } from '../../../../stores';
import { AvatarExtra } from '../../../layouts/CabinetLayout/Sider/UserBlock/UserBlock.styled';

interface UserListOnlineProps {}

export const UserListOnline = observer(({}: UserListOnlineProps) => {
	const { userOnlineStore } = useStores();

	return (
		<UserListOnlineContainer>
			{userOnlineStore.list.map((user) => {
				return (
					<UserListOnlineRow>
						<AvatarExtra icon={<UserOutlined />} src={user.avatarFullPath} size={25} style={{ marginBottom: 0 }} />
						{user.first_name ? user.shortFullName : user.email}
						<UserListOnlineButton>
							<VideoCameraOutlined />
						</UserListOnlineButton>
					</UserListOnlineRow>
				);
			})}
		</UserListOnlineContainer>
	);
});
