import { Col, DatePicker, Form, FormInstance, Input, InputNumber, Row, Select } from 'antd';
import { useWatch } from 'antd/es/form/Form';
import { observer } from 'mobx-react-lite';

import { AgreementContractorList } from './AgreementContractorList/AgreementContractorList';
import { DATE_FORMAT } from '../../../../constants';
import { getIconCurrency } from '../../../../helpers';
import { Agreement } from '../../../../interfaces';
import { useStores } from '../../../../stores';

interface AgreementFormProps {
	form: FormInstance;
}

const { RangePicker } = DatePicker;

export const AgreementForm = observer(({ form }: AgreementFormProps) => {
	const { agreementsStore, currenciesStore, itemsStore, agreementStatusesStore } = useStores();

	const watcherForm = useWatch([], form);

	const handleOnSave = async (values: Agreement) => {
		try {
			if (agreementsStore.selected.isNotNewStore) {
				await agreementsStore.selected.update(values);
			} else {
				await agreementsStore.selected.create(values);
			}

			agreementsStore.selected.setCleanForm(true);

			// Обновляем список
			await agreementsStore.table.getList();
		} catch (e) {
			/* empty */
		}
	};

	return (
		<Form
			form={form}
			layout={'vertical'}
			onFinish={handleOnSave}
			onValuesChange={() => agreementsStore.selected.setCleanForm(false)}
		>
			<Row gutter={15}>
				<Col span={24}>
					<Form.Item name={'item_id'} label={'Ссылка на объект'} rules={[{ required: false }]}>
						<Select
							placeholder={`Выберите проект`}
							showSearch
							allowClear
							filterOption={(inputValue, option) => {
								return String(option?.children ?? '')
									.toLowerCase()
									.includes(inputValue.toLowerCase());
							}}
						>
							{itemsStore.all.list?.map((item) => {
								return (
									<Select.Option key={item.id} value={item.id}>
										{item.title}
									</Select.Option>
								);
							})}
						</Select>
					</Form.Item>
				</Col>
			</Row>

			<Row gutter={15}>
				<Col span={24}>
					<Form.Item name={'title'} label={'Титул объекта'} rules={[{ required: true }]}>
						<Input.TextArea placeholder={'Введите титул'} autoSize={{ minRows: 1 }} />
					</Form.Item>
				</Col>
			</Row>

			<Row gutter={15}>
				<Col span={12}>
					<Form.Item name={'system_number'} label={'Системный номер'}>
						<Input placeholder={'Введите системный номер'} />
					</Form.Item>
				</Col>

				<Col span={12}>
					<Form.Item name={'status_id'} label={'Статус'}>
						<Select disabled={agreementsStore.selected.isNewStore} placeholder={'Выберите статус'}>
							{agreementStatusesStore.all.list.map((agreementStatus) => {
								return (
									<Select.Option key={agreementStatus.id} value={agreementStatus.id}>
										{agreementStatus.name}
									</Select.Option>
								);
							})}
						</Select>
					</Form.Item>
				</Col>
			</Row>

			<Row gutter={15}>
				<Col span={12}>
					<Form.Item name={'start_date'} label={'Дата начала'} rules={[{ required: true }]}>
						<DatePicker format={DATE_FORMAT.FULL} />
					</Form.Item>
				</Col>

				<Col span={12}>
					<Form.Item shouldUpdate style={{ margin: 0 }}>
						{() => {
							const fieldStartDate = form.getFieldValue('start_date');
							return (
								<Form.Item
									label={'Дата завершения'}
									name={'end_date'}
									rules={[
										{ required: true },
										{
											validator: async (_, workEndDate) => {
												if (workEndDate) {
													if (workEndDate.diff(fieldStartDate, 'days') <= 0) {
														return Promise.reject('Завершение работы < чем начало работ');
													}

													return Promise.resolve();
												} else {
													return Promise.resolve();
												}
											},
										},
									]}
								>
									<DatePicker
										format={DATE_FORMAT.FULL}
										disabledDate={(current) => {
											if (fieldStartDate && current) {
												return current && current < fieldStartDate.endOf('day');
											} else {
												return false;
											}
										}}
									/>
								</Form.Item>
							);
						}}
					</Form.Item>
				</Col>

				<Col span={24}>
					<Form.Item name={'price'} label={'Сумма'} rules={[{ required: true }]}>
						<InputNumber
							style={{ width: '100%' }}
							formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
							addonAfter={
								<Form.Item
									name="currency_id"
									noStyle
									rules={[{ required: true, message: 'Пожалуйста, выберите валюту' }]}
								>
									<Select style={{ width: 120 }}>
										{currenciesStore.all.list.map((currency) => {
											return (
												<Select.Option key={currency.id} value={currency.id}>
													{getIconCurrency(currency.code)}
												</Select.Option>
											);
										})}
									</Select>
								</Form.Item>
							}
							placeholder={'Введите сумму'}
						/>
					</Form.Item>
				</Col>
			</Row>

			<Row gutter={15}>
				<Col span={24}>
					<Form.Item name={'comment'} label={'Заметки о суб.подрядчиках'} rules={[{ required: false }]}>
						<Input.TextArea autoSize={{ minRows: 1 }} placeholder={'Введите заметки о суб.подрядчиках'} />
					</Form.Item>
				</Col>
			</Row>

			<Row gutter={15}>
				<AgreementContractorList form={form} />
			</Row>
		</Form>
	);
});
