import { DragEvent, HTMLAttributes, useState } from 'react';

import { AnimatePresence } from 'framer-motion';

import {
	DragZoneUploadContainer,
	DragZoneUploadNotification,
	DragZoneUploadProgress,
	DragZoneUploadTitle,
} from './DragZoneUpload.styled';

interface DragZoneUploadProps extends HTMLAttributes<HTMLDivElement> {
	hidden?: boolean;
	onUpload: (files: FileList, callbackStop: () => void) => void;
}

export const DragZoneUpload = ({ children, hidden = false, onUpload }: DragZoneUploadProps) => {
	const [show, setShow] = useState(false);
	const [uploading, setUploading] = useState(false);

	const handleOnDragEnter = () => setShow(true);

	const handleOnDragLeave = () => setShow(false);

	const handleOnDrop = (e: DragEvent<HTMLDivElement>) => {
		e.preventDefault();
		e.stopPropagation();
		setUploading(true);

		onUpload(e.dataTransfer.files, () => {
			// Заканчиваем закгрузку
			setUploading(false);
			setShow(false);
		});
	};

	const handleOnDragOver = (e: DragEvent<HTMLDivElement>) => {
		e.preventDefault();
		e.stopPropagation();
		setShow(true);
	};

	return (
		<DragZoneUploadContainer
			isHidden={hidden}
			id={'drop_zone'}
			onDragOver={handleOnDragOver}
			onDragEnter={handleOnDragEnter}
			onDragLeave={handleOnDragLeave}
			onDrop={handleOnDrop}
		>
			{children}

			<AnimatePresence>
				{show && (
					<DragZoneUploadNotification
						initial={{
							opacity: 0,
						}}
						animate={{
							opacity: 1,
							transition: { type: 'spring', stiffness: 150, damping: 20, duration: 0.5 },
						}}
						exit={{
							opacity: 0,
							transition: { type: 'spring', stiffness: 150, damping: 20, duration: 1 },
						}}
					>
						{!hidden && (
							<>
								<DragZoneUploadTitle>Переместите в область файлы и дождитесь их загрузки</DragZoneUploadTitle>

								{uploading && (
									<DragZoneUploadProgress
										initial={{
											scale: 0,
										}}
										animate={{
											scale: 1,
										}}
										exit={{
											scale: 0,
										}}
									>
										Пожалуйста дождитесь загрузки файлов
									</DragZoneUploadProgress>
								)}
							</>
						)}
					</DragZoneUploadNotification>
				)}
			</AnimatePresence>
		</DragZoneUploadContainer>
	);
};
