import { useEffect } from 'react';

import { FloatButton } from 'antd';
import { observer } from 'mobx-react-lite';

import { numWords } from '../../../helpers';
import { WsCountUserI, WsMessageI, WsTypeEvent } from '../../../interfaces/websocket/websocket.interface';
import { useStores } from '../../../stores';

export const CountUsers = observer(() => {
	const { userCountStore, websocketStore } = useStores();

	useEffect(() => {
		websocketStore.addCallback<WsCountUserI>({
			event: WsTypeEvent.count,
			callback: handeOnUserCounter,
		});
	}, []);

	const handeOnUserCounter = (data: WsMessageI<WsCountUserI>) => {
		userCountStore.setCount(data.payload.count);
	};

	return (
		<FloatButton
			shape={'square'}
			description={`${userCountStore.count}`}
			tooltip={`В сети : ${userCountStore.count}  ${numWords(userCountStore.count, ['человек', 'человека'])}`}
		/>
	);
});
