import { action, makeObservable, observable } from 'mobx';

import { TaskFileStore } from './task-file.store';
import { TaskFileI } from '../../interfaces';

export class TaskFilesStore {
	@observable list: TaskFileStore[] = [];

	constructor() {
		makeObservable(this);
	}

	@action.bound
	setList(values: TaskFileI[]) {
		this.list = (values || []).map((file) => new TaskFileStore(file));
	}

	@action.bound
	addFile(value: TaskFileStore) {
		this.list.push(value);
	}

	@action.bound
	deleteFile(value: TaskFileStore) {
		this.list = this.list.filter((file) => file.id !== value.id);
	}
}
