import { Popover } from 'antd';
import { observer } from 'mobx-react-lite';

import { NotificationBody } from './NotificationBody';

export const Notifications = observer(() => {
	const handleOnClearNotification = (isShow: boolean) => {
		if (isShow) {
			return false;
		}

		//notificationsStore.clear();
	};

	return (
		<>
			<Popover
				placement={'bottom'}
				title={'Уведомления'}
				content={<NotificationBody />}
				onOpenChange={handleOnClearNotification}
			></Popover>
		</>
	);
});
