import { action, computed, makeObservable, observable } from 'mobx';

import { TriggerExecuteEventStore } from './trigger-execute-event.store';
import { API } from '../../core';
import { ErrorAPI } from '../../helpers';
import { TriggerExecuteEvent } from '../../interfaces/triggers';
import { CoreResponse } from '../../responses';

export class TriggerExecuteEventsStore {
	PATH = 'trigger-execute-events';

	@observable list: TriggerExecuteEventStore[] = [];

	constructor() {
		makeObservable(this);
	}

	@action.bound
	async getList() {
		try {
			const { data } = await API.request<CoreResponse<TriggerExecuteEvent[]>>(`${this.PATH}`);

			// Записываем
			this.setList(data);
		} catch (e) {
			ErrorAPI('getList', e);
		}
	}

	@action.bound
	setList(values: TriggerExecuteEvent[]) {
		this.list = (values || []).map((triggerExecuteEvent) => new TriggerExecuteEventStore(triggerExecuteEvent));
	}

	@computed
	get selectBox() {
		return this.list.map((triggerExecuteEvent) => ({
			label: triggerExecuteEvent.name,
			value: triggerExecuteEvent.id,
		}));
	}
}
