import { action, makeObservable, observable } from 'mobx';

import { API } from '../../core';
import { WebsocketCallback, WsMessageI } from '../../interfaces';
import { RootStore } from '../index';

export class WebsocketStore {
	@observable ws: WebSocket | null = null;
	@observable isConnection = false;
	@observable callbacks: WebsocketCallback<any>[] = [];

	private rootStore: RootStore;

	constructor(rootStore: RootStore) {
		makeObservable(this);

		this.rootStore = rootStore;
	}

	@action.bound
	async init(): Promise<unknown> {
		console.log('INIT WS');

		if (this.isConnection) {
			return Promise.resolve();
		}

		// Connection
		this.ws = new WebSocket(`${process.env.REACT_APP_WEBSOCKET}?jwt=${API.token}&bla=1&ef=2`);

		this.ws.onopen = () => {
			if (this.ws?.readyState === WebSocket.OPEN) {
				this.setIsConnection(true);
				return Promise.resolve();
			} else {
				return Promise.reject();
			}
		};

		this.ws.onmessage = (e: MessageEvent) => {
			const data = JSON.parse(e.data);

			this.callbacks.forEach((callback) => {
				if (callback.event === data.event) {
					callback.callback(data);
				}
			});
		};

		this.ws.onerror = () => {
			this.setIsConnection(false);
		};

		this.ws.addEventListener('close', (evt) => {
			this.setIsConnection(false);

			if (evt.wasClean) {
				return;
			}

			setTimeout(() => {
				(async () => {
					await this.init();
				})();
			}, 5000);
		});
	}

	@action.bound
	setIsConnection(value: boolean) {
		this.isConnection = value;
	}

	@action.bound
	sendMessage<T>(values: WsMessageI<T>) {
		if (!this.isConnection) {
			console.warn(`Not connection in WS`);
			return;
		}

		const { event, payload } = values;
		this.ws?.send(
			JSON.stringify({
				event: event,
				payload: payload,
			}),
		);
	}

	@action.bound
	addCallback<T>(value: WebsocketCallback<T>) {
		const findCallback = this.callbacks.find((item) => {
			return item.event === value.event;
		});

		if (!findCallback) {
			this.callbacks.push(value);
		}
	}

	@action.bound
	removeCallback(value: string) {
		this.callbacks = (this.callbacks || []).filter((item) => item.event !== value);
	}

	@action.bound
	clearCallbacks() {
		this.callbacks = [];
	}

	@action.bound
	close() {
		if (this.isConnection) {
			this.ws?.close();
		}
	}
}
