import { DeleteOutlined, DownloadOutlined } from '@ant-design/icons';
import { Button, Divider, Popconfirm, Tooltip } from 'antd';
import { ColumnsType } from 'antd/es/table';

import { niceBytes } from '../../../../../helpers';
import { useStores } from '../../../../../stores';
import { AgreementFileTemplateStore } from '../../../../../stores/agreement-file-templates';

export const useColumns = (): ColumnsType<AgreementFileTemplateStore> => {
	const { agreementFileTemplatesStore } = useStores();

	const handleOnDownload = async (row: AgreementFileTemplateStore) => {
		try {
			const resultBlob = await row.download();

			if (!resultBlob) {
				return;
			}

			const url = URL.createObjectURL(resultBlob);
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', row.origin_name);
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
		} catch (e) {
			console.error(`handleOnDownload`, e);
		}
	};

	const handleOnDeleteFile = async (row: AgreementFileTemplateStore) => {
		try {
			await row.delete();

			await agreementFileTemplatesStore.table.getList();
		} catch (e) {
			console.error(`handleOnDeleteFile`, e);
		}
	};

	return [
		{
			title: 'Название файла',
			dataIndex: 'origin_name',
			align: 'center',
			render: (value) => <div style={{ display: 'flex' }}>{value}</div>,
		},
		{
			title: 'Загружен',
			dataIndex: 'created_at',
			align: 'center',
			width: 220,
			render: (value) => value.format('DD MMMM YYYY HH:mm'),
		},
		{
			title: 'Рамер',
			dataIndex: 'size_file',
			align: 'center',
			width: 180,
			render: (value) => niceBytes(value),
		},
		{
			title: '',
			dataIndex: '',
			align: 'center',
			width: 160,
			render: (_, record) => {
				return (
					<>
						<Tooltip title={`Скачать файл ${record.origin_name}`}>
							<Button icon={<DownloadOutlined />} onClick={() => handleOnDownload(record)} />
						</Tooltip>
						<Divider type={'vertical'} />
						<Popconfirm title={'Удалить файл?'} onConfirm={() => handleOnDeleteFile(record)}>
							<Button icon={<DeleteOutlined />} type={'primary'} danger />
						</Popconfirm>
					</>
				);
			},
		},
	];
};
