import dayjs from 'dayjs';
import { action, computed, observable } from 'mobx';

import { API } from '../../core';
import { UserFile } from '../../interfaces';
import { CrudStore } from '../common/crud.store';

export class UserFileStore extends CrudStore<UserFile> implements UserFile {
	PATH = 'user-files';

	@observable user_id = 0;
	@observable name = '';
	@observable file = '';
	@observable is_delete = false;
	@observable created_at = dayjs();
	@observable updated_at = dayjs();

	constructor(initialData: UserFile | null) {
		super();

		if (initialData) {
			this.fillStore(initialData);
		}
	}

	@computed
	get fullPathLink() {
		return `${API.getStoragePath}${this.file}`;
	}

	@action
	fillStore(data: UserFile) {
		const { id, user_id, name, file, is_delete, created_at, updated_at } = data;

		this.id = id;
		this.user_id = user_id;
		this.name = name;
		this.file = file;
		this.is_delete = is_delete;
		this.created_at = dayjs(created_at);
		this.updated_at = dayjs(updated_at);
	}
}
