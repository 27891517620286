import { action, makeObservable, observable } from 'mobx';

import { WsTypeEvent } from '../../interfaces';
import { UserStore } from '../users/user.store';
import { WebsocketStore } from '../websocket/websocket.store';

export class GeolocationStore {
	@observable isConnect = false;
	@observable isFirstRender = true;
	@observable currentPermission: PermissionStatus | null = null;

	@observable current: Geolocation = navigator.geolocation;

	constructor() {
		makeObservable(this);
	}

	@action.bound
	setIsConnect(value: boolean) {
		this.isConnect = value;
	}

	@action.bound
	setIsNotFirstRender() {
		this.isFirstRender = false;
	}

	@action.bound
	setCurrentPermission(value: PermissionStatus) {
		this.currentPermission = value;
	}

	@action.bound
	sendPosition(ws: WebsocketStore, values: GeolocationPosition, payload: UserStore): void {
		if (!ws.isConnection) {
			return;
		}

		ws.sendMessage({
			event: WsTypeEvent.geolocation,
			payload: {
				latitude: values.coords.latitude,
				longitude: values.coords.longitude,
				datetime: values.timestamp,
				fullName: payload.shortFullName,
			},
		});
	}
}
