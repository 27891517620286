import dayjs from 'dayjs';
import { action, computed, makeObservable, observable } from 'mobx';

import { ItemReportFileStore } from './item-report-file.store';
import { ItemReport } from '../../interfaces/item';
import { CrudStore } from '../common/crud.store';
import { UserDetailStore } from '../users';

export class ItemReportStore extends CrudStore<ItemReport> implements ItemReport {
	PATH = 'item-reports';

	@observable item_id = 0;
	@observable user_id = 0;
	@observable comment = '';
	@observable created_at = dayjs();
	@observable updated_at = dayjs();
	@observable files: Array<ItemReportFileStore> = [];
	@observable user: UserDetailStore = new UserDetailStore(null);

	constructor(initialData: ItemReport | null) {
		super();
		makeObservable(this);

		if (initialData) {
			this.fillStore(initialData);
		}
	}

	@action.bound
	async uploadFiles(files: Array<File>) {
		try {
			if (files.length === 0) {
				return false;
			}

			const uploadedFiles: Array<ItemReportFileStore> = [];

			for (const file of files) {
				// Инстанс для файла
				const instanceReportFile = new ItemReportFileStore(null);

				// Отправляем на загрузку
				await instanceReportFile.create({ item_report_id: this.id, file: file }, false);

				// Добавляем в массив загруженный файл
				uploadedFiles.push(instanceReportFile);
			}

			// Добавляем все загруженное
			this.files.push(...uploadedFiles);
		} catch (e) {
			console.error(`Error in method uploadFiles: `, e);
		}
	}

	@computed
	get createdAtFormatted() {
		if (this.created_at) return this.created_at.format('DD MMMM YYYY HH:mm');

		return undefined;
	}

	@action
	fillStore(data: ItemReport) {
		const { id, item_id, user_id, comment, created_at, updated_at, files, user } = data;

		this.id = id;
		this.item_id = item_id;
		this.user_id = user_id;
		this.comment = comment;
		this.created_at = dayjs(created_at);
		this.updated_at = dayjs(updated_at);
		this.files = (files || []).map((file) => new ItemReportFileStore(file));
		this.user = new UserDetailStore(user);
	}
}
