import { InputHTMLAttributes, Ref } from 'react';

import { InputUploadContainer } from './InputUpload.styled';

interface InputUploadProps extends InputHTMLAttributes<HTMLInputElement> {
	innerRef: Ref<HTMLInputElement>;
}

export const InputUpload = ({ innerRef, ...props }: InputUploadProps) => {
	return <InputUploadContainer ref={innerRef} {...props} type={'file'} />;
};
