import { makeObservable } from 'mobx';

import { User } from '../../interfaces';
import { UserStore } from '../users/user.store';

export class EmployeeStore extends UserStore {
	public PATH = 'employees';

	constructor(initialData: User | null) {
		super(initialData);

		makeObservable(this);
		if (initialData) {
			this.fillStore(initialData);
		}
	}
}
