import { useEffect } from 'react';

import { Typography } from 'antd';
import { observer } from 'mobx-react-lite';

import { useStores } from '../../../../stores';
import { BlockTitle, ContainerBody, ContainerBodyPadding } from '../../../common';
import { TaskExchangeTable } from '../../../ui';

export const TaskExchangePage = observer(() => {
	const { taskExchangeStore } = useStores();

	useEffect(() => {
		(async () => {
			await taskExchangeStore.table.getList();
		})();
	}, [taskExchangeStore.table.pagination.current_page]);

	return (
		<>
			<ContainerBody>
				<ContainerBodyPadding>
					<BlockTitle>
						<Typography.Title level={2}>Биржа задач</Typography.Title>
					</BlockTitle>

					<TaskExchangeTable />
				</ContainerBodyPadding>
			</ContainerBody>
		</>
	);
});
