import { BellFilled, BellOutlined } from '@ant-design/icons';
import { Layout, Menu } from 'antd';
import styled from 'styled-components';

import { COLORS } from '../../../stylesheets';

export const SiderCustom = styled(Layout.Sider)`
	display: flex;
	flex-direction: column;
	background: ${COLORS.White} !important;
	margin: 25px;

	.ant-layout-sider-children {
		display: flex;
		flex-direction: column;
	}
`;

export const Logotype = styled.img`
	display: flex;
	height: 60px;
	margin: 0;
	border-radius: 8px;
`;

interface LogotypeContainerI {
	collapsed: boolean;
}

export const LogotypeContainer = styled.div<LogotypeContainerI>`
	display: flex;
	overflow: hidden;
	margin: 0 auto 25px;
	width: ${(props) => (props.collapsed ? `60px` : 'auto')};
	transition: all 0.2s;
`;

export const LayoutMain = styled(Layout)`
	margin: 25px 25px 25px 0;
	background: ${COLORS.White};
`;

export const HeaderCustom = styled.header`
	height: 60px;
	display: grid;
	align-items: center;
	grid-template-columns: 100px 1fr;
	margin-bottom: 25px;
`;

export const ContentCustom = styled(Layout.Content)`
	margin: 0;
`;

export const HeaderRightSide = styled.div`
	display: flex;
	justify-content: right;
	align-items: center;
	column-gap: 20px;
`;

export const LayoutLink = styled.button`
	display: flex;
	background: transparent;
	margin: 0 auto;
	padding: 0;
	border: 0;
	cursor: pointer;
`;

export const BellIcon = styled(BellOutlined)`
	font-size: 18px;
	color: ${COLORS.BLUE_80};
`;

export const BellIconNew = styled(BellFilled)`
	font-size: 18px;
	color: ${COLORS.BLUE_80};
`;

export const MenuCustom = styled(Menu)`
	background: ${COLORS.White};
	border-inline-end: 0 !important;
	display: flex;
	flex-direction: column;
`;

export const ActionsUserButtons = styled.div`
	margin-top: auto;
	display: flex;
	justify-content: center;
`;

export const ActionsHeader = styled.div`
	display: flex;
	align-items: center;
	column-gap: 25px;
	margin-left: auto;
`;
