import { Typography } from 'antd';

import { BlockTitle, ContainerBody, ContainerBodyPadding } from '../../../common';
import { NotificationsList } from '../../../ui';

export const NotificationsPage = () => {
	return (
		<>
			<ContainerBody>
				<ContainerBodyPadding>
					<BlockTitle>
						<Typography.Title level={2}>Уведомления</Typography.Title>
					</BlockTitle>

					<NotificationsList />
				</ContainerBodyPadding>
			</ContainerBody>
		</>
	);
};
