import { action, makeObservable, observable } from 'mobx';

import { TaskStore } from './task.store';
import { PayloadParamsI, TaskI } from '../../interfaces';
import { DetailStore } from '../common/detail.store';
import { ModalStore } from '../common/modal.store';
import { TableStore } from '../common/table.store';

export class TasksStore {
	@observable modal = new ModalStore();
	@observable list: TaskStore[] = [];
	@observable selected = new TaskStore(null);
	@observable viewDetail = new DetailStore<TaskStore>(new TaskStore(null));
	@observable tableUser = new TableStore<TaskI, TaskStore>({
		url: 'user-tasks',
		getListItemStore: this.getListItemStore,
		getPayloadParams: this.getPayloadParams.bind(this),
	});

	constructor() {
		makeObservable(this);
	}

	@action.bound
	getListItemStore(value: TaskI): TaskStore {
		return new TaskStore(value);
	}

	@action.bound
	getPayloadParams(): PayloadParamsI {
		return {};
	}

	@action.bound
	setList(values: TaskI[]) {
		this.list = (values || []).map((task) => new TaskStore(task));
	}

	@action.bound
	setSelected(value: TaskStore) {
		this.selected = value;
	}

	@action.bound
	addTask(value: TaskStore) {
		const list = [...this.list];
		list.push(value);
		this.list = list.map((task) => new TaskStore(task));
	}

	@action.bound
	updateTask(value: TaskStore) {
		const list = [...this.list];
		const findIndex = this.list.findIndex((task) => task.id === value.id);

		if (findIndex !== -1) {
			this.list[findIndex] = value;
		}

		this.list = list.map((task) => new TaskStore(task));
	}

	@action.bound
	deleteTask(value: TaskStore) {
		let list = [...this.list];
		list = list.filter((task) => task.id !== value.id);
		this.list = list.map((task) => new TaskStore(task));
	}

	@action.bound
	clearSelected() {
		this.selected = new TaskStore(null);
	}
}
