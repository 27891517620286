import { useEffect, useState } from 'react';

import { Table } from 'antd';
import { observer } from 'mobx-react-lite';

import { useColumns } from './EmployeesTable.columns';
import { useStores } from '../../../../stores';

export const EmployeesTable = observer(() => {
	const { employeesStore } = useStores();
	const [isShow, setShow] = useState(false);

	useEffect(() => {
		setShow(true);
	}, []);

	if (!isShow) {
		return null;
	}

	return (
		<>
			<Table
				rowKey={'id'}
				columns={useColumns()}
				dataSource={employeesStore.table.list}
				loading={employeesStore.table.isLoading}
				pagination={{
					total: employeesStore.table.pagination.total,
					pageSize: employeesStore.table.pagination.per_page,
					onChange: (page) => employeesStore.table.pagination.setCurrentPage(page),
				}}
			/>
		</>
	);
});
