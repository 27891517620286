export enum ItemTypes {
	object = 1,
	volume = 2,
	tasks = 3,
}

export const ItemTypesDrawerMapping = {
	[ItemTypes.object]: 'объект',
	[ItemTypes.volume]: 'том',
	[ItemTypes.tasks]: 'задача',
};
