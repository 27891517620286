import { action, makeObservable, observable } from 'mobx';

import { ItemTypes } from '../../constants/item-types';
import { ItemStatus } from '../../interfaces/item';
import { CrudStore } from '../common/crud.store';

export class ItemStatusStore extends CrudStore<ItemStatus> implements ItemStatus {
	@observable item_type_id: ItemTypes | null = null;
	@observable parent_id = 0;
	@observable name = '';
	@observable description = '';
	@observable children: ItemStatusStore[] = [];

	constructor(initialData: ItemStatus | null) {
		super();
		makeObservable(this);

		if (initialData) {
			this.fillStore(initialData);
		}
	}

	@action
	fillStore(data: ItemStatus) {
		const { id, item_type_id, parent_id, name, description, children } = data;

		this.id = id;
		this.item_type_id = item_type_id;
		this.parent_id = parent_id;
		this.name = name;
		this.description = description;
		this.children = (children || []).map((item) => new ItemStatusStore(item));
	}
}
