import { Fragment } from 'react';

import { DeleteOutlined, PlusCircleOutlined } from '@ant-design/icons';
import {
	Alert,
	Button,
	Col,
	Collapse,
	Divider,
	Form,
	FormInstance,
	FormListFieldData,
	Input,
	Popconfirm,
	Row,
	Select,
	Tag,
	Tooltip,
	Typography,
} from 'antd';
import { useWatch } from 'antd/es/form/Form';
import { observer } from 'mobx-react-lite';

import { useStores } from '../../../../../stores';

interface AgreementContractorListProps {
	form: FormInstance;
}

export const AgreementContractorList = observer(({ form }: AgreementContractorListProps) => {
	const { contractorsStore, agreementsStore, agreementFileTemplatesStore } = useStores();
	const watcherContractors: Array<any> = useWatch(['contractors'], form);

	console.log('watcherForm', watcherContractors);

	const getCurrentStatus = (value: FormListFieldData) => {
		const data = watcherContractors;

		if (
			!Array.isArray(data) ||
			!data[value.name] ||
			!data[value.name].contractor_id ||
			agreementsStore.selected.isNewStore
		) {
			return 'Не установлен';
		}

		const currentData = data[value.name];

		if (!currentData.approve && !currentData.send_email) {
			return `Ожидает отправки`;
		} else if (!currentData.approve && currentData.send_email) {
			return `Отправлено контрагенту`;
		} else if (currentData.approve && currentData.send_email) {
			return `Получен ответ`;
		}

		return 'Не установлен';
	};

	const handleOnSendEmails = async () => {
		try {
			// Отпарвляем письма
			await agreementsStore.selected.setEmails();

			// Обновляем стор
			await agreementsStore.selected.get();
		} catch (e) {
			console.error(`handleOnSendEmails`, e);
		}
		console.log('send');
	};

	const getTitleMessage = () => {
		if (!agreementsStore.selected.cleanForm) {
			return `Сохраните изменение формы. После этого кнопка будет доступна.`;
		}

		return null;
	};

	return (
		<Form.List name={'contractors'}>
			{(fields, { add: onAdd, remove: onRemove }) => {
				return (
					<Fragment>
						<Col span={12}>
							<Typography.Title level={5}>Контрагенты</Typography.Title>
						</Col>

						<Col span={12} style={{ textAlign: 'right' }}>
							<Popconfirm title={'Вы уверены выслать предложения контрагентам?'} onConfirm={handleOnSendEmails}>
								<Tooltip title={getTitleMessage()}>
									<Button
										style={{
											display: 'inline-flex',
											alignItems: 'center',
											justifyContent: 'center',
											marginLeft: 'auto',
										}}
										disabled={
											agreementsStore.selected.isNewStore ||
											!agreementsStore.selected.cleanForm ||
											(watcherContractors || []).length === 0
										}
										shape={'round'}
										type={'default'}
										icon={<PlusCircleOutlined />}
									>
										Отправить письма
									</Button>
								</Tooltip>
							</Popconfirm>

							<Divider type={'vertical'} />

							<Button
								style={{
									display: 'inline-flex',
									alignItems: 'center',
									justifyContent: 'center',
									marginLeft: 'auto',
								}}
								shape={'round'}
								type={'primary'}
								icon={<PlusCircleOutlined />}
								onClick={() => onAdd()}
							>
								Добавить
							</Button>
						</Col>

						{fields.length === 0 && (
							<Col span={24} style={{ marginTop: 25 }}>
								<Alert showIcon type={'info'} message={'Нет контрагентов'} />
							</Col>
						)}

						{fields.length > 0 && (
							<Col span={24} style={{ marginTop: 25 }}>
								{fields.map((field) => {
									const currentContractor = agreementsStore.selected.contractors[field.key];

									return (
										<Row key={field.name} gutter={15}>
											<Col span={10}>
												<Form.Item
													label={'Контрагент'}
													name={[field.name, 'contractor_id']}
													initialValue={undefined}
													rules={[{ required: true, message: 'Выберите контрагента' }]}
												>
													<Select
														placeholder={'Выберите контрагента'}
														style={{ width: '100%' }}
														options={contractorsStore.dataAllForSelectBox}
														showSearch
														allowClear
														filterOption={(inputValue, option) => {
															return String(option?.label ?? '')
																.toLowerCase()
																.includes(inputValue.toLowerCase());
														}}
													/>
												</Form.Item>

												<Form.Item name={[field.name, 'approve']} initialValue={null} hidden />
												<Form.Item name={[field.name, 'send_email']} initialValue={null} hidden />
											</Col>

											<Col span={6}>
												<Form.Item
													label={'Типовой договор'}
													name={[field.name, 'agreement_file_template_id']}
													rules={[{ required: true, message: 'Выберите договор' }]}
												>
													<Select placeholder={'Выберите договор'} style={{ width: '100%' }} allowClear>
														{agreementFileTemplatesStore.all.list.map((agreementFileTemplateStore) => {
															return (
																<Select.Option
																	key={agreementFileTemplateStore.id}
																	value={agreementFileTemplateStore.id}
																>
																	{agreementFileTemplateStore.origin_name}
																</Select.Option>
															);
														})}
													</Select>
												</Form.Item>
											</Col>

											<Col span={6} style={{}}>
												<Form.Item label={'Текущий статус'}>
													<Tag style={{ marginRight: 15 }}>{getCurrentStatus(field)}</Tag>
												</Form.Item>
											</Col>

											<Col span={2}>
												<Button
													style={{ marginTop: 28 }}
													onClick={() => onRemove(field.name)}
													type={'primary'}
													danger
													icon={<DeleteOutlined />}
												/>
											</Col>

											<Col span={24}>
												<Form.Item label={'Комментарий для контрагента'} name={[field.name, 'comment']}>
													<Input.TextArea autoSize={{ minRows: 3 }} />
												</Form.Item>
											</Col>

											<Col span={24} style={{}}>
												<Collapse
													items={[
														{
															key: 'files',
															label: `Загруженные файлы ${currentContractor?.files?.length || 0}`,
															children: (
																<div style={{ display: 'flex', flexDirection: 'column', rowGap: 10 }}>
																	{currentContractor?.files?.map((file) => {
																		return (
																			<a key={file.id} href={file.fullPath}>
																				{file.origin_name}
																			</a>
																		);
																	})}
																</div>
															),
														},
													]}
													collapsible={currentContractor?.files?.length > 0 ? 'header' : 'disabled'}
												/>
											</Col>

											<Divider />
										</Row>
									);
								})}
							</Col>
						)}
					</Fragment>
				);
			}}
		</Form.List>
	);
});
