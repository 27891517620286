import { useState } from 'react';

import { MinusCircleOutlined } from '@ant-design/icons';
import { Button, Col, Form, FormInstance, Input, Row, Select } from 'antd';
import { observer } from 'mobx-react-lite';

import { useStores } from '../../../../../../../stores';
import { UserFileStore } from '../../../../../../../stores/users/user-file.store';
import { UploadFilesBlock } from '../../../../../../common';
import { UploadedUserBlock } from '../../../../../../common/UploadedUserBlock/UploadedUserBlock';

interface ContractorItemProps {
	form: FormInstance;
	keyOrder: number;
	name: number;
	onRemove: (index: number) => void;
}

export const ContractorItem = observer(({ name, form, onRemove }: ContractorItemProps) => {
	const {
		contractorsStore,
		itemsStore: { selected: currentItem },
	} = useStores();

	const [uploadedFiles, setUploadedFiles] = useState(currentItem?.contractors?.[name]?.files);

	const handleOnDeleteFile = async (fileStore: UserFileStore) => {
		// Удаляем выбранный файл
		await fileStore.delete(false);

		// Обновляем список загруженных файлов
		setUploadedFiles((prevState) => {
			const findIndex = prevState?.findIndex((item) => item.id === fileStore.id);

			if (findIndex !== -1) {
				prevState?.splice(findIndex, 1);
			}

			return [...prevState];
		});
	};

	return (
		<>
			<Row>
				<Col>
					<Col span={22}>
						<Form.Item name={[name, 'id']} hidden>
							<Input />
						</Form.Item>
					</Col>
				</Col>

				<Col span={22}>
					<Form.Item label={'Контрагент'} name={[name, 'contractor_id']} rules={[{ required: true }]}>
						<Select showSearch optionFilterProp="children">
							{contractorsStore.all.list.map((contractor) => {
								return (
									<Select.Option key={contractor.id} value={contractor.id}>
										{contractor.company_name} ({contractor.inn})
									</Select.Option>
								);
							})}
						</Select>
					</Form.Item>
				</Col>

				<Col span={2} style={{ textAlign: 'right' }}>
					<Button
						style={{ marginTop: 30 }}
						icon={<MinusCircleOutlined />}
						type={'primary'}
						danger
						onClick={() => onRemove(name)}
					/>
				</Col>
			</Row>

			<Row gutter={15}>
				<Col span={24}>
					<Form.Item label={'Предмет договора'} name={[name, 'subject_contract']}>
						<Input />
					</Form.Item>
				</Col>
			</Row>

			<Row gutter={15}>
				<Col span={12}>
					<UploadFilesBlock form={form} label={'Файлы'} nameList={[name, 'files']} />
				</Col>

				<Col span={12}>
					<UploadedUserBlock files={uploadedFiles || []} onDelete={handleOnDeleteFile} />
				</Col>
			</Row>
		</>
	);
});
