import { FormInstance } from 'antd';
import { observer } from 'mobx-react-lite';

import { Volumes } from './Volumes';

interface VolumesTabProps {
	form: FormInstance;
}

export const VolumesTab = observer(({ form }: VolumesTabProps) => {
	return (
		<>
			<Volumes form={form} />
		</>
	);
});
