import { Descriptions, Typography } from 'antd';

import { ItemStore } from '../../../../../stores';

interface FilesBlockProps {
	itemStore: ItemStore;
}

export const FilesBlock = ({ itemStore }: FilesBlockProps) => {
	const { technical_documentation, administrative_documentation, design_assignments } = itemStore.files;

	if (
		technical_documentation.length === 0 &&
		administrative_documentation.length === 0 &&
		design_assignments.length === 0
	) {
		return null;
	}

	return (
		<>
			<div style={{ height: 20 }} />

			<Descriptions title={'Файлы'} column={1} bordered size={'small'}>
				{technical_documentation.map((file) => {
					return (
						<Descriptions.Item key={file.id} label={'Техническая документация'}>
							<span key={file.id}>
								<Typography.Link href={file.fullPathLink} target={'_blank'}>
									{file.name}
								</Typography.Link>
							</span>
						</Descriptions.Item>
					);
				})}

				{administrative_documentation.map((file) => {
					return (
						<Descriptions.Item key={file.id} label={'Административная документация'}>
							<span key={file.id}>
								<Typography.Link href={file.fullPathLink} target={'_blank'}>
									{file.name}
								</Typography.Link>
							</span>
						</Descriptions.Item>
					);
				})}

				{design_assignments.map((file) => {
					return (
						<Descriptions.Item key={file.id} label={'Задание на проектирование'}>
							<span key={file.id}>
								<Typography.Link href={file.fullPathLink} target={'_blank'}>
									{file.name}
								</Typography.Link>
							</span>
						</Descriptions.Item>
					);
				})}
			</Descriptions>
		</>
	);
};
