import { action, makeObservable, observable } from 'mobx';

import { ModalStore } from '../common/modal.store';
import { ItemStore } from '../item';

export class ItemProjectGenerateStore {
	@observable modal = new ModalStore();
	@observable selectedObject = new ItemStore(null);

	constructor() {
		makeObservable(this);
	}

	@action.bound
	setSelectedObject(value: ItemStore) {
		this.selectedObject = value;
	}

	@action.bound
	clearSelectedObject() {
		this.selectedObject = new ItemStore(null);
	}
}
