import { action, makeObservable, observable } from 'mobx';

import { OBJECT_TYPES } from '../../constants';
import { ProjectDocumentationI } from '../../interfaces';
import { CrudStore } from '../common/crud.store';

export class ProjectDocumentationStore extends CrudStore<ProjectDocumentationI> implements ProjectDocumentationI {
	PATH = 'project-documentations';

	@observable object_type_id = OBJECT_TYPES.MAIN_NETWORKS;
	@observable name = '';

	constructor(initialData: ProjectDocumentationI | null) {
		super();
		makeObservable(this);

		if (initialData) {
			this.fillStore(initialData);
		}
	}

	@action
	fillStore(data: ProjectDocumentationI) {
		const { id, object_type_id, name } = data;

		this.id = id;
		this.object_type_id = object_type_id;
		this.name = name;
	}
}
