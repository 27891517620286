import { Descriptions, Typography } from 'antd';
import { observer } from 'mobx-react-lite';

import { ItemObjectTypesMapping } from '../../../../../../constants';
import { formattedPrice } from '../../../../../../helpers';
import { ItemStore } from '../../../../../../stores';
import { CompleteBlock } from '../../CompleteBlock/CompleteBlock';
import { ConfirmBlock } from '../../ConfirmBlock/ConfirmBlock';
import { ContractorsBlock } from '../../ContractorsBlock/ContractorsBlock';
import { FilesBlock } from '../../FilesBlock/FilesBlock';
import { ResultBlock } from '../../ResultBlock/ResultBlock';

interface ItemObjectViewProps {
	itemObject: ItemStore;
	// Когда участвует в истории отображения
	forHistory?: boolean;
	isShort?: boolean;
}

export const ItemObjectView = observer(({ itemObject, forHistory = false, isShort = false }: ItemObjectViewProps) => {
	return (
		<>
			{forHistory && <Typography.Title level={3}>Объект</Typography.Title>}

			<Descriptions title={'Основная информация'} layout={'vertical'} column={3} bordered size={'small'}>
				<Descriptions.Item label={'Тип объекта'}>
					{itemObject.item_object_type_id && ItemObjectTypesMapping[itemObject.item_object_type_id]}
				</Descriptions.Item>

				<Descriptions.Item label={'Статус'}>{itemObject.status.name}</Descriptions.Item>

				<Descriptions.Item label={'Шифр'}>{itemObject.cipher}</Descriptions.Item>

				<Descriptions.Item label={'Ответственный объекта'}>{itemObject.creator.shortFullName}</Descriptions.Item>

				<Descriptions.Item label={'Исполнитель объект'} span={2}>
					{itemObject.responsible.shortFullName}
				</Descriptions.Item>

				<Descriptions.Item label={'Титул проекта'} span={3}>
					{itemObject.title}
				</Descriptions.Item>

				<Descriptions.Item label={'Номер договора'}>{itemObject.contract_number}</Descriptions.Item>
				<Descriptions.Item label={'Стоимость'} span={2}>
					{formattedPrice(itemObject.price)}
				</Descriptions.Item>

				<Descriptions.Item label={'Дата начала проектирование'}>{itemObject.startDateFormatted}</Descriptions.Item>
				<Descriptions.Item label={'Дата окончания проектирования'}>{itemObject.endDateFormatted}</Descriptions.Item>
			</Descriptions>

			<ContractorsBlock itemStore={itemObject} />
			<FilesBlock itemStore={itemObject} />

			{!forHistory && !isShort && (
				<>
					<ResultBlock itemStore={itemObject} />
					<ConfirmBlock itemStore={itemObject} />
					<CompleteBlock itemStore={itemObject} />
				</>
			)}
		</>
	);
});
