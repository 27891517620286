export enum ItemDropDownKey {
	Edit = 'edit',
	View = 'view',
	CreateVolume = 'create-volume',
	ViewVolume = 'view-volume',
	CreateTask = 'create-task',
	ViewTask = 'view-task',
	Export = 'export',
	Delete = 'delete',
	GenerateVolume = 'generate-volume',
	Clone = 'clone',
}

export enum ItemTypeGroupFiles {
	TechnicalDocumentation = 'technical_documentation',
	AdministrativeDocumentation = 'administrative_documentation',
	DesignAssignments = 'design_assignments',
	WorkResult = 'work_result',
}

export enum ItemViewTabs {
	Main = 'main',
	Tree = 'tree',
	Requests = 'requests',
}
