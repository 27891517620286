import { SearchOutlined } from '@ant-design/icons';
import { Input } from 'antd';

export const SearchMessage = () => {
	return (
		<>
			<Input prefix={<SearchOutlined />} placeholder={'Поиск контакта'} />
		</>
	);
};
