import { useEffect, useState } from 'react';

import { Drawer, Spin, Tabs, TabsProps } from 'antd';
import { observer } from 'mobx-react-lite';

import { TaskRequest } from './TaskRequest/TaskRequest';
import { TreeTab } from './TreeTab/TreeTab';
import { ItemObjectView, ItemTaskView, ItemVolumeView } from './Viewers';
import { ItemViewTabs } from '../../../../constants';
import { ItemTypes } from '../../../../constants/item-types';
import { useStores } from '../../../../stores';

export const ItemViewDrawer = observer(() => {
	const { authStore, itemsStore, userItemsStore } = useStores();
	const { selected: itemViewer } = itemsStore;

	const [currentTab, setCurrentTab] = useState<ItemViewTabs>(ItemViewTabs.Main);

	const refItem = itemViewer;

	useEffect(() => {
		document.addEventListener('keydown', handleOnShortKey);

		return () => {
			document.removeEventListener('keydown', handleOnShortKey);
		};
	}, []);

	useEffect(() => {
		if (
			itemViewer.viewer.isShow &&
			itemViewer.responsible_id === authStore.current.id &&
			itemViewer.local_status.isNew
		) {
			(async () => {
				await itemViewer.update({
					item_local_status_id: 2,
				});

				await userItemsStore.all.getList();
			})();
		}

		if (itemViewer.viewer.isShow) {
			(async () => {
				await itemViewer.getHistory();
			})();
		}

		if (!itemViewer.viewer.isShow) {
			setCurrentTab(ItemViewTabs.Main);
		}
	}, [itemViewer.viewer.isShow]);

	const getTitle = () => {
		switch (itemViewer.item_type_id) {
			case ItemTypes.object:
				return `объекта : ${itemViewer.title}`;
			case ItemTypes.volume:
				return `тома : ${itemViewer.title}`;
			case ItemTypes.tasks:
				return `задачи : ${itemViewer.title}`;
		}
	};

	const handleOnShortKey = (e: KeyboardEvent) => {
		if (e.key === 'Enter') {
			if (e.shiftKey) {
				alert('Close');
				refItem.drawer.close();
			}
		}
	};

	const handleOnChangeTab = (key: string) => {
		setCurrentTab(key as ItemViewTabs);
	};

	const itemsTab: TabsProps['items'] = [
		{
			key: ItemViewTabs.Main,
			label: 'Основная информация',
			children: (
				<Spin tip={'Загрузка'} spinning={itemsStore.selected.isLoading}>
					{itemViewer.item_type_id === ItemTypes.object && <ItemObjectView itemObject={itemsStore.selected} />}
					{itemViewer.item_type_id === ItemTypes.volume && <ItemVolumeView itemVolume={itemsStore.selected} />}
					{itemViewer.item_type_id === ItemTypes.tasks && <ItemTaskView itemTask={itemsStore.selected} />}
				</Spin>
			),
		},
		{
			key: ItemViewTabs.Tree,
			label: 'Дерево',
			children: <TreeTab itemStore={itemsStore.selected} />,
		},
	];

	if (itemViewer.item_type_id === ItemTypes.tasks && !itemViewer.responsible_id) {
		itemsTab.push({
			key: ItemViewTabs.Requests,
			label: 'Запросы на задачу',
			children: <TaskRequest itemTask={itemsStore.selected} />,
		});
	}

	if (!itemViewer.viewer.isShow) {
		return null;
	}

	return (
		<>
			<Drawer
				title={`Просмотр ${getTitle()}`}
				width={1000}
				open={itemViewer.viewer.isShow}
				onClose={() => {
					itemViewer.viewer.close();
					itemsStore.clearSelected();
				}}
			>
				<Tabs items={itemsTab} activeKey={currentTab} onChange={handleOnChangeTab} />
			</Drawer>
		</>
	);
});
