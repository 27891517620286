import { ChangeEvent, useEffect, useRef } from 'react';

import { SearchOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, Col, Divider, Input, notification, Row, Typography } from 'antd';
import { debounce } from 'debounce';
import { observer } from 'mobx-react-lite';

import { useStores } from '../../../../stores';
import { BlockTitle, ContainerBody, ContainerBodyPadding, DragZoneUpload, InputUpload } from '../../../common';
import { AgreementFileTemplatesTable } from '../../../ui';

interface AgreementFileTemplatesPageProps {}

export const AgreementFileTemplatesPage = observer(({}: AgreementFileTemplatesPageProps) => {
	const { agreementFileTemplatesStore } = useStores();

	const inputUploadRef = useRef<HTMLInputElement>(null);

	const searchRequest = debounce(async (value) => {
		//Регистрируем фильтр
		agreementFileTemplatesStore.table.addFilter('query_search', value);

		// Выполняем поиск
		await agreementFileTemplatesStore.table.getList();
	}, 300);

	// Получаем файлы
	useEffect(() => {
		(async () => {
			await agreementFileTemplatesStore.table.getList();
		})();
	}, [agreementFileTemplatesStore.table.pagination.current_page]);

	const handleOnUpload = async (files: FileList | null, callbackFn) => {
		if (!files) {
			return;
		}

		try {
			await agreementFileTemplatesStore.uploadFiles(files);

			// Уведомление об успешной загрузке
			notification.success({
				message: 'Успешно загружены',
			});

			// Обновляем список файлов
			await agreementFileTemplatesStore.table.getList();
		} catch (e) {
			console.error(e);
		} finally {
			callbackFn();
		}
	};

	const handleOnOpenWindow = () => {
		inputUploadRef?.current?.click();
	};

	const handleOnSearch = async (e: ChangeEvent<HTMLInputElement>) => {
		await searchRequest(e.target.value);
	};

	return (
		<DragZoneUpload onUpload={handleOnUpload}>
			<InputUpload
				innerRef={inputUploadRef}
				multiple
				onChange={(e) => handleOnUpload(e.target.files, () => console.log())}
			/>

			<ContainerBody>
				<ContainerBodyPadding>
					<BlockTitle>
						<Typography.Title level={2}>Файлы шаблонных договоров</Typography.Title>

						<Button.Group>
							<Button type={'primary'} icon={<UploadOutlined />} onClick={handleOnOpenWindow}>
								Загрузить
							</Button>
						</Button.Group>
					</BlockTitle>

					<Row>
						<Col span={24}>
							<Input
								placeholder={'Поиск по файлам'}
								onChange={handleOnSearch}
								allowClear
								addonBefore={<SearchOutlined />}
							/>
						</Col>
					</Row>

					<Divider />

					<AgreementFileTemplatesTable />
				</ContainerBodyPadding>
			</ContainerBody>
		</DragZoneUpload>
	);
});
