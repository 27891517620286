import {
	Col,
	DatePicker,
	Form,
	FormInstance,
	Input,
	InputNumber,
	Radio,
	RadioChangeEvent,
	Row,
	TreeSelect,
	Select,
} from 'antd';
import { observer } from 'mobx-react-lite';

import { OBJECT_TYPES } from '../../../../../constants';
import { useStores } from '../../../../../stores';

interface InformationProps {
	form: FormInstance;
}

export const Information = observer(({ form }: InformationProps) => {
	const {
		objectsStore,
		objectStatusesStore,
		objectTypesStore,
		responsiblesStore,
		authStore,
		compositionProjectDocumentationsStore,
	} = useStores();
	const { selected: currentObject } = objectsStore;

	const handleOnChangeType = (e: RadioChangeEvent) => {
		const { value } = e.target;
		currentObject.setObjectTypeId(value);

		// Чистим выбранный статус от предыдущего типа
		form.resetFields(['work_object_status_id']);

		// Чистим выбранный состав проектной документации от предыдущего типа
		form.resetFields(['composition_project_documentation_id']);
	};

	const documentsList = compositionProjectDocumentationsStore.selectBoxList;

	return (
		<>
			<Row gutter={15}>
				<Col span={24}>
					<Form.Item label={'Тип объекта'} name={'object_type_id'} rules={[{ required: true }]}>
						<Radio.Group
							buttonStyle={'solid'}
							onChange={handleOnChangeType}
							style={{ width: '100%', display: 'flex', textAlign: 'center' }}
						>
							{objectTypesStore.list.map((workObjectType) => {
								return (
									<Radio.Button key={workObjectType.id} value={workObjectType.id} style={{ width: '100%' }}>
										{workObjectType.name}
									</Radio.Button>
								);
							})}
						</Radio.Group>
					</Form.Item>
				</Col>
			</Row>

			<Row gutter={15}>
				<Col span={12}>
					<Form.Item shouldUpdate style={{ margin: 0 }}>
						{() => {
							const ObjectTypeId = form.getFieldValue('object_type_id');
							return (
								<Form.Item
									label={'Состав проектной документации'}
									name={'project_documentation_id'}
									rules={[{ required: [OBJECT_TYPES.MAIN_NETWORKS].includes(ObjectTypeId) }]}
								>
									<Select placeholder={'Выберите составную документацию'}>
										{documentsList.map((itemDocument) => {
											return (
												<Select.Option key={itemDocument.id} value={itemDocument.id}>
													{itemDocument.name}
												</Select.Option>
											);
										})}
									</Select>
								</Form.Item>
							);
						}}
					</Form.Item>
				</Col>

				<Col span={12}>
					<Form.Item label={'Статус'} name={'object_status_id'} rules={[{ required: true }]}>
						<TreeSelect
							style={{ width: '100%' }}
							placeholder={'Выберите статус'}
							treeData={objectStatusesStore.selectBoxList}
						/>
					</Form.Item>
				</Col>
			</Row>

			<Row gutter={15}>
				<Col span={12}>
					<Form.Item label={'Автор объекта'}>
						(<strong>Вы</strong>) {authStore.current.shortFullName}
					</Form.Item>
				</Col>

				<Col span={12}>
					<Form.Item label={'Ответственный по объекту'} name={'responsible_id'}>
						<Select
							placeholder={'Выберите ответсвенного'}
							showSearch
							filterOption={(inputValue, option) => {
								return String(option?.children ?? '')
									.toLowerCase()
									.includes(inputValue.toLowerCase());
							}}
						>
							{responsiblesStore.list.map((responsible) => {
								return (
									<Select.Option key={responsible.id} value={responsible.id}>
										{responsible.shortFullName}
									</Select.Option>
								);
							})}
						</Select>
					</Form.Item>
				</Col>
			</Row>

			<Row gutter={15}>
				<Col span={24}>
					<Form.Item label={'Шифр'}>
						{currentObject.isNewStore ? `Будет сгенерирован автоматически` : currentObject.cipher}
					</Form.Item>
				</Col>
			</Row>

			<Row gutter={15}>
				<Col span={24}>
					<Form.Item label={'Титул проекта'} name={'title'} rules={[{ required: true }]}>
						<Input />
					</Form.Item>
				</Col>
			</Row>

			<Row gutter={15}>
				<Col span={12}>
					<Form.Item label={'Номер договора'} name={'contract_number'}>
						<Input />
					</Form.Item>
				</Col>
				<Col span={12}>
					<Form.Item label={'Стоимость'} name={'price'} rules={[{ required: true }]}>
						<InputNumber
							precision={2}
							decimalSeparator={','}
							min={0}
							formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ').replace(',', '.')}
						/>
					</Form.Item>
				</Col>
			</Row>

			<Row gutter={15}>
				<Col span={12}>
					<Form.Item label={'Дата начала проектирование'} name={'start_date'} rules={[{ required: true }]}>
						<DatePicker format={'DD MMMM YYYY'} />
					</Form.Item>
				</Col>
				<Col span={12}>
					<Form.Item shouldUpdate style={{ margin: 0 }}>
						{() => {
							const fieldStartDate = form.getFieldValue('start_date');
							return (
								<Form.Item
									label={'Дата окончания проектирования'}
									name={'end_date'}
									rules={[
										{ required: true },
										{
											validator: async (_, workEndDate) => {
												if (workEndDate) {
													if (workEndDate.diff(fieldStartDate, 'days') <= 0) {
														return Promise.reject('Окончание работы < чем начало работ');
													}

													return Promise.resolve();
												} else {
													return Promise.resolve();
												}
											},
										},
									]}
								>
									<DatePicker
										format={'DD MMMM YYYY'}
										disabledDate={(current) => {
											if (fieldStartDate && current) {
												return current && current < fieldStartDate.endOf('day');
											} else {
												return false;
											}
										}}
									/>
								</Form.Item>
							);
						}}
					</Form.Item>
				</Col>
			</Row>
		</>
	);
});
