import { action, makeObservable, observable } from 'mobx';

import { GenerateVolumesI } from '../../interfaces';

export class GenerateVolumeStore implements GenerateVolumesI {
	@observable id = 0;
	@observable project_documentation_id = 0;
	@observable responsible_id: number | undefined = undefined;
	@observable number_section = '';
	@observable nomination = '';
	@observable note = '';

	constructor(initialData: GenerateVolumesI | null) {
		makeObservable(this);
		if (initialData) {
			this.fillStore(initialData);
		}
	}

	@action.bound
	setResponsibleId(value: number | undefined) {
		this.responsible_id = value;
	}

	@action
	fillStore(data: GenerateVolumesI) {
		const { id, project_documentation_id, number_section, nomination, note } = data;

		this.id = id;
		this.project_documentation_id = project_documentation_id;
		this.number_section = number_section;
		this.nomination = nomination;
		this.note = note;
	}
}
