import { EyeOutlined } from '@ant-design/icons';
import { Badge, Button, List, Typography } from 'antd';
import { Observer, observer } from 'mobx-react-lite';

import { useStores } from '../../../../stores';
import { NotificationStore } from '../../../../stores/notifications/notification.store';

export const NotificationsList = observer(() => {
	const { notificationsStore, itemsStore } = useStores();

	const handleOnShowItem = async (notification: NotificationStore) => {
		// Читаем уведомление
		if (notification && !notification.is_show) {
			await notification?.read();
		}

		const item_id = notification?.payload.id;

		if (item_id) {
			itemsStore.selected.setId(Number(notification?.payload.id));
			await itemsStore.selected.get();
			itemsStore.selected.viewer.show();
		}
	};

	return (
		<>
			<List
				itemLayout={'horizontal'}
				size={'small'}
				dataSource={notificationsStore.table.list}
				renderItem={(notification) => {
					return (
						<Observer>
							{() => (
								<List.Item
									actions={[
										<Button
											key={'view'}
											size={'small'}
											icon={<EyeOutlined />}
											onClick={() => handleOnShowItem(notification)}
										></Button>,
									]}
								>
									<List.Item.Meta
										title={
											<>
												{!notification.is_show && <Badge status={'error'} />} {notification?.title}
											</>
										}
										description={
											<Typography.Paragraph ellipsis={{ rows: 1, tooltip: '' }}>
												{notification.description}
											</Typography.Paragraph>
										}
									/>
								</List.Item>
							)}
						</Observer>
					);
				}}
			/>
		</>
	);
});
