import { action, computed, makeObservable, observable } from 'mobx';

import { AgreementPrivateItemFile } from '../../interfaces';

export class AgreementPrivateItemFileStore implements AgreementPrivateItemFile {
	@observable id = 0;
	@observable name = '';
	@observable file = '';

	constructor(initialData: AgreementPrivateItemFile | null) {
		makeObservable(this);

		if (initialData) {
			this.fillStore(initialData);
		}
	}

	@computed
	get fullPath(): string {
		return `${process.env.REACT_APP_CORE_FILE_URL}${this.file}`;
	}

	@action
	fillStore(data: AgreementPrivateItemFile) {
		const { id, name, file } = data;

		this.id = id;
		this.name = name;
		this.file = file;
	}
}
