import { Layout } from 'antd';
import styled from 'styled-components';

import { COLORS } from '../../../stylesheets';

export const ContentExtra = styled(Layout.Content)`
	position: relative;
	display: flex;
	flex: 1;
	background: ${COLORS.White};
	box-shadow: 0 5px 10px 2px rgba(0, 0, 0, 0.1);
	border-radius: 20px;
	z-index: 10;
`;

export const ContainerBodyPadding = styled.div`
	overflow-x: hidden;
	display: flex;
	flex-direction: column;
	flex: 1;
	padding: 25px 20px;
`;

export const TwoColumnsContainer = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

export const ExtraActionsBlock = styled.div`
	margin-bottom: 25px;
`;

export const RightSideExtra = styled.div`
	display: flex;
	align-items: center;
	column-gap: 15px;
`;
