import { red } from '@ant-design/colors';
import styled, { createGlobalStyle } from 'styled-components';

import { COLORS } from './colors.styled';
import { fontMixin } from './mixins.styled';

export const GlobalStyled = createGlobalStyle`
  @font-face {
    font-family: 'Brutal Type';
    src: url('/fonts/BrutalType-Light.woff2') format('woff2'),
    url('/fonts/BrutalType-Light.woff') format('woff'),
    url('/fonts/BrutalType-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Brutal Type';
    src: url('/fonts/BrutalType-Bold.woff2') format('woff2'),
    url('/fonts/BrutalType-Bold.woff') format('woff'),
    url('/fonts/BrutalType-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Brutal Type';
    src: url('/fonts/BrutalType-ExtraLight.woff2') format('woff2'),
    url('/fonts/BrutalType-ExtraLight.woff') format('woff'),
    url('/fonts/BrutalType-ExtraLight.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Brutal Type';
    src: url('/fonts/BrutalType-ExtraBold.woff2') format('woff2'),
    url('/fonts/BrutalType-ExtraBold.woff') format('woff'),
    url('/fonts/BrutalType-ExtraBold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Brutal Type';
    src: url('/fonts/BrutalType-Thin.woff2') format('woff2'),
    url('/fonts/BrutalType-Thin.woff') format('woff'),
    url('/fonts/BrutalType-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Brutal Type';
    src: url('/fonts/BrutalType-Black.woff2') format('woff2'),
    url('/fonts/BrutalType-Black.woff') format('woff'),
    url('/fonts/BrutalType-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Brutal Type';
    src: url('/fonts/BrutalType.woff2') format('woff2'),
    url('/fonts/BrutalType.woff') format('woff'),
    url('/fonts/BrutalType.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Brutal Type';
    src: url('/fonts/BrutalType-Medium.woff2') format('woff2'),
    url('/fonts/BrutalType-Medium.woff') format('woff'),
    url('/fonts/BrutalType-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
  }


  html {
    height: 100%;
  }

  body {
    position: relative;
    min-height: 100%;
    height: auto;
    display: grid;
    grid-template-rows: 1fr;
    ${fontMixin()}

  }

  #root {
    display: grid;
  }

  /*.ant-form-item{
    &:last-child{
      margin-bottom: 0;
    }
  }*/

  .ant-form-item-label {
    font-weight: 500;
  }

  .ant-form-item-explain-error {
    position: relative;
    top: 4px;
    font-size: 12px;
  }

  .ant-menu-item-selected {
    background: ${COLORS.BLUE_100} !important;
    color: ${COLORS.White} !important;
  }

  .ant-picker, .ant-input-number {
    width: 100% !important;
  }

  .hidden {
    display: none !important;
  }

  .row {
    &-overdue {
      td {
      }
    }
  }

  .hidden {
    display: none;
  }

  h2 {
    &.ant-typography {
      margin-bottom: 25px !important;
    }
  }

  .ant-table-tbody {
    min-height: 500px;
  }

  .tree-transfer .ant-transfer-list {
    &:first-child, &:last-child {
      width: 50%;
    }
  }

  .ant-table-filter-dropdown {
    padding: 15px !important;
  }

  tr.ant-table-row-selected {
    td {
      background: transparent !important;
    }
  }

  .ant-select-tree-treenode {
    width: 100% !important;
  }

  .ant-input-number-input {
    text-align: center !important;
  }

  .ant-descriptions-row {
    .ant-descriptions-item-label {
      color: rgba(0, 0, 0, 0.78) !important;
      font-weight: 600 !important;
    }
  }


  .green-badge {
    font-weight: 700;
    color: #27ae60;
  }

  .ant-upload-select {
    width: 100%;
  }

  .ant-list-item-meta-content {
    width: 200px !important;
  }

  .ant-collapse-header {
    align-items: center !important;
  }

  .ant-switch-inner-checked, .ant-switch-inner-unchecked {
    display: flex !important;
    align-items: center !important;
    height: 100%;
  }

  caption {
    caption-side: top;
  }

  .ant-table-caption {
    margin: 0 !important;
    padding: 0 !important;
  }

  table {
    &:has(caption) {
      th {
        &:first-child, &:last-child {
          border-radius: 0 !important;
        }
      }
    }
  }

  .ant-drawer-body {
    &:has([role="tablist"]) {
      padding-top: 4px !important;
    }
  }

  .drag-over {
    background: ${COLORS.BLUE_10};
  }

  .cursor-pointer {
    cursor: pointer !important;
  }

  .ant-collapse-header {
    display: grid !important;
    grid-template-columns: 25px 1fr 50px !important;
  }

  .anticon svg {
    //margin-top: 2px;
  }

  .tr_task_hidden {
    td {
      background: ${COLORS.BLUE_10};
      cursor: not-allowed;
    }

    &:hover {
      td {
        background: ${COLORS.BLUE_10} !important;
      }
    }
  }

  .disabled-hover {
    &:hover {
      & > td {
        background: unset !important;
      }
    }
  }
`;

export const IconPositionAbsolute = styled.div`
	display: flex;
	position: absolute;
	left: 10px;
	top: 10px;
	z-index: 100;
	font-size: 12px;
	color: ${red[7]};
`;

export const RowContainer = styled.div`
	text-align: left;
`;
