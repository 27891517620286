import { Avatar } from 'antd';
import styled from 'styled-components';

import { COLORS, fontMixin } from '../../../../../stylesheets';

export const Container = styled.div`
	margin: 20px 0;
	display: flex;
	flex-direction: column;
	align-items: center;
`;

export const AvatarExtra = styled(Avatar)`
	border-color: ${COLORS.BLUE_80};
	background: ${COLORS.BLUE_05};
	cursor: pointer;
	transition: all 0.2s;
	margin-bottom: 10px;

	&:active {
		transform: scale(0.95);
	}

	svg {
		color: ${COLORS.BLUE_100};
	}
`;

export const UserInitials = styled.div`
	font-weight: 500;
	font-size: 20px;
	margin-bottom: 5px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	width: 100%;
	text-align: center;
`;

export const UserPost = styled.div`
	margin-bottom: 15px;
	color: ${COLORS.GRAY_40};
	${fontMixin(12, 500)}
`;
