import styled from 'styled-components';

export const Container = styled.div``;

export const Header = styled.div`
	display: flex;
	column-gap: 25px;
	align-items: flex-start;
	margin-top: 20px;
`;

export const LeftSide = styled.div``;

export const RightSide = styled.div`
	margin-left: auto;
`;
