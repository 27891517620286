import dayjs, { Dayjs } from 'dayjs';
import { serialize } from 'object-to-formdata';

import { MethodsRequest } from '../constants';

class APIWrapper {
	public get tokenValue() {
		return localStorage.getItem(API.nameToken);
	}

	public get nameToken(): string {
		return process.env.REACT_APP_NAME_TOKEN || '';
	}

	public get getCorePath(): string {
		return process.env.REACT_APP_CORE_API_URL || '/not-set/';
	}

	public get getStoragePath(): string {
		return process.env.REACT_APP_CORE_FILE_URL || '/not-set/';
	}

	public set saveToken(token: string) {
		localStorage.setItem(this.nameToken, token);
	}

	public get token(): string {
		return localStorage.getItem(API.nameToken) ?? '';
	}

	public async request<T>(url = '', options?: RequestInit): Promise<T> {
		const defaultOptions = {
			method: MethodsRequest.Get,
			headers: {
				Authorization: `Bearer ${this.tokenValue}`,
				'x-requested-with': 'XMLHttpRequest',
			},
		};

		options = { ...defaultOptions, ...options };

		const result = await fetch(`${this.getCorePath}${url}`, options);
		const response = await result.json();

		if (!result.ok) {
			throw response;
		}

		return response;
	}

	public async download(url = '', options?: RequestInit): Promise<Blob> {
		const defaultOptions = {
			method: MethodsRequest.Get,
			headers: {
				Authorization: `Bearer ${this.tokenValue}`,
				'x-requested-with': 'XMLHttpRequest',
			},
		};

		options = { ...defaultOptions, ...options };

		const result = await fetch(`${this.getCorePath}${url}`, options);
		return result.blob();
	}

	public getFormData(data: any): BodyInit {
		Object.keys(data).forEach((key) => {
			console.log('data[key]', key);

			if (data[key] instanceof dayjs) {
				data[key] = dayjs(data[key]).format('YYYY-MM-DD');
			}

			if (data[key] && Array.isArray(data[key])) {
				data[key].forEach((item: any, index: number) => {
					if (data[key][index]) {
						console.log('data[key][index]', data[key][index]);

						if (data[key][index] instanceof dayjs) {
							data[key][index] = dayjs(data[key][index]).format('YYYY-MM-DD');
						} else {
							Object.keys(data[key][index]).forEach((keySub) => {
								if (data[key][index][keySub] instanceof dayjs) {
									data[key][index][keySub] = dayjs(data[key][index][keySub]).format('YYYY-MM-DD');
								}

								if (typeof data[key][index][keySub] === 'object' && data[key][index][keySub]) {
									Object.keys(data[key][index][keySub]).forEach((keySubLevel) => {
										if (data[key][index][keySub][keySubLevel] instanceof dayjs) {
											data[key][index][keySub][keySubLevel] = dayjs(data[key][index][keySub][keySubLevel]).format(
												'YYYY-MM-DD',
											);
										}
									});
								}
							});
						}
					}
				});
			}
		});

		return serialize(data, {
			indices: true,
			booleansAsIntegers: true,
			noFilesWithArrayNotation: false,
		});
	}

	public checkIsDate(value: any) {
		if (value instanceof dayjs) {
			return dayjs(value as Dayjs).toISOString();
		} else if (typeof value === 'object') {
			return JSON.stringify(value);
		} else {
			return value;
		}
	}

	public removeToken() {
		localStorage.removeItem(this.nameToken);
	}
}

export const API = new APIWrapper();
