import { action, computed, makeObservable, observable } from 'mobx';

import { GenerateVolumeStore } from './generate-volume.store';
import { API } from '../../core';
import { ErrorAPI } from '../../helpers';
import { GenerateVolumesI } from '../../interfaces';
import { CoreResponse } from '../../responses';
import { ModalStore } from '../common/modal.store';

export class GenerateVolumesStore {
	@observable modal = new ModalStore();
	@observable list: GenerateVolumeStore[] = [];
	@observable isLoading = false;

	constructor() {
		makeObservable(this);
	}

	async getByProjectDocumentationId(project_documentation_id: number) {
		try {
			const { data } = await API.request<CoreResponse<GenerateVolumesI[]>>(
				`generate-volumes?project_documentation_id=${project_documentation_id}`,
			);

			this.setList(data);
		} catch (e) {
			ErrorAPI('getByProjectDocumentationId', e);
		}
	}

	@action.bound
	setList(values: GenerateVolumesI[]) {
		this.list = (values || []).map((generateVolume) => new GenerateVolumeStore(generateVolume));
	}

	@action.bound
	setIsLoading(value: boolean) {
		this.isLoading = value;
	}

	@computed
	get allKeyID(): Array<number> {
		return this.list.map((item) => item.id);
	}
}
