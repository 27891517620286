import { motion } from 'framer-motion';
import styled from 'styled-components';

import { COLORS, fontMixin } from '../../../../stylesheets';

export const BackgroundBase = styled.div`
	display: flex;
	flex: 1;
	background-image: url('/images/watermark-blue.png');
	background-repeat: no-repeat;
	background-position: center left;
	height: 100%;
`;

export const ContractorPageContainer = styled(motion.div)`
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
`;

export const ContractorPageBodyUpload = styled.div`
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 450px;
	height: 300px;
	border: 1px dashed ${COLORS.BLUE_100};
	border-radius: 20px;
`;

export const ContractorPageTitle = styled.div`
	display: flex;
	justify-content: center;
	${fontMixin(18, 700)};
	margin-bottom: 20px;
	color: ${COLORS.BLUE_100};
`;

export const ContractorPageDescription = styled.div`
	display: flex;
	justify-content: center;
	${fontMixin(16, 300)};
	margin-bottom: 20px;

	span {
		font-weight: 500;
		margin-left: 5px;
	}
`;

export const ContractorPageFiles = styled.div`
	display: flex;
	margin-bottom: 25px;
`;

export const ContractorPageUploadedBlock = styled.div`
	display: flex;
	flex-direction: column;
	row-gap: 10px;
	margin-top: 30px;
	margin-bottom: 25px;

	span {
		display: flex;
		${fontMixin(18, 600)}
	}
`;
