import { action, makeObservable, observable } from 'mobx';

import { AgreementStore } from './agreement.store';
import { Agreement } from '../../interfaces';
import { DrawerStore } from '../common/drawer.store';
import { TableStore } from '../common/table.store';

export class AgreementsStore {
	// Опцианальные св-ва
	@observable drawer = new DrawerStore();

	@observable selected = new AgreementStore(null);

	// Данные
	@observable table = new TableStore({
		url: 'agreements',
		getListItemStore: this.getListItemStore,
		getPayloadParams: this.getPayloadParams,
	});

	constructor() {
		makeObservable(this);
	}

	@action.bound
	setSelected(value: AgreementStore) {
		this.selected = value;
	}

	@action.bound
	clearSelected() {
		this.selected = new AgreementStore(null);
	}

	@action.bound
	private getListItemStore(value: Agreement): AgreementStore {
		return new AgreementStore(value);
	}

	@action.bound
	private getPayloadParams() {
		return {};
	}
}
