import { action, makeObservable, observable } from 'mobx';

import { ItemListStore } from './item-list.store';
import { ItemStore } from './item.store';
import { ItemObjectTypes } from '../../constants';
import { ItemList } from '../../interfaces/item';
import { FilterStore } from '../common';
import { AllStore } from '../common/all.store';
import { ModalStore } from '../common/modal.store';
import { TableStore } from '../common/table.store';

export class ItemsStore {
	@observable modal = new ModalStore();
	@observable selected = new ItemStore(null);
	@observable selectedTree = new ItemStore(null);
	@observable table = new TableStore<ItemList, ItemListStore>({
		url: 'items',
		getListItemStore: this.getListItemStore,
		getPayloadParams: this.getPayloadParams.bind(this),
	});
	@observable all = new AllStore({
		url: 'items',
		getListItemStore: this.getListItemStore,
		getPayloadParams: this.getPayloadParams.bind(this),
	});

	@observable filter = new FilterStore();
	@observable item_object_type_id = ItemObjectTypes.DISTRIBUTION_NETWORKS;
	@observable is_delete = 0;

	@observable childrenRelations: Array<number> = [];

	constructor() {
		makeObservable(this);
	}

	@action.bound
	setChildrenRelations(value: Array<number> = []) {
		this.childrenRelations = value;
	}

	@action.bound
	getListItemStore(value: ItemList): ItemListStore {
		return new ItemListStore(value);
	}

	@action.bound
	getPayloadParams() {
		return {
			item_object_type_id: this.item_object_type_id,
			is_delete: this.is_delete,
		};
	}

	@action.bound
	setIsDelete(value: number) {
		this.is_delete = value;
	}

	@action.bound
	setItemObjectTypeId(value: ItemObjectTypes) {
		this.item_object_type_id = value;
	}

	@action.bound
	setSelected(value: ItemStore) {
		this.selected = value;
	}

	@action.bound
	clearSelected() {
		this.selected = new ItemStore(null);
	}

	@action.bound
	setSelectedTree(value: ItemStore) {
		this.selectedTree = value;
	}

	@action.bound
	clearSelectedTree() {
		this.selectedTree = new ItemStore(null);
	}

	@action.bound
	async updateSelected(item_id: number) {
		try {
			if (item_id === this.selected.id) {
				await this.selected.get();
			}
		} catch (e) {
			console.error(`Error in method updateSelected : `, e);
		}
	}
}
