import { List, Space } from 'antd';
import { observer } from 'mobx-react-lite';

import { useStores } from '../../../../../stores';

export const Contractors = observer(() => {
	const { userDashboardStore } = useStores();
	const { objects } = userDashboardStore;
	const { viewDetail } = objects;
	const { data } = viewDetail;

	return (
		<List
			itemLayout={'vertical'}
			dataSource={data.contractors}
			renderItem={({ id, contractor, subject_contract, files }) => {
				return (
					<List.Item key={id}>
						<List.Item.Meta title={`${contractor.company_name}`} description={`${subject_contract}`} />

						<Space>
							{files.map((file) => {
								return (
									<a key={file.id} target={'_blank'} href={file.fullPath} rel="noreferrer">
										{file.origin_name}
									</a>
								);
							})}
						</Space>
					</List.Item>
				);
			}}
		/>
	);
});
