import { notification } from 'antd';
import { action, computed, makeObservable, observable } from 'mobx';

import { MethodsRequest } from '../../constants';
import { API } from '../../core';
import { ErrorAPI } from '../../helpers';
import { UpdateSortI } from '../../interfaces';

interface Core<T> {
	fillStore: (data: T) => void;
}

export abstract class CrudStore<T> implements Core<T> {
	public PATH = '';

	@observable id = 0;
	@observable isLoading = false;
	@observable isError = false;
	@observable isDataLoaded = false;

	protected constructor() {
		makeObservable(this);
	}

	@computed
	get isNotNewStore() {
		return this.id !== 0;
	}

	@computed
	get isNewStore() {
		return this.id === 0;
	}

	@computed
	get isDataLoadedProcess() {
		return !this.isDataLoaded;
	}

	@computed
	get getUrlShow() {
		return `${API.getCorePath}${this.PATH}/${this.id}`;
	}

	@action.bound
	setId(value: number) {
		this.id = value;
	}

	@action.bound
	setPath(value: string) {
		this.PATH = value;
	}

	@action.bound
	setIsLoading(value: boolean) {
		this.isLoading = value;
	}

	@action.bound
	setIsDataLoaded(value = false) {
		this.isDataLoaded = value;
	}

	@action.bound
	showError() {
		this.isError = true;

		setTimeout(() => {
			this.isError = false;
		}, 4000);
	}

	@action.bound
	async get(notReWrite = false) {
		this.setIsLoading(true);
		this.setIsDataLoaded();

		try {
			const data = await API.request<T>(`${this.PATH}/${this.id}`);

			if (!notReWrite) {
				this.fillStore(data);
			}

			this.setIsDataLoaded(true);
		} catch (e) {
			this.setIsDataLoaded();
			console.error(`Error in method ${this.PATH} : `, e);
		} finally {
			this.setIsLoading(false);
		}
	}

	@action.bound
	async create(values: Partial<T>, showNotification = true) {
		this.setIsLoading(true);

		try {
			const created = await API.request<T>(`${this.PATH}`, {
				method: MethodsRequest.Post,
				body: API.getFormData(values),
			});

			console.log('created', created);

			this.fillStore(created);

			if (showNotification) {
				notification.success({
					message: 'Успешно создано',
				});
			}
			return created;
		} catch (e: any) {
			ErrorAPI('create', e);
		} finally {
			this.setIsLoading(false);
		}
	}

	@action.bound
	async update(values: Partial<T>, showNotification = true, updateRecord = true) {
		this.setIsLoading(true);

		try {
			const updated = await API.request<T>(`${this.PATH}/${this.id}`, {
				method: MethodsRequest.Post,
				body: API.getFormData({ ...{ _method: MethodsRequest.Put }, ...values }),
			});

			if (updateRecord) {
				this.fillStore(updated);
			}

			if (showNotification) {
				notification.success({
					message: 'Успешно обновлен',
				});
			}
		} catch (e) {
			ErrorAPI('update', e);
		} finally {
			this.setIsLoading(false);
		}
	}

	@action.bound
	async delete(soft = true) {
		this.setIsLoading(true);

		try {
			if (soft) {
				await API.request(`${this.PATH}/${this.id}`, {
					method: MethodsRequest.Post,
					body: API.getFormData({ ...{ _method: MethodsRequest.Put }, ...{ is_delete: true } }),
				});
			} else {
				await API.request(`${this.PATH}/${this.id}`, {
					method: MethodsRequest.Delete,
				});
			}

			notification.success({
				message: 'Успешно удален',
			});
		} catch (e) {
			console.error(`Error in method ${this.PATH} : `, e);
		} finally {
			this.setIsLoading(false);
		}
	}

	@action.bound
	async updateSort(values: UpdateSortI[]) {
		try {
			await API.request(`${this.PATH}/update-sort`, {
				method: MethodsRequest.Post,
				body: API.getFormData({
					_method: MethodsRequest.Put,
					data: values,
				}),
			});

			notification.success({
				message: 'Успешно обновлен',
			});
		} catch {
			/* empty */
		}
	}

	@action.bound
	async download(): Promise<Blob | undefined> {
		try {
			const result = await API.download(`${this.PATH}/${this.id}`);

			if (result) {
				return result;
			}
		} catch (e) {
			ErrorAPI('download', e);
		}
	}

	abstract fillStore(data: T): void;
}
