import { Alert, Col, Form, Input, InputNumber, Row } from 'antd';
import { observer } from 'mobx-react-lite';

import { useStores } from '../../../../../../stores';

export const InformationTab = observer(() => {
	const { itemProjectDocumentsStore } = useStores();
	const { detail } = itemProjectDocumentsStore;

	return (
		<>
			<Row gutter={15}>
				<Col span={24}>
					<Form.Item name={'item_object_type_id'} hidden>
						<Input />
					</Form.Item>
				</Col>
			</Row>

			<Row gutter={15}>
				<Col span={24}>
					<Alert showIcon message={`Тип сетей : ${detail.data.nameItemObjectType}`} style={{ marginBottom: 25 }} />
				</Col>
			</Row>

			<Row gutter={15}>
				<Col span={4}>
					<Form.Item label={'Позиция'} name={'sort'} rules={[{ required: true }]}>
						<InputNumber min={1} />
					</Form.Item>
				</Col>

				<Col span={20}>
					<Form.Item label={'Название проектной документации'} name={'name'} rules={[{ required: true }]}>
						<Input placeholder={'Введите название'} />
					</Form.Item>
				</Col>
			</Row>

			<Row gutter={15}>
				<Col span={24}>
					<Form.Item label={'Описание'} name={'description'}>
						<Input.TextArea placeholder={'Введите описание'} autoSize={{ minRows: 5, maxRows: 10 }} />
					</Form.Item>
				</Col>
			</Row>
		</>
	);
});
