import { useEffect } from 'react';

import { Typography } from 'antd';
import { observer } from 'mobx-react-lite';

import { useStores } from '../../../../stores';
import { BlockTitle, ContainerBody, ContainerBodyPadding } from '../../../common';
import { Messenger } from '../../../ui';

export const MessengerPage = observer(() => {
	const { employeesStore, dialogsStore } = useStores();

	useEffect(() => {
		(async () => {
			await employeesStore.all.getList();
			await dialogsStore.getList();
		})();
	}, []);

	return (
		<>
			<ContainerBody>
				<ContainerBodyPadding>
					<BlockTitle>
						<Typography.Title level={2}>Сообщения</Typography.Title>
					</BlockTitle>

					<Messenger />
				</ContainerBodyPadding>
			</ContainerBody>
		</>
	);
});
