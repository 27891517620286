import dayjs from 'dayjs';
import { action, computed, makeObservable, observable } from 'mobx';

import { ItemObjectTypes, MethodsRequest } from '../../constants';
import { API } from '../../core';
import { ItemProjectDocument } from '../../interfaces/item-project-document';
import { CrudStore } from '../common/crud.store';
import { ItemVolumeGenerateStore } from '../item-volume-generate';

export class ItemProjectDocumentStore extends CrudStore<ItemProjectDocument> implements ItemProjectDocument {
	PATH = 'item-project-documents';

	@observable item_object_type_id = ItemObjectTypes.DISTRIBUTION_NETWORKS;
	@observable sort = 1;
	@observable name = '';
	@observable description = '';
	@observable item_volume_generates: ItemVolumeGenerateStore[] = [];
	@observable item_volume_generates_count = 0;
	@observable created_at = dayjs();
	@observable updated_at = dayjs();

	@observable isClone = false;

	constructor(initialData: ItemProjectDocument | null) {
		super();
		makeObservable(this);

		if (initialData) {
			this.fillStore(initialData);
		}
	}

	@computed
	get createdAtFormatted(): string {
		return this.created_at.format(`DD MMMM YYYY`);
	}

	@computed
	get updatedAtFormatted(): string {
		return this.updated_at.format(`DD MMMM YYYY`);
	}

	@computed
	get nameItemObjectType(): string {
		switch (this.item_object_type_id) {
			case ItemObjectTypes.DISTRIBUTION_NETWORKS:
				return `Распределительные сети`;
			case ItemObjectTypes.MAIN_NETWORKS:
				return `Основные сети`;
			default:
				return 'Не определено';
		}
	}

	@computed
	get formListData() {
		return this.item_volume_generates.map((item) => ({
			sort: item.sort,
			number_section: item.number_section,
			nomination: item.nomination,
			payload: item.payload,
			note: item.note,
			is_finish: item.is_finish,
			item_volume_tasks: item.item_volume_tasks.map((itemTask) => ({
				id: itemTask.id,
				inside_uuid: itemTask.inside_uuid,
				sort: itemTask.sort,
				role_id: String(itemTask.role_id),
				title: itemTask.title,
				description: itemTask.description,
				is_finish: itemTask.is_finish,
				triggers: itemTask.task_triggers.map((trigger) => ({
					condition: trigger.condition,
					relation_inside_uuid: trigger.relation_inside_uuid,
					relation_inside_uuid_no: trigger.relation_inside_uuid_no,
					title: trigger.title,
					description: trigger.description,
				})),
			})),
		}));
	}

	@action.bound
	setIsClone(value: boolean) {
		this.isClone = value;
	}

	@action.bound
	setItemObjectTypeId(value: ItemObjectTypes) {
		this.item_object_type_id = value;
	}

	@action.bound
	async clone() {
		this.setIsClone(true);

		try {
			await API.request(`${this.PATH}/clone`, {
				method: MethodsRequest.Post,
				body: API.getFormData({
					id: this.id,
				}),
			});
		} catch (e) {
			console.error(`Error in method clone: `, e);
		} finally {
			this.setIsClone(false);
		}
	}

	@action
	fillStore(data: ItemProjectDocument) {
		const {
			id,
			item_object_type_id,
			sort,
			name,
			description,
			item_volume_generates,
			item_volume_generates_count,
			created_at,
			updated_at,
		} = data;

		this.id = id;
		this.item_object_type_id = item_object_type_id;
		this.sort = sort;
		this.name = name;
		this.description = description;
		this.item_volume_generates = (item_volume_generates || []).map((item) => new ItemVolumeGenerateStore(item));
		this.item_volume_generates_count = item_volume_generates_count;
		this.created_at = dayjs(created_at);
		this.updated_at = dayjs(updated_at);
	}
}
