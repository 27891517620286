import styled from 'styled-components';

import { COLORS } from '../../../../../../stylesheets';

export const Container = styled.div`
	display: flex;
	grid-row: 1/3;
	//background: ${COLORS.TABLE_TD};
	border-radius: 20px;
	padding: 25px;
	width: 100%;
`;

export const Body = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
`;
