import { Table } from 'antd';
import { observer } from 'mobx-react-lite';

import { useTaskItemTableColumns } from './TaskItemTable.columns';
import { ItemStore, useStores } from '../../../../../../../stores';

interface TaskItemTableProps {
	items: ItemStore[];
}

export const TaskItemTable = observer(({ items }: TaskItemTableProps) => {
	const { itemsStore } = useStores();

	return (
		<Table
			rowKey={(row) => row.id}
			pagination={false}
			columns={useTaskItemTableColumns()}
			dataSource={items}
			footer={() => <div style={{ height: 23 }} />}
			onRow={(row) => {
				return {
					onDoubleClick: async () => {
						// Показываем окно
						itemsStore.selected.viewer.show();

						// Устанавливаем ID
						itemsStore.selected.setId(row.id);

						// Получаем инфомацию
						await itemsStore.selected.get();
					},
				};
			}}
		/>
	);
});
