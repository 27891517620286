import { action, computed, makeObservable, observable } from 'mobx';

import { DayJsNull } from '../../interfaces';
import { ItemVolumeGenerate } from '../../interfaces/item-volume-generate';
import { CrudStore } from '../common/crud.store';
import { ItemVolumeTaskStore } from '../item-volume-task';

export class ItemVolumeGenerateStore extends CrudStore<ItemVolumeGenerate> implements ItemVolumeGenerate {
	@observable sort = 0;
	@observable item_project_document_id = 0;
	@observable number_section = '';
	@observable nomination = '';
	@observable note = '';
	@observable is_finish = false;
	@observable payload: { [key: string]: string | number } = {};
	@observable actions = {};
	@observable item_volume_tasks: Array<ItemVolumeTaskStore> = [];

	@observable start_date: DayJsNull = null;
	@observable end_date: DayJsNull = null;

	constructor(initialData: ItemVolumeGenerate | null) {
		super();
		makeObservable(this);

		if (initialData) {
			this.fillStore(initialData);
		}
	}

	@computed
	get titleProjectDocumentation() {
		return `${this.number_section} ${this.nomination}`;
	}

	@action.bound
	setStartDate(value: DayJsNull) {
		this.start_date = value;
	}

	@action.bound
	setEndDate(value: DayJsNull) {
		this.end_date = value;
	}

	@action
	fillStore(data: ItemVolumeGenerate) {
		const { id, sort, number_section, nomination, note, payload, actions, item_volume_tasks } = data;

		this.id = id;
		this.sort = sort;
		this.number_section = number_section;
		this.nomination = nomination;
		this.note = note;

		if (payload) {
			this.payload = payload;
		}
		this.actions = actions || {};
		this.item_volume_tasks = (item_volume_tasks || []).map((item) => new ItemVolumeTaskStore(item));
	}
}
