import { HTMLAttributes, ReactNode } from 'react';

import { ContainerBodyPadding, ContentExtra } from './ContainerBody.styled';

interface ContainerBodyProps extends HTMLAttributes<HTMLDivElement> {
	children?: ReactNode;
}

export const ContainerBody = ({ children = null, ...props }: ContainerBodyProps) => {
	return <ContentExtra {...props}>{children}</ContentExtra>;
};

export { ContainerBodyPadding };
