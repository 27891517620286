import dayjs from 'dayjs';
import { action, computed, observable } from 'mobx';

import { VolumeFileI } from '../../interfaces';
import { CrudStore } from '../common/crud.store';

export class VolumeFileStore extends CrudStore<VolumeFileI> implements VolumeFileI {
	PATH = 'volume-files';

	@observable volume_id = 0;
	@observable user_id = 0;
	@observable origin_name = '';
	@observable file_name = '';
	@observable is_delete = false;
	@observable created_at = dayjs();
	@observable updated_at = dayjs();

	constructor(initialData: VolumeFileI | null) {
		super();
		if (initialData) {
			this.fillStore(initialData);
		}
	}

	@computed
	get fullPath(): string {
		return `${process.env.REACT_APP_CORE_FILE_URL}${this.file_name}`;
	}

	@computed
	get formattedCreatedAt(): string {
		return this.created_at.format('HH:mm DD MMMM YYYY');
	}

	@action
	fillStore(data: VolumeFileI) {
		const { id, volume_id, user_id, origin_name, file_name, is_delete, created_at, updated_at } = data;

		this.id = id;
		this.volume_id = volume_id;
		this.user_id = user_id;
		this.origin_name = origin_name;
		this.file_name = file_name;
		this.is_delete = is_delete;
		this.created_at = dayjs(created_at);
		this.updated_at = dayjs(updated_at);
	}
}
