import dayjs from 'dayjs';
import { action, computed, makeObservable, observable } from 'mobx';

import { Notification, NotificationPayload, NotificationType } from '../../interfaces/notifications';
import { CrudStore } from '../common/crud.store';

export class NotificationStore extends CrudStore<Notification> implements Notification {
	PATH = 'notifications';

	@observable user_id = 0;
	@observable title = '';
	@observable content = '';
	@observable payload: NotificationPayload = {};
	@observable is_show = false;
	@observable type = NotificationType.Item;
	@observable created_at = dayjs();
	@observable updated_at = dayjs();

	constructor(initialData: Notification | null) {
		super();
		makeObservable(this);

		if (initialData) {
			this.fillStore(initialData);
		}
	}

	@action.bound
	async read() {
		try {
			await this.update({ is_show: true }, false);
		} catch (e) {
			console.error(`Error in method read : `, e);
		}
	}

	@computed
	get description(): string {
		if (this.type === NotificationType.Item) {
			return `Срок исполнения до ${dayjs(this.payload.end_date).format('DD MMMM YYYY')}`;
		}

		return '';
	}

	@action
	fillStore(data: Notification) {
		const { id, user_id, title, content, payload, is_show, type, created_at, updated_at } = data;

		this.id = id;
		this.user_id = user_id;
		this.title = title;
		this.content = content;
		this.payload = payload;
		this.is_show = is_show;
		this.type = type;
		this.created_at = dayjs(created_at);
		this.updated_at = dayjs(updated_at);
	}
}
