import dayjs from 'dayjs';
import { action, computed, makeObservable, observable } from 'mobx';

import { ContractorFileI } from '../../interfaces';
import { CrudStore } from '../common/crud.store';

export class ContractorFileStore extends CrudStore<ContractorFileI> implements ContractorFileI {
	PATH = 'contractor-file';

	@observable contractor_id = 0;
	@observable name = '';
	@observable file = '';
	@observable is_delete = false;
	@observable created_at = dayjs();
	@observable updated_at = dayjs();

	constructor(initialData: ContractorFileI | null) {
		super();
		makeObservable(this);

		if (initialData) {
			this.fillStore(initialData);
		}
	}

	@computed
	get pathFile() {
		return `${process.env.REACT_APP_CORE_FILE_URL}${this.file}`;
	}

	@action
	fillStore(data: ContractorFileI) {
		const { id, contractor_id, name, file, is_delete, created_at, updated_at } = data;

		this.id = id;
		this.contractor_id = contractor_id;
		this.name = name;
		this.file = file;
		this.is_delete = is_delete;
		this.created_at = dayjs(created_at);
		this.updated_at = dayjs(updated_at);
	}
}
