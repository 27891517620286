import dayjs from 'dayjs';
import { action, makeObservable, observable } from 'mobx';

import { AgreementPrivateItemStore } from './agreement-private-item.store';
import { AgreementPrivateDetail } from '../../interfaces';

export class AgreementPrivateDetailStore implements AgreementPrivateDetail {
	@observable id = 0;
	@observable title = '';
	@observable start_date = dayjs();
	@observable end_date = dayjs();
	@observable item = new AgreementPrivateItemStore(null);

	constructor(initialData: AgreementPrivateDetail | null) {
		makeObservable(this);

		if (initialData) {
			this.fillStore(initialData);
		}
	}

	@action
	fillStore(data: AgreementPrivateDetail) {
		const { id, title, start_date, end_date, item } = data;

		this.id = id;
		this.title = title;
		this.start_date = dayjs(start_date);
		this.end_date = dayjs(end_date);
		this.item = new AgreementPrivateItemStore(item);
	}
}
