import dayjs from 'dayjs';
import { action, computed, makeObservable, observable } from 'mobx';

import { ItemObjectTypes, MethodsRequest } from '../../constants';
import { API } from '../../core';
import { ItemProjectDocumentList } from '../../interfaces/item-project-document';
import { ItemVolumeGenerateStore } from '../item-volume-generate';

export class ItemProjectDocumentListStore implements ItemProjectDocumentList {
	@observable id = 0;
	@observable item_object_type_id = ItemObjectTypes.DISTRIBUTION_NETWORKS;
	@observable sort = 1;
	@observable name = '';
	@observable description = '';
	@observable item_volume_generates_count = 0;
	@observable item_volume_generates: ItemVolumeGenerateStore[] = [];
	@observable created_at = dayjs();
	@observable updated_at = dayjs();

	constructor(initialData: ItemProjectDocumentList | null) {
		makeObservable(this);

		if (initialData) {
			this.fillStore(initialData);
		}
	}

	@computed
	get createdAtFormatted(): string {
		return this.created_at.format(`DD MMMM YYYY`);
	}

	@computed
	get updatedAtFormatted(): string {
		return this.updated_at.format(`DD MMMM YYYY`);
	}

	@computed
	get dataForForm() {
		return this.item_volume_generates.map((item_volume_generate) => {
			return {
				number_section: item_volume_generate.number_section,
				nomination: item_volume_generate.nomination,
				note: item_volume_generate.note,
				item_volume_tasks: item_volume_generate.item_volume_tasks,
			};
		});
	}

	@action.bound
	async clone() {
		try {
			await API.request(`item-project-documents/clone`, {
				method: MethodsRequest.Post,
				body: API.getFormData({
					id: this.id,
				}),
			});
		} catch (e) {
			console.error(`Error in method clone : `, e);
		}
	}

	@action
	fillStore(data: ItemProjectDocumentList) {
		const {
			id,
			item_object_type_id,
			sort,
			name,
			description,
			item_volume_generates_count,
			item_volume_generates,
			created_at,
			updated_at,
		} = data;

		this.id = id;
		this.item_object_type_id = item_object_type_id;
		this.sort = sort;
		this.name = name;
		this.description = description;
		this.item_volume_generates_count = item_volume_generates_count;
		this.item_volume_generates = (item_volume_generates || []).map(
			(item_volume_generate) => new ItemVolumeGenerateStore(item_volume_generate),
		);
		this.created_at = dayjs(created_at);
		this.updated_at = dayjs(updated_at);
	}
}
