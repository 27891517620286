import { FormInstance } from 'antd';
import { observer } from 'mobx-react-lite';

import { ItemObject } from './ItemObject/ItemObject';
import { ItemTypes } from '../../../../../constants/item-types';
import { ItemStore } from '../../../../../stores/item/item.store';

interface FactoryItemProps {
	form: FormInstance;
	itemStore: ItemStore;
}

export const FactoryItem = observer(({ form, itemStore }: FactoryItemProps) => {
	switch (itemStore.item_type_id) {
		case ItemTypes.object:
			return <ItemObject form={form} itemStore={itemStore} />;
		default:
			return <div>Не найден тип. Обратитесь к администратору. </div>;
	}
});
