import dayjs from 'dayjs';
import { action, makeObservable, observable } from 'mobx';

import { ItemTriggerPayloadStore } from './item-trigger-payload.store';
import { ItemTrigger } from '../../interfaces/item';

export class ItemTriggerStore implements ItemTrigger {
	@observable id = 0;
	@observable item_id = 0;
	@observable trigger_id = 0;
	@observable trigger_execute_event_id = 0;
	@observable value: string | number = '';
	@observable payload = new ItemTriggerPayloadStore(null);
	@observable is_done = false;
	@observable created_at = dayjs();
	@observable updated_at = dayjs();

	constructor(initialData: ItemTrigger | null) {
		makeObservable(this);

		if (initialData) {
			this.fillStore(initialData);
		}
	}

	@action
	fillStore(data: ItemTrigger) {
		const { id, item_id, trigger_id, trigger_execute_event_id, value, payload, is_done, created_at, updated_at } = data;

		this.id = id;
		this.item_id = item_id;
		this.trigger_id = trigger_id;
		this.trigger_execute_event_id = trigger_execute_event_id;
		this.value = value || '';
		this.payload = new ItemTriggerPayloadStore(payload);
		this.is_done = is_done;
		this.created_at = dayjs(created_at);
		this.updated_at = dayjs(updated_at);
	}
}
