import { action, computed, makeObservable, observable } from 'mobx';

import { UserStore } from './user.store';
import { UsersTypeId, UsersTypeMapping } from '../../constants';
import { User } from '../../interfaces';
import { AllStore } from '../common/all.store';

export class UsersStore {
	@observable all = new AllStore<User, UserStore>({
		url: 'users',
		getListItemStore: this.getListItemStore,
	});

	constructor() {
		makeObservable(this);
	}

	@action.bound
	private getListItemStore(value: User): UserStore {
		return new UserStore(value);
	}

	@computed
	get selectBoxVolume() {
		const data = this.all.list.reduce(
			(group, product) => {
				const { user_type_id } = product;
				group[user_type_id] = group[user_type_id] ?? [];
				group[user_type_id].push(product);
				return group;
			},
			{} as { [key: number]: Array<UserStore> },
		);

		return Object.entries(data).map(([key, list]) => {
			return {
				label: UsersTypeMapping[Number(key) as UsersTypeId],
				options: (list || []).map((user) => ({
					label: user.isContractor ? user.contractor.company_name : user.shortFullName,
					value: user.id,
				})),
			};
		});
	}
}
