import { action, makeObservable, observable } from 'mobx';

import { OBJECT_TYPES } from '../../constants';
import { ObjectTypeI } from '../../interfaces';

export class ObjectTypeStore implements ObjectTypeI {
	@observable id = OBJECT_TYPES.DISTRIBUTION_NETWORKS;
	@observable name = '';
	@observable description = '';

	constructor(initialData: ObjectTypeI | null) {
		makeObservable(this);

		if (initialData) {
			this.fillStore(initialData);
		}
	}

	@action
	fillStore(data: ObjectTypeI) {
		const { id, name, description } = data;

		this.id = id;
		this.name = name;
		this.description = description;
	}
}
