import { Fragment } from 'react';

import { PlusOutlined } from '@ant-design/icons';
import { Button, Divider, Form, FormInstance } from 'antd';

import { ContractorItem } from './ContractorItem';

interface ContractorsListProps {
	form: FormInstance;
}

export const ContractorsList = ({ form }: ContractorsListProps) => {
	return (
		<Form.List name="contractors">
			{(fields, { add: onAddContractor, remove: onRemoveContractor }) => (
				<>
					<Form.Item>
						<Button
							type="dashed"
							onClick={() =>
								onAddContractor({
									id: 0,
								})
							}
							block
							icon={<PlusOutlined />}
						>
							Добавить контрагента
						</Button>
					</Form.Item>

					{fields.map(({ name }) => {
						return (
							<Fragment key={name}>
								<ContractorItem form={form} keyOrder={name} name={name} onRemove={onRemoveContractor} />
								{name < fields.length - 1 && <Divider />}
							</Fragment>
						);
					})}
				</>
			)}
		</Form.List>
	);
};
