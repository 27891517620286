import { useEffect, useState } from 'react';

import { UploadOutlined, WarningOutlined } from '@ant-design/icons';
import { Badge, Button, Checkbox, Descriptions, Input, Tag, Typography, Upload, UploadFile } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { observer } from 'mobx-react-lite';

import { ItemStore, useStores } from '../../../../../stores';
import { Container } from '../../../../common/FileUploadBlock/FileUploadBlock.styled';
import { HistoryReport } from '../HistoryReport/HistoryReport';

interface ResultBlockProps {
	itemStore: ItemStore;
}

/**
 * прикрепить файлы при завершении
 */

export const ResultBlock = observer(({ itemStore }: ResultBlockProps) => {
	const { authStore, userItemsStore } = useStores();

	const [isDone, setIsDone] = useState(false);
	const [comment, setComment] = useState('');
	const [uploadFiles, setUploadFiles] = useState<Array<File>>([]);

	useEffect(() => {
		if (itemStore.is_done) {
			setIsDone(itemStore.is_done);
			setComment(itemStore.result_report);
		}

		return () => {
			setComment('');
			setIsDone(false);
		};
	}, [itemStore]);

	if (itemStore.responsible_id !== authStore.current.id) {
		return null;
	}

	const handleOnUpdate = async () => {
		try {
			// Загрузка файла пока отключена по просьба заказчика
			/*if (uploadFiles.length === 0) {
				notification.error({
					message: 'Ошибка',
					description: 'Добавьте файл при отправке отчета.',
				});
				return;
			}*/

			// Отправляем данные
			await itemStore.sendIsDone({ isDone, comment, files: uploadFiles });

			// Очищаем состояние
			setComment('');
			setIsDone(false);

			// Обновляем табилцу
			await userItemsStore.all.getList();
		} catch {
			setIsDone(true);
		}
	};

	const handleOnIsDone = (e: CheckboxChangeEvent) => {
		setIsDone(e.target.checked);
	};

	const handleOnSetUpload = (files: UploadFile[]) => {
		const completeUploadFile = files.map((file) => file.originFileObj);
		setUploadFiles(completeUploadFile as File[]);
	};

	if (itemStore.is_delete) {
		return null;
	}

	if (itemStore.is_done && itemStore.is_confirmed) {
		return null;
	}

	return (
		<>
			<div style={{ height: 20 }} />

			<Descriptions
				title={'Выполнение'}
				extra={[itemStore.reports.length > 0 && <HistoryReport key={'history-button'} itemStore={itemStore} />]}
				column={1}
				bordered
				labelStyle={{ width: 350 }}
			>
				{itemStore.isSendConfirm && (
					<>
						<Descriptions.Item label={'Отправлено на утверждение'}>
							{itemStore.lastReport.createdAtFormatted}
						</Descriptions.Item>

						<Descriptions.Item label={'Текущий статус'}>
							<Tag>{itemStore.localStatus}</Tag>
						</Descriptions.Item>

						<Descriptions.Item label={'Информация'}>{itemStore.lastReport.comment}</Descriptions.Item>

						{itemStore.lastReport.files.length > 0 && (
							<>
								<Descriptions.Item label={'Загруженные файлы'}>
									{itemStore.lastReport.files.map((file) => (
										<div key={file.id}>
											<a href={file.fullPath} target={'_blank'} rel="noreferrer">
												{file.name}
											</a>
										</div>
									))}
								</Descriptions.Item>
							</>
						)}
					</>
				)}

				{/** Если была возвращаена */}

				{itemStore.local_status.isReturn && (
					<>
						<Descriptions.Item
							label={
								<>
									<Typography.Paragraph
										style={{ display: 'flex', columnGap: 5, alignItems: 'center', margin: 0, color: '#f5222d' }}
									>
										<Badge count={<WarningOutlined />} style={{ color: '#f5222d' }} />
										На доработку
									</Typography.Paragraph>
								</>
							}
						>
							{itemStore.lastReport.createdAtFormatted} от {itemStore.lastReport.user.shortFullName}
						</Descriptions.Item>

						<Descriptions.Item label={''}>{itemStore.lastReport.comment}</Descriptions.Item>

						{itemStore.lastReport.files.length > 0 && (
							<Descriptions.Item label={''}>
								{itemStore.lastReport.files.map((file) => (
									<div key={file.id}>
										<a href={file.fullPath} target={'_blank'} rel="noreferrer">
											{file.name}
										</a>
									</div>
								))}
							</Descriptions.Item>
						)}
					</>
				)}

				{!itemStore.isSendConfirm && (
					<>
						<Descriptions.Item label={'Подвердить выполнение'}>
							<Checkbox checked={isDone} onChange={handleOnIsDone} disabled={itemStore.isSendConfirm} />
						</Descriptions.Item>

						{isDone && (
							<>
								<Descriptions.Item label={'Комментарий выполнения'}>
									<Input.TextArea
										disabled={itemStore.isSendConfirm}
										autoSize={{ minRows: 3 }}
										value={comment}
										onChange={(e) => setComment(e.target.value)}
									/>
								</Descriptions.Item>

								<Descriptions.Item label={'Файлы'}>
									<Upload
										beforeUpload={() => false}
										multiple
										onChange={(e) => handleOnSetUpload(e.fileList)}
										onRemove={(e) => console.log(e)}
									>
										<Container>
											<UploadOutlined /> Выбрать файлы или переместить сюда
										</Container>
									</Upload>
								</Descriptions.Item>

								<Descriptions.Item>
									<Button
										type={'primary'}
										onClick={handleOnUpdate}
										disabled={isDone && itemStore.result_report.length > 0}
									>
										Отправить отчет
									</Button>
								</Descriptions.Item>
							</>
						)}
					</>
				)}
			</Descriptions>
		</>
	);
});
