import { Card, List } from 'antd';
import { observer } from 'mobx-react-lite';

import { PermissionChild } from './PermissionChild';
import { PermissionStore } from '../../../../stores/permissions/permission.store';

interface PermissionCardProps {
	permissionStore: PermissionStore;
}

export const PermissionCard = observer(({ permissionStore }: PermissionCardProps) => {
	const handleOnDeleteFromParent = (id: number) => {
		permissionStore.deleteChildById(id);
	};

	return (
		<Card type={'inner'} title={permissionStore.description} style={{ height: '100%' }}>
			<List
				itemLayout={'horizontal'}
				dataSource={permissionStore.children}
				renderItem={(permission: PermissionStore) => {
					return (
						<PermissionChild
							key={permission.id}
							permissionStore={permission}
							onDeleteFromParent={handleOnDeleteFromParent}
						/>
					);
				}}
			/>
		</Card>
	);
});
