import {
	EditOutlined,
	EyeOutlined,
	HolderOutlined,
	IssuesCloseOutlined,
	MinusCircleTwoTone,
	PlusCircleTwoTone,
	PlusOutlined,
	SearchOutlined,
	WarningOutlined,
} from '@ant-design/icons';
import { SensorDescriptor, SensorOptions } from '@dnd-kit/core/dist/sensors/types';
import { Avatar, Button, Dropdown, Input, MenuProps, Popover, Table, Tag, Tooltip, Typography } from 'antd';
import { Observer } from 'mobx-react-lite';

import { taskExpandedRowRender } from './ItemTaskTable/ItemTaskTable';
import { ItemDropDownKey, NameDays } from '../../../../../../constants';
import { getInformationUser, IconEditItem, numWords } from '../../../../../../helpers';
import { MenuInfoAntD } from '../../../../../../interfaces';
import { useStores } from '../../../../../../stores';
import { ItemListStore } from '../../../../../../stores/item/item-list.store';
import { ItemsStore } from '../../../../../../stores/item/items.store';
import { COLORS } from '../../../../../../stylesheets';
import { AvatarExtra } from '../../../../../layouts/CabinetLayout/Sider/UserBlock/UserBlock.styled';

interface ExpandedRowRenderProps {
	itemObject: ItemListStore;
	itemsStore: ItemsStore;
	expandedRowKeys?: Array<number>;
	onSetExpandedRowKeys?: (data: Array<number>) => void;
	sensors: SensorDescriptor<SensorOptions>[];
}

export const expandedRowRender = ({
	itemObject,
	itemsStore,
	expandedRowKeys,
	onSetExpandedRowKeys,
	sensors,
}: ExpandedRowRenderProps) => {
	const { authStore } = useStores();

	const items: MenuProps['items'] = [
		{
			key: ItemDropDownKey.ViewVolume,
			label: (
				<Button type={'link'} icon={<EyeOutlined />}>
					Просмотр
				</Button>
			),
		},
		{
			key: ItemDropDownKey.Edit,
			label: (
				<Button type={'link'} icon={<EditOutlined />}>
					Редактировать
				</Button>
			),
		},
		{
			key: ItemDropDownKey.CreateTask,
			label: (
				<Button type={'link'} icon={<PlusOutlined />}>
					Создать задачу
				</Button>
			),
		},
		/*{
      key: ItemDropDownKey.Export,
      label: (
        <Button type={'link'} icon={<PrinterOutlined />}>
          Экспорт
        </Button>
      ),
    },*/
	];

	const handleOnClick = async (e: MenuInfoAntD, row: ItemListStore) => {
		if (e.key === ItemDropDownKey.Edit) {
			// Очищаем стор
			itemsStore.clearSelected();

			// Устанавливаем
			itemsStore.selected.setId(row.id);

			// Получаем данные
			await itemsStore.selected.get();

			// Открываем окно
			itemsStore.selected.drawer.show();
			return;
		}

		// Создание задачи
		else if (e.key === ItemDropDownKey.CreateTask) {
			// Очищаем стор
			itemsStore.selected.clearParentVolumeWrapper();

			// Устанавливаем
			itemsStore.selected.createParenVolumeWrapper();

			itemsStore.selected.parent_volume?.setId(row.id);

			// Получаем данные
			await itemsStore.selected.parent_volume?.get();

			// Устанавливаем типа
			itemsStore.selected.setTypeTask();

			// Устанавливаем тип сетей
			if (itemsStore.selected.parent_volume?.item_object_type_id) {
				itemsStore.selected.setItemObjectType(itemsStore.selected.parent_volume?.item_object_type_id);
			}

			// Открываем окно
			itemsStore.selected.drawer.show();
		}

		// Просмотр объекта
		else if (e.key === ItemDropDownKey.ViewVolume) {
			// Очищаем стор
			itemsStore.clearSelected();

			// Устанавливаем
			itemsStore.selected.setId(row.id);

			// Получаем данные
			await itemsStore.selected.get();

			// Открываем окно
			itemsStore.selected.viewer.show();
		}
	};

	const columns: any = [
		{
			title: 'Шифр тома',
			align: 'center',
			render: (_, row) => {
				return (
					<Observer>
						{() => {
							return (
								<Typography.Title style={{ color: COLORS.BLUE_100, fontSize: 12, margin: 0 }} ellipsis={true}>
									<IconEditItem item={row} />
									{itemObject.cipher}-{row.cipher}
								</Typography.Title>
							);
						}}
					</Observer>
				);
			},
			filterDropdown: ({ selectedKeys, setSelectedKeys, confirm, clearFilters }) => {
				return (
					<Input
						autoFocus
						placeholder={'Минимум 3 символа'}
						name={'cipher'}
						allowClear
						value={selectedKeys[0] as string}
						onChange={async (e) => {
							const { value, name } = e.target;
							setSelectedKeys(value ? [value] : []);
							itemObject.childrenTable.addFilter(name, value);

							if (value.length === 0 && clearFilters) {
								clearFilters();
								setSelectedKeys([]);
							}

							if (value.length > 0 && value.length < 3) {
								return false;
							}
						}}
						onBlur={() => {
							confirm({
								closeDropdown: true,
							});
						}}
					/>
				);
			},
			filterIcon: () => {
				return <SearchOutlined />;
			},
		},
		{
			title: 'Наименование тома',
			dataIndex: 'title',
			align: 'center',
			ellipsis: true,
			filterDropdown: ({ selectedKeys, confirm }) => {
				return (
					<Input
						autoFocus
						placeholder={'Минимум 3 символа'}
						name={'title'}
						allowClear
						value={selectedKeys[0] as string}
						onBlur={() => confirm()}
					/>
				);
			},
			filterIcon: () => {
				return <SearchOutlined />;
			},
			render: (_, row) => {
				return (
					<>
						{row.is_finish && (
							<Tooltip title={`Закрывающий том`}>
								<Tag icon={<IssuesCloseOutlined />} />
							</Tooltip>
						)}{' '}
						{row.title}
					</>
				);
			},
		},
		{
			title: 'Статус',
			align: 'center',
			width: 150,
			render: (_, record) => {
				return (
					<Typography.Title style={{ color: COLORS.BLUE_100, fontSize: 12 }} ellipsis={true}>
						{record.local_status.status}
					</Typography.Title>
				);
			},
		},
		{
			title: 'Участники',
			align: 'center',
			render: (_, row) => {
				return (
					<Observer>
						{() => {
							return (
								<Avatar.Group>
									{row.creator.isExist && (
										<Popover title={'Ответственный'} content={getInformationUser(row.creator)}>
											<AvatarExtra style={{ background: COLORS.BLUE_80 }}>{row.creator.initials}</AvatarExtra>
										</Popover>
									)}

									{row.responsible.isExist && (
										<Popover
											title={'Исполнитель'}
											content={
												row.responsible.isContractor
													? getInformationUser(row.responsible)
													: getInformationUser(row.responsible)
											}
										>
											<AvatarExtra style={{ background: COLORS.BLUE_100 }}>
												{row.responsible.isContractor ? row.responsible.initials : row.responsible.initials}
											</AvatarExtra>
										</Popover>
									)}
								</Avatar.Group>
							);
						}}
					</Observer>
				);
			},
		},
		{
			title: 'Начало / Заершение',
			align: 'center',
			render: (_, row) => {
				return (
					<Observer>
						{() => {
							return (
								<>
									{row.start_date.isValid() && row.end_date.isValid() && (
										<span>
											{row.start_date?.format('DD MMMM YYYY')} - {row.end_date?.format('DD MMMM YYYY')}
										</span>
									)}

									{!row.start_date.isValid() && !row.end_date.isValid() && (
										<Tag color={'red'} icon={<WarningOutlined />}>
											Даты не установлены
										</Tag>
									)}
								</>
							);
						}}
					</Observer>
				);
			},
		},
		{
			title: 'Отклонение',
			align: 'center',
			render: (_, row) => {
				return (
					<Observer>
						{() => {
							return (
								<span>
									{row.deviation_days} {numWords(row.deviation_days, NameDays)}
								</span>
							);
						}}
					</Observer>
				);
			},
		},
		{
			title: 'Действия',
			align: 'center',
			width: 100,
			render: (_, row) => {
				return (
					<Dropdown
						menu={{
							onClick: (e) => handleOnClick(e, row),
							items,
						}}
						trigger={['click']}
					>
						<Button
							icon={<HolderOutlined />}
							type={'default'}
							style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', margin: '0 auto' }}
						/>
					</Dropdown>
				);
			},
		},
	];

	return (
		<Observer>
			{() => {
				return (
					<Table
						loading={itemObject.childrenTable.isLoading}
						rowKey={'id'}
						columns={columns}
						dataSource={itemObject.childrenTable.list}
						pagination={{
							current: itemObject.childrenTable.pagination.current_page,
							total: itemObject.childrenTable.pagination.total,
							onChange: (page) => itemObject.childrenTable.pagination.setCurrentPage(page),
							style: {
								marginTop: 45,
							},
						}}
						expandable={{
							rowExpandable: (row) => row.isExistChildren,
							expandedRowRender: (row) => taskExpandedRowRender(row, itemsStore, sensors),
							onExpand: async (expanded, record) => {
								if (expanded) {
									// Если подгрузки еще не было
									if (!record.childrenTable.isDirty && record.childrenTable.list.length === 0) {
										await record.childrenTable.getList();
									}

									// Записываем
									if (expandedRowKeys && onSetExpandedRowKeys) {
										itemsStore.setChildrenRelations([...expandedRowKeys, record.id]);
										onSetExpandedRowKeys([...expandedRowKeys, record.id]);
									}
								} else {
									if (expandedRowKeys && onSetExpandedRowKeys) {
										const findIndex = expandedRowKeys.findIndex((item) => item === record.id);

										if (findIndex) {
											// Удаляем элемент
											expandedRowKeys.splice(findIndex);

											itemsStore.setChildrenRelations(expandedRowKeys);

											// Записываем результат
											onSetExpandedRowKeys(expandedRowKeys);
										}
									}
								}
							},
							expandIcon: ({ expanded, onExpand, record }) => {
								return expanded ? (
									<MinusCircleTwoTone
										onClick={(e) => {
											e.preventDefault();
											e.stopPropagation();
											onExpand(record, e);
										}}
									/>
								) : (
									<PlusCircleTwoTone
										onClick={(e) => {
											e.preventDefault();
											e.stopPropagation();
											onExpand(record, e);
										}}
									/>
								);
							},
						}}
						onRow={(row) => {
							return {
								onDoubleClick: async () => {
									// Открываем окно для редактирования
									itemsStore.selected.drawer.show();

									// Устаналиваем ID
									itemsStore.selected.setId(row.id);

									// Получаем информацию по объекту
									await itemsStore.selected.get();
								},
							};
						}}
					/>
				);
			}}
		</Observer>
	);
};
