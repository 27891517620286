import { action, makeObservable, observable } from 'mobx';

import { UserTypeI } from '../../interfaces/user';

export class UserTypeStore implements UserTypeI {
	@observable id = 0;
	@observable name = '';

	constructor(initialData: UserTypeI | null) {
		makeObservable(this);

		if (initialData) {
			this.fillStore(initialData);
		}
	}

	@action
	fillStore(data: UserTypeI) {
		const { id, name } = data;

		this.id = id;
		this.name = name;
	}
}
