import { observer } from 'mobx-react-lite';

import { useStores } from '../../../../stores';

export const NotificationBody = observer(() => {
	const { notificationsStore } = useStores();
	return (
		<div style={{ width: 300 }}>
			{notificationsStore.table.list.map((notify) => {
				return <div key={notify.id}></div>;
			})}
		</div>
	);
});
