import { RefObject } from 'react';

import { action, makeObservable, observable } from 'mobx';

interface Tour {
	[key: string]: RefObject<any>;
}

export class TourStore {
	@observable targets: Tour = {};

	constructor() {
		makeObservable(this);
	}

	@action.bound
	addTarget(name = '', ref: RefObject<any>) {
		if (!this.targets[name]) {
			this.targets[name] = ref;
		}
	}
}
