import dayjs from 'dayjs';
import { action, makeObservable, observable } from 'mobx';

import { TriggerExecuteEvent } from '../../interfaces/triggers';

export class TriggerExecuteEventStore implements TriggerExecuteEvent {
	@observable id = 0;
	@observable slug = '';
	@observable name = '';
	@observable created_at = dayjs();
	@observable updated_at = dayjs();

	constructor(initialData: TriggerExecuteEvent | null) {
		makeObservable(this);

		if (initialData) {
			this.fillStore(initialData);
		}
	}

	@action
	fillStore(data: TriggerExecuteEvent) {
		const { id, slug, name, created_at, updated_at } = data;

		this.id = id;
		this.slug = slug;
		this.name = name;
		this.created_at = created_at;
		this.updated_at = updated_at;
	}
}
