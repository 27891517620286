import { action, makeObservable, observable } from 'mobx';

import { WorkWeekData } from '../../interfaces/work-week';

export class WorkWeekDataStore implements WorkWeekData {
	@observable complete = 0;
	@observable total = 0;

	constructor(initialData: WorkWeekData | null) {
		makeObservable(this);

		if (initialData) {
			this.fillStore(initialData);
		}
	}

	@action
	fillStore(data: WorkWeekData) {
		const { complete, total } = data;

		this.complete = complete;
		this.total = total;
	}
}
