import { makeObservable, observable } from 'mobx';

import { ObjectFileI } from '../../interfaces/object/object-file.interface';
import { FileStore } from '../file/file.store';

export class ObjectFileStore extends FileStore implements ObjectFileI {
	@observable object_id = 0;

	constructor(initialData: ObjectFileI | null) {
		super(initialData);
		makeObservable(this);

		if (initialData) {
			this.fillStore(initialData);
		}
	}
}
