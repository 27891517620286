import { ObjectDetailStore } from '../../stores/objects/object-detail.store';
import { VolumeStore } from '../../stores/volume/volume.store';

export interface WebsocketCallback<T> {
	event: WsTypeEvent;
	// eslint-disable-next-line no-unused-vars
	callback: (data: WsMessageI<T>) => void;
}

export interface WsMessageI<T> {
	event: WsTypeEvent;
	payload: T;
}

export interface WsCountUserI {
	count: number;
}

export enum WsTypeEvent {
	count = 'count',
	object = 'object',
	volume = 'volume',
	task = 'task',
	assigned_to_me = 'assigned_to_me',
	work_object_volume = 'work_object_volume',
	chatMessage = 'chat_message',
	geolocation = 'geolocation',
	geolocationNotify = 'geolocation_notify',
	assignItem = 'assign_item',
	assignItemCreator = 'assign_item_creator',
	notification = 'notification',
	statusChange = 'status_changed',
	itemEnabled = 'item_enabled',
	itemDisabled = 'item_disabled',
}

export type WsNotification = WsPayload;

export type WsNotificationStore = ObjectDetailStore | VolumeStore | null;

export interface WsPayload {
	[key: string]: any;
}
