import { FormInstance } from 'antd';
import { observer } from 'mobx-react-lite';

import { useStores } from '../../../../../../stores';
import { FileUploadBlock } from '../../../../../common';

interface FilesTabProps {
	form: FormInstance;
}

export const FilesTab = observer(({ form }: FilesTabProps) => {
	const { objectsStore } = useStores();
	const { selected: currentObject } = objectsStore;
	const { volumes } = currentObject;
	const { selected: currentVolume } = volumes;

	console.log(form);

	return (
		<>
			<FileUploadBlock label={'Загрузка файлов'} nameField={'files'} list={currentVolume.files.list} />

			{/*<Form.Item
				label={'Загрузка файлов'}
				name={'files'}
				valuePropName={'fileList'}
				getValueFromEvent={(e) => e.fileList}
			>
				<Upload beforeUpload={() => false} multiple>
					<Button loading={currentVolume.isLoading}>Загрузить</Button>
				</Upload>
			</Form.Item>

			<Form.Item label={'Загруженные файла'}>
				{currentVolume.files.list.map((file) => {
					return (
						<div key={file.id} style={{ display: 'flex', columnGap: 15, alignItems: 'center', marginBottom: 10 }}>
							<Popconfirm title={'Уверены что хотите удалить?'} onConfirm={() => handleOnDelete(file)}>
								<Button icon={<DeleteOutlined />} type={'primary'} danger />
							</Popconfirm>
							<a href={file.fullPath} target={'_blank'} rel="noreferrer">
								{file.origin_name}
							</a>
							<span>Загружен в {file.formattedCreatedAt}</span>
						</div>
					);
				})}
			</Form.Item>*/}
		</>
	);
});
