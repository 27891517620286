import styled, { css } from 'styled-components';

import { COLORS } from '../../../../../../stylesheets';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	height: 100%;
	margin: 25px 0 0;
`;

interface ItemProps {
	isChoose: boolean;
}

export const Item = styled.div<ItemProps>`
	display: grid;
	grid-template-columns: 20px 1fr;
	border-bottom: 1px solid ${COLORS.GRAY_20};
	padding: 10px 15px 5px;
	cursor: pointer;
	transition: all 0.2s;
	background: ${COLORS.TABLE_TD};

	&:first-child {
		border-radius: 10px 10px 0 0;
	}

	&:last-child {
		border-radius: 0 0 10px 10px;
	}

	&:hover {
		background: ${COLORS.SILVER_20};
	}

	${(props) =>
		props.isChoose &&
		css`
			background: ${COLORS.BLUE_100};

			${Person}, ${LastMessage} > span {
				color: ${COLORS.White};
			}

			&:hover {
				background: ${COLORS.BLUE_100};
			}
		`}
`;

export const StatusContainer = styled.div``;

export const BodyContainer = styled.div`
	overflow: hidden;
`;

export const Person = styled.div`
	font-weight: 600;
`;

export const LastMessage = styled.div`
	display: flex;
	margin: 5px 0;
	color: ${COLORS.GRAY_60};
`;

export const EmptyContainer = styled.div`
	display: flex;
	flex-direction: column;
	height: 100%;
	align-items: center;
	justify-content: center;
	color: ${COLORS.GRAY_80};
`;
