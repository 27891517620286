import { ReactNode } from 'react';

import { ContentExtra } from './Content.styled';

interface ContentProps {
	children?: ReactNode;
}

export const Content = ({ children = null }: ContentProps) => {
	return <ContentExtra>{children}</ContentExtra>;
};
