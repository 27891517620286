import { useEffect } from 'react';

import { EyeOutlined } from '@ant-design/icons';
import { Button, List } from 'antd';
import { observer } from 'mobx-react-lite';

import { useStores } from '../../../../stores';
import { VolumeStore } from '../../../../stores/volume/volume.store';
import { CardWidget, Status } from '../../../common';

export const VolumeWidget = observer(() => {
	const { userDashboardStore } = useStores();
	const { volumes } = userDashboardStore;
	const { tableUser, viewDetail } = volumes;

	useEffect(() => {
		(async () => {
			await tableUser.getList();
		})();
	}, []);

	const handleOnShow = async (volumeStore: VolumeStore) => {
		viewDetail.setData(volumeStore);
		await viewDetail.data.get();
		viewDetail.modal.open();
	};

	return (
		<>
			<CardWidget title={'Тома'} tableStore={tableUser}>
				<List
					loading={tableUser.isLoading || viewDetail.data.isLoading}
					dataSource={tableUser.list}
					renderItem={(volumeStore: VolumeStore) => {
						return (
							<List.Item
								key={volumeStore.id}
								actions={[
									<Button
										key={'show'}
										type={'primary'}
										icon={<EyeOutlined />}
										onClick={() => handleOnShow(volumeStore)}
									/>,
								]}
							>
								<List.Item.Meta
									avatar={null}
									title={
										<span style={{ display: 'flex', alignItems: 'center' }}>
											<Status
												confirmation_by_responsible={volumeStore.confirmation_by_responsible}
												confirmation_by_creator={volumeStore.confirmation_by_creator}
											/>
											{volumeStore.completeCipher} | {volumeStore.name_volume}
										</span>
									}
									description={
										<>
											Объект : {volumeStore.object.title}
											<br />
											{volumeStore.startDateFormatted} - {volumeStore.endDateFormatted}
										</>
									}
								/>
							</List.Item>
						);
					}}
				/>
			</CardWidget>
		</>
	);
});
