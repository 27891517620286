import { Table } from 'antd';
import { observer } from 'mobx-react-lite';

import { useColumns } from './ContractorsTable.columns';
import { useStores } from '../../../../stores';

export const ContractorsTable = observer(() => {
	const { contractorsStore } = useStores();

	return (
		<>
			<Table
				rowKey={'id'}
				columns={useColumns()}
				dataSource={contractorsStore.table.list}
				loading={contractorsStore.table.isLoading}
				pagination={{
					total: contractorsStore.table.pagination.total,
					pageSize: contractorsStore.table.pagination.per_page,
					onChange: (page) => contractorsStore.table.pagination.setCurrentPage(page),
				}}
				onRow={(row) => {
					return {
						onDoubleClick: async () => {
							// Открываем окно для редактирования
							contractorsStore.drawer.show();

							// Устаналиваем ID
							contractorsStore.selected.setId(row.id);

							// Получаем информацию
							await contractorsStore.selected.get();
						},
					};
				}}
				scroll={{ x: 1000 }}
			/>
		</>
	);
});
