import dayjs from 'dayjs';
import { action, computed, makeObservable, observable } from 'mobx';

import { UserFileStore } from './user-file.store';
import { UserPhoneStore } from './user-phone.store';
import { UserTypeStore } from './user-type.store';
import { MethodsRequest, UsersTypeId } from '../../constants';
import { API } from '../../core';
import { checkDateIsValid, ErrorAPI, getFirstChar, makeAbbr } from '../../helpers';
import { User, UserFile } from '../../interfaces';
import { UploadRequest } from '../../requests';
import { CoreResponse } from '../../responses';
import { CrudStore } from '../common/crud.store';
import { ContractorStore } from '../contractors/contractor.store';
import { RoleStore } from '../role/role.store';

export class UserStore extends CrudStore<User> implements User {
	PATH = 'users';

	@observable user_type_id = UsersTypeId.Employees;
	@observable email = '';
	@observable first_name = '';
	@observable last_name = '';
	@observable patronymic = '';
	@observable post = '';
	@observable birthday = dayjs().set('year', 1985);
	@observable avatar = '';
	@observable type = new UserTypeStore(null);
	@observable role = new RoleStore(null);
	@observable phones: UserPhoneStore[] = [];
	@observable files: UserFile[] = [];
	@observable is_delete = false;
	@observable created_at = dayjs();
	@observable updated_at = dayjs();

	@observable contractor = new ContractorStore(null);

	constructor(initialData: User | null) {
		super();
		makeObservable(this);

		if (initialData) {
			this.fillStore(initialData);
		}
	}

	@computed
	get initials() {
		return makeAbbr(`${this.first_name} ${this.patronymic}`);
	}

	@computed
	get shortFullName() {
		const name = getFirstChar(this.first_name);
		const patronymic = getFirstChar(this.patronymic);
		const complete = `${this.last_name}${name ? ' ' : ''}${name}${patronymic ? '.' : ''}${patronymic}.`;
		return complete || this.email;
	}

	@computed
	get shortNameWithRole() {
		return this.shortFullName ? `${this.shortFullName} (${this.roleName})` : '';
	}

	@computed
	get roleName() {
		return this.role.name;
	}

	@computed
	get formattedBirthday() {
		return checkDateIsValid(this.birthday) ? this.birthday.format('DD MMMM YYYY') : 'Не указан';
	}

	@computed
	get phoneUser() {
		return 'Не указан';
	}

	@computed
	get formattedLastEnter() {
		return this.updated_at.format('DD MMMM YYYY HH:mm');
	}

	@computed
	get avatarFullPath(): string {
		let complete = 'avatars/default.png';

		if (this.avatar !== 'avatars/default.png') {
			complete = `${process.env.REACT_APP_CORE_FILE_URL}${this.avatar}`;
		}

		return complete;
	}

	@computed
	get isExistAvatar(): boolean {
		return this.avatar !== 'avatars/default.png';
	}

	@computed
	get fieldsProfile() {
		return {
			email: this.email,
			first_name: this.first_name,
			last_name: this.last_name,
			patronymic: this.patronymic,
			birthday: this.birthday,
			phones: this.phones,
		};
	}

	@computed
	get isExist() {
		return this.id !== 0;
	}

	@computed
	get isContractor() {
		return Boolean(this.contractor.id);
	}

	@action.bound
	async uploadAvatar(values: UploadRequest) {
		try {
			const { data } = await API.request<CoreResponse<User>>(`${this.PATH}/upload-avatar/${this.id}`, {
				method: MethodsRequest.Post,
				body: API.getFormData(values),
			});

			this.fillStore(data);
		} catch (e) {
			ErrorAPI('uploadAvatar', e);
		}
	}

	@action.bound
	async deleteAvatar() {
		try {
			const { data } = await API.request<CoreResponse<User>>(`${this.PATH}/delete-avatar/${this.id}`, {
				method: MethodsRequest.Delete,
			});

			this.fillStore(data);
		} catch (e) {
			ErrorAPI('deleteAvatar', e);
		}
	}

	@action
	fillStore(data: User) {
		const {
			id,
			user_type_id,
			email,
			first_name,
			last_name,
			patronymic,
			post,
			birthday,
			avatar,
			type,
			phones,
			files,
			role,
			is_delete,
			created_at,
			updated_at,
			contractor,
		} = data;

		this.id = id;
		this.user_type_id = user_type_id;
		this.email = email;
		this.first_name = first_name;
		this.last_name = last_name;
		this.patronymic = patronymic;
		this.post = post;
		this.birthday = birthday ? dayjs(birthday) : dayjs().subtract(18, 'years');
		this.avatar = avatar;
		this.type = new UserTypeStore(type);
		this.phones = (phones || []).map((phone) => new UserPhoneStore(phone));
		this.files = (files || []).map((file) => new UserFileStore(file));
		this.role = new RoleStore(role);
		this.is_delete = is_delete;
		this.created_at = dayjs(created_at);
		this.updated_at = dayjs(updated_at);
		this.contractor = new ContractorStore(contractor);
	}
}
