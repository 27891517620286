import { Col, DatePicker, Form, FormInstance, Input, Row, Select } from 'antd';
import FormItem from 'antd/es/form/FormItem';
import { observer } from 'mobx-react-lite';

import { useStores } from '../../../../../../../stores';

interface InformationTabProps {
	form: FormInstance;
}

export const InformationTab = observer(({ form }: InformationTabProps) => {
	const {
		authStore,
		usersStore,
		itemsStore: { selected: currentItemVolume },
		responsiblesStore,
	} = useStores();

	return (
		<>
			<Form.Item name={'parent_id'} hidden>
				<Input type={'hidden'} />
			</Form.Item>
			<Form.Item name={'item_type_id'} hidden>
				<Input type={'hidden'} />
			</Form.Item>
			<Form.Item name={'item_object_type_id'} hidden>
				<Input type={'hidden'} />
			</Form.Item>

			<Row gutter={15}>
				<Col span={12}>
					<Form.Item label={'Ответственный тома'} name={'creator_id'}>
						<Select
							placeholder={'Выберите ответсвенного'}
							showSearch
							filterOption={(inputValue, option) => {
								return String(option?.children ?? '')
									.toLowerCase()
									.includes(inputValue.toLowerCase());
							}}
						>
							{responsiblesStore.list.map((responsible) => {
								return (
									<Select.Option key={responsible.id} value={responsible.id}>
										{responsible.shortFullName}
									</Select.Option>
								);
							})}
						</Select>
					</Form.Item>
				</Col>

				<Col span={12}>
					<Form.Item label={'Исполнитель тома'} name={'responsible_id'} rules={[{ required: true }]}>
						<Select
							showSearch
							filterOption={(inputValue, option) => {
								return String(option?.label ?? '')
									.toLowerCase()
									.includes(inputValue.toLowerCase());
							}}
							placeholder={'Выберите исполнителя'}
							options={usersStore.selectBoxVolume}
						/>
					</Form.Item>
				</Col>
			</Row>

			<Row gutter={15}>
				<Col span={12}>
					<FormItem label={'Наименование тома'} name={'title'} rules={[{ required: true }]}>
						<Input placeholder={'Введите наименование тома'} />
					</FormItem>
				</Col>

				<Col span={12}>
					<FormItem label={'Шифр тома'} name={'cipher'} rules={[{ required: true }]}>
						<Input addonBefore={`${currentItemVolume?.parent_object?.cipher}-`} placeholder={'Введите шифр тома'} />
					</FormItem>
				</Col>
			</Row>

			<Row gutter={15}>
				<Col span={12}>
					<FormItem shouldUpdate>
						{() => {
							const objectStartDate = currentItemVolume?.parent_object?.start_date;
							const objectEndDate = currentItemVolume?.parent_object?.end_date;

							return (
								<FormItem
									label={'Начало работ'}
									name={'start_date'}
									rules={[
										{ required: true },
										{
											validator: async (_, volumeStartDate) => {
												if (volumeStartDate && objectStartDate) {
													return Promise.resolve();
												} else {
													return Promise.resolve();
												}
											},
										},
									]}
								>
									<DatePicker
										format={'DD MMMM YYYY'}
										disabledDate={(current) => {
											if (objectStartDate && current && objectEndDate) {
												return (
													(current && current <= objectStartDate.startOf('day')) ||
													current >= objectEndDate.endOf('day')
												);
											} else {
												return false;
											}
										}}
									/>
								</FormItem>
							);
						}}
					</FormItem>
				</Col>

				<Col span={12}>
					<FormItem shouldUpdate>
						{() => {
							const objectStartDate = currentItemVolume?.parent_object?.start_date;
							const objectEndDate = currentItemVolume?.parent_object?.end_date;
							const fieldStartDate = form.getFieldValue('start_date');

							return (
								<FormItem
									label={'Окончание работ'}
									name={'end_date'}
									rules={[
										{ required: true },
										{
											validator: async (_, volumeEndDate) => {
												if (volumeEndDate && objectEndDate) {
													return Promise.resolve();
												} else {
													return Promise.resolve();
												}
											},
										},
									]}
								>
									<DatePicker
										format={'DD MMMM YYYY'}
										disabledDate={(current) => {
											if (fieldStartDate && current && objectEndDate) {
												if (objectStartDate && current && objectEndDate) {
													return (
														(current && current <= objectStartDate.startOf('day')) ||
														current >= objectEndDate.endOf('day') ||
														current <= fieldStartDate
													);
												} else {
													return false;
												}
											} else {
												return false;
											}
										}}
									/>
								</FormItem>
							);
						}}
					</FormItem>
				</Col>
			</Row>

			{!currentItemVolume.isNewStore && (
				<>
					<Row gutter={15}>
						<Col span={12}>
							<FormItem label={'Фактическое завершение работ'} name={'actual_date'}>
								<DatePicker disabled={currentItemVolume.isNewStore} format={'DD MMMM YYYY'} />
							</FormItem>
						</Col>

						<Col span={12}>
							<FormItem label={'Отклонение от даты завершения'}>{currentItemVolume.deviation_days}</FormItem>
						</Col>
					</Row>
				</>
			)}
		</>
	);
});
