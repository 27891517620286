import styled from 'styled-components';

export const Container = styled.div`
	grid-row: 1/4;
	display: flex;
	width: 100%;
	column-gap: 15px;
	margin-top: auto;
	margin-bottom: auto;
	padding-inline: 50px;

	& > div {
		width: 100%;
	}
`;
