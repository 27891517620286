import { List, Skeleton } from 'antd';

import { PermissionStore } from '../../../../stores/permissions/permission.store';

interface PermissionChildProps {
	permissionStore?: PermissionStore;
	onDeleteFromParent: (id: number) => void;
}

export const PermissionChild = ({ permissionStore, onDeleteFromParent }: PermissionChildProps) => {
	/*const handleOnDelete = async () => {
    try {
      await permissionStore.delete(false);
      onDeleteFromParent(permissionStore.id);
    } catch (e) {
      ErrorAPI('handleOnDelete', e);
    }
  };*/

	console.log(permissionStore, onDeleteFromParent);

	return (
		<List.Item>
			<Skeleton title={false} loading={false}>
				{/*<Popconfirm title={'Удалить этот доступ?'} onConfirm={handleOnDelete}>
					<Button type={'primary'} danger icon={<MinusCircleOutlined />} />
				</Popconfirm>*/}
			</Skeleton>
		</List.Item>
	);
};
