import { action, computed, makeObservable, observable } from 'mobx';

import { ItemStatusStore } from './item-status.store';
import { ItemObjectTypes } from '../../constants';
import { API } from '../../core';
import { ErrorAPI } from '../../helpers';
import { ItemStatus } from '../../interfaces/item';
import { RootStore } from '../index';

export class ItemStatusesStore {
	@observable list: ItemStatusStore[] = [];

	private rootStore: RootStore;

	constructor(rootStore: RootStore) {
		makeObservable(this);

		this.rootStore = rootStore;
	}

	@action.bound
	async getList(item_object_type_id: ItemObjectTypes) {
		try {
			const data = await API.request<ItemStatus[]>(`item-statuses?item_object_type_id=${item_object_type_id}`);
			this.setList(data);
		} catch (e) {
			ErrorAPI('getList', e);
		}
	}

	@action.bound
	setList(values: ItemStatus[]) {
		this.list = (values || []).map((item) => new ItemStatusStore(item));
	}

	@computed
	get selectBoxList() {
		const { isNewStore } = this.rootStore.itemsStore.selected;

		return this.list.map((itemStatus, index) => ({
			disabled: index !== 0 && isNewStore,
			title: itemStatus.name,
			value: itemStatus.id,
			children: (itemStatus.children || []).map((subChild) => ({
				title: subChild.name,
				value: subChild.id,
				disabled: isNewStore,
			})),
		}));
	}
}
