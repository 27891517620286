import { useState } from 'react';

import { Table } from 'antd';
import { observer } from 'mobx-react-lite';

import { useColumns } from './DashboardTable.columns';
import { useColumnsTaskExchange } from './DashboardTaskExchangeTable.columns';
import { VolumeItemsTable } from './VolumeItemsTable';
import { useStores } from '../../../../../stores';

export const DashboardTable = observer(() => {
	const { userItemsStore, itemsStore } = useStores();
	// Ключи для открытых таблиц
	const [expandedRowKeys, setExpandedRowKeys] = useState<Array<number>>([]);

	return (
		<>
			<Table
				key={userItemsStore.filter_by}
				loading={userItemsStore.all.isLoading}
				rowKey={(record) => record.id}
				columns={userItemsStore.isTaskRequestTable ? useColumnsTaskExchange() : useColumns()}
				onRow={(row) => {
					return {
						onDoubleClick: async () => {
							// Показываем окно
							itemsStore.selected.viewer.show();

							// Устанавливаем ID
							itemsStore.selected.setId(row.id);

							// Получаем инфомацию
							await itemsStore.selected.get();
						},
					};
				}}
				rowClassName={'cursor-pointer'}
				dataSource={userItemsStore.all.list}
				scroll={{ x: 1000 }}
				expandable={{
					rowExpandable: (row) => (row?.children_list || []).length > 0,
					expandedRowRender: (record) => {
						return <VolumeItemsTable items={record.children_list || []} />;
					},
				}}
			/>
		</>
	);
});
