import { useEffect } from 'react';

import { Badge, Drawer, Form, Space, Tabs } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { observer } from 'mobx-react-lite';

import { Information } from './Information/Information';
import { useStores } from '../../../../stores';
import { COLORS } from '../../../../stylesheets';

export const WorkObjectDrawer = observer(() => {
	const { objectsStore } = useStores();
	const { selected: currentWorkObject } = objectsStore;
	const [form] = useForm();

	const getTitle = () => {
		return currentWorkObject.id ? `Редактирование` : 'Создание объекта';
	};

	//const getNameButton = () => (currentWorkObject.id ? 'Обновить' : 'Создать');

	const fields = {};

	// Заполняем данными
	useEffect(() => {
		form.setFieldsValue({
			...fields,
		});
	}, [currentWorkObject.id]);

	// Очистка формы
	useEffect(() => {
		return () => {
			if (!objectsStore.drawer.isShow) {
				form.resetFields();
				objectsStore.clearSelected();
			}
		};
	}, [objectsStore.drawer.isShow]);

	const handleOnSave = async () => {
		/*try {
      if (currentWorkObject.id) {
        await currentWorkObject.update(values);
      } else {
        // Сохраняем
        await currentWorkObject.create(values);
      }
      // Отправляем созданный или обновленный объект
      websocketStore.sendMessage<OldWorkObjectI>({
        event: WsTypeEvent.WORK_OBJECT,
        payload: currentWorkObject,
      });

      // Обновляем список
      await objectsStore.table.getList();
      // Закрываем drawer
      objectsStore.drawer.close();
      // Очищаем форму
      form.resetFields();
    } catch {
      /!* empty *!/
    }*/
	};

	//const handleOnSend = () => form.submit();

	return (
		<Drawer
			forceRender
			open={objectsStore.drawer.isShow}
			width={800}
			title={getTitle()}
			styles={{
				body: {
					paddingTop: 0,
				},
				footer: {
					textAlign: 'center',
					padding: 15,
				},
			}}
			onClose={objectsStore.drawer.close}
			footer={[
				<Space size={15} key={'space'}>
					{/*<Button key={'cancel'} onClick={objectsStore.drawer.close}>
						Отменить
					</Button>
					<Button
						key={'create'}
						icon={<SaveOutlined />}
						type={'primary'}
						loading={currentWorkObject.isLoading}
						onClick={handleOnSend}
					>
						{getNameButton()}
					</Button>*/}
				</Space>,
			]}
		>
			<Form form={form} layout={'vertical'} onFinish={handleOnSave} autoComplete="off">
				<Tabs defaultActiveKey={'information'}>
					<Tabs.TabPane key={'information'} tab="Основная информация">
						<Information form={form} />
					</Tabs.TabPane>
					<Tabs.TabPane key={'files'} tab="Файлы"></Tabs.TabPane>
					<Tabs.TabPane
						key={'contractors'}
						tab={
							<Badge
								count={0}
								showZero
								size={'small'}
								offset={[5, -5]}
								style={{ fontSize: 10 }}
								color={COLORS.BLUE_100}
							>
								Контрагенты
							</Badge>
						}
					></Tabs.TabPane>
				</Tabs>
			</Form>
		</Drawer>
	);
});

{
	/*<Row>
          <Col span={24}>
            <Form.Item label={'Тип объекта'} name={'object_type_id'}>
              <Radio.Group buttonStyle={'solid'}>
                {objectTypesStore.list.map((workObjectType) => {
                  return (
                    <Radio.Button key={workObjectType.id} value={workObjectType.id}>
                      {workObjectType.name}
                    </Radio.Button>
                  );
                })}
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={12}>
            <Form.Item label={'Автор объекта'}>
              (<strong>Вы</strong>) {authStore.current.shortFullName}
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item label={'Ответсвенный по объекту'} name={'responsible_id'} rules={[{ required: true }]}>
              <Select placeholder={'Выберите ответсвенного'}>
                {responsiblesStore.list.map((responsible) => {
                  return (
                    <Select.Option key={responsible.id} value={responsible.id}>
                      {responsible.shortFullName}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={24}>
            <Form.Item label={'Титул проекта'} name={'object_title'} rules={[{ required: true }]}>
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={15}>
          <Col span={12}>
            <Form.Item label={'Номер договора'} name={'contract_number'} rules={[{ required: true }]}>
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label={'Стоимость'} name={'price'} rules={[{ required: true }]}>
              <InputNumber min={0} formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')} />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={15}>
          <Col span={12}>
            <Form.Item label={'Дата начала проектирование'} name={'start_designing'} rules={[{ required: true }]}>
              <DatePicker format={'DD MMMM YYYY'} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item shouldUpdate style={{ margin: 0 }}>
              {() => {
                const fieldStartDate = form.getFieldValue('start_designing');
                return (
                  <Form.Item
                    label={'Дата окончания проектирования'}
                    name={'end_contract'}
                    rules={[
                      { required: true },
                      {
                        validator: async (_, workEndDate) => {
                          if (workEndDate) {
                            if (workEndDate.diff(fieldStartDate, 'days') <= 0) {
                              return Promise.reject('Окончание работы < чем начало работ');
                            }

                            return Promise.resolve();
                          } else {
                            return Promise.resolve();
                          }
                        },
                      },
                    ]}
                  >
                    <DatePicker
                      format={'DD MMMM YYYY'}
                      disabledDate={(current) => {
                        if (fieldStartDate && current) {
                          return current && current < fieldStartDate.endOf('day');
                        } else {
                          return false;
                        }
                      }}
                    />
                  </Form.Item>
                );
              }}
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={15}>
          <Col span={12}>
            <Form.Item label={'Наличие субподряда'} name={'subcontracting'} valuePropName="checked">
              <Checkbox>Есть</Checkbox>
            </Form.Item>
          </Col>
        </Row>*/
}
