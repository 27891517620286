import { ColumnFilterItem } from 'antd/es/table/interface';
import { action, computed, makeObservable, observable } from 'mobx';

import { ResponsibleStore } from './responsible.store';
import { API } from '../../core';
import { ErrorAPI } from '../../helpers';
import { User } from '../../interfaces';
import { CoreResponse } from '../../responses';

export class ResponsiblesStore {
	@observable list: ResponsibleStore[] = [];

	constructor() {
		makeObservable(this);
	}

	@action.bound
	setList(values: User[]) {
		this.list = (values || []).map((value) => new ResponsibleStore(value));
	}

	@action.bound
	async getAll() {
		try {
			const { data } = await API.request<CoreResponse<User[]>>(`responsibles/all`);
			this.setList(data);
		} catch (e) {
			ErrorAPI('getList', e);
		}
	}

	@computed
	get filterList(): ColumnFilterItem[] {
		return this.list.map((responsible) => ({
			text: responsible.shortFullName,
			value: responsible.id,
		}));
	}

	@computed
	get selectBox() {
		return this.list.map((responsible) => ({
			value: responsible.id,
			label: responsible.shortFullName,
		}));
	}
}
