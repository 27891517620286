import { useEffect, useState } from 'react';

import { DeleteOutlined, InfoCircleOutlined, SaveOutlined } from '@ant-design/icons';
import { Avatar, Button, Checkbox, Col, DatePicker, Drawer, Form, Input, Popconfirm, Row, Tooltip, Upload } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { RcFile } from 'antd/es/upload/interface';
import dayjs from 'dayjs';
import { observer } from 'mobx-react-lite';

import { DATE_FORMAT } from '../../../../constants';
import { User } from '../../../../interfaces';
import { useStores } from '../../../../stores';
import { COLORS } from '../../../../stylesheets';
import { UserPhones } from '../../../common';

export const ProfileDrawer = observer(() => {
	const { authStore } = useStores();
	const { current: currentUser } = authStore;
	const [form] = useForm();

	const [isShowChangePassword, setIsShowChangePassword] = useState(false);

	useEffect(() => {
		if (authStore.drawer.isShow) {
			updateFields();
		}
	}, [authStore.drawer.isShow]);

	const handleOnSend = () => form.submit();

	const handleOnUpdate = async (values: User) => {
		try {
			// Обновление
			await authStore.current.update(values);

			// Убираем галоку
			setIsShowChangePassword(false);
		} catch {
			/* empty */
		}
	};

	const handleOnUploadAvatar = async (file: RcFile) => {
		await authStore.current.uploadAvatar({
			file: file,
		});

		return false;
	};

	const handleOnDeletePhoto = async () => {
		await authStore.current.deleteAvatar();
	};

	const updateFields = () => {
		form.setFieldsValue({ ...currentUser.fieldsProfile });
	};

	return (
		<Drawer
			forceRender
			open={authStore.drawer.isShow}
			width={800}
			title={`Редактирование личного профиля`}
			onClose={authStore.drawer.close}
			footer={[
				<Button
					key={'create'}
					htmlType={'submit'}
					icon={<SaveOutlined />}
					type={'primary'}
					onClick={handleOnSend}
					loading={authStore.current.isLoading}
				>
					Обновить
				</Button>,
			]}
			styles={{
				footer: {
					textAlign: 'center',
				},
			}}
		>
			<Form id={'form_profile'} form={form} layout={'vertical'} name={'profile'} onFinish={handleOnUpdate}>
				<Row gutter={15}>
					<Col span={12} style={{ textAlign: 'center' }}>
						<Form.Item label={'Фотография'} style={{ position: 'relative' }}>
							<Upload beforeUpload={handleOnUploadAvatar} showUploadList={false}>
								<Avatar
									size={150}
									style={{
										cursor: 'pointer',
										border: `2px solid ${COLORS.BLUE_100}`,
										background: COLORS.BLUE_100,
										fontSize: 12,
									}}
									src={authStore.current.avatarFullPath}
								>
									Загрузить аватарку
								</Avatar>
							</Upload>

							{authStore.current.avatar && (
								<Popconfirm title={'Удалить?'} onConfirm={handleOnDeletePhoto}>
									<Button
										style={{ position: 'absolute', right: 0, top: 0 }}
										icon={<DeleteOutlined />}
										type={'primary'}
										shape={'circle'}
										danger
									/>
								</Popconfirm>
							)}
						</Form.Item>
					</Col>

					<Col span={12}>
						<Checkbox style={{ marginBottom: 10 }} onChange={(e) => setIsShowChangePassword(e.target.checked)}>
							Сменить пароль
						</Checkbox>
						<Form.Item name={'password'} label={'Пароль'} rules={[{ required: isShowChangePassword }]}>
							<Input.Password disabled={!isShowChangePassword} />
						</Form.Item>

						<Form.Item
							name={'password_confirmation'}
							label={'Повтор пароля'}
							dependencies={['password']}
							rules={[
								{ required: isShowChangePassword },
								({ getFieldValue }) => ({
									validator(_, value) {
										if (!value || getFieldValue('password') === value) {
											return Promise.resolve();
										}
										return Promise.reject(new Error('Пароли должны совпадать'));
									},
								}),
							]}
						>
							<Input.Password disabled={!isShowChangePassword} />
						</Form.Item>
					</Col>
				</Row>

				<Row gutter={15} style={{ marginBottom: 15 }}>
					<Col span={8}>
						<Form.Item label={'Фамилия'} name={'last_name'}>
							<Input />
						</Form.Item>
					</Col>

					<Col span={8}>
						<Form.Item label={'Имя'} name={'first_name'}>
							<Input />
						</Form.Item>
					</Col>

					<Col span={8}>
						<Form.Item label={'Отчество'} name={'patronymic'}>
							<Input />
						</Form.Item>
					</Col>
				</Row>

				<Row gutter={15} style={{ marginBottom: 15 }}>
					<Col span={8}>
						<Form.Item label={'Email'} name={'email'}>
							<Input />
						</Form.Item>
					</Col>

					<Col span={8}>
						<Form.Item label={'Дата рождения'} name={'birthday'}>
							<DatePicker
								format={DATE_FORMAT.FULL}
								disabledDate={(current) => {
									return current && current > dayjs().subtract(18, 'years');
								}}
							/>
						</Form.Item>
					</Col>

					<Col span={8}>
						<Form.Item
							label={
								<span>
									<Tooltip title={'Должность назначается администратором системы'}>
										<InfoCircleOutlined />
									</Tooltip>
									<span dangerouslySetInnerHTML={{ __html: '&nbsp;' }} /> Должность
								</span>
							}
						>
							{authStore.current.roleName}
						</Form.Item>
					</Col>
				</Row>

				<Row gutter={15} style={{ marginBottom: 15 }}>
					<Col span={24}>
						<UserPhones />
					</Col>
				</Row>
			</Form>
		</Drawer>
	);
});
