import { makeObservable, observable } from 'mobx';

import { ModalStore } from '../common/modal.store';

export class ItemGenerateVolumeStore {
	@observable modal = new ModalStore();

	constructor() {
		makeObservable(this);
	}
}
