import { action, computed, makeObservable, observable } from 'mobx';

import { ProjectDocumentationStore } from './project-documentation.store';
import { API } from '../../core';
import { ErrorAPI } from '../../helpers';
import { ProjectDocumentationI } from '../../interfaces';
import { CoreResponse } from '../../responses';
import { RootStore } from '../index';

export class ProjectDocumentationsStore {
	private rootStore: RootStore;

	@observable list: ProjectDocumentationStore[] = [];

	constructor(rootStore: RootStore) {
		makeObservable(this);

		this.rootStore = rootStore;
	}

	@action.bound
	async getList() {
		try {
			const { data } = await API.request<CoreResponse<ProjectDocumentationI[]>>(`project-documentations`);

			this.setList(data);
		} catch (e) {
			ErrorAPI('getList', e);
		}
	}

	@action.bound
	setList(values: ProjectDocumentationI[]) {
		this.list = (values || []).map(
			(compositionProjectDocumentation) => new ProjectDocumentationStore(compositionProjectDocumentation),
		);
	}

	@computed
	get selectBoxList() {
		const { object_type_id } = this.rootStore.objectsStore.selected;

		return this.list.filter((item) => item.object_type_id === object_type_id);
	}
}
