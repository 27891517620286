import { action, makeObservable, observable } from 'mobx';

import { AgreementContractor, BoolNull, NumberNull, NumberNullString } from '../../interfaces';
import { AgreementPrivateContractorFileStore } from '../agreement-private-contractor/agreement-private-contractor-file.store';

export class AgreementContractorStore implements AgreementContractor {
	@observable id = 0;
	@observable agreement_id = 0;
	@observable contractor_id: NumberNullString = null;
	@observable security_id: NumberNull = null;
	@observable agreement_file_template_id: NumberNull = null;
	@observable hash_link = '';
	@observable comment = '';
	@observable approve: BoolNull = null;
	@observable send_email: BoolNull = null;
	@observable files: AgreementPrivateContractorFileStore[] = [];

	constructor(initialData: AgreementContractor | null) {
		makeObservable(this);

		if (initialData) {
			this.fillStore(initialData);
		}
	}

	@action
	fillStore(data: AgreementContractor) {
		const {
			id,
			agreement_id,
			contractor_id,
			security_id,
			agreement_file_template_id,
			hash_link,
			comment,
			approve,
			send_email,
			files,
		} = data;

		this.id = id;
		this.agreement_id = agreement_id;
		this.contractor_id = contractor_id;
		this.security_id = security_id;
		this.agreement_file_template_id = agreement_file_template_id;
		this.hash_link = hash_link;
		this.comment = comment;
		this.approve = approve;
		this.send_email = send_email;
		this.files = (files || []).map((file) => new AgreementPrivateContractorFileStore(file));
	}
}
