import React, { ChangeEvent, HTMLAttributes } from 'react';

import { Form, Input } from 'antd';
import { observer } from 'mobx-react-lite';

import { Container } from './GlobalSearch.styled';
import { FilterStore } from '../../../stores/common';

interface GlobalSearchProps extends HTMLAttributes<HTMLDivElement> {
	filterStore: FilterStore;
	ref?: React.Ref<any>;
}

export const GlobalSearch = observer(({ filterStore, ref }: GlobalSearchProps) => {
	const handleOnSearch = (e: ChangeEvent<HTMLInputElement>) => {
		const { value } = e.target;

		filterStore.setQuery(value);

		if (!filterStore.isDirty) {
			filterStore.setIsDirty();
		}
	};

	return (
		<Container>
			<Form layout={'vertical'} style={{ width: '100%' }} ref={ref}>
				<Form.Item label={'Поиск'}>
					<Input placeholder={'Введите поисковый запрос'} style={{ width: '100%' }} onChange={handleOnSearch} />
				</Form.Item>
			</Form>
		</Container>
	);
});
