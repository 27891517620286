import { useEffect, useState } from 'react';

import { Form, FormInstance, FormProps, Modal, Tabs, TabsProps } from 'antd';
import { observer } from 'mobx-react-lite';

import { InformationTab, VolumesTab } from './Tabs';
import { ItemProjectDocumentationTabs } from '../../../../constants';
import { ItemProjectDocument } from '../../../../interfaces/item-project-document';
import { useStores } from '../../../../stores';

interface ItemProjectDocumentationFormProps {
	form: FormInstance;
}

export const ItemProjectDocumentationForm = observer(({ form }: ItemProjectDocumentationFormProps) => {
	const { itemProjectDocumentsStore } = useStores();
	const { detail } = itemProjectDocumentsStore;

	const [currentTab, setCurrentTab] = useState(ItemProjectDocumentationTabs.Main);

	// Инстанс модального окна
	const [modalInstance, contextModal] = Modal.useModal();

	const itemTabs: TabsProps['items'] = [
		{
			key: ItemProjectDocumentationTabs.Main,
			label: 'Основная информация',
			children: <InformationTab />,
			forceRender: true,
		},
		{
			key: ItemProjectDocumentationTabs.Volumes,
			label: 'Тома',
			children: <VolumesTab form={form} />,
			forceRender: true,
			disabled: detail.data.isNewStore,
		},
	];

	const fields = {
		id: detail.data.id,
		item_object_type_id: detail.data.item_object_type_id,
		sort: detail.data.isNewStore ? itemProjectDocumentsStore.lastSort : detail.data.sort,
		name: detail.data.name,
		description: detail.data.description,
		item_volume_generates: detail.data.formListData,
	};

	// Если коно закрывают
	useEffect(() => {
		if (!detail.modal.isShow) {
			form.setFieldsValue({ ...fields });
			setCurrentTab(ItemProjectDocumentationTabs.Main);
		}
	}, [detail.modal.isShow]);

	// Заполнение данных
	useEffect(() => {
		if (detail.data.isDataLoaded) {
			form.setFieldsValue({ ...fields });
		}
	}, [detail.data.isDataLoaded, detail.modal.isShow]);

	// Очистка стора
	useEffect(() => {
		if (!detail.modal.isShow) {
			itemProjectDocumentsStore.clearDetail();
		}
	}, [detail.modal.isShow]);

	const handleOnSend = async (values: ItemProjectDocument) => {
		let sort = 1;

		try {
			if (detail.data.isNewStore) {
				if (Array.isArray(values?.item_volume_generates)) {
					// Обновляем сортировку
					values?.item_volume_generates.forEach((item_volume_generate, indexVolumeGenerate) => {
						if (Array.isArray(item_volume_generate.item_volume_tasks)) {
							item_volume_generate.item_volume_tasks.forEach((item_volume_task, indexVolumeTask) => {
								values.item_volume_generates[indexVolumeGenerate].item_volume_tasks[indexVolumeTask].sort = sort;
								sort++;
							});
						}
					});
				}

				// Создание
				await detail.data.create(values);
				// Закрываем окно
				detail.modal.close();
			} else {
				console.log('update', values?.item_volume_generates);

				if (Array.isArray(values?.item_volume_generates)) {
					// Обновляем сортировку
					values?.item_volume_generates.forEach((item_volume_generate, indexVolumeGenerate) => {
						if (Array.isArray(item_volume_generate.item_volume_tasks)) {
							item_volume_generate.item_volume_tasks.forEach((item_volume_task, indexVolumeTask) => {
								values.item_volume_generates[indexVolumeGenerate].item_volume_tasks[indexVolumeTask].sort = sort;
								sort++;
							});
						}
					});
				}

				// Обновление
				await detail.data.update(values);
			}

			// ID проектной документации
			const idProjectDocumentation = detail.data.id;

			detail.data.setId(0);

			setTimeout(() => {
				detail.data.setId(idProjectDocumentation);
			}, 0);

			// Обновляем список
			await itemProjectDocumentsStore.table.getList();
		} catch (e) {
			console.error(`Error in method handleOnSend: `, e);
		}
	};

	const getTitleError = () => {
		if (detail.data.isNewStore) {
			return `создании проектной документаии`;
		}

		return `редактировании проектной документации`;
	};

	const handleOnError: FormProps<ItemProjectDocument>['onFinishFailed'] = async (errorInfo) => {
		console.log('errorInfo', errorInfo);

		modalInstance.error({
			title: `Внимание ошибка при ${getTitleError()}`,
			width: 700,
			centered: true,
			content: (
				<div
					dangerouslySetInnerHTML={{
						__html: errorInfo.errorFields
							.map((error) => {
								return error.errors.join('<br/>');
							})
							.join('<br/>'),
					}}
				></div>
			),
		});
	};

	const handleOnChangeTab = (value: string) => {
		setCurrentTab(value);
	};

	return (
		<>
			{contextModal}
			<Form form={form} layout={'vertical'} autoComplete="off" onFinish={handleOnSend} onFinishFailed={handleOnError}>
				<Tabs activeKey={currentTab} items={itemTabs} onChange={handleOnChangeTab} />
			</Form>
		</>
	);
});
