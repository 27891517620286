import { EditOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { observer } from 'mobx-react-lite';

import { useStores } from '../../../../../../stores';

export const CreateChat = observer(() => {
	const { dialogsStore } = useStores();

	const handleOnCreate = () => {
		dialogsStore.createNew();
	};

	return (
		<>
			<div style={{ height: 20 }} />
			<Button type={'primary'} icon={<EditOutlined />} onClick={handleOnCreate}>
				Создать переписку
			</Button>
		</>
	);
});
