import { ChangeEvent, useState } from 'react';

import { Button, Col, Descriptions, Input, Popconfirm, Row, Tag } from 'antd';
import { observer } from 'mobx-react-lite';

import { useStores } from '../../../../../stores';
import { Status } from '../../../../common';

export const Information = observer(() => {
	const { userDashboardStore, authStore } = useStores();
	const { objects } = userDashboardStore;
	const { viewDetail, tableUser } = objects;
	const { data } = viewDetail;

	const [responsibleAnswer, setResponsibleAnswer] = useState('');

	const handleOnConfirm = async () => {
		try {
			await data.update({
				confirmation_by_responsible: true,
				responsible_answer: responsibleAnswer,
			});

			await tableUser.getList();
		} catch {
			/* empty */
		}
	};

	const handleOnConfirmCreator = async () => {
		try {
			await data.update({
				confirmation_by_creator: true,
			});

			await tableUser.getList();
		} catch {
			/* empty */
		}
	};

	const handleOnChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
		setResponsibleAnswer(e.currentTarget.value);
	};

	return (
		<>
			<Descriptions layout={'vertical'} column={1}>
				<Descriptions.Item label={'Титул проекта'}>{data.title}</Descriptions.Item>
			</Descriptions>

			<Descriptions layout={'vertical'}>
				<Descriptions.Item label={'Тип объекта'}>{data.object_type.name}</Descriptions.Item>
				<Descriptions.Item label={'Состав проектной документации'}>{data.project_documentation.name}</Descriptions.Item>
				<Descriptions.Item label={'Статус'}>
					<Status
						confirmation_by_responsible={data.confirmation_by_responsible}
						confirmation_by_creator={data.confirmation_by_creator}
					/>
				</Descriptions.Item>
			</Descriptions>

			<Descriptions layout={'vertical'}>
				<Descriptions.Item label={'Шифр'}>{data.cipher}</Descriptions.Item>
				<Descriptions.Item label={'Номер договора'}>{data.contract_number}</Descriptions.Item>
				<Descriptions.Item label={'Стоимость'}>{data.priceFormatted}</Descriptions.Item>
			</Descriptions>

			<Descriptions layout={'vertical'}>
				<Descriptions.Item label={'Автор проекта'}>{data.creator.shortFullName}</Descriptions.Item>
				<Descriptions.Item label={'Ответственный по объекту'}>{data.responsibleShortFullName}</Descriptions.Item>
			</Descriptions>

			<Descriptions layout={'vertical'}>
				<Descriptions.Item label={'Дата начала проектирование'}>{data.startDateFormatted}</Descriptions.Item>
				<Descriptions.Item label={'Дата окончания проектирования'}>{data.endDateFormatted}</Descriptions.Item>
			</Descriptions>

			{data.responsible_id === authStore.current.id &&
				data.confirmation_by_responsible &&
				!data.confirmation_by_creator && (
					<Descriptions layout={'vertical'} column={1}>
						<Descriptions.Item label={'Информация о выполнении объекта'}>{data.responsible_answer}</Descriptions.Item>
					</Descriptions>
				)}

			{data.responsible_id === authStore.current.id &&
				data.confirmation_by_responsible &&
				!data.confirmation_by_creator &&
				data.creator_id !== authStore.current.id && (
					<Row>
						<Col span={24}>
							<Tag color={'gold'}>Отправлен на проверку {data.creator.shortFullName}</Tag>
						</Col>
					</Row>
				)}

			{data.responsible_id === authStore.current.id &&
				!data.confirmation_by_responsible &&
				!data.confirmation_by_creator && (
					<Row>
						<Col span={24} style={{ marginBottom: 15, marginTop: 40 }}>
							<Input.TextArea
								autoSize
								value={responsibleAnswer}
								style={{ width: '100%' }}
								placeholder={'Информация о выполнении объекта'}
								onChange={handleOnChange}
							/>
						</Col>
						<Col span={24}>
							<Popconfirm
								title={'Подтверждаете выполнение?'}
								onConfirm={handleOnConfirm}
								disabled={responsibleAnswer.length === 0}
							>
								<Button type={'primary'} disabled={responsibleAnswer.length === 0}>
									Объект выполнен
								</Button>
							</Popconfirm>
						</Col>
					</Row>
				)}

			{data.creator_id === authStore.current.id &&
				data.confirmation_by_responsible &&
				!data.confirmation_by_creator && (
					<Row>
						<Col span={24}>
							<Popconfirm title={'Подтверждаете выполнение?'} onConfirm={handleOnConfirmCreator}>
								<Button type={'primary'}>Объект выполнен</Button>
							</Popconfirm>
						</Col>
					</Row>
				)}
		</>
	);
});
