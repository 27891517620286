import { useEffect, useState } from 'react';

import {
	CrownOutlined,
	DatabaseOutlined,
	DiffOutlined,
	DollarOutlined,
	LockOutlined,
	LogoutOutlined,
	MenuFoldOutlined,
	MenuUnfoldOutlined,
	PropertySafetyOutlined,
	UsergroupAddOutlined,
} from '@ant-design/icons';
import { Button, Layout, MenuProps } from 'antd';
import { MenuItemType } from 'antd/lib/menu/interface';
import { observer } from 'mobx-react-lite';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import { Notifications } from './Notifications/Notifications';
import {
	ActionsHeader,
	ActionsUserButtons,
	ContentCustom,
	HeaderCustom,
	LayoutLink,
	LayoutMain,
	Logotype,
	LogotypeContainer,
	MenuCustom,
	SiderCustom,
} from './ProtectedRoute.styled';
import { UserInformation } from './UserInformation/UserInformation';
import { useStores } from '../../../stores';
import { COLORS } from '../../../stylesheets';
import { LoginPage } from '../../pages';
import { ProfileDrawer } from '../../ui';

export const ProtectedRoute = observer(() => {
	const navigate = useNavigate();
	const location = useLocation();
	const { authStore } = useStores();
	const { current: currentUser } = authStore;

	const [collapsed, setCollapsed] = useState(false);
	const [currentLink, setCurrentLink] = useState('');
	const [menu, setMenu] = useState<MenuItemType[]>([]);

	useEffect(() => {
		setCurrentLink(location.pathname);
	}, []);

	useEffect(() => {
		// Формирование меню
		if (currentUser.role.objectView) {
			setMenu(() => {
				const list: MenuItemType[] = [];
				list.push(
					{
						key: '/objects',
						icon: <DatabaseOutlined />,
						label: 'Объекты',
					},
					{
						key: '/project-documentations',
						icon: <DiffOutlined />,
						label: 'Проектная документация',
					},
					{
						key: '/exchange',
						icon: <DollarOutlined />,
						label: 'Биржа задач',
					},
				);

				return list;
			});
		}

		if (currentUser.role.userCreate) {
			setMenu((prevState) => {
				const list = [...prevState];
				list.push({
					key: '/employees',
					icon: <UsergroupAddOutlined />,
					label: 'Сотрудники',
				});

				return list;
			});
		}

		if (currentUser.role.contractorCreate) {
			setMenu((prevState) => {
				const list = [...prevState];
				list.push({
					key: '/contractors',
					icon: <CrownOutlined />,
					label: 'Контрагенты',
				});

				return list;
			});
		}

		if (currentUser.role.isAdmin) {
			setMenu((prevState) => {
				const list = [...prevState];
				list.push(
					{
						key: '/roles',
						icon: <LockOutlined />,
						label: 'Должности',
					},
					{
						key: '/permissions',
						icon: <PropertySafetyOutlined />,
						label: 'Доступы',
					},
				);

				return list;
			});
		}
	}, [currentUser.role.permissionsList]);

	const handleOnTriggerCollapsed = () => {
		setCollapsed(!collapsed);
	};

	const handleOnMenuMainPage = () => {
		navigate('/');
		setCurrentLink('/');
	};

	const handleOnMenu: MenuProps['onClick'] = ({ key }) => {
		navigate(key);
		setCurrentLink(key);
	};

	if (!authStore.isAuth) {
		return <LoginPage />;
	}

	return (
		<Layout style={{ background: COLORS.White }}>
			<SiderCustom width={250} theme={'light'} trigger={null} collapsed={collapsed}>
				<LogotypeContainer collapsed={collapsed}>
					<LayoutLink onClick={handleOnMenuMainPage}>
						<Logotype src={'/images/logotype.png'} />
					</LayoutLink>
				</LogotypeContainer>

				<MenuCustom
					theme={'light'}
					mode={'inline'}
					openKeys={[currentLink]}
					selectedKeys={[currentLink]}
					onClick={handleOnMenu}
					items={menu}
				/>

				<ActionsUserButtons>
					<Button type={'text'} icon={<LogoutOutlined />} onClick={authStore.logout}>
						Выход
					</Button>
				</ActionsUserButtons>
			</SiderCustom>

			<LayoutMain>
				<HeaderCustom>
					<Button
						icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
						type={'link'}
						onClick={handleOnTriggerCollapsed}
					/>

					<ActionsHeader>
						<Notifications />
						<UserInformation />
					</ActionsHeader>
				</HeaderCustom>
				<ContentCustom>
					<Outlet />
				</ContentCustom>
			</LayoutMain>

			<ProfileDrawer />
		</Layout>
	);
});

export default ProtectedRoute;
