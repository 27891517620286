import { Fragment } from 'react';

import { Button, Descriptions, Divider, Popconfirm, Select, Tag, Typography } from 'antd';
import { observer } from 'mobx-react-lite';

import { ItemObjectTypesMapping } from '../../../../../../constants';
import { formattedPrice, numWords } from '../../../../../../helpers';
import { ItemStore, useStores } from '../../../../../../stores';
import { CompleteBlock } from '../../CompleteBlock/CompleteBlock';
import { ConfirmBlock } from '../../ConfirmBlock/ConfirmBlock';
import { ResultBlock } from '../../ResultBlock/ResultBlock';

interface ItemTaskViewProps {
	itemTask: ItemStore;
	isShort?: boolean;
}

export const ItemTaskView = observer(({ itemTask, isShort }: ItemTaskViewProps) => {
	const { taskExchangeStore, authStore, usersStore } = useStores();
	const { parent_volume: itemVolume, parent_object: itemObject } = itemTask;

	const handleOnConfirm = async () => {
		try {
			// Запрос на выполение
			await itemTask.sendTaskRequest();

			// Обновляем текущее состояние
			await itemTask.get();

			/*// Устанавливаем на задачу исполнителя
      await itemTask.update({
        responsible_id: authStore.current.id,
      });

      // Обновляем таблицу
      await taskExchangeStore.table.getList();*/
		} catch (e) {
			console.error(`Error in task : `, e);
		}
	};

	// Изменить исполнителя
	const handleOnChangeResponsibleId = async (responsible_id: number) => {
		try {
			await itemTask.update({
				responsible_id: responsible_id,
			});
		} catch (e) {
			console.error(`Error in method handleOnChangeResponsibleId : `, e);
		}
	};

	return (
		<>
			<Descriptions title={'Основная информация'} layout={'vertical'} column={2} bordered size={'small'}>
				<Descriptions.Item label={'Отвественный задачи'}>{itemTask.creator.shortFullName}</Descriptions.Item>
				<Descriptions.Item label={'Исполнитель задачи'}>
					{authStore.current.id === itemTask.creator_id && (
						<Select
							allowClear
							showSearch
							style={{ width: '100%' }}
							placeholder={`Изменить исполнителя`}
							value={itemTask.responsible_id}
							filterOption={(input, option) => {
								return (String(option?.children).toLowerCase() ?? '').includes(input.toLowerCase());
							}}
							onChange={async (value) => handleOnChangeResponsibleId(value)}
						>
							{usersStore.all.list.map((user) => {
								return (
									<Select.Option key={user.id} value={user.id}>
										{user.shortFullName}
									</Select.Option>
								);
							})}
						</Select>
					)}

					{authStore.current.id !== itemTask.creator_id && itemTask.responsible.shortFullName}
				</Descriptions.Item>

				<Descriptions.Item label={'Начало работ'}>{itemTask.startDateFormatted}</Descriptions.Item>
				<Descriptions.Item label={'Окончание работ'}>{itemTask.endDateFormatted}</Descriptions.Item>

				<Descriptions.Item label={'Название задачи'} span={2}>
					{itemTask.title}
				</Descriptions.Item>

				<Descriptions.Item label={'Описание'} span={2}>
					{itemTask.content}
				</Descriptions.Item>
			</Descriptions>

			<div style={{ height: 20 }} />

			<ResultBlock itemStore={itemTask} />
			<ConfirmBlock itemStore={itemTask} />
			<CompleteBlock itemStore={itemTask} />

			{!itemTask.responsible_id && (
				<>
					<Popconfirm title={'Уверены что хотите забрать задачу?'} onConfirm={handleOnConfirm}>
						<Button type={'primary'} disabled={itemTask.taskRequestIDs.includes(authStore.current.id)}>
							Подать заявку
						</Button>
					</Popconfirm>
					{itemTask.taskRequestIDs.includes(authStore.current.id) && (
						<>
							<Divider type={'vertical'} />
							<Tag>Запрос отправлен на рассмотрение</Tag>
						</>
					)}
				</>
			)}

			<Divider />

			{itemVolume && !isShort && (
				<>
					<Typography.Title level={4}>Том</Typography.Title>

					<Descriptions title={'Основная информация'} layout={'vertical'} column={2} bordered size={'small'}>
						<Descriptions.Item label={'Отвественный тома'}>{itemVolume.creator.shortFullName}</Descriptions.Item>
						<Descriptions.Item label={'Исполнитель тома'}>{itemVolume.responsible.shortFullName}</Descriptions.Item>

						<Descriptions.Item label={'Наименование тома'}>{itemVolume.title}</Descriptions.Item>
						<Descriptions.Item label={'Шифр тома'}>
							{itemObject?.cipher}-{itemVolume.cipher}
						</Descriptions.Item>

						<Descriptions.Item label={'Начало работ'}>{itemVolume.startDateFormatted}</Descriptions.Item>
						<Descriptions.Item label={'Окончание работ'}>{itemVolume.endDateFormatted}</Descriptions.Item>

						<Descriptions.Item label={'Фактическое завершение работ'}>
							{itemVolume.actualDateFormatted || '-'}
						</Descriptions.Item>
						<Descriptions.Item label={'Отклонение от даты завершения'}>
							{itemVolume.deviation_days} {numWords(itemVolume.deviation_days, ['день', 'дня', 'дней'])}
						</Descriptions.Item>
					</Descriptions>

					<div style={{ height: 20 }} />

					<Descriptions title={'Файлы'} column={1} bordered size={'small'}>
						{itemVolume.files.technical_documentation.map((file) => {
							return (
								<Descriptions.Item key={file.id} label={'Файлы'}>
									<span key={file.id}>
										<Typography.Link href={file.fullPathLink} target={'_blank'}>
											{file.name}
										</Typography.Link>
									</span>
								</Descriptions.Item>
							);
						})}
					</Descriptions>

					<div style={{ height: 20 }} />
				</>
			)}

			{itemObject && !isShort && (
				<>
					<Typography.Title level={4}>Объект</Typography.Title>

					<Descriptions title={'Основная информация'} layout={'vertical'} column={3} bordered size={'small'}>
						<Descriptions.Item label={'Тип объекта'}>
							{itemObject.item_object_type_id && ItemObjectTypesMapping[itemObject.item_object_type_id]}
						</Descriptions.Item>

						<Descriptions.Item label={'Статус'}>{itemObject.status.name}</Descriptions.Item>

						<Descriptions.Item label={'Шифр'}>{itemObject.cipher}</Descriptions.Item>

						<Descriptions.Item label={'Ответственный объекта'}>{itemObject.creator.shortFullName}</Descriptions.Item>

						<Descriptions.Item label={'Исполнитель объект'} span={2}>
							{itemObject.responsible.shortFullName}
						</Descriptions.Item>

						<Descriptions.Item label={'Титул проекта'} span={3}>
							{itemObject.title}
						</Descriptions.Item>

						<Descriptions.Item label={'Номер договора'}>{itemObject.contract_number}</Descriptions.Item>
						<Descriptions.Item label={'Стоимость'} span={2}>
							{formattedPrice(itemObject.price)}
						</Descriptions.Item>

						<Descriptions.Item label={'Дата начала проектирование'}>{itemObject.startDateFormatted}</Descriptions.Item>
						<Descriptions.Item label={'Дата окончания проектирования'}>{itemObject.endDateFormatted}</Descriptions.Item>
					</Descriptions>

					<div style={{ height: 20 }} />

					<Descriptions title={'Контрагенты'} column={2} bordered size={'small'}>
						{itemObject.contractors.map((contractor) => {
							return (
								<Fragment key={contractor.id}>
									<Descriptions.Item label={'Контрагент'}>{contractor.contractor.company_name}</Descriptions.Item>
									<Descriptions.Item label={'Предмет договора'}>{contractor.subject_contract}</Descriptions.Item>
									<Descriptions.Item label={'Файлы'} span={2}>
										{contractor.files.map((file) => {
											return (
												<span key={file.id}>
													<Typography.Link href={file.fullPathLink} target={'_blank'}>
														{file.name}
													</Typography.Link>{' '}
												</span>
											);
										})}
									</Descriptions.Item>
								</Fragment>
							);
						})}
					</Descriptions>

					<div style={{ height: 20 }} />

					<Descriptions title={'Файлы'} column={1} bordered size={'small'}>
						{itemObject.files.technical_documentation.map((file) => {
							return (
								<Descriptions.Item key={file.id} label={'Техническая документация'}>
									<span key={file.id}>
										<Typography.Link href={file.fullPathLink} target={'_blank'}>
											{file.name}
										</Typography.Link>
									</span>
								</Descriptions.Item>
							);
						})}

						{itemObject.files.administrative_documentation.map((file) => {
							return (
								<Descriptions.Item key={file.id} label={'Административная документация'}>
									<span key={file.id}>
										<Typography.Link href={file.fullPathLink} target={'_blank'}>
											{file.name}
										</Typography.Link>
									</span>
								</Descriptions.Item>
							);
						})}

						{itemObject.files.design_assignments.map((file) => {
							return (
								<Descriptions.Item key={file.id} label={'Задание на проектирование'}>
									<span key={file.id}>
										<Typography.Link href={file.fullPathLink} target={'_blank'}>
											{file.name}
										</Typography.Link>
									</span>
								</Descriptions.Item>
							);
						})}
					</Descriptions>
				</>
			)}
		</>
	);
});
