import { Table } from 'antd';
import { observer } from 'mobx-react-lite';

import { useColumns } from './AgreementTable.columns';
import { useStores } from '../../../../../stores';

interface AgreementTableProps {}

export const AgreementTable = observer(({}: AgreementTableProps) => {
	const { agreementsStore } = useStores();

	return (
		<Table
			rowKey={'id'}
			loading={agreementsStore.table.isLoading}
			columns={useColumns()}
			dataSource={agreementsStore.table.list}
			pagination={{
				current: agreementsStore.table.pagination.current_page,
				total: agreementsStore.table.pagination.total,
				onChange: (page) => agreementsStore.table.pagination.setCurrentPage(page),
			}}
			onRow={(row) => {
				return {
					onDoubleClick: async () => {
						// Открываем окно для редактирования
						agreementsStore.drawer.show();

						// Устаналиваем ID
						agreementsStore.selected.setId(row.id);

						// Получаем информацию по объекту
						await agreementsStore.selected.get();
					},
				};
			}}
		/>
	);
});
