import { action, makeObservable, observable } from 'mobx';

import { CurrencyStore } from './currency.store';
import { Currency } from '../../interfaces';
import { AllStore } from '../common/all.store';

export class CurrenciesStore {
	@observable all = new AllStore({
		url: 'currencies',
		getListItemStore: this.getListItemStore,
		getPayloadParams: this.getPayloadParams,
	});

	constructor() {
		makeObservable(this);
	}

	@action.bound
	private getListItemStore(value: Currency): CurrencyStore {
		return new CurrencyStore(value);
	}

	@action.bound
	private getPayloadParams() {
		return {};
	}
}
