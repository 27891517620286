import { useEffect } from 'react';

import { PlusCircleOutlined } from '@ant-design/icons';
import { Button, Typography } from 'antd';
import { observer } from 'mobx-react-lite';

import { useStores } from '../../../../stores';
import { BlockTitle, ContainerBody, ContainerBodyPadding } from '../../../common';
import { RoleModal } from '../../../ui/Modals';
import { RolesTable } from '../../../ui/Tables/RolesTable/RolesTable';

export const RolesPage = observer(() => {
	const { rolesStore } = useStores();

	useEffect(() => {
		(async () => {
			await rolesStore.table.getList();
		})();
	}, []);

	const handleOnShowCreate = () => {
		rolesStore.modal.open();
	};

	return (
		<>
			<ContainerBody>
				<ContainerBodyPadding>
					<BlockTitle>
						<Typography.Title level={2}>Должности</Typography.Title>

						<Button.Group>
							<Button icon={<PlusCircleOutlined />} type={'primary'} onClick={handleOnShowCreate}>
								Создать
							</Button>
						</Button.Group>
					</BlockTitle>

					<RolesTable />
				</ContainerBodyPadding>
			</ContainerBody>

			<RoleModal />
		</>
	);
});
