import styled from 'styled-components';

export const Container = styled.div`
	display: flex;
	flex: 1;
	background-image: url('/images/watermark-blue.png');
	background-repeat: no-repeat;
	background-position: center left;
	height: 100%;
`;
