import styled from 'styled-components';

import { COLORS } from '../../../stylesheets';

export const Container = styled.div`
	width: 100%;
	display: grid;
	grid-template-columns: 1fr auto;
	column-gap: 15px;
	margin-bottom: 10px;

	&:last-child {
		margin-bottom: 0;
	}
`;

export const InformationFile = styled.div``;

export const NameFile = styled.div``;

export const DateUploaded = styled.div`
	font-size: 12px;
	color: ${COLORS.GRAY_60};
`;
