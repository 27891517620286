import { action, makeObservable, observable } from 'mobx';

import { ItemVolumeTaskTrigger, NumberNull } from '../../interfaces';
import { ItemVolumeTask } from '../../interfaces/item-volume-task';
import { CrudStore } from '../common/crud.store';
import { ItemVolumeTaskTriggerStore } from '../item-volume-task-trigger';

export class ItemVolumeTaskStore extends CrudStore<ItemVolumeTask> implements ItemVolumeTask {
	@observable item_volume_generate_id = 0;
	@observable sort = 0;
	@observable role_id: NumberNull = null;
	@observable inside_uuid = '';
	@observable title = '';
	@observable description = '';
	@observable is_finish = false;
	@observable actions: Array<{ [key: string]: string }> = [];
	@observable task_triggers: Array<ItemVolumeTaskTrigger> = [];

	// Дополнительное поле при генерации томов
	@observable count_day = 1;
	@observable responsible_id: NumberNull = null;

	constructor(initialData: ItemVolumeTask | null) {
		super();
		makeObservable(this);

		if (initialData) {
			this.fillStore(initialData);
		}
	}

	@action.bound
	setCountDay(value: number) {
		this.count_day = value;
	}

	@action.bound
	setResponsibleId(value: number) {
		this.responsible_id = value;
	}

	@action
	fillStore(data: ItemVolumeTask) {
		const {
			id,
			item_volume_generate_id,
			sort,
			role_id,
			inside_uuid,
			title,
			description,
			is_finish,
			actions,
			task_triggers,
		} = data;

		this.id = id;
		this.item_volume_generate_id = item_volume_generate_id;
		this.sort = sort;
		this.inside_uuid = inside_uuid;
		this.role_id = role_id;
		this.title = title;
		this.description = description;
		this.actions = actions;
		this.is_finish = is_finish;
		this.task_triggers = (task_triggers || []).map((trigger) => new ItemVolumeTaskTriggerStore(trigger));
	}
}
