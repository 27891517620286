import React, { useEffect } from 'react';

import { notification } from 'antd';
import { AnimatePresence } from 'framer-motion';
import { observer } from 'mobx-react-lite';
import { Route, Routes, useLocation } from 'react-router-dom';

import { AgreementFileTemplatesPage, AgreementsPage, ContractorPage } from './components';
import { BaseLayout } from './components/layouts';
import { CabinetLayout } from './components/layouts/CabinetLayout/CabinetLayout';
import 'leaflet/dist/leaflet.css';
import { ContractorsPage } from './components/pages/Cabinet/ContractorsPage/ContractorsPage';
import { DashboardPage } from './components/pages/Cabinet/DashboardPage/DashboardPage';
import { EmployeesPage } from './components/pages/Cabinet/EmployeesPage/EmployeesPage';
import { MapWorkPage } from './components/pages/Cabinet/MapWorkPage/MapWorkPage';
import { MessengerPage } from './components/pages/Cabinet/MessengerPage/MessengerPage';
import { NotificationsPage } from './components/pages/Cabinet/Notifications/NotificationsPage';
import { ObjectsDeletePage } from './components/pages/Cabinet/ObjectsDeletePage/ObjectsDeletePage';
import { ObjectsDraftsPage } from './components/pages/Cabinet/ObjectsDraftsPage';
import { ObjectsPage } from './components/pages/Cabinet/ObjectsPage/ObjectsPage';
import { ProjectDocumentationsPage } from './components/pages/Cabinet/ProjectDocumentationsPage/ProjectDocumentationsPage';
import { RolesPage } from './components/pages/Cabinet/RolesPage/RolesPage';
import { TaskExchangePage } from './components/pages/Cabinet/TaskExchangePage/TaskExchangePage';
import { generateNotificationAssignItem, generateNotificationChangeStatus } from './helpers';
import { ItemEnabledDisabled, UserOnline, WsTypeEvent } from './interfaces';
import { Item } from './interfaces/item';
import { useStores } from './stores';

// Инициализация уведомлений
notification.config({
	top: 64,
	duration: 2,
});

export const AppSvep = observer(() => {
	const {
		authStore,
		websocketStore,
		userItemsStore,
		audioStore,
		workWeekStore,
		notificationsStore,
		itemsStore,
		currenciesStore,
		agreementStatusesStore,
		userOnlineStore,
		itemDisabledStore,
	} = useStores();

	const location = useLocation();
	const [, contextHolder] = notification.useNotification();

	useEffect(() => {
		(async () => {
			await authStore.check(false);
		})();
	}, []);

	useEffect(() => {
		if (authStore.isAuth) {
			(async () => {
				// Получаем уведомления
				await notificationsStore.table.getList();

				// Получаем курс валют
				await currenciesStore.all.getList();

				// Получаем статусы договоров
				await agreementStatusesStore.all.getList();
			})();
		}
	}, [authStore.isAuth]);

	useEffect(() => {
		(async () => {
			if (authStore.isAuth && !websocketStore.isConnection) {
				await websocketStore.init();
			}
		})();
	}, [authStore.isAuth, location.pathname]);

	// Подписываем на события, как только подключились к WS
	useEffect(() => {
		if (!websocketStore.isConnection) {
			return;
		}

		// Изменение статуса item'а
		websocketStore.addCallback<Item>({
			event: WsTypeEvent.statusChange,
			callback: async (data) => {
				// Находим в текущем пулле item и обновляем его данные
				await userItemsStore.findAndActualData(data.payload.id);

				// Если item открыт, то обновляем его
				await itemsStore.updateSelected(data.payload.id);

				// Получаем данные для рабочего стола
				await userItemsStore.all.getList();

				// Уведомление о изменении статуса
				notification.info({
					message: `${generateNotificationChangeStatus(data.payload)}`,
				});

				if (!audioStore.currentMuteState) {
					await audioStore.notification.play();
				}
			},
		});

		// Назначено на исполнение
		websocketStore.addCallback<Item>({
			event: WsTypeEvent.assignItem,
			callback: async (data) => {
				// Уведомление о назначении
				notification.success({
					message: `${generateNotificationAssignItem(data.payload)}`,
				});

				// Получаем данные для рабочего стола
				await userItemsStore.all.getList();

				if (!audioStore.currentMuteState) {
					await audioStore.notification.play();
				}
			},
		});

		// Назначено на ответсвенного
		websocketStore.addCallback<Item>({
			event: WsTypeEvent.assignItemCreator,
			callback: async (data) => {
				// Уведомление о назначении
				notification.success({
					message: `${generateNotificationAssignItem(data.payload)}`,
				});

				// Получаем данные для рабочего стола
				await userItemsStore.all.getList();

				if (!audioStore.currentMuteState) {
					await audioStore.notification.play();
				}
			},
		});

		// Уведодмения
		websocketStore.addCallback<Item>({
			event: WsTypeEvent.notification,
			callback: async (dataCallback: any) => {
				// Актуализируем виджет недели
				await workWeekStore.getList();

				// Получаем данные для рабочего стола
				await userItemsStore.all.getList();

				// Получаем уведомления
				await notificationsStore.table.getList();

				if (dataCallback) {
					const {
						payload: { data },
					} = dataCallback;

					notification.success({
						message: data.title,
					});
				}

				if (!audioStore.currentMuteState) {
					await audioStore.notification.play();
				}
			},
		});

		// Количество пользователей
		websocketStore.addCallback<UserOnline>({
			event: WsTypeEvent.count,
			callback: async (data) => {
				userOnlineStore.setCount(data.payload.count);
				userOnlineStore.setList(data.payload.users);
			},
		});

		// Документ взят в редактирование
		websocketStore.addCallback<ItemEnabledDisabled>({
			event: WsTypeEvent.itemDisabled,
			callback: async (data) => {
				// Добавляем в спискок заблокированных
				//itemDisabledStore.add(data.payload);
			},
		});

		// Документ освобожден от редактирования
		websocketStore.addCallback<ItemEnabledDisabled>({
			event: WsTypeEvent.itemEnabled,
			callback: async (data) => {
				// Удаляем из списка заблокированных
				//itemDisabledStore.remove(data.payload);
			},
		});
	}, [websocketStore.isConnection]);

	return (
		<>
			<AnimatePresence mode={'wait'}>
				<Routes key={location.pathname.split('/')[1] ?? '/'} location={location}>
					<Route path={'/'} element={<BaseLayout />}>
						<Route path={'cabinet'} element={<CabinetLayout />}>
							<Route index element={<DashboardPage />} />
							<Route path={'agreements'} element={<AgreementsPage />} />
							<Route path={'agreement-file-templates'} element={<AgreementFileTemplatesPage />} />
							<Route path={'messenger'} element={<MessengerPage />} />
							<Route path={'task-exchange'} element={<TaskExchangePage />} />
							<Route path={'notifications'} element={<NotificationsPage />} />
							<Route path={'objects'} element={<ObjectsPage />} />
							<Route path={'objects-drafts'} element={<ObjectsDraftsPage />} />
							<Route path={'objects-delete'} element={<ObjectsDeletePage />} />
							<Route path={'project-documentations'} element={<ProjectDocumentationsPage />} />
							<Route path={'employers'} element={<EmployeesPage />} />
							<Route path={'contractors'} element={<ContractorsPage />} />
							<Route path={'roles'} element={<RolesPage />} />
							<Route path={'map-work'} element={<MapWorkPage />} />
						</Route>
					</Route>

					<Route path={'private'}>
						<Route path={'contractor/:hash'} element={<ContractorPage />} />
					</Route>
				</Routes>
			</AnimatePresence>

			{contextHolder}
		</>
	);
});
