import { Col, DatePicker, Form, FormInstance, Input, InputNumber, Radio, Row, Select } from 'antd';
import { MaskedInput } from 'antd-mask-input';
import { observer } from 'mobx-react-lite';

import { useStores } from '../../../../../stores';

interface InformationProps {
	form: FormInstance;
}

export const Information = observer(({ form }: InformationProps) => {
	const { objectTypesStore, responsiblesStore, authStore } = useStores();

	return (
		<>
			<Row>
				<Col span={24}>
					<Form.Item label={'Тип объекта'} name={'object_type_id'}>
						<Radio.Group buttonStyle={'solid'}>
							{objectTypesStore.list.map((workObjectType) => {
								return (
									<Radio.Button key={workObjectType.id} value={workObjectType.id}>
										{workObjectType.name}
									</Radio.Button>
								);
							})}
						</Radio.Group>
					</Form.Item>
				</Col>
			</Row>

			<Row>
				<Col span={12}>
					<Form.Item label={'Автор объекта'}>
						(<strong>Вы</strong>) {authStore.current.shortFullName}
					</Form.Item>
				</Col>

				<Col span={12}>
					<Form.Item label={'Ответственный по объекту'} name={'responsible_id'} rules={[{ required: true }]}>
						<Select placeholder={'Выберите ответсвенного'}>
							{responsiblesStore.list.map((responsible) => {
								return (
									<Select.Option key={responsible.id} value={responsible.id}>
										{responsible.shortFullName}
									</Select.Option>
								);
							})}
						</Select>
					</Form.Item>
				</Col>
			</Row>

			<Row>
				<Col span={24}>
					<Form.Item label={'Шифр'} name={'cipher'} rules={[{ required: true, message: 'Введите шифр' }]}>
						<MaskedInput mask={'aaa'} />
					</Form.Item>
				</Col>
			</Row>

			<Row>
				<Col span={24}>
					<Form.Item label={'Титул проекта'} name={'title'} rules={[{ required: true }]}>
						<Input />
					</Form.Item>
				</Col>
			</Row>

			<Row gutter={15}>
				<Col span={12}>
					<Form.Item label={'Номер договора'} name={'contract_number'} rules={[{ required: true }]}>
						<Input />
					</Form.Item>
				</Col>
				<Col span={12}>
					<Form.Item label={'Стоимость'} name={'price'} rules={[{ required: true }]}>
						<InputNumber min={0} formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')} />
					</Form.Item>
				</Col>
			</Row>

			<Row gutter={15}>
				<Col span={12}>
					<Form.Item label={'Дата начала проектирование'} name={'start_designing'} rules={[{ required: true }]}>
						<DatePicker format={'DD MMMM YYYY'} />
					</Form.Item>
				</Col>
				<Col span={12}>
					<Form.Item shouldUpdate style={{ margin: 0 }}>
						{() => {
							const fieldStartDate = form.getFieldValue('start_designing');
							return (
								<Form.Item
									label={'Дата окончания проектирования'}
									name={'end_contract'}
									rules={[
										{ required: true },
										{
											validator: async (_, workEndDate) => {
												if (workEndDate) {
													if (workEndDate.diff(fieldStartDate, 'days') <= 0) {
														return Promise.reject('Окончание работы < чем начало работ');
													}

													return Promise.resolve();
												} else {
													return Promise.resolve();
												}
											},
										},
									]}
								>
									<DatePicker
										format={'DD MMMM YYYY'}
										disabledDate={(current) => {
											if (fieldStartDate && current) {
												return current && current < fieldStartDate.endOf('day');
											} else {
												return false;
											}
										}}
									/>
								</Form.Item>
							);
						}}
					</Form.Item>
				</Col>
			</Row>
		</>
	);
});
