import { action, computed, makeObservable, observable } from 'mobx';

import { ItemObjectTypeStore } from './item-object-type.store';
import { API } from '../../core';
import { ErrorAPI } from '../../helpers';
import { ItemObjectType } from '../../interfaces/item';

export class ItemObjectTypesStore {
	@observable list: ItemObjectType[] = [];

	constructor() {
		makeObservable(this);
	}

	@action.bound
	async getList() {
		try {
			const data = await API.request<ItemObjectType[]>(`item-object-types`);
			this.setList(data);
		} catch (e) {
			ErrorAPI('getList', e);
		}
	}

	@action.bound
	setList(values: ItemObjectType[]) {
		this.list = (values || []).map((item) => new ItemObjectTypeStore(item));
	}

	@computed
	get radioButtons() {
		return this.list.map((item) => ({
			label: item.name,
			value: item.id,
		}));
	}

	@computed
	get firstValue(): number | undefined {
		return this.list[0]?.id || undefined;
	}
}
