import { Layout } from 'antd';
import { motion } from 'framer-motion';
import styled from 'styled-components';

import { COLORS } from '../../../stylesheets';

export const Container = styled(motion.div)`
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	background: ${COLORS.White};
`;

export const LayoutExtra = styled(Layout)`
	background: ${COLORS.BLUE_05};
	padding: 20px 15px;
`;
