import { Modal, Tabs, TabsProps } from 'antd';
import { observer } from 'mobx-react-lite';

import { Files } from './Files/Files';
import { Information } from './Information/Information';
import { Tasks } from './Tasks/Tasks';
import { VOLUME_TABS } from '../../../../constants';
import { useStores } from '../../../../stores';

export const VolumeDetailModal = observer(() => {
	const { userDashboardStore } = useStores();
	const { volumes } = userDashboardStore;
	const { viewDetail } = volumes;
	const { data: viewVolume } = viewDetail;

	const itemTabs: TabsProps['items'] = [
		{
			key: VOLUME_TABS.INFORMATION,
			label: 'Информация',
			children: <Information />,
		},
		{
			key: VOLUME_TABS.TASKS,
			label: 'Задачи',
			children: <Tasks />,
		},
		{
			key: VOLUME_TABS.FILES,
			label: 'Файлы',
			children: <Files />,
		},
	];

	const handleOnClose = () => {
		viewDetail.modal.close();
	};

	return (
		<>
			<Modal
				open={viewDetail.modal.isShow}
				forceRender
				centered
				title={`Просмотр тома : ${viewVolume.name_volume}`}
				width={1200}
				styles={{
					body: {
						height: 600,
					},
				}}
				okButtonProps={{ hidden: true }}
				cancelText={'Закрыть'}
				onCancel={handleOnClose}
			>
				<Tabs defaultActiveKey={VOLUME_TABS.INFORMATION} items={itemTabs} />
			</Modal>
		</>
	);
});
