import { useState } from 'react';

import { CheckCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Collapse, CollapseProps, Form, FormInstance, FormListFieldData, Tag, Tooltip } from 'antd';
import { useWatch } from 'antd/es/form/Form';
import { observer } from 'mobx-react-lite';

import { TaskItem } from './TaskItem';
import { CollapseItem } from '../../../../../../../../interfaces';
import { ContainerLine, InputNumberCustom } from '../../VolumeList.styled';

interface TasksTabProps {
	form: FormInstance;
	volumeIndex: number;
}

export const TasksTab = observer(({ form, volumeIndex }: TasksTabProps) => {
	const volumeTaskList = useWatch(['item_volume_generates', volumeIndex, 'item_volume_tasks'], form) || [];

	const [activeTask, setActiveTask] = useState<CollapseItem>('');

	const getItemsTask = (
		fields: FormListFieldData[],
		onRemoveTask: (key: number) => void,
		onMoveTask: (from: number, to: number) => void,
	): CollapseProps['items'] => {
		const items: CollapseProps['items'] = [];

		fields
			.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0))
			.forEach((field) => {
				const titleTask = volumeTaskList[field.name]?.title || 'Название не указано';
				const isFinishTask = volumeTaskList[field.name]?.is_finish || false;
				const triggersCount = volumeTaskList[field.name]?.triggers.length || 0;
				const orderNumber = field.name;

				if (items) {
					// Формирование массива задач
					items.push({
						key: field.name,
						label: (
							<ContainerLine onClick={(e) => e.stopPropagation()}>
								<Form.Item
									initialValue={orderNumber}
									//name={[field.name, 'sort']}
									rules={[{ required: true }]}
									style={{ marginBottom: 0 }}
								>
									<InputNumberCustom
										min={1}
										value={field.name + 1}
										// Логика изменении сортировки
										onBlur={(event) => {
											const value = event.target.value;
											if (value) {
												// Индекс изменяемого позицию элемента
												const indexChangePosition = field.name;

												// Новая позиция элемента
												const newPositionElement = Number(value) - 1;

												const movePosition =
													newPositionElement > fields.length - 1 ? fields.length - 1 : newPositionElement;

												// Перемещаем элемент
												onMoveTask(indexChangePosition, movePosition);
											}
										}}
									/>
								</Form.Item>
								<span>
									{triggersCount ? (
										<Tooltip title={`Количество триггеров`}>
											<Tag>ТГ : {triggersCount}</Tag>
										</Tooltip>
									) : null}
									{isFinishTask ? (
										<Tooltip title={`После выполнения этой задачи, будет завершен том`}>
											<Tag color={'error'} icon={<CheckCircleOutlined />}></Tag>
										</Tooltip>
									) : null}
									{titleTask}
								</span>
							</ContainerLine>
						),
						children: (
							<TaskItem
								orderNumber={orderNumber}
								volumeIndex={volumeIndex}
								key={field.name}
								field={field}
								form={form}
								onRemove={onRemoveTask}
							/>
						),
					});
				}
			});

		return items;
	};

	return (
		<div>
			<Form.List name={[volumeIndex, 'item_volume_tasks']}>
				{(fields, { add: onAddTask, remove: onRemoveTask, move: onMoveTask }) => {
					return (
						<>
							<div style={{ marginBottom: 25 }}>
								<Button
									icon={<PlusOutlined />}
									type={'primary'}
									shape={'circle'}
									style={{ paddingBottom: 2 }}
									onClick={() => {
										// Добавляем задачу
										onAddTask();

										// Открываем последнюю добавленую задачу
										const lastVolumeItem = String(Object.keys(volumeTaskList).length);
										setActiveTask([lastVolumeItem]);
									}}
								/>
							</div>

							<Collapse
								accordion
								items={getItemsTask(
									fields.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0)),
									onRemoveTask,
									onMoveTask,
								)}
								activeKey={activeTask}
								onChange={(itemTaskNumber) => {
									setActiveTask(itemTaskNumber);
								}}
							/>
						</>
					);
				}}
			</Form.List>
		</div>
	);
});
