import { Fragment, useEffect } from 'react';

import { DeleteOutlined, SaveOutlined } from '@ant-design/icons';
import { Button, Drawer, Popconfirm, Space } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { observer } from 'mobx-react-lite';

import { getButtonName, getTitle } from '../../../../helpers';
import { useStores } from '../../../../stores';
import { AgreementStore } from '../../../../stores/agreements';
import { AgreementForm } from '../../Forms';

interface AgreementDrawerProps {}

export const AgreementDrawer = observer(({}: AgreementDrawerProps) => {
	const { agreementsStore, itemsStore, contractorsStore, agreementFileTemplatesStore } = useStores();

	const { selected } = agreementsStore;

	const [form] = useForm<AgreementStore>();

	// Instance полей формы
	const filedNames = {
		item_id: selected.item_id,
		creator_id: selected.creator_id,
		status_id: selected.status_id || undefined,
		currency_id: selected.currency_id,
		system_number: selected.system_number,
		title: selected.title,
		price: selected.price,
		start_date: selected.start_date,
		end_date: selected.end_date,
		comment: selected.comment,
		status: selected.status,
		contractors: selected.contractors.map((contractor) => ({
			agreement_file_template_id: contractor.agreement_file_template_id,
			contractor_id: contractor.contractor_id,
			comment: contractor.comment,
			approve: contractor.approve,
			send_email: contractor.send_email,
		})),
	};

	// Заполняем
	useEffect(() => {
		form.setFieldsValue({
			...filedNames,
		});
	}, [selected.id, selected.title]);

	// Получаем все объекты
	useEffect(() => {
		(async () => {
			itemsStore.all.addFilter('item_type_id', 1);
			itemsStore.all.addFilter('is_delete', 0);
			itemsStore.all.addFilter('is_draft', 0);

			// Запрос
			await itemsStore.all.getList();

			// Получаем все шаблонные договоры
			await agreementFileTemplatesStore.all.getList();
		})();
	}, []);

	const handleOnSend = () => form.submit();

	const handleOnDelete = async () => {
		try {
			// Удаляем контрагента
			await agreementsStore.selected.delete(false);
			// Закрываем окно
			agreementsStore.drawer.close();
			// Обновляем список
			await agreementsStore.table.getList();
		} catch (e) {
			/* empty */
		}
	};

	return (
		<>
			<Drawer
				loading={agreementsStore.selected.isLoading}
				forceRender
				open={agreementsStore.drawer.isShow}
				width={800}
				title={getTitle('договор', agreementsStore.selected.isNewStore, agreementsStore.selected.title)}
				onClose={() => {
					agreementsStore.drawer.close();
					agreementsStore.clearSelected();
					form.resetFields();
				}}
				extra={[
					<Fragment key={'actions'}>
						<Space size={15}>
							{agreementsStore.selected.isNotNewStore && (
								<Popconfirm title={`Удалить?`} onConfirm={handleOnDelete}>
									<Button
										key={'delete'}
										htmlType={'submit'}
										icon={<DeleteOutlined />}
										type={'primary'}
										danger
										loading={agreementsStore.selected.isLoading}
									>
										Удалить
									</Button>
								</Popconfirm>
							)}

							<Button
								key={'create'}
								htmlType={'submit'}
								icon={<SaveOutlined />}
								type={'primary'}
								onClick={handleOnSend}
								loading={agreementsStore.selected.isLoading}
							>
								{getButtonName(agreementsStore.selected.isNewStore)}
							</Button>
						</Space>
					</Fragment>,
				]}
			>
				<AgreementForm form={form} />
			</Drawer>
		</>
	);
});
