import { CheckCircleOutlined, DeleteOutlined } from '@ant-design/icons';
import {
	Button,
	Collapse,
	CollapseProps,
	Empty,
	Form,
	FormInstance,
	FormListFieldData,
	Popconfirm,
	Tag,
	Tooltip,
} from 'antd';
import { observer } from 'mobx-react-lite';

import { VolumeItem } from './VolumeItem';
import { ContainerLine, InputNumberCustom } from './VolumeList.styled';
import { CollapseItem } from '../../../../../../interfaces';
import { ItemVolumeGenerateStore } from '../../../../../../stores/item-volume-generate';

interface VolumesListProps {
	form: FormInstance;
	fields: FormListFieldData[];
	onRemove: (index: number | number[]) => void;

	currentCollapse: CollapseItem;
	onSetCurrentCollapse: (value: CollapseItem) => void;
}

export const VolumesList = observer(
	({ form, fields, onRemove, currentCollapse, onSetCurrentCollapse }: VolumesListProps) => {
		const volumeListWatch: ItemVolumeGenerateStore[] = Form.useWatch(['item_volume_generates'], form);

		const handleOnChangeCollapse = (value: CollapseItem) => {
			onSetCurrentCollapse(value);
		};

		const handleOnDelete = (index: number) => {
			onRemove(index);
		};

		const getPanel = () => {
			const items: CollapseProps['items'] = [];

			fields.forEach(({ name, key }) => {
				const isFinishTask = volumeListWatch?.[name]?.payload?.is_finish || false;

				// Заголовок для тома
				const getTitle = () => {
					const number_section = volumeListWatch?.[name]?.number_section;
					const nomination = volumeListWatch?.[name]?.nomination;

					if (number_section && nomination) {
						return `${number_section} | ${nomination}`;
					} else if (!number_section && nomination) {
						return `- | ${nomination}`;
					} else if (number_section && !nomination) {
						return `${number_section} | -`;
					}

					return `Не указано`;
				};

				const orderNumber = key + 1;

				items.push({
					key: name,
					label: (
						<ContainerLine onClick={(e) => e.stopPropagation()}>
							<Form.Item
								initialValue={orderNumber}
								name={[name, 'sort']}
								rules={[{ required: true }]}
								style={{ marginBottom: 0 }}
							>
								<InputNumberCustom min={1} />
							</Form.Item>
							<span>
								{isFinishTask ? (
									<Tooltip title={`После выполнения этой задачи, будет завершен том`}>
										<Tag color={'error'} icon={<CheckCircleOutlined />}></Tag>
									</Tooltip>
								) : null}

								{getTitle()}
							</span>
						</ContainerLine>
					),
					children: <VolumeItem form={form} volumeIndex={name} />,
					extra: [
						<Popconfirm
							key={'delete'}
							title={`Вы уверены что хотите удалить том?`}
							onConfirm={() => handleOnDelete(name)}
						>
							<Button danger type={'primary'} icon={<DeleteOutlined />} />
						</Popconfirm>,
					],
					forceRender: true,
				});
			});

			return items;
		};

		if (getPanel().length > 0) {
			return (
				<Collapse
					accordion
					collapsible={'header'}
					items={getPanel()}
					activeKey={currentCollapse}
					onChange={handleOnChangeCollapse}
				/>
			);
		}

		return <Empty description={<span>Нет томов</span>}></Empty>;
	},
);
