import { action, computed, makeObservable, observable } from 'mobx';

import { ObjectDetailStore } from './object-detail.store';
import { OBJECT_TYPES } from '../../constants';
import { API } from '../../core';
import { ErrorAPI } from '../../helpers';
import { ObjectDetailI, PayloadParamsI } from '../../interfaces';
import { CoreResponse } from '../../responses';
import { DetailStore } from '../common/detail.store';
import { DrawerStore } from '../common/drawer.store';
import { ModalStore } from '../common/modal.store';
import { TableStore } from '../common/table.store';
import { TaskStore } from '../task/task.store';
import { VolumeStore } from '../volume/volume.store';

export class ObjectsStore {
	@observable modal = new ModalStore();
	@observable drawer = new DrawerStore();
	@observable table = new TableStore<ObjectDetailI, ObjectDetailStore>({
		url: 'objects',
		getListItemStore: this.getListItemStore,
		getPayloadParams: this.getPayloadParams.bind(this),
	});
	@observable tableUser = new TableStore<ObjectDetailI, ObjectDetailStore>({
		url: 'user-objects',
		getListItemStore: this.getListItemStore,
		getPayloadParams: this.getPayloadParams.bind(this),
	});
	@observable selected = new ObjectDetailStore(null);
	@observable viewDetail = new DetailStore<ObjectDetailStore>(new ObjectDetailStore(null));

	@observable selectedTypeId: OBJECT_TYPES | undefined = undefined;

	constructor() {
		makeObservable(this);
	}

	@computed
	get selectedObjectVolume() {
		return new VolumeStore(null);
	}

	@computed
	get tasksVolume() {
		return [];
	}

	@computed
	get selectedTaskVolume() {
		return new TaskStore(null);
	}

	@action.bound
	getListItemStore(value: ObjectDetailI): ObjectDetailStore {
		return new ObjectDetailStore(value);
	}

	@action.bound
	getPayloadParams(): PayloadParamsI {
		return {
			object_type_id: this.selectedTypeId || 1,
		};
	}

	@action.bound
	async getById(id: number) {
		try {
			const { data } = await API.request<CoreResponse<ObjectDetailI>>(`objects/${id}`);
		} catch (e) {
			ErrorAPI('getById', e);
		}
	}

	@action.bound
	setSelectedTypeId(value: OBJECT_TYPES) {
		this.selectedTypeId = value;
		// Сбарсывем пагинацию
		this.table.pagination.reset();
	}

	@action.bound
	clearSelected() {
		this.selected = new ObjectDetailStore(null);
	}

	@action.bound
	setSelected(value: ObjectDetailStore) {
		this.selected = value;
	}
}
