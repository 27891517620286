import { UserOutlined } from '@ant-design/icons';
import { Dayjs } from 'dayjs';
import { observer } from 'mobx-react-lite';

import { Date, Item, Text } from './MessageList.styled';
import { DATE_FORMAT } from '../../../../../../constants';
import { UserStore } from '../../../../../../stores/users/user.store';
import { AvatarExtra } from '../../../../../layouts/CabinetLayout/Sider/UserBlock/UserBlock.styled';

interface MessageItemProps {
	fromMe: boolean;
	date: Dayjs;
	user?: UserStore;
	message: string;
}

export const MessageItem = observer(({ fromMe, date, message, user }: MessageItemProps) => {
	return (
		<Item fromMe={fromMe}>
			<div>
				<Date>{date.format(DATE_FORMAT.CHAT)}</Date>
				<Text>{message}</Text>
			</div>
			<AvatarExtra size={50} style={{ marginBottom: 5 }} src={user?.avatarFullPath} icon={<UserOutlined />} />
		</Item>
	);
});
