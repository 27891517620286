import { action, makeObservable, observable } from 'mobx';

import { ItemLocalStatusStore } from './item-local-status.store';
import { API } from '../../core';
import { ItemLocalStatus } from '../../interfaces/item';
import { CoreResponse } from '../../responses';

export class ItemLocalStatusesStore {
	@observable list: ItemLocalStatusStore[] = [];

	constructor() {
		makeObservable(this);
	}

	@action.bound
	async getList() {
		try {
			const { data } = await API.request<CoreResponse<ItemLocalStatus[]>>(`item-local-statuses`);

			this.list = (data || []).map((itemLocalStatus) => new ItemLocalStatusStore(itemLocalStatus));
		} catch (e) {
			console.error(`Error in method getList`, e);
		}
	}
}
