import { action, computed, makeObservable, observable } from 'mobx';

import { UserItemStore } from './user-item.store';
import { FilterItemBy } from '../../interfaces';
import { Item } from '../../interfaces/item';
import { FilterStore } from '../common';
import { AllStore } from '../common/all.store';
import { TableStore } from '../common/table.store';
import { ItemStore } from '../item';

export class UserItemsStore {
	@observable table = new TableStore<Item, ItemStore>({
		url: 'user-items',
		getListItemStore: this.getListItemStore,
		getPayloadParams: this.getPayloadParams.bind(this),
	});

	@observable all = new AllStore<Item, ItemStore>({
		url: 'user-items',
		getListItemStore: this.getListItemStore,
		getPayloadParams: this.getPayloadParams.bind(this),
	});

	@observable filter = new FilterStore();
	@observable filter_by: FilterItemBy = FilterItemBy.ForMe;

	constructor() {
		makeObservable(this);
	}

	@computed
	get isTaskRequestTable() {
		return this.filter_by === FilterItemBy.TaskExchange;
	}

	@action.bound
	async findAndActualData(item_id: number) {
		try {
			// Находим текущий item
			const findItem = this.table.list.find((item) => item.id === item_id);

			if (findItem) {
				await findItem.get();
			}
		} catch (e) {
			console.error(`Error in method findAndActualData : `, e);
		}
	}

	@action.bound
	setFilterBy(value: FilterItemBy) {
		this.filter_by = value;
	}

	@action.bound
	getListItemStore(value: Item): UserItemStore {
		return new UserItemStore(value);
	}

	@action.bound
	getPayloadParams() {
		return {
			filter_by: this.filter_by,
		};
	}
}
