import { Descriptions } from 'antd';
import { observer } from 'mobx-react-lite';

import { useStores } from '../../../../../stores';
import { FileButtons } from '../../../../common';

export const Files = observer(() => {
	const { userDashboardStore } = useStores();
	const { volumes } = userDashboardStore;
	const { viewDetail } = volumes;
	const { data: viewVolume } = viewDetail;

	return (
		<>
			<Descriptions layout={'vertical'} column={1}>
				<Descriptions.Item label={'Файлы'}>
					<div style={{ display: 'flex', width: '100%', flexDirection: 'column' }}>
						{viewVolume.files.list.map((file) => {
							return <FileButtons key={file.id} file={file} readOnly />;
						})}
					</div>
				</Descriptions.Item>
			</Descriptions>
		</>
	);
});
