import dayjs from 'dayjs';
import { action, makeObservable, observable } from 'mobx';

import { AgreementPrivateContractorDetail } from '../../interfaces';

export class AgreementPrivateContractorDetailStore implements AgreementPrivateContractorDetail {
	@observable id = 0;
	@observable ceo = '';
	@observable company_name = '';
	@observable registration_date = dayjs();
	@observable inn = 0;
	@observable legal_address = '';

	constructor(initialData: AgreementPrivateContractorDetail | null) {
		makeObservable(this);

		if (initialData) {
			this.fillStore(initialData);
		}
	}

	@action
	fillStore(data: AgreementPrivateContractorDetail) {
		const { id, ceo, company_name, registration_date, inn, legal_address } = data;

		this.id = id;
		this.ceo = ceo;
		this.company_name = company_name;
		this.registration_date = dayjs(registration_date);
		this.inn = inn;
		this.legal_address = legal_address;
	}
}
