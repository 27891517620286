import { action, computed, makeObservable, observable } from 'mobx';

import { NotificationStore } from './notification.store';
import { Notification } from '../../interfaces/notifications';
import { TableStore } from '../common/table.store';

export class NotificationsStore {
	@observable table = new TableStore<Notification, NotificationStore>({
		url: 'notifications',
		getListItemStore: this.getListItemStore,
		getPayloadParams: this.getPayloadParams.bind(this),
	});

	constructor() {
		makeObservable(this);
	}

	@action.bound
	getListItemStore(value: Notification): NotificationStore {
		return new NotificationStore(value);
	}

	@action.bound
	getPayloadParams() {
		return {};
	}

	@computed
	get lastNotification(): NotificationStore | null {
		return this.table.list[0] || null;
	}

	@computed
	get isExistNotShow(): boolean {
		return this.table.list.some((notify) => !notify.is_show);
	}
}
