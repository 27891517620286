import { Fragment } from 'react';

import { Descriptions, Typography } from 'antd';

import { ItemStore } from '../../../../../stores';

interface ContractorsBlockProps {
	itemStore: ItemStore;
}

export const ContractorsBlock = ({ itemStore }: ContractorsBlockProps) => {
	if (itemStore.contractors.length === 0) {
		return null;
	}

	return (
		<>
			<div style={{ height: 20 }} />

			<Descriptions title={'Контрагенты'} column={2} bordered size={'small'}>
				{itemStore.contractors.map((contractor) => {
					return (
						<Fragment key={contractor.id}>
							<Descriptions.Item label={'Контрагент'}>{contractor.contractor.company_name}</Descriptions.Item>
							<Descriptions.Item label={'Предмет договора'}>{contractor.subject_contract}</Descriptions.Item>
							<Descriptions.Item label={'Файлы'} span={2}>
								{contractor.files.map((file) => {
									return (
										<span key={file.id}>
											<Typography.Link href={file.fullPathLink} target={'_blank'}>
												{file.name}
											</Typography.Link>{' '}
										</span>
									);
								})}
							</Descriptions.Item>
						</Fragment>
					);
				})}
			</Descriptions>
		</>
	);
};
