import { useState } from 'react';

import { HistoryOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Drawer, List } from 'antd';

import { ItemStore } from '../../../../../stores';
import { AvatarExtra } from '../../../../layouts/CabinetLayout/Sider/UserBlock/UserBlock.styled';

interface HistoryReportProps {
	itemStore: ItemStore;
}

export const HistoryReport = ({ itemStore }: HistoryReportProps) => {
	const [isShow, setIsShow] = useState(false);

	const handleOnShow = () => setIsShow(true);

	const handleOnClose = () => setIsShow(false);

	return (
		<>
			<Button icon={<HistoryOutlined />} onClick={handleOnShow} type={'dashed'}>
				Посмотреть историю
			</Button>
			<Drawer title={`История`} open={isShow} width={600} closable={false} onClose={handleOnClose}>
				<List
					itemLayout={'horizontal'}
					dataSource={itemStore.reports}
					renderItem={(item) => {
						return (
							<List.Item key={item.id}>
								<List.Item.Meta
									avatar={<AvatarExtra icon={<UserOutlined />} src={item.user.fullPathAvatar} />}
									title={item.user.shortFullName}
									description={`${item.user.role.name} | ${item.createdAtFormatted}`}
									style={{ marginBottom: 5 }}
								/>
								{item.comment}

								{item.files.length ? (
									<div style={{ marginTop: 10 }}>
										{item.files.map((file) => (
											<div key={file.id}>
												<a href={file.fullPath} target={'_blank'} rel="noreferrer">
													{file.name}
												</a>
											</div>
										))}
									</div>
								) : null}
							</List.Item>
						);
					}}
				></List>
			</Drawer>
		</>
	);
};
