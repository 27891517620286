import { Modal } from 'antd';
import { observer } from 'mobx-react-lite';

import { ItemTypes } from '../../../../constants/item-types';
import { useStores } from '../../../../stores';
import { ItemObjectView, ItemTaskView, ItemVolumeView } from '../../Drawers/ItemViewDrawer/Viewers';

export const ItemViewTreeModal = observer(() => {
	const { itemsStore } = useStores();
	const { selectedTree } = itemsStore;

	const handleOnClose = () => {
		selectedTree.modal.close();
		itemsStore.clearSelectedTree();
	};

	return (
		<Modal open={selectedTree.modal.isShow} onCancel={handleOnClose} width={1000} footer={null}>
			{selectedTree.item_type_id === ItemTypes.object && <ItemObjectView itemObject={selectedTree} isShort />}
			{selectedTree.item_type_id === ItemTypes.volume && <ItemVolumeView itemVolume={selectedTree} isShort />}
			{selectedTree.item_type_id === ItemTypes.tasks && <ItemTaskView itemTask={selectedTree} isShort />}
		</Modal>
	);
});
