import { observer } from 'mobx-react-lite';
import ClipLoader from 'react-spinners/ClipLoader';

import { StartScreen } from './StartScreen/StartScreen';
import { Container, Loading } from './TurnOnGeolocation.styled';
import { useStores } from '../../../../stores';
import { COLORS } from '../../../../stylesheets';

export const TurnOnGeolocation = observer(() => {
	const { geolocationStore } = useStores();

	return (
		<Container>
			{geolocationStore.currentPermission &&
				['prompt', 'denied'].includes(geolocationStore.currentPermission.state) && <StartScreen />}

			<Loading>
				<ClipLoader color={COLORS.BLUE_100} size={50} />
				<div>Загрузка системы ... </div>
			</Loading>
		</Container>
	);
});
