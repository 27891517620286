import { CheckOutlined } from '@ant-design/icons';
import { Button, Col, Divider, Popconfirm, Row } from 'antd';
import { observer } from 'mobx-react-lite';

import { ItemStore, useStores } from '../../../../../stores';

interface TaskRequestProps {
	itemTask: ItemStore;
}

export const TaskRequest = observer(({ itemTask }: TaskRequestProps) => {
	const { itemsStore, userItemsStore } = useStores();
	const { selected: itemViewer } = itemsStore;

	const handleOnConfirm = async (responsible_id: number) => {
		await itemTask.update({
			responsible_id,
		});

		itemViewer.viewer.close();

		await userItemsStore.all.getList();
	};

	return (
		<>
			{itemTask.tasks_requests.map((task_request) => {
				return (
					<Row gutter={15} key={task_request.id}>
						<Col>
							{task_request.created_at.format('DD MMM YYYY')} <Divider type={'vertical'} />{' '}
							{task_request.responsible.shortFullName}
							<Divider type={'vertical'} />
							<Popconfirm title={`Утвердить?`} onConfirm={() => handleOnConfirm(task_request.responsible_id)}>
								<Button icon={<CheckOutlined />} type={'primary'} />
							</Popconfirm>
						</Col>
					</Row>
				);
			})}
		</>
	);
});
