export * from './method-requests.constants';
export * from './object-type.constants';
export * from './permissions.constants';
export * from './item-object-types';
export * from './frame-motion.constants';
export * from './auth.constants';
export * from './items.constants';
export * from './messages.constants';
export * from './actions.constants';
export * from './item.constants';
export * from './users.constants';
export * from './triggers.constants';
export * from './days.constants';
export * from './audio.constants';
export * from './item-project-documentation.constants';
export * from './forms.constants';

export const DRAWER_WINDOW = {
	OPEN: true,
	CLOSE: false,
};

export const ITEMS_TABS = {
	MAIN: 'main',
	FILES: 'files',
	CONTRACTORS: 'contractors',
	NOTES: 'notes',
	RELATIONSHIP: 'relationship',
};

export const PROJECT_DOCUMENTATION_TABS = {
	MAIN: 'main',
	VOLUMES: 'volumes',
	SCENARIOS: 'scenarios',
};

export const OBJECT_TABS = {
	MAIN: 'main',
	FILES: 'files',
	CONTRACTORS: 'contractors',
	NOTES: 'notes',
};

export const VOLUME_TABS = {
	INFORMATION: 'volume_information',
	TASKS: 'volume_tasks',
	FILES: 'volume_files',
};

export const TASK_TABS = {
	INFORMATION: 'task_information',
	FILES: 'task_files',
	CHAT: 'task_chat',
};

export const LOCAL_STORAGE_ITEMS = {
	OBJECT_TYPE_TAB: 'object_type_tab',
};

export const OBJECT_FILES_BLOCK = {
	TECH_FILES: 'tech_files',
	ADMIN_FILES: 'admin_files',
	TECH_TASK_FILES: 'tech_task_files',
};

export const DATE_FORMAT = {
	FULL: 'DD MMMM YYYY',
	REQUEST_FULL_DATE: 'YYYY-MM-DD',
	CHAT: 'HH:mm  DD MMMM YYYY',
};

export const FILTER_CARD_WIDGET = {
	CREATOR: 'creator_id',
	RESPONSIBLE: 'responsible_id',
};

export const NAMES_MAPPING = {
	CREATE: 'Создать',
	ADD: 'Добавить',
};
