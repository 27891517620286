import { action, makeObservable, observable } from 'mobx';

import { TableStore } from './table.store';
import { PaginationI } from '../../interfaces';

export class PaginationStore implements PaginationI {
	@observable total = 0;
	@observable count = 0;
	@observable per_page = 10;
	@observable current_page = 1;
	@observable total_pages = 0;

	tableStore: TableStore<any, any>;

	constructor(initialData: PaginationI | null, tableStore: TableStore<any, any>) {
		makeObservable(this);

		this.tableStore = tableStore;

		if (initialData) {
			this.fillStore(initialData);
		}
	}

	@action.bound
	async setCurrentPage(value: number) {
		this.current_page = value;
		await this.tableStore.getList();
	}

	@action.bound
	setLastPage() {
		this.current_page = this.total_pages;
	}

	@action.bound
	reset() {
		this.current_page = 1;
		this.total = 0;
		this.total_pages = 0;
	}

	@action
	fillStore(data: PaginationI) {
		const { total, count, per_page, current_page, total_pages } = data;

		this.total = total;
		this.count = count;
		this.per_page = per_page;
		this.current_page = current_page;
		this.total_pages = total_pages;
	}
}
