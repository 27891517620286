import { useEffect, useState } from 'react';

import { UploadOutlined } from '@ant-design/icons';
import { Button, Descriptions, Divider, Input, Modal, Upload, UploadFile } from 'antd';
import { observer } from 'mobx-react-lite';

import { ItemStore, useStores } from '../../../../../stores';
import { TriggerWorkflowStore } from '../../../../../stores/trigger-workflows';
import { Container } from '../../../../common/FileUploadBlock/FileUploadBlock.styled';
import { HistoryReport } from '../HistoryReport/HistoryReport';

interface ConfirmBlockProps {
	itemStore: ItemStore;
}

const { confirm, info } = Modal;

export const ConfirmBlock = observer(({ itemStore }: ConfirmBlockProps) => {
	const { authStore, userItemsStore, itemsStore } = useStores();
	const { selected: itemViewer } = itemsStore;

	const [comment, setComment] = useState('');
	const [uploadFiles, setUploadFiles] = useState<Array<File>>([]);

	useEffect(() => {
		if (itemStore.is_confirmed && authStore.current.id === itemViewer.creator_id) {
			if (itemStore.trigger_workflows.length > 0) {
				itemStore.trigger_workflows.forEach((triggerWorkflow) => {
					if (!triggerWorkflow.is_done) {
						if (triggerWorkflow.isConfirmModal) {
							confirm({
								title: triggerWorkflow.title,
								content: triggerWorkflow.description,
								okText: 'Да',
								cancelText: 'Нет',
								onOk: async () => {
									if (triggerWorkflow.relation_inside_uuid) {
										await handleActiveRelationItem(triggerWorkflow, triggerWorkflow.relation_inside_uuid);

										itemViewer.viewer.close();

										await userItemsStore.all.getList();
									}
								},
								onCancel: async () => {
									if (triggerWorkflow.relation_inside_uuid_no) {
										await handleActiveRelationItem(triggerWorkflow, triggerWorkflow.relation_inside_uuid_no);

										itemViewer.viewer.close();

										await userItemsStore.all.getList();
									}
								},
							});
						} else {
							info({
								title: `Активация следующей задачи : ${triggerWorkflow.trigger_item.title}`,
								onOk: async () => {
									if (triggerWorkflow.relation_inside_uuid) {
										await handleActiveRelationItem(triggerWorkflow, triggerWorkflow.relation_inside_uuid);

										itemViewer.viewer.close();

										await userItemsStore.all.getList();
									}
								},
							});
						}
					}
				});
			}
		}
	}, [itemStore.is_confirmed]);

	const handleActiveRelationItem = async (triggerWorkflow: TriggerWorkflowStore, variant: string) => {
		try {
			await triggerWorkflow.update(
				{
					variant,
				} as any,
				true,
				false,
			);
		} catch (e) {
			console.error(`Error in method handleActiveRelationItem : `, e);
		}
	};

	const handleOnIsUpdate = async () => {
		try {
			// Если нет замечаний
			await itemStore.update({
				is_confirmed: true,
			});

			// Обновление рабочего стола
			await userItemsStore.all.getList();
		} catch (e) {
			/* empty */
		}
	};

	const handleOnSendBack = async () => {
		try {
			// Возвращаем на доработку
			await itemStore.sendBack({ comment, files: uploadFiles });

			// Очищаем состояние
			setComment('');

			await userItemsStore.all.getList();
		} catch (e) {
			console.error(`Error handleOnSendBack`);
		}
	};

	const handleOnSetUpload = (files: UploadFile[]) => {
		const completeUploadFile = files.map((file) => file.originFileObj);
		setUploadFiles(completeUploadFile as File[]);
	};

	if (itemStore.is_delete) {
		return null;
	}

	if (!itemStore.canConfirm || authStore.current.id !== itemStore.creator_id) {
		return null;
	}

	return (
		<>
			<div style={{ height: 25 }} />

			<Descriptions
				title={'Подтверждение'}
				column={1}
				bordered
				labelStyle={{ width: 350 }}
				extra={[itemStore.reports.length > 0 && <HistoryReport key={'history-button'} itemStore={itemStore} />]}
			>
				<Descriptions.Item label={'Отправлено на утверждение'}>
					{itemStore.lastReport.createdAtFormatted}
				</Descriptions.Item>

				<Descriptions.Item label={'Информация'}>{itemStore.lastReport.comment}</Descriptions.Item>

				{itemStore.lastReport.files.length > 0 && (
					<Descriptions.Item label={'Файлы'}>
						{itemStore.lastReport.files.map((file) => (
							<div key={file.id}>
								<a href={file.fullPath} target={'_blank'} rel="noreferrer">
									{file.name}
								</a>
							</div>
						))}
					</Descriptions.Item>
				)}

				<>
					<Descriptions.Item label={'Комментарий'}>
						<Input.TextArea
							autoSize={{ minRows: 2, maxRows: 6 }}
							value={comment}
							onChange={(e) => setComment(e.target.value)}
						/>
					</Descriptions.Item>

					<Descriptions.Item label={'Файлы'}>
						<Upload
							beforeUpload={() => false}
							multiple
							onChange={(e) => handleOnSetUpload(e.fileList)}
							onRemove={(e) => console.log(e)}
						>
							<Container>
								<UploadOutlined /> Выбрать файлы или переместить сюда
							</Container>
						</Upload>
					</Descriptions.Item>
				</>

				<Descriptions.Item>
					<Button type={'primary'} onClick={handleOnIsUpdate}>
						Согласовано
					</Button>

					<Divider type={'vertical'} />

					<Button type={'primary'} danger onClick={handleOnSendBack}>
						Отправить на доработку
					</Button>
				</Descriptions.Item>
			</Descriptions>
		</>
	);
});
