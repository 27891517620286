import { notification } from 'antd';
import { action, computed, makeObservable, observable } from 'mobx';

import { VolumeStore } from './volume.store';
import { ErrorAPI } from '../../helpers';
import { PayloadParamsI, VolumeI } from '../../interfaces';
import { DetailStore } from '../common/detail.store';
import { ModalStore } from '../common/modal.store';
import { TableStore } from '../common/table.store';

export class VolumesStore {
	@observable isLoading = false;
	@observable modal = new ModalStore();
	@observable list: VolumeStore[] = [];
	@observable selected = new VolumeStore(null);
	@observable viewDetail = new DetailStore<VolumeStore>(new VolumeStore(null));
	@observable tableUser = new TableStore<VolumeI, VolumeStore>({
		url: 'user-volumes',
		getListItemStore: this.getListItemStore,
		getPayloadParams: this.getPayloadParams.bind(this),
	});

	constructor() {
		makeObservable(this);
	}

	@action.bound
	getListItemStore(value: VolumeI): VolumeStore {
		return new VolumeStore(value);
	}

	@action.bound
	getPayloadParams(): PayloadParamsI {
		return {};
	}

	@action.bound
	setIsLoading(value: boolean) {
		this.isLoading = value;
	}

	@action.bound
	setList(values: VolumeI[]) {
		this.list = (values || []).map((value) => new VolumeStore(value));
	}

	@action.bound
	clearList() {
		this.list = [];
	}

	@action.bound
	setSelected(value: VolumeStore) {
		this.selected = value;
	}

	@action.bound
	clearSelected() {
		this.selected = new VolumeStore(null);
	}

	@action.bound
	add(value: VolumeStore) {
		const list = [...this.list];
		list.push(value);
		this.list = list;
	}

	@action.bound
	delete(id: number) {
		const list = [...this.list];
		this.list = list.filter((item) => item.id !== id);
	}

	@action.bound
	changeSort() {
		const updateList = [...this.list];
		updateList.sort((a, b) => a.sort - b.sort);
		this.list = updateList.map((volume) => new VolumeStore(volume));
	}

	@action.bound
	async updateSort() {
		this.setIsLoading(true);
		try {
			for (let i = 0; i < this.list.length; i++) {
				const volumeStore = this.list[i];
				await volumeStore.update(
					{
						sort: volumeStore.sort,
					},
					false,
				);
			}

			notification.success({
				message: 'Порядок успешно обновлен',
			});
		} catch (e) {
			ErrorAPI('updateSort', e);
		} finally {
			this.setIsLoading(false);
		}
	}

	@computed
	get totalVolumes() {
		return this.list.length;
	}
}
