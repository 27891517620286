import { action, makeObservable, observable } from 'mobx';

import { OBJECT_TYPES } from '../../constants';
import { ObjectStatusI } from '../../interfaces';
import { CrudStore } from '../common/crud.store';

export class ObjectStatusStore extends CrudStore<ObjectStatusI> implements ObjectStatusI {
	PATH = 'work-object-status';

	@observable object_type_id = OBJECT_TYPES.MAIN_NETWORKS;
	@observable parent_id = 0;
	@observable name = '';
	@observable description = '';
	@observable children: ObjectStatusStore[] = [];

	constructor(initialData: ObjectStatusI | null) {
		super();
		makeObservable(this);

		if (initialData) {
			this.fillStore(initialData);
		}
	}

	@action
	fillStore(data: ObjectStatusI) {
		const { id, object_type_id, parent_id, name, description, children } = data;

		this.id = id;
		this.object_type_id = object_type_id;
		this.parent_id = parent_id;
		this.name = name;
		this.description = description;
		this.children = (children || []).map((status) => new ObjectStatusStore(status));
	}
}
