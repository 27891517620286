import { action, makeObservable, observable } from 'mobx';

import { RoleDetailSmallI } from '../../interfaces/role';

export class RoleDetailSmallStore implements RoleDetailSmallI {
	@observable id = 0;
	@observable name = '';

	constructor(initialData: RoleDetailSmallI | null) {
		makeObservable(this);

		if (initialData) {
			this.fillStore(initialData);
		}
	}

	@action
	fillStore(data: RoleDetailSmallI) {
		const { id, name } = data;

		this.id = id;
		this.name = name;
	}
}
