import { Typography } from 'antd';
import { observer } from 'mobx-react-lite';

import { BodyContainer, Item, LastMessage, Person, StatusContainer } from './Dialogs.styled';
import { useStores } from '../../../../../../stores';
import { DialogStore } from '../../../../../../stores/dialogs/dialog.store';

interface DialogItemProps {
	dialog: DialogStore;
}

export const DialogItem = observer(({ dialog }: DialogItemProps) => {
	const { authStore, dialogsStore } = useStores();
	const { current: currentUser } = authStore;

	const handleOnChooseDialog = () => {
		dialogsStore.setSelected(dialog);
	};

	const getName = () => {
		return currentUser.id === dialog.from_user_id ? dialog.to_user.shortFullName : dialog.from_user.shortFullName;
	};

	const getLastMessage = (): string => {
		const lastMessage = dialog.lastMessage;

		if (lastMessage) {
			return lastMessage.message;
		}

		return '-- Переписка не начата --';
	};

	return (
		<Item onClick={handleOnChooseDialog} isChoose={dialog.id === dialogsStore.selected?.id}>
			<StatusContainer></StatusContainer>

			<BodyContainer>
				<Person>{getName()}</Person>
				<LastMessage>
					<Typography.Text ellipsis>{getLastMessage()}</Typography.Text>
				</LastMessage>
			</BodyContainer>
		</Item>
	);
});
