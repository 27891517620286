import { Tag } from 'antd';

interface StatusProps {
	confirmation_by_responsible: boolean;
	confirmation_by_creator: boolean;
}

export const Status = ({ confirmation_by_responsible, confirmation_by_creator }: StatusProps) => {
	if (!confirmation_by_responsible && !confirmation_by_creator) {
		return <Tag>В работе</Tag>;
	} else if (confirmation_by_responsible && !confirmation_by_creator) {
		return <Tag>Проверка</Tag>;
	} else if (confirmation_by_responsible && confirmation_by_creator) {
		return <Tag>Выполнено</Tag>;
	} else {
		return <Tag>Не указано</Tag>;
	}
};
