import { Avatar, Popover, Tag } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { Observer } from 'mobx-react-lite';

import { getInformationUser } from '../../../../helpers';
import { UserItemStore } from '../../../../stores/user-items';
import { COLORS } from '../../../../stylesheets';
import { AvatarExtra } from '../../../layouts/CabinetLayout/Sider/UserBlock/UserBlock.styled';

export const useColumns = (): ColumnsType<UserItemStore> => {
	return [
		{
			title: 'Статус',
			align: 'center',
			width: 150,
			render: (_, row) => (
				<Observer>
					{() => {
						return <Tag>{row.localStatus}</Tag>;
					}}
				</Observer>
			),
		},
		{
			title: 'Название',
			align: 'center',
			ellipsis: true,
			render: (_, row) => row.title,
		},
		{
			title: 'Участники',
			align: 'center',
			width: 150,
			render: (_, row) => {
				return (
					<Observer>
						{() => {
							return (
								<Avatar.Group>
									{row.creator.isExist && (
										<Popover title={'Ответственный'} content={getInformationUser(row.creator)}>
											<AvatarExtra style={{ background: COLORS.BLUE_80 }}>{row.creator.initials}</AvatarExtra>
										</Popover>
									)}

									{row.responsible.isExist && (
										<Popover
											title={'Исполнитель'}
											content={
												row.responsible.isContractor
													? getInformationUser(row.responsible.contractor)
													: getInformationUser(row.responsible)
											}
										>
											<AvatarExtra style={{ background: COLORS.BLUE_100 }}>
												{row.responsible.isContractor
													? row.responsible.contractor.company_name
													: row.responsible.initials}
											</AvatarExtra>
										</Popover>
									)}
								</Avatar.Group>
							);
						}}
					</Observer>
				);
			},
		},
		{
			title: 'Начало / Заершение',
			align: 'center',
			width: 300,
			render: (_, row) => {
				return (
					<Observer>
						{() => {
							return (
								<span>
									{row.start_date?.format('DD MMMM YYYY')} - {row.end_date?.format('DD MMMM YYYY')}
								</span>
							);
						}}
					</Observer>
				);
			},
		},
		{
			title: 'Заявки принимаются до',
			align: 'center',
			width: 300,
			render: (_, row) => {
				return (
					<Observer>
						{() => {
							return <span>{row.deadline_execute_date?.format('DD MMMM YYYY')}</span>;
						}}
					</Observer>
				);
			},
		},
	];
};
