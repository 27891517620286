import { MinusCircleTwoTone, PlusCircleTwoTone } from '@ant-design/icons';
import { PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { Radio, RadioChangeEvent, Table, Typography } from 'antd';
import { observer } from 'mobx-react-lite';

import { useItemsTableColumns } from './ItemsTable.columns';
import { expandedRowRender } from './ItemVolumeTable/ItemVolumeTable';
import { useStores } from '../../../../../stores';
import { COLORS } from '../../../../../stylesheets';

interface ItemsTableProps {
	expandedRowKeys?: Array<number>;
	onSetExpandedRowKeys?: (data: Array<number>) => void;
}

export const ItemsTable = observer(({ expandedRowKeys, onSetExpandedRowKeys }: ItemsTableProps) => {
	const { itemsStore, itemObjectTypesStore } = useStores();

	const sensors = useSensors(
		useSensor(PointerSensor, {
			activationConstraint: {
				// https://docs.dndkit.com/api-documentation/sensors/pointer#activation-constraints
				distance: 1,
			},
		}),
	);

	const handleOnChangeType = (e: RadioChangeEvent) => {
		const { value } = e.target;
		itemsStore.setItemObjectTypeId(value);
	};

	const getHeader = () => {
		return (
			<div
				style={{
					padding: '25px 25px 15px',
					background: COLORS.TABLE_TD,
					display: 'flex',
					borderRadius: '20px 20px 0 0',
					justifyContent: 'left',
					columnGap: 15,
					borderBottom: `1px solid ${COLORS.SILVER_40}`,
				}}
			>
				<Typography.Text strong>Тип сетей :</Typography.Text>
				<Radio.Group
					disabled={false}
					value={itemsStore.item_object_type_id}
					options={itemObjectTypesStore.radioButtons}
					onChange={handleOnChangeType}
				/>
			</div>
		);
	};

	return (
		<>
			<Table
				loading={itemsStore.table.isLoading}
				rowKey={'id'}
				caption={getHeader()}
				columns={useItemsTableColumns()}
				dataSource={itemsStore.table.list}
				onRow={(row) => {
					return {
						onDoubleClick: async () => {
							// Открываем окно для редактирования
							itemsStore.selected.drawer.show();

							// Устаналиваем ID
							itemsStore.selected.setId(row.id);

							// Получаем информацию по объекту
							await itemsStore.selected.get();
						},
					};
				}}
				scroll={{
					x: 1200,
				}}
				expandable={{
					rowExpandable: (row) => row.isExistChildren,
					expandedRowKeys: expandedRowKeys,
					expandedRowRender: (row) =>
						expandedRowRender({
							itemObject: row,
							itemsStore,
							expandedRowKeys,
							onSetExpandedRowKeys,
							sensors,
						}),
					expandIcon: ({ expanded, onExpand, record }) => {
						if (record.children_count === 0) {
							return null;
						}

						return expanded ? (
							<MinusCircleTwoTone
								onClick={(e) => {
									e.preventDefault();
									e.stopPropagation();
									onExpand(record, e);
								}}
							/>
						) : (
							<PlusCircleTwoTone
								onClick={(e) => {
									e.preventDefault();
									e.stopPropagation();
									onExpand(record, e);
								}}
							/>
						);
					},
					onExpand: async (expanded, record) => {
						const keys: Array<number> = [];

						if (expanded) {
							keys.push(record.id);
							// Если подгрузки еще не было
							if (!record.childrenTable.isDirty && record.childrenTable.list.length === 0) {
								await record.childrenTable.getList();
							}
						}

						// Записываем ключи
						if (onSetExpandedRowKeys) {
							onSetExpandedRowKeys(keys);
							itemsStore.setChildrenRelations(keys);
						}
					},
				}}
				pagination={{
					current: itemsStore.table.pagination.current_page,
					total: itemsStore.table.pagination.total,
					onChange: (page) => itemsStore.table.pagination.setCurrentPage(page),
				}}
			/>
		</>
	);
});
