import { IssuesCloseOutlined, SearchOutlined } from '@ant-design/icons';
import { Avatar, Button, Checkbox, DatePicker, Input, Popover, Tag, Tooltip, Typography } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { Observer } from 'mobx-react-lite';

import { NameDays } from '../../../../../../constants';
import { getColorLocalStatus, getInformationUser, numWords } from '../../../../../../helpers';
import { ItemStore } from '../../../../../../stores';
import { COLORS } from '../../../../../../stylesheets';
import { AvatarExtra } from '../../../../../layouts/CabinetLayout/Sider/UserBlock/UserBlock.styled';

export const useVolumeItemsTableColumns = (): ColumnsType<ItemStore> => {
	return [
		{
			title: 'Шифр',
			align: 'center',
			width: 150,
			render: (_, row) => (
				<Typography.Title
					style={{ color: COLORS.BLUE_100, fontSize: 12, margin: 0 }}
					ellipsis={true}
				></Typography.Title>
			),
			filterIcon: () => {
				return <SearchOutlined />;
			},
			filterDropdown: ({ selectedKeys, setSelectedKeys, confirm }) => {
				return (
					<Input
						autoFocus
						placeholder={'Минимум 3 символа'}
						name={'cipher'}
						allowClear
						value={selectedKeys[0] as string}
						onChange={async (e) => {
							console.log('change ');
						}}
						onBlur={() => confirm()}
					/>
				);
			},
		},
		{
			title: 'Статус',
			align: 'center',
			width: 150,
			filterDropdown: ({ setSelectedKeys, confirm, selectedKeys }) => {
				return (
					<div>
						{/*{itemLocalStatusesStore.list.map((itemLocalStatus) => {
							return (
								<div key={itemLocalStatus.id}>
									<Checkbox
										value={itemLocalStatus.id}
										checked={selectedKeys.includes(itemLocalStatus.id)}
										onChange={(e) => {
											const { value } = e.target;
											const findIndex = selectedKeys.findIndex((item) => item === value);

											// Записываем или удаляем выбранный вариант
											if (findIndex === -1) {
												const complete = [...selectedKeys, ...[value]] as Array<number>;
												setSelectedKeys(complete);

												// Итоговый вариант записываем в фильтры
												userItemsStore.table.addFilter('item_local_status', complete);
											} else {
												selectedKeys.splice(findIndex, 1);
												setSelectedKeys([...selectedKeys]);

												// Итоговый вариант записываем в фильтры
												userItemsStore.table.addFilter('item_local_status', selectedKeys as Array<number>);
											}
										}}
									>
										{itemLocalStatus.status}
									</Checkbox>
								</div>
							);
						})}

						<Button
							type={'primary'}
							style={{ width: '100%', marginTop: 20 }}
							onClick={async () => {
								confirm();
							}}
						>
							Найти
						</Button>*/}
					</div>
				);
			},
			render: (_, row) => (
				<Observer>
					{() => {
						return <Tag color={getColorLocalStatus(row)}>{row.localStatus}</Tag>;
					}}
				</Observer>
			),
		},
		{
			title: 'Название',
			ellipsis: true,
			width: 400,
			render: (_, row) => (
				<>
					{row.parent_object?.id ? (
						<>
							<div style={{ display: 'flex', textAlign: 'left' }}>
								<Typography.Text strong style={{ fontSize: 12 }} ellipsis={{ tooltip: row.parent_object.title }}>
									Объект : {row.parent_object.title}
								</Typography.Text>
							</div>
						</>
					) : null}
					{row.is_finish && (
						<Tooltip title={`Закрывающая задача`}>
							<Tag icon={<IssuesCloseOutlined />} />
						</Tooltip>
					)}{' '}
					{row.title}
				</>
			),
			filterIcon: () => {
				return <SearchOutlined />;
			},
			filterDropdown: ({ selectedKeys, setSelectedKeys, confirm }) => {
				return (
					<Input
						autoFocus
						placeholder={'Минимум 3 символа'}
						name={'title'}
						allowClear
						value={selectedKeys[0] as string}
						onChange={async (e) => {
							/*const { value, name } = e.target;
              setSelectedKeys(value ? [value] : []);
              userItemsStore.table.addFilter(name, value);

              if (value.length > 0 && value.length < 3) {
                return false;
              }*/
						}}
						onBlur={() => confirm()}
					/>
				);
			},
		},

		{
			title: 'Участники',
			align: 'center',
			width: 150,
			render: (_, row) => {
				return (
					<Observer>
						{() => {
							return (
								<Avatar.Group>
									{row.creator.isExist && (
										<Popover title={'Ответственный'} content={getInformationUser(row.creator)}>
											<AvatarExtra style={{ background: COLORS.BLUE_80 }}>{row.creator.initials}</AvatarExtra>
										</Popover>
									)}

									{row.responsible.isExist && (
										<Popover
											title={'Исполнитель'}
											content={
												row.responsible.isContractor
													? getInformationUser(row.responsible.contractor)
													: getInformationUser(row.responsible)
											}
										>
											<AvatarExtra style={{ background: COLORS.BLUE_100 }}>
												{row.responsible.isContractor
													? row.responsible.contractor.company_name
													: row.responsible.initials}
											</AvatarExtra>
										</Popover>
									)}
								</Avatar.Group>
							);
						}}
					</Observer>
				);
			},
		},
		{
			title: 'Начало / Заершение',
			align: 'center',
			width: 300,
			render: (_, row) => {
				return (
					<Observer>
						{() => {
							return (
								<span>
									{row.start_date?.format('DD MMMM YYYY')} - {row.end_date?.format('DD MMMM YYYY')}
								</span>
							);
						}}
					</Observer>
				);
			},
			filterDropdown: ({ setSelectedKeys, confirm }) => {
				return (
					<div>
						<DatePicker.RangePicker
							picker={'date'}
							format={'DD MMM YYYY'}
							onChange={(e) => {
								/*if (Array.isArray(e)) {
                  const [dateStart, dateEnd] = e;

                  if (dateStart && dateEnd) {
                    setSelectedKeys([dateStart.toISOString(), dateEnd.toISOString()]);

                    userItemsStore.table.addFilter('date_range', [dateStart.toISOString(), dateEnd.toISOString()]);
                  }
                } else {
                  setSelectedKeys([]);
                  userItemsStore.table.addFilter('date_range', '');
                }*/
							}}
						/>

						<Button
							type={'primary'}
							style={{ width: '100%', marginTop: 20 }}
							onClick={async () => {
								confirm();
							}}
						>
							Найти
						</Button>
					</div>
				);
			},
		},
		{
			title: 'Отклонение',
			align: 'center',
			width: 150,
			render: (_, row) => {
				return (
					<Observer>
						{() => {
							return (
								<span>
									{row.deviation_days} {numWords(row.deviation_days, NameDays)}
								</span>
							);
						}}
					</Observer>
				);
			},
		},
	];
};
