import { InputNumber } from 'antd';
import styled from 'styled-components';

export const ContainerLine = styled.div`
	display: grid;
	grid-template-columns: 80px 1fr;
	align-items: center;
	column-gap: 20px;
	user-select: none;
	cursor: default;
`;

export const InputNumberCustom = styled(InputNumber)``;
