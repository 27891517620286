import dayjs, { Dayjs } from 'dayjs';
import { action, computed, makeObservable, observable } from 'mobx';

import { DATE_FORMAT } from '../../constants';
import { VolumeI } from '../../interfaces';
import { CrudStore } from '../common/crud.store';
import { ObjectDetailFlatStore } from '../objects/object-detail-flat.store';
import { TasksStore } from '../task/tasks.store';
import { UserDetailStore } from '../users/user-detail.store';
import { VolumeFilesStore } from '../volume-file/volume-files.store';

export class VolumeStore extends CrudStore<VolumeI> implements VolumeI {
	PATH = 'volumes';

	@observable sort = 0;
	@observable object_id: number | undefined = undefined;
	@observable creator_id: number | undefined = undefined;
	@observable responsible_id: number | undefined = undefined;
	@observable name_volume = '';
	@observable cipher = '';
	@observable start_date: Dayjs | undefined = undefined;
	@observable end_date: Dayjs | undefined = undefined;
	@observable actual_end_date: Dayjs | undefined = undefined;
	@observable deviation = 0;
	@observable confirmation_by_responsible = false;
	@observable confirmation_by_creator = false;
	@observable responsible_answer = '';
	@observable created_at = dayjs();
	@observable updated_at = dayjs();
	@observable creator = new UserDetailStore(null);
	@observable responsible = new UserDetailStore(null);

	@observable object = new ObjectDetailFlatStore(null);
	@observable tasks = new TasksStore();
	@observable files = new VolumeFilesStore();

	constructor(initialData: VolumeI | null) {
		super();
		makeObservable(this);

		if (initialData) {
			this.fillStore(initialData);
		}
	}

	@action.bound
	setObjectId(value: number) {
		this.object_id = value;
	}

	@action.bound
	setCreatorId(value: number) {
		this.creator_id = value;
	}

	@action.bound
	setSort(value: number) {
		this.sort = value;
	}

	@computed
	get completeCipher(): string {
		return `${this.object.cipher}-${this.cipher}`;
	}

	@computed
	get startDateFormatted() {
		if (this.start_date) return this.start_date.format('DD MMMM YYYY');

		return undefined;
	}

	@computed
	get endDateFormatted() {
		if (this.end_date) return this.end_date.format('DD MMMM YYYY');

		return undefined;
	}

	@computed
	get formattedWorkActualEndDate() {
		const year = this.actual_end_date?.format('YYYY');
		return year && year === '1899' ? undefined : this.actual_end_date;
	}

	@computed
	get deviationDays() {
		if (this.actual_end_date) {
			const countDays = this.actual_end_date.diff(this.end_date, 'days');
			return countDays && countDays >= 0 ? countDays : 0;
		} else {
			return 0;
		}
	}

	@computed
	get totalTasks() {
		return this.tasks.list.length;
	}

	@computed
	get actualEndDateView(): string {
		const year = this.actual_end_date?.format('YYYY');
		return year !== '1899' ? String(this.actual_end_date?.format(DATE_FORMAT.FULL)) : 'Не указана';
	}

	@action
	fillStore(data: VolumeI) {
		const {
			id,
			sort,
			object_id,
			creator_id,
			responsible_id,
			name_volume,
			cipher,
			start_date,
			end_date,
			actual_end_date,
			deviation,
			confirmation_by_responsible,
			confirmation_by_creator,
			responsible_answer,
			created_at,
			updated_at,
			creator,
			responsible,

			object,
			taskList,
			filesList,
		} = data;

		this.id = id;
		this.sort = sort;
		this.object_id = object_id;
		this.creator_id = creator_id;
		this.responsible_id = responsible_id;
		this.name_volume = name_volume;
		this.cipher = cipher;
		this.start_date = dayjs(start_date);
		this.end_date = dayjs(end_date);
		this.actual_end_date = dayjs(actual_end_date);
		this.deviation = deviation;
		this.confirmation_by_responsible = confirmation_by_responsible;
		this.confirmation_by_creator = confirmation_by_creator;
		this.responsible_answer = responsible_answer;
		this.created_at = dayjs(created_at);
		this.updated_at = dayjs(updated_at);
		this.creator = new UserDetailStore(creator);
		this.responsible = new UserDetailStore(responsible);

		if (Array.isArray(taskList)) {
			this.tasks.setList(taskList);
		}

		if (Array.isArray(filesList)) {
			this.files.setList(filesList);
		}

		if (object) {
			this.object = new ObjectDetailFlatStore(object);
		}
	}
}
