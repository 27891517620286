import { Alert, Button, Collapse, CollapseProps, FormInstance, FormListFieldData } from 'antd';
import { observer } from 'mobx-react-lite';

interface VolumeListProps {
	fields: FormListFieldData[];
	onRemoveItem: (index: number | number[]) => void;
	form: FormInstance;
}

export const VolumeList = observer(({ fields, form, onRemoveItem }: VolumeListProps) => {
	if (fields.length === 0) {
		return <Alert showIcon type={'info'} message={'Нет томов'} />;
	}

	console.log(form);

	const getPanel = () => {
		const items: CollapseProps['items'] = [];

		fields.forEach(({ name, key }) => {
			console.log(name, key);

			items.push({
				key: name,
				label: `Том ${key}`,
				children: <div></div>,
				extra: (
					<>
						<Button
							danger
							type={'primary'}
							onClick={(e) => {
								e.stopPropagation();
								onRemoveItem(name);
							}}
						/>
					</>
				),
			});
		});

		return items;
	};

	return <Collapse accordion items={getPanel()} />;
});
