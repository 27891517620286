import { rgba } from 'polished';
import styled from 'styled-components';

import { COLORS } from '../../../stylesheets';

export const Container = styled.div`
	border: 1px dashed ${COLORS.BLUE_100};
	border-radius: 20px;
	cursor: pointer;
	transition: all 0.2s;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	row-gap: 15px;
	overflow-y: auto;

	&:hover {
		background: ${rgba(COLORS.BLUE_100, 0.05)};
	}
`;

export const Icon = styled.div`
	svg {
		font-size: 35px;
		fill: ${rgba(COLORS.BLUE_100, 1)};
	}
`;

export const UploadText = styled.div`
	font-size: 14px;
	text-align: center;
	color: ${rgba(COLORS.BLUE_100, 1)};
`;

export const CountUploadFile = styled.div`
	font-size: 14px;
	text-align: center;
	color: ${rgba(COLORS.BLUE_100, 1)};
`;
