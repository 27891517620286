import { action, makeObservable, observable } from 'mobx';

import { AgreementStatusStore } from './agreement-status.store';
import { AgreementStatus } from '../../interfaces';
import { AllStore } from '../common/all.store';

export class AgreementStatusesStore {
	@observable all = new AllStore({
		url: 'agreement-statuses',
		getListItemStore: this.getListItemStore,
		getPayloadParams: this.getPayloadParams,
	});

	constructor() {
		makeObservable(this);
	}

	@action.bound
	private getListItemStore(value: AgreementStatus) {
		return new AgreementStatusStore(value);
	}

	@action.bound
	private getPayloadParams() {
		return {};
	}
}
