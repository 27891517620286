import dayjs from 'dayjs';
import { action, makeObservable, observable } from 'mobx';

import { ItemContractorFile } from '../../interfaces/item';
import { UserFileStore } from '../users/user-file.store';

export class ItemContractorFileStore extends UserFileStore implements ItemContractorFile {
	PATH = 'item-contractor-file';

	@observable item_contractor_id = 0;
	@observable contractor_id = 0;

	constructor(initialData: ItemContractorFile | null) {
		super(initialData);
		makeObservable(this);

		if (initialData) {
			this.fillStore(initialData);
		}

		this.fillStore = action(this.fillStore.bind(this));
	}

	fillStore(data: ItemContractorFile) {
		const { id, item_contractor_id, contractor_id, user_id, name, file, is_delete, created_at, updated_at } = data;

		this.id = id;
		this.item_contractor_id = item_contractor_id;
		this.contractor_id = contractor_id;
		this.user_id = user_id;
		this.name = name;
		this.file = file;
		this.is_delete = is_delete;
		this.created_at = dayjs(created_at);
		this.updated_at = dayjs(updated_at);
	}
}
