import React, { useEffect, useState } from 'react';

import { Button, Col, Empty, Form, FormInstance, Modal, Popconfirm, Row, Select, Spin } from 'antd';
import { useForm } from 'antd/es/form/Form';
import dayjs from 'dayjs';
import { observer } from 'mobx-react-lite';

import { Container } from './ItemProjectGenerateModal.styled';
import { ItemObjectTypes } from '../../../../constants';
import {
	deleteProjectGenerateLocalStorage,
	findProjectGenerateLocalStorage,
	saveProjectGenerateLocalStorage,
} from '../../../../helpers';
import { useStores } from '../../../../stores';
import { ItemProjectGenerateTable } from '../../Tables';

export const ItemProjectGenerateModal = observer(() => {
	const { itemProjectGenerateStore, itemProjectDocumentsStore, itemsStore, employeesStore } = useStores();
	const { selectedObject } = itemProjectGenerateStore;

	const [isDirtyForm, setIsDirtyForm] = useState(false);
	const [selectedId, setSelectedId] = useState<undefined | number>(undefined);
	const [existDraft, setExistDraft] = useState(false);

	// Контроль формы
	const [form] = useForm<FormInstance>();

	useEffect(() => {
		// Если закрыли окно, очищаем
		if (!itemProjectGenerateStore.modal.isShow) {
			itemProjectGenerateStore.clearSelectedObject();
			setSelectedId(undefined);
		}
	}, [itemProjectGenerateStore.modal.isShow]);

	useEffect(() => {
		if (selectedObject.isDataLoaded) {
			(async () => {
				// Получаем проектную документацию по типу проекта
				itemProjectDocumentsStore.setCurrentItemObjectTypeId(selectedObject.item_object_type_id as ItemObjectTypes);
				await itemProjectDocumentsStore.all.getList();

				// Получаем весь список пользователей
				await employeesStore.all.getList();
			})();
		}
	}, [selectedObject.isDataLoadedProcess]);

	// Ищем промежуточные сохранения формы
	useEffect(() => {
		if (selectedId) {
			const result = findProjectGenerateLocalStorage(Number(selectedId));

			// Если результат найден то заполняем форму
			if (result) {
				setExistDraft(true);
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				form.setFieldsValue({ items: result });
			}
		}
	}, [selectedId]);

	const handleOnClose = () => {
		if (isDirtyForm) {
			Modal.confirm({
				title: 'Внимание',
				content: 'Если Вы закроете окно, результаты будут сохранены в локальной памяти.',
				onOk: () => {
					itemProjectGenerateStore.modal.close();
					setIsDirtyForm(false);
				},
				okText: 'Закрыть',
			});
		} else {
			itemProjectGenerateStore.modal.close();
		}
	};

	const getTitle = () => {
		return `Генерация томов для объекта : ${selectedObject.title}`;
	};

	const handleOnChange = (id: number) => {
		// Очистка формы
		form.resetFields();

		setSelectedId(id);

		const dataForm = itemProjectDocumentsStore.getDataForFormById(id);

		if (dataForm) {
			// Прогружаем новые данные
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			form.setFieldsValue({ items: dataForm });
		}
	};

	const handleOnFinish = async (values: any) => {
		try {
			const cloneValues = JSON.parse(JSON.stringify(values));

			// Сразу правим даты в формат
			cloneValues['items'].forEach((item: any, index: number) => {
				const startDate = cloneValues['items'][index]['volume_dates'][0];
				const endDate = cloneValues['items'][index]['volume_dates'][1];

				if (startDate instanceof dayjs) {
					cloneValues['items'][index]['volume_dates'][0] =
						cloneValues['items'][index]['volume_dates'][0].format('YYYY-MM-DD');
				}

				if (endDate instanceof dayjs) {
					cloneValues['items'][index]['volume_dates'][1] =
						cloneValues['items'][index]['volume_dates'][1].format('YYYY-MM-DD');
				}
			});

			// Генерация
			await selectedObject.generate(cloneValues['items']);

			// Обновление таблицы
			await itemsStore.table.getList();

			// Закрываем окно
			itemProjectGenerateStore.modal.close();

			// Удаляем промежуточный черновик
			deleteProjectGenerateLocalStorage(Number(selectedId));

			// Удаляем пометку о черновике
			setExistDraft(false);
		} catch (e) {
			console.error(`Error in generate handleOnOk : `, e);
		}
	};

	const handleOnChangeForm = () => {
		// данные
		console.log('handleOnChangeForm', form.getFieldsValue());

		// Сохраняем данные
		saveProjectGenerateLocalStorage(JSON.stringify(form.getFieldsValue()), Number(selectedId));

		setIsDirtyForm(true);
	};

	return (
		<>
			<Modal
				title={getTitle()}
				width={1600}
				styles={{
					body: { minHeight: 600 },
				}}
				open={itemProjectGenerateStore.modal.isShow}
				onCancel={handleOnClose}
				onOk={form.submit}
				okText={'Сгенерировать'}
				okButtonProps={{
					disabled: false,
					loading: selectedObject.isLoading,
					//disabled:  !selectedRowKeys.length || selectedObject.isLoading,
				}}
			>
				<Spin spinning={itemProjectGenerateStore.selectedObject.isLoading} tip={`Загрузка`}>
					<Form>
						<Form.Item label={'Проектная документация'}>
							<Select
								value={selectedId}
								placeholder={'Выберите тип проектной документации'}
								options={itemProjectDocumentsStore.selectBox}
								onChange={(value) => {
									setExistDraft(false);

									handleOnChange(value);
								}}
							/>
						</Form.Item>
					</Form>

					{itemProjectDocumentsStore.getSelectedById(selectedId) ? (
						<Container>
							<Form
								layout={'vertical'}
								form={form}
								onFinish={handleOnFinish}
								onFinishFailed={(data) => console.log(data)}
								initialValues={
									{
										//items: itemProjectDocumentsStore.getDataForFormById(selectedId),
									}
								}
								onValuesChange={handleOnChangeForm}
							>
								{existDraft && (
									<>
										<Row>
											<Col span={24} style={{ textAlign: 'right' }}>
												<Popconfirm
													title={`Хотите удалить черновик?`}
													onConfirm={() => {
														deleteProjectGenerateLocalStorage(Number(selectedId));
														setExistDraft(false);
													}}
												>
													<Button danger type={'primary'} style={{ marginBottom: 15 }}>
														Удалить черновик
													</Button>
												</Popconfirm>
											</Col>
										</Row>
									</>
								)}

								<ItemProjectGenerateTable
									form={form}
									index={selectedId || 0}
									data={itemProjectDocumentsStore.getSelectedById(selectedId)?.item_volume_generates ?? []}
								/>
							</Form>
						</Container>
					) : (
						<Empty description={`Выберите из списка вариант проектной документации`} />
					)}
				</Spin>
			</Modal>
		</>
	);
});
