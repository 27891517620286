import { CloseOutlined, DownloadOutlined } from '@ant-design/icons';
import { Button, Form, Popconfirm, Space, Tag } from 'antd';

import { UserFileStore } from '../../../stores/users/user-file.store';

interface UploadedUserBlockProps {
	files: UserFileStore[];
	onDelete: (file: UserFileStore) => void;
}

export const UploadedUserBlock = ({ files, onDelete }: UploadedUserBlockProps) => {
	const handleOnDelete = (file: UserFileStore) => {
		onDelete(file);
	};

	const handleOnDownload = (file: UserFileStore) => {
		const link = document.createElement('a');
		link.setAttribute('download', file.name);
		link.href = file.fullPathLink;
		document.body.appendChild(link);
		link.click();
		link.remove();
	};

	return (
		<>
			<Form.Item label={'Загруженные файлы'}>
				<Space direction={'vertical'}>
					{files.map((file) => {
						return (
							<Popconfirm key={file.id} title={'Удалить?'} trigger={['click']} onConfirm={() => handleOnDelete(file)}>
								<Tag
									style={{ cursor: 'pointer', whiteSpace: 'normal', wordBreak: 'break-all' }}
									icon={<CloseOutlined />}
								>
									{file.name}
								</Tag>
								<Button size={'small'} icon={<DownloadOutlined />} onClick={() => handleOnDownload(file)} />
							</Popconfirm>
						);
					})}
				</Space>
			</Form.Item>
		</>
	);
};
