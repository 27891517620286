import { UserOutlined } from '@ant-design/icons';
import { Avatar, Dropdown, MenuProps } from 'antd';
import { observer } from 'mobx-react-lite';

import { Block, Body, FullName, Post, UserInformationContainer } from './UserInformation.styled';
import { useStores } from '../../../../stores';
import { COLORS } from '../../../../stylesheets';

export const UserInformation = observer(() => {
	const { authStore } = useStores();

	const menuDropDown: MenuProps['items'] = [
		{
			key: 'profile',
			label: 'Профиль',
		},
		{
			key: 'exit',
			label: 'Выход',
		},
	];

	const handleOnClickDropDown: MenuProps['onClick'] = ({ key }) => {
		switch (key) {
			case 'profile':
				authStore.drawer.show();
				break;
			case 'exit':
				authStore.logout();
				break;
		}
	};

	return (
		<UserInformationContainer>
			<Body>
				<Dropdown trigger={['click']} menu={{ items: menuDropDown, onClick: handleOnClickDropDown }}>
					<Block>
						<FullName>{authStore.current.shortFullName}</FullName>
						<Post>{authStore.current.roleName}</Post>
					</Block>
				</Dropdown>
				<Avatar
					icon={<UserOutlined />}
					size={60}
					src={authStore.current.avatarFullPath}
					style={{ border: `2px solid ${COLORS.BLUE_100}`, background: COLORS.BLUE_100 }}
				/>
			</Body>
		</UserInformationContainer>
	);
});
