import dayjs from 'dayjs';
import { action, computed, makeObservable, observable } from 'mobx';

import { PERMISSIONS } from '../../constants';
import { DayJsNull, Role } from '../../interfaces';
import { CrudStore } from '../common/crud.store';
import { PermissionStore } from '../permissions/permission.store';

export class RoleStore extends CrudStore<Role> implements Role {
	PATH = 'roles';

	@observable name = '';
	@observable created_at: DayJsNull = dayjs();
	@observable updated_at: DayJsNull = dayjs();
	@observable permissions: PermissionStore[] = [];

	@observable permissionsList: Array<string> = [];

	constructor(initialData: Role | null) {
		super();
		makeObservable(this);

		if (initialData) {
			this.fillStore(initialData);
		}
	}

	@action.bound
	togglePermission(values: Array<string>) {
		this.permissionsList = values;
	}

	// Объекты
	@computed
	get objectCreate() {
		return this.permissionsList.includes(String(PERMISSIONS.OBJECT_CREATE));
	}

	@computed
	get objectEdit() {
		return this.permissionsList.includes(String(PERMISSIONS.OBJECT_EDIT));
	}

	@computed
	get objectView() {
		return this.permissionsList.includes(String(PERMISSIONS.OBJECT_VIEW));
	}

	@computed
	get objectDelete() {
		return this.permissionsList.includes(String(PERMISSIONS.OBJECT_DELETE));
	}

	@computed
	get objectUploadTechFile() {
		return this.permissionsList.includes(String(PERMISSIONS.OBJECT_UPLOAD_TECH_FILES));
	}

	@computed
	get objectViewTechFile() {
		return this.permissionsList.includes(String(PERMISSIONS.OBJECT_VIEW_TECH_FILES));
	}

	@computed
	get objectUploadAdminFile() {
		return this.permissionsList.includes(String(PERMISSIONS.OBJECT_UPLOAD_ADMIN_FILES));
	}

	@computed
	get objectViewAdminFile() {
		return this.permissionsList.includes(String(PERMISSIONS.OBJECT_VIEW_ADMIN_FILES));
	}

	@computed
	get objectUploadTechTaskFile() {
		return this.permissionsList.includes(String(PERMISSIONS.OBJECT_UPLOAD_TECH_TASK_FILES));
	}

	@computed
	get objectViewTechTaskFile() {
		return this.permissionsList.includes(String(PERMISSIONS.OBJECT_VIEW_TECH_TASK_FILES));
	}

	// Тома
	@computed
	get volumeCreate() {
		return this.permissionsList.includes(String(PERMISSIONS.VOLUME_CREATE));
	}

	@computed
	get volumeEdit() {
		return this.permissionsList.includes(String(PERMISSIONS.VOLUME_EDIT));
	}

	@computed
	get volumeView() {
		return this.permissionsList.includes(String(PERMISSIONS.VOLUME_VIEW));
	}

	@computed
	get volumeDelete() {
		return this.permissionsList.includes(String(PERMISSIONS.VOLUME_DELETE));
	}

	@computed
	get volumeUploadFile() {
		return this.permissionsList.includes(String(PERMISSIONS.VOLUME_UPLOAD_FILES));
	}

	@computed
	get volumeViewFile() {
		return this.permissionsList.includes(String(PERMISSIONS.VOLUME_VIEW_FILES));
	}

	// Задачи
	@computed
	get taskCreate() {
		return this.permissionsList.includes(String(PERMISSIONS.TASK_CREATE));
	}

	@computed
	get taskEdit() {
		return this.permissionsList.includes(String(PERMISSIONS.TASK_EDIT));
	}

	@computed
	get taskView() {
		return this.permissionsList.includes(String(PERMISSIONS.TASK_VIEW));
	}

	@computed
	get taskDelete() {
		return this.permissionsList.includes(String(PERMISSIONS.TASK_DELETE));
	}

	@computed
	get taskUploadFile() {
		return this.permissionsList.includes(String(PERMISSIONS.TASK_UPLOAD_FILES));
	}

	@computed
	get taskViewFile() {
		return this.permissionsList.includes(String(PERMISSIONS.TASK_VIEW_FILES));
	}

	// Пользователи
	@computed
	get userCreate() {
		return this.permissionsList.includes(String(PERMISSIONS.USER_CREATE));
	}

	@computed
	get userEdit() {
		return this.permissionsList.includes(String(PERMISSIONS.USER_EDIT));
	}

	@computed
	get userView() {
		return this.permissionsList.includes(String(PERMISSIONS.USER_VIEW));
	}

	@computed
	get userDelete() {
		return this.permissionsList.includes(String(PERMISSIONS.USER_DELETE));
	}

	// Контрагенты
	@computed
	get contractorCreate() {
		return this.permissionsList.includes(String(PERMISSIONS.CONTRACTOR_CREATE));
	}

	@computed
	get contractorEdit() {
		return this.permissionsList.includes(String(PERMISSIONS.CONTRACTOR_EDIT));
	}

	@computed
	get contractorView() {
		return this.permissionsList.includes(String(PERMISSIONS.CONTRACTOR_VIEW));
	}

	@computed
	get contractorDelete() {
		return this.permissionsList.includes(String(PERMISSIONS.CONTRACTOR_DELETE));
	}

	// Другие свойства
	@computed
	get isAdmin() {
		return [1, 2, 3].includes(this.id);
	}

	@computed
	get isHeadEmployee() {
		return [1, 2, 3].includes(this.id);
	}

	@computed
	get isEmployee() {
		return [4, 5, 6, 7, 8, 9, 10, 11].includes(this.id);
	}

	@computed
	get generateProjectDocumentation() {
		return [1, 2, 3, 4].includes(this.id);
	}

	@action
	fillStore(data: Role) {
		const { id, name, created_at, updated_at, permissions } = data;

		this.id = id;
		this.name = name;
		this.created_at = created_at ? dayjs(created_at) : null;
		this.updated_at = updated_at ? dayjs(updated_at) : null;
		this.permissions = (permissions || []).map((permission) => new PermissionStore(permission));

		// Загружаем доступы
		const permissionsFlat: Array<string> = [];

		(permissions || []).forEach((permission) => {
			permissionsFlat.push(permission.id.toString());

			if (Array.isArray(permission.children)) {
				permission.children.forEach((subPermission) => {
					permissionsFlat.push(subPermission.id.toString());
				});
			}
		});

		this.permissionsList = permissionsFlat;
	}
}
