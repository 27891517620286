import { action, makeObservable, observable } from 'mobx';

import { ObjectTechTaskFileStore } from './object-tech-task-file.store';
import { ObjectFileI } from '../../interfaces/object/object-file.interface';

export class ObjectTechTaskFilesStore {
	@observable list: ObjectTechTaskFileStore[] = [];

	constructor() {
		makeObservable(this);
	}

	@action.bound
	setList(values: ObjectFileI[]) {
		this.list = (values || []).map((file) => new ObjectTechTaskFileStore(file));
	}

	@action.bound
	deleteFromList(id: number) {
		this.list = this.list.filter((file) => file.id !== id);
	}
}
