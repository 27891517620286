import { observable } from 'mobx';

import { Item } from '../../interfaces/item';
import { ItemStore } from '../item';

export class UserItemStore extends ItemStore {
	constructor(initialData: Item | null) {
		super(initialData);

		if (initialData) {
			this.fillStore(initialData);
		}
	}
}
