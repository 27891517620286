import { action, makeObservable, observable } from 'mobx';

import { API } from '../../core';
import { ErrorAPI } from '../../helpers';
import { TaskList } from '../../interfaces';

export class TaskListStore {
	@observable list: TaskList[] = [];

	constructor() {
		makeObservable(this);
	}

	@action.bound
	async getAllByObjectId(objectId: number) {
		try {
			this.list = await API.request<TaskList[]>(`items/tasks?object_id=${objectId}`);
		} catch (e) {
			ErrorAPI('getAllByObjectId', e);
		}
	}
}
