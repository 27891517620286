import { useCallback, useEffect, useState } from 'react';

import { PlusCircleOutlined } from '@ant-design/icons';
import { Button, Typography } from 'antd';
import { debounce } from 'debounce';
import { observer } from 'mobx-react-lite';

import { useStores } from '../../../../stores';
import { BlockTitle, ContainerBody, ContainerBodyPadding, GlobalSearch } from '../../../common';
import { ItemProjectGenerateModal, ItemsTable } from '../../../ui';

export const ObjectsDraftsPage = observer(() => {
	const { itemsStore, itemObjectTypesStore, itemStatusesStore } = useStores();
	const { selected: createItem } = itemsStore;

	// Ключи для открытых таблиц
	const [expandedRowKeys, setExpandedRowKeys] = useState<Array<number>>([]);

	useEffect(() => {
		(async () => {
			// Добавляем по умолчанию выборку черновиков
			itemsStore.table.addFilter('is_draft', 1);

			// Показать неудаленные записи
			itemsStore.setIsDelete(0);

			// Получаем список типов
			await itemObjectTypesStore.getList();
		})();
	}, []);

	useEffect(() => {
		(async () => {
			await itemsStore.table.getList(itemsStore.filter.query);
		})();
	}, [itemsStore.item_object_type_id]);

	useEffect(() => {
		(async () => {
			if (createItem.item_object_type_id) {
				await itemStatusesStore.getList(createItem.item_object_type_id);
			}
		})();
	}, [createItem.item_object_type_id]);

	// Поиск
	useEffect(() => {
		if (itemsStore.filter.query.length > 0) {
			handleOnSearch();
			return;
		}

		if (itemsStore.filter.query.length === 0 && itemsStore.filter.isDirty) {
			handleOnSearch();
		}
	}, [itemsStore.filter.query]);

	const handleOnSearch = useCallback(
		debounce(() => {
			(async () => {
				await itemsStore.table.getList(itemsStore.filter.query);
			})();
		}, 300),
		[],
	);

	const handleOnShow = () => {
		// Устанавливаем что это черновик
		createItem.setIsDraft();

		// Устанавливаем выбранный тип сетей
		createItem.setItemObjectType(itemsStore.item_object_type_id);

		// Устанавливаем тип объект
		createItem.setTypeObject();

		// Открываем окно
		createItem.drawer.show();
	};

	return (
		<>
			<ContainerBody>
				<ContainerBodyPadding>
					<BlockTitle>
						<Typography.Title level={2}>Объекты (Черновики)</Typography.Title>

						<Button.Group>
							<Button type={'primary'} icon={<PlusCircleOutlined />} onClick={handleOnShow}>
								Создать
							</Button>
						</Button.Group>
					</BlockTitle>

					<GlobalSearch filterStore={itemsStore.filter} />

					<ItemsTable expandedRowKeys={expandedRowKeys} onSetExpandedRowKeys={setExpandedRowKeys} />
				</ContainerBodyPadding>
			</ContainerBody>

			<ItemProjectGenerateModal />
		</>
	);
});
