export const PERMISSIONS = {
	OBJECT_CREATE: 2,
	OBJECT_EDIT: 3,
	OBJECT_VIEW: 4,
	OBJECT_DELETE: 5,
	OBJECT_UPLOAD_TECH_FILES: 6,
	OBJECT_UPLOAD_ADMIN_FILES: 7,
	OBJECT_UPLOAD_TECH_TASK_FILES: 8,
	OBJECT_VIEW_TECH_FILES: 9,
	OBJECT_VIEW_ADMIN_FILES: 10,
	OBJECT_VIEW_TECH_TASK_FILES: 11,
	VOLUME_CREATE: 13,
	VOLUME_EDIT: 14,
	VOLUME_VIEW: 15,
	VOLUME_DELETE: 16,
	VOLUME_UPLOAD_FILES: 17,
	VOLUME_VIEW_FILES: 18,
	TASK_CREATE: 20,
	TASK_EDIT: 21,
	TASK_VIEW: 22,
	TASK_DELETE: 23,
	TASK_UPLOAD_FILES: 24,
	TASK_VIEW_FILES: 25,
	USER_CREATE: 27,
	USER_EDIT: 28,
	USER_VIEW: 29,
	USER_DELETE: 30,
	CONTRACTOR_CREATE: 32,
	CONTRACTOR_EDIT: 33,
	CONTRACTOR_VIEW: 34,
	CONTRACTOR_DELETE: 35,
};
