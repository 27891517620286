import { Table } from 'antd';
import { observer } from 'mobx-react-lite';

import { useColumns } from './TaskExchangeTable.columns';
import { useStores } from '../../../../stores';

export const TaskExchangeTable = observer(() => {
	const { taskExchangeStore, itemsStore } = useStores();

	return (
		<>
			<Table
				loading={taskExchangeStore.table.isLoading}
				rowKey={(record) => record.id}
				columns={useColumns()}
				onRow={(row) => {
					return {
						onDoubleClick: async () => {
							// Открываем окно
							itemsStore.selected.viewer.show();

							// Устаналиваем ID
							itemsStore.selected.setId(row.id);

							// Получаем информацию
							await itemsStore.selected.get();
						},
					};
				}}
				rowClassName={'cursor-pointer'}
				dataSource={taskExchangeStore.table.list}
				scroll={{ x: 1000 }}
				pagination={{
					current: taskExchangeStore.table.pagination.current_page,
					total: taskExchangeStore.table.pagination.total,
					onChange: (page) => taskExchangeStore.table.pagination.setCurrentPage(page),
				}}
			/>
		</>
	);
});
