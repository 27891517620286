import dayjs from 'dayjs';
import { action, computed, makeObservable, observable } from 'mobx';

import { ItemLocalStatusStore } from './item-local-status.store';
import { DayJsNull, NumberNull } from '../../interfaces';
import { ItemList } from '../../interfaces/item';
import { TableStore } from '../common/table.store';
import { ItemStatusShortStore } from '../item-statuses';
import { TriggerWorkflowStore } from '../trigger-workflows';
import { UserShortStore } from '../users';

export class ItemListStore implements ItemList {
	@observable id = 0;
	@observable parent_id: NumberNull = null;
	@observable creator_id = 0;
	@observable responsible_id = 0;
	@observable item_type_id = 0;
	@observable item_status_id = 0;
	@observable item_object_type_id = 0;
	@observable hidden = false;
	@observable order_number = 0;
	@observable current_year = 0;
	@observable title = '';
	@observable price = 0;
	@observable contract_number = '';
	@observable description = '';
	@observable content = '';
	@observable cipher = '';
	@observable start_date = dayjs();
	@observable end_date = dayjs();
	@observable actual_date: DayJsNull = null;
	@observable is_subcontract = false;
	@observable is_delete = false;
	@observable custom_status = '';
	@observable children_count = 0;
	@observable deviation_days = 0;
	@observable status = new ItemStatusShortStore(null);
	@observable creator = new UserShortStore(null);
	@observable responsible = new UserShortStore(null);
	@observable trigger_workflows: TriggerWorkflowStore[] = [];
	@observable local_status = new ItemLocalStatusStore(null);
	@observable is_finish = false;
	@observable is_blocked = false;
	@observable user_blocked_id: NumberNull = 0;

	@observable childrenTable = new TableStore<ItemList, ItemListStore>({
		url: `items/children`,
		getListItemStore: this.getListItemStore,
		getPayloadParams: this.getPayloadParams.bind(this),
	});

	@observable childrenShow = false;

	constructor(initialData: ItemList | null) {
		makeObservable(this);

		if (initialData) {
			this.fillStore(initialData);
		}
	}

	@computed
	get isExistChildren() {
		return this.children_count > 0;
	}

	@computed
	get startDateFormatted() {
		if (this.start_date) return this.start_date.format('DD MMMM YYYY');

		return undefined;
	}

	@computed
	get endDateFormatted() {
		if (this.end_date) return this.end_date.format('DD MMMM YYYY');

		return undefined;
	}

	@action.bound
	setChildrenShow(value = true) {
		this.childrenShow = value;
	}

	@action.bound
	getListItemStore(value: ItemList) {
		return new ItemListStore(value);
	}

	@action.bound
	getPayloadParams() {
		return {
			parent_id: this.id,
			is_delete: 0,
		};
	}

	@action.bound
	setCurrentEdit(is_blocked = false, user_blocked_id: NumberNull = null) {
		this.is_blocked = is_blocked;
		this.user_blocked_id = user_blocked_id;
	}

	@action
	fillStore(data: ItemList) {
		const {
			id,
			parent_id,
			creator_id,
			responsible_id,
			item_type_id,
			item_status_id,
			item_object_type_id,
			hidden,
			order_number,
			current_year,
			title,
			price,
			contract_number,
			description,
			content,
			cipher,
			start_date,
			end_date,
			actual_date,
			is_subcontract,
			is_delete,
			custom_status,
			children_count,
			deviation_days,
			status,
			creator,
			responsible,
			trigger_workflows,
			local_status,
			is_finish,
			is_blocked,
			user_blocked_id,
		} = data;

		this.id = id;
		this.parent_id = parent_id;
		this.creator_id = creator_id;
		this.responsible_id = responsible_id;
		this.item_type_id = item_type_id;
		this.item_status_id = item_status_id;
		this.item_object_type_id = item_object_type_id;
		this.hidden = hidden;
		this.order_number = order_number;
		this.current_year = current_year;
		this.title = title;
		this.price = price;
		this.contract_number = contract_number;
		this.description = description;
		this.content = content;
		this.cipher = cipher;
		this.start_date = dayjs(start_date);
		this.end_date = dayjs(end_date);
		this.actual_date = actual_date ? dayjs(actual_date) : null;
		this.is_subcontract = is_subcontract;
		this.is_delete = is_delete;
		this.custom_status = custom_status;
		this.children_count = children_count;
		this.deviation_days = deviation_days;
		this.status = new ItemStatusShortStore(status);
		this.creator = new UserShortStore(creator);
		this.responsible = new UserShortStore(responsible);
		this.trigger_workflows = (trigger_workflows || []).map(
			(trigger_workflow) => new TriggerWorkflowStore(trigger_workflow),
		);
		this.local_status = new ItemLocalStatusStore(local_status);
		this.is_finish = is_finish;
		this.is_blocked = is_blocked;
		this.user_blocked_id = user_blocked_id;
	}
}
