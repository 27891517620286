import { action, makeObservable, observable } from 'mobx';

import { ItemTypeStore } from './item-type.store';
import { API } from '../../core';
import { ItemType } from '../../interfaces/item-type';
import { CoreResponse } from '../../responses';

export class ItemTypesStore {
	@observable list: ItemTypeStore[] = [];

	constructor() {
		makeObservable(this);
	}

	@action.bound
	setList(values: ItemType[]) {
		this.list = (values || []).map((itemType) => new ItemTypeStore(itemType));
	}

	@action.bound
	async getList() {
		try {
			const { data } = await API.request<CoreResponse<Array<ItemType>>>(`item-types`);

			this.setList(data);
		} catch (e) {
			console.error(`Error in method ItemTypesStore : `, e);
		}
	}
}
