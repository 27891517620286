import { useCallback, useState } from 'react';

import { PlusOutlined } from '@ant-design/icons';
import { Alert, Button, Collapse, CollapseProps, Form, FormInstance, FormListFieldData, Typography } from 'antd';
import { useWatch } from 'antd/es/form/Form';

import { TriggerItem } from './TriggerItem';
import { CollapseItem } from '../../../../../../../../../interfaces';

interface TriggerListProps {
	form: FormInstance;
	volumeIndex: number;
	taskIndex: number;
}

export const TriggerList = ({ volumeIndex, taskIndex, form }: TriggerListProps) => {
	const [activeTrigger, setActiveTrigger] = useState<CollapseItem>('');

	// Получаем текущее кол-во триггеров
	const triggersList =
		useWatch(['item_volume_generates', volumeIndex, 'item_volume_tasks', taskIndex, 'triggers'], form) || [];

	const getItemsTask = useCallback(
		(fields: FormListFieldData[], onRemoveTrigger: (key: number) => void): CollapseProps['items'] => {
			const items: CollapseProps['items'] = [];

			fields.forEach((field) => {
				if (items) {
					items.push({
						key: field.name,
						label: `Триггер ${field.key + 1}`,
						children: (
							<TriggerItem
								form={form}
								volumeIndex={volumeIndex}
								taskFieldNumber={taskIndex}
								triggerItemNumber={field.name}
								onDeleteTriggerItem={onRemoveTrigger}
							/>
						),
					});
				}
			});

			return items;
		},
		[],
	);

	return (
		<Form.List name={[taskIndex, 'triggers']} initialValue={[]}>
			{(triggersFields, { add: onAddTriggerTask, remove: onRemoveTriggerTask }) => (
				<>
					<div
						style={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'space-between',
							marginBottom: 25,
						}}
					>
						<span>
							<Typography.Title level={5}>Триггер после выполнения</Typography.Title>
						</span>

						<span>
							<Button
								type={'primary'}
								icon={<PlusOutlined />}
								onClick={() => {
									onAddTriggerTask();

									// Открываем последнюю добавленую задачу
									const lastTriggersTaskItem = String(Object.keys(triggersList).length);
									setActiveTrigger([lastTriggersTaskItem]);
								}}
							>
								Добавить триггер
							</Button>
						</span>
					</div>

					{triggersFields.length > 0 && (
						<Collapse
							accordion
							items={getItemsTask(triggersFields, onRemoveTriggerTask)}
							activeKey={activeTrigger}
							onChange={(itemTriggerNumber) => {
								setActiveTrigger(itemTriggerNumber);
							}}
						/>
					)}

					{triggersFields.length === 0 && (
						<Alert showIcon type="info" description={`У задачи нет ни одного триггера`} />
					)}
				</>
			)}
		</Form.List>
	);
};
