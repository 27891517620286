import { action, computed, makeObservable, observable } from 'mobx';

import { ContractorStore } from './contractor.store';
import { Contractor } from '../../interfaces';
import { AllStore } from '../common/all.store';
import { DrawerStore } from '../common/drawer.store';
import { TableStore } from '../common/table.store';

export class ContractorsStore {
	@observable drawer = new DrawerStore();
	@observable table = new TableStore({
		url: 'contractors',
		getListItemStore: this.getListItemStore,
		getPayloadParams: this.getPayloadParams,
	});
	@observable all = new AllStore({
		url: 'contractors',
		getListItemStore: this.getListItemStore,
	});
	@observable selected = new ContractorStore(null);

	constructor() {
		makeObservable(this);
	}

	@computed
	get dataAllForSelectBox() {
		return this.all.list.map((contractor) => {
			return {
				key: contractor.id,
				value: contractor.id,
				label: contractor.company_name,
			};
		});
	}

	@action.bound
	setSelected(value: ContractorStore) {
		this.selected = value;
	}

	@action.bound
	clearSelected() {
		this.selected = new ContractorStore(null);
	}

	@action.bound
	private getListItemStore(value: Contractor): ContractorStore {
		return new ContractorStore(value);
	}

	@action.bound
	private getPayloadParams() {
		return {};
	}
}
