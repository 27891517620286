import { useRef, useState } from 'react';

import { Alert, Button, Drawer, Modal, Popconfirm, Space, Spin } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { observer } from 'mobx-react-lite';

import { ItemTypeGroupFiles } from '../../../../constants';
import { ItemTypes } from '../../../../constants/item-types';
import { getButtonName, getTitle } from '../../../../helpers';
import { useBlockedItemHook } from '../../../../hooks';
import { useStores } from '../../../../stores';
import { ItemObjectForm, ItemTaskForm, ItemVolumeForm } from '../../Forms';

export const ItemDrawer = observer(() => {
	const { itemsStore } = useStores();
	const { selected: currentItem } = itemsStore;

	const buttonCancelRef = useRef<HTMLButtonElement>(null);
	const [form] = useForm();
	const [isChangeForm, setIsChangeForm] = useState(false);
	const [, setIsShowConfirm] = useState(false);

	// Проверка блокировки редактирования
	const [isBlockedItem] = useBlockedItemHook(currentItem);

	let clearTimeoutId = 0;

	// Метод для разблокирования
	const enabledItemRequest = async () => {
		// Выход из редактирования
		if (!isBlockedItem) {
			await currentItem.enabledEdit();
		}
	};

	// Закрытие окна
	const handleOnClose = async () => {
		if (isChangeForm) {
			Modal.confirm({
				title: 'Закрыть без сохранения?',
				content: 'Были внесены изменения, уверены что хотите закрыть без сохранения',
				onOk: async () => {
					itemsStore.clearSelected();
					currentItem.drawer.close();
					setIsChangeForm(false);

					// Разблокирование
					await enabledItemRequest();
				},
				okText: 'Закрыть',
			});
		} else {
			itemsStore.clearSelected();
			currentItem.drawer.close();
			setIsChangeForm(false);

			// Разблокирование
			await enabledItemRequest();
		}
	};

	const handleOnDelete = async () => {
		await itemsStore.selected.delete();
		itemsStore.clearSelected();
		currentItem.drawer.close();

		// Обновляем таблицу
		await itemsStore.table.getList();
	};

	const handleOnSubmit = () => form.submit();

	const handleOnSend = async (values: any) => {
		currentItem.drawer.setIsError(false);

		if (currentItem.isNewStore) {
			await currentItem.create(values);
		} else {
			await currentItem.update(values);
		}

		// Очистка форм загрузки файла
		form.resetFields([
			ItemTypeGroupFiles.TechnicalDocumentation,
			ItemTypeGroupFiles.AdministrativeDocumentation,
			ItemTypeGroupFiles.DesignAssignments,
		]);

		// Очистка формы загрузки файлов
		const uploadContractors = form.getFieldValue('contractors');

		if (uploadContractors) {
			for (let i = 0; i < uploadContractors.length; i++) {
				form.resetFields(['contractors', i, 'files']);
			}
		}

		// Очистка контейнера с файлами
		await itemsStore.table.getList();

		// Если есть открутое дерево проходим по нему
		if (itemsStore.childrenRelations.length > 0) {
			const object_id = itemsStore.childrenRelations[0];

			// Находим текущий объект
			const findObject = itemsStore.table.list.find((itemObject) => itemObject.id === object_id);

			// Если нашли получаем потомков
			if (findObject) {
				await findObject.childrenTable.getList();

				if (itemsStore.childrenRelations.length > 1) {
					const volume_id = itemsStore.childrenRelations[1];

					// Находим текущий объект
					const findVolume = findObject.childrenTable.list.find((itemVolume) => itemVolume.id === volume_id);

					if (findVolume) {
						await findVolume.childrenTable.getList();
					}
				}
			}
		}

		// Стираем информацию о изменении формы
		setIsChangeForm(false);
	};

	const handleOnError = () => {
		currentItem.drawer.setIsError(true);

		clearTimeout(clearTimeoutId);

		clearTimeoutId = window.setTimeout(() => {
			currentItem.drawer.setIsError(false);
		}, 3000);
	};

	const handleOnChangeForm = () => setIsChangeForm(true);

	const getFooter = () => {
		return currentItem.drawer.isError ? (
			<div key={'errors'}>
				<Alert showIcon type={'error'} message={'Не заполнены обязательные поля. Проверьте табы.'} />
			</div>
		) : null;
	};

	return (
		<>
			<Drawer
				forceRender
				title={`${getTitle(currentItem.nameByType, currentItem.isNewStore, currentItem.title)}`}
				width={1000}
				closable={false}
				open={currentItem.drawer.isShow}
				onClose={() => {
					buttonCancelRef.current?.click();
					setIsShowConfirm(true);
				}}
				extra={[
					<Space key={'actions'}>
						<Button ref={buttonCancelRef} onClick={handleOnClose}>
							Закрыть
						</Button>

						{itemsStore.selected.isNotNewStore && (
							<Popconfirm title={'Уверены что хотите удалить?'} onConfirm={handleOnDelete}>
								<Button type={'primary'} danger disabled={isBlockedItem}>
									Удалить
								</Button>
							</Popconfirm>
						)}

						<Button type={'primary'} onClick={handleOnSubmit} loading={currentItem.isLoading} disabled={isBlockedItem}>
							{getButtonName(currentItem.isNewStore)}
						</Button>
					</Space>,
				]}
				footer={getFooter()}
			>
				<Spin spinning={currentItem.isLoading} tip={'Загрузка'}>
					{isBlockedItem && (
						<Alert
							showIcon
							type={'warning'}
							style={{ marginTop: 15 }}
							message={`Внимание! Данная форма уже редактируется.`}
							description={`Форма редактируется сотрудником : Иванов И.И`}
						/>
					)}

					{currentItem.item_type_id === ItemTypes.object && (
						<ItemObjectForm
							isBlockedItem={isBlockedItem}
							form={form}
							onSend={handleOnSend}
							onError={handleOnError}
							onChangeForm={handleOnChangeForm}
						/>
					)}

					{currentItem.item_type_id === ItemTypes.volume && (
						<ItemVolumeForm
							isBlockedItem={isBlockedItem}
							form={form}
							onSend={handleOnSend}
							onError={handleOnError}
							onChangeForm={handleOnChangeForm}
						/>
					)}

					{currentItem.item_type_id === ItemTypes.tasks && (
						<ItemTaskForm
							isBlockedItem={isBlockedItem}
							form={form}
							onSend={handleOnSend}
							onError={handleOnError}
							onChangeForm={handleOnChangeForm}
						/>
					)}
				</Spin>
			</Drawer>
		</>
	);
});
