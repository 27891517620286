import { useState } from 'react';

import { ArrowRightOutlined } from '@ant-design/icons';
import { Button, Select } from 'antd';
import { observer } from 'mobx-react-lite';

import { Container } from './ChooseUser.styled';
import { useStores } from '../../../../../../stores';

export const ChooseUser = observer(() => {
	const { employeesStore, dialogsStore, authStore } = useStores();

	const [chooseUserId, setChooseUser] = useState<undefined | number>(undefined);

	const handleOnChoose = (value: number) => {
		setChooseUser(value);
	};

	const handleOnCreateDialog = async () => {
		if (chooseUserId) {
			await dialogsStore.selected?.create({
				creator_id: authStore.current.id,
				from_user_id: authStore.current.id,
				to_user_id: chooseUserId,
			});

			await dialogsStore.getList();
		}
	};

	return (
		<Container>
			<Select
				showSearch
				placeholder={'Выбирите участника'}
				value={chooseUserId}
				options={employeesStore.selectBoxChat(authStore.current.id)}
				filterOption={(input, option) => {
					return (option?.label.toLowerCase() ?? '').includes(input);
				}}
				onChange={handleOnChoose}
			/>

			<Button disabled={!chooseUserId} type={'primary'} icon={<ArrowRightOutlined />} onClick={handleOnCreateDialog}>
				Начать
			</Button>
		</Container>
	);
});
