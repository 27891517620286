import { useEffect, useState } from 'react';

import { SaveOutlined } from '@ant-design/icons';
import { Alert, Button, Form, Modal, Space, Spin, Tabs, TabsProps } from 'antd';
import { useForm } from 'antd/es/form/Form';
import dayjs from 'dayjs';
import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';

import { Contractors } from './Contractors/Contractors';
import { Files } from './Files/Files';
import { Information } from './Information/Information';
import { Notes } from './Notes/Notes';
import { OBJECT_FILES_BLOCK, OBJECT_TABS } from '../../../../constants';
import { getButtonName, getTitle } from '../../../../helpers';
import { ObjectDetailI, WsPayload, WsTypeEvent } from '../../../../interfaces';
import { useStores } from '../../../../stores';

export const ObjectModal = observer(() => {
	const { objectsStore, websocketStore } = useStores();
	const { selected: currentObject } = objectsStore;
	const [form] = useForm();
	const [currentTab, setCurrentTab] = useState(OBJECT_TABS.MAIN);

	const fields = {
		id: currentObject.id,
		creator_id: currentObject.creator_id,
		responsible_id: currentObject.responsible_id,
		project_documentation_id: currentObject.project_documentation_id,
		object_type_id: currentObject.object_type_id,
		object_type: currentObject.object_type,
		object_status_id: currentObject.object_status_id,
		title: currentObject.title,
		price: currentObject.price,
		contract_number: currentObject.contract_number,
		cipher: currentObject.cipher,
		start_date: currentObject.start_date,
		end_date: currentObject.end_date,
		actual_date: currentObject.actual_date,
		subcontracting: currentObject.subcontracting,
		confirmation_by_responsible: currentObject.confirmation_by_responsible,
		confirmation_by_responsible_date: currentObject.confirmation_by_responsible_date,
		is_delete: currentObject.is_delete,
		responsible_show: currentObject.responsible_show,
		contractors: toJS(
			currentObject.contractors.map((contractor) => ({
				id: contractor.id,
				contractor_id: contractor.contractor_id,
				subject_contract: contractor.subject_contract,
			})),
		),
		admin_files: [],
		tech_files: [],
		tech_task_files: [],
	};

	const itemsTab: TabsProps['items'] = [
		{
			key: OBJECT_TABS.MAIN,
			label: 'Основная информация',
			children: <Information form={form} />,
		},
		{
			key: OBJECT_TABS.CONTRACTORS,
			label: 'Контрагенты',
			children: <Contractors />,
		},
		{
			key: OBJECT_TABS.FILES,
			label: 'Файлы',
			children: <Files form={form} />,
		},
		{
			key: OBJECT_TABS.NOTES,
			label: 'Примечания',
			children: <Notes />,
			disabled: currentObject.isNewStore,
		},
	];

	// Заполняем данными
	useEffect(() => {
		if (objectsStore.modal.isShow) {
			form.setFieldsValue({
				...fields,
			});
		}
	}, [currentObject.id, currentObject.object_type_id, objectsStore.modal.isShow]);

	// Когда создем новый объект
	useEffect(() => {
		if (currentObject.isNewStore && objectsStore.modal.isShow) {
			// Статус
			form.setFieldsValue({ object_status_id: currentObject.getNewStatus });
			// Проектная документация в зависимости от типа
			form.setFieldsValue({ project_documentation_id: currentObject.getNewProjectDocumentationId });
			// Дата начала проектирования
			form.setFieldsValue({ start_date: dayjs() });
			// Дата окончания проектирования
			form.setFieldsValue({ end_date: dayjs().add(30, 'days') });
		}
	}, [currentObject.object_type_id, objectsStore.modal.isShow]);

	// Очистка формы
	useEffect(() => {
		return () => {
			if (!objectsStore.modal.isShow) {
				form.resetFields();
				objectsStore.clearSelected();

				// Переключаемся на первый таб
				if (itemsTab.length > 0) {
					setCurrentTab(itemsTab[0].key);
				}
			}
		};
	}, [objectsStore.modal.isShow]);

	// Создание / обновление объекта
	const handleOnSave = async (values: ObjectDetailI) => {
		try {
			if (currentObject.id) {
				// Обновляем
				await currentObject.update(values);

				// Очищаем загрузку файлов
				form.resetFields([
					OBJECT_FILES_BLOCK.TECH_FILES,
					OBJECT_FILES_BLOCK.ADMIN_FILES,
					OBJECT_FILES_BLOCK.TECH_TASK_FILES,
				]);
			} else {
				// Сохраняем
				await currentObject.create(values);
				// Очищаем форму
				form.resetFields();
				// Закрываем модальное окно
				objectsStore.modal.close();
			}

			// Отправляем созданный или обновленный объект
			websocketStore.sendMessage<WsPayload>({
				event: WsTypeEvent.object,
				payload: {
					type: WsTypeEvent.object,
					id: values.id,
					title: values.title,
					responsible_id: values.responsible_id,
				},
			});

			// Обновляем список
			await objectsStore.table.getList();
		} catch (e) {
			console.log('Error', e);
			/* empty */
		}
	};

	const handleOnSend = () => form.submit();

	const handleOnClose = () => {
		objectsStore.modal.close();
		setCurrentTab(OBJECT_TABS.MAIN);
	};

	const handleOnError = () => {
		currentObject.showError();
	};

	return (
		<Modal
			forceRender
			open={objectsStore.modal.isShow}
			title={getTitle('объекта', currentObject.isNewStore, currentObject.title)}
			width={1000}
			styles={{
				body: {
					height: 700,
					overflowY: 'auto',
					overflowX: 'hidden',
				},
			}}
			onCancel={handleOnClose}
			footer={[
				<Space size={15} key={'space'}>
					{currentObject.isError && (
						<Alert
							type={'error'}
							showIcon
							closable
							message={'Ошибка. Есть незаполненные поля.'}
							style={{ height: 32 }}
						/>
					)}

					<Button key={'cancel'} disabled={currentObject.isLoading} onClick={handleOnClose}>
						Отменить
					</Button>
					<Button
						key={'create'}
						icon={<SaveOutlined />}
						type={'primary'}
						loading={currentObject.isLoading}
						onClick={handleOnSend}
					>
						{getButtonName(currentObject.isNewStore)}
					</Button>
				</Space>,
			]}
		>
			<Spin spinning={currentObject.isLoading}>
				<Form
					form={form}
					name={'create_object'}
					layout={'vertical'}
					onFinish={handleOnSave}
					autoComplete="off"
					onFinishFailed={handleOnError}
				>
					<Tabs items={itemsTab} activeKey={currentTab} onChange={(key) => setCurrentTab(key)} />
				</Form>
			</Spin>
		</Modal>
	);
});
