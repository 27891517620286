import { useEffect } from 'react';

import { EyeOutlined } from '@ant-design/icons';
import { Button, List } from 'antd';
import { observer } from 'mobx-react-lite';

import { useStores } from '../../../../stores';
import { TaskStore } from '../../../../stores/task/task.store';
import { CardWidget, Status } from '../../../common';

export const TasksWidget = observer(() => {
	const { userDashboardStore } = useStores();
	const { tasks } = userDashboardStore;
	const { tableUser, viewDetail } = tasks;

	useEffect(() => {
		(async () => {
			await tableUser.getList();
		})();
	}, []);

	const handleOnShow = async (taskStore: TaskStore) => {
		viewDetail.setData(taskStore);
		await viewDetail.data.get();
		viewDetail.modal.open();
	};

	return (
		<CardWidget title={'Задачи'} tableStore={tableUser}>
			<List
				loading={tableUser.isLoading}
				dataSource={tableUser.list}
				renderItem={(taskStore: TaskStore) => {
					return (
						<List.Item
							key={taskStore.id}
							actions={[
								<Button key={'show'} type={'primary'} icon={<EyeOutlined />} onClick={() => handleOnShow(taskStore)} />,
							]}
						>
							<List.Item.Meta
								avatar={null}
								title={
									<span style={{ display: 'flex', alignItems: 'center' }}>
										<Status
											confirmation_by_responsible={taskStore.confirmation_by_responsible}
											confirmation_by_creator={taskStore.confirmation_by_creator}
										/>
										{taskStore.title}
									</span>
								}
								description={
									<>
										Том : Не известен
										<br />
										{taskStore.startDateFormatted} - {taskStore.endDateFormatted}
									</>
								}
							/>
						</List.Item>
					);
				}}
			/>
		</CardWidget>
	);
});
