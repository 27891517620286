import { useEffect } from 'react';

import { Form, Modal, Tabs, TabsProps } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { observer } from 'mobx-react-lite';

import { Files } from './Files/Files';
import { Information } from './Information/Information';
import { TASK_TABS } from '../../../../constants';
import { getButtonName, getTitle } from '../../../../helpers';
import { TaskI, WsPayload, WsTypeEvent } from '../../../../interfaces';
import { useStores } from '../../../../stores';

export const TaskModal = observer(() => {
	const { objectsStore, websocketStore } = useStores();
	const { selected: currentObject } = objectsStore;
	const { volumes } = currentObject;
	const { selected: currentVolume } = volumes;
	const { tasks } = currentVolume;
	const { selected: currentTask } = tasks;

	const [form] = useForm();

	const fields = {
		id: currentTask.id,
		creator_id: currentTask.creator_id,
		responsible_id: currentTask.responsible_id,
		volume_id: currentVolume.id,
		title: currentTask.title,
		content: currentTask.content,
		start_date: currentTask.start_date,
		end_date: currentTask.end_date,
		confirmation_by_responsible: currentTask.confirmation_by_responsible,
		confirmation_by_creator: currentTask.confirmation_by_creator,
		responsible_id_show: currentTask.responsible_id_show,
		confirmation_id_show: currentTask.confirmation_id_show,
		is_delete: currentTask.is_delete,
	};

	// Табы
	const itemsTab: TabsProps['items'] = [
		{
			key: TASK_TABS.INFORMATION,
			label: 'Информация задачи',
			children: <Information form={form} />,
		},

		{
			key: TASK_TABS.FILES,
			label: 'Файлы',
			children: <Files form={form} />,
		},
		{
			key: TASK_TABS.CHAT,
			label: 'Комментарии',
			disabled: true,
		},
	];

	// Заполняем данными
	useEffect(() => {
		form.setFieldsValue({
			...fields,
		});
	}, [currentTask.id, currentVolume.id]);

	const handleOnCloseModal = () => {
		tasks.modal.close();
		tasks.clearSelected();
	};

	const handleOnSubmit = () => form.submit();

	const handleOnSend = async (values: TaskI) => {
		if (currentTask.isNewStore) {
			// Создаем такс
			await currentTask.create(values);
			// Добавляем созданный таск в список тома
			currentVolume.tasks.addTask(currentTask);
		} else {
			await currentTask.update(values);
			currentVolume.tasks.updateTask(currentTask);
		}

		// Очищаем поле загрузки файлов
		form.resetFields(['files']);

		// Отправляем в сокет данные по тому
		websocketStore.sendMessage<WsPayload>({
			event: WsTypeEvent.task,
			payload: {
				type: WsTypeEvent.task,
				id: values.id,
				title: values.title,
				responsible_id: values.responsible_id,
			},
		});
	};

	return (
		<Modal
			forceRender
			open={tasks.modal.isShow}
			title={getTitle('задачи', currentTask.isNewStore, currentTask.title)}
			centered
			width={1000}
			styles={{
				body: {
					height: 600,
				},
			}}
			onCancel={handleOnCloseModal}
			onOk={handleOnSubmit}
			okText={getButtonName(currentTask.isNewStore)}
		>
			<Form name={'task'} layout={'vertical'} form={form} onFinish={handleOnSend}>
				<Tabs items={itemsTab} />
			</Form>
		</Modal>
	);
});
