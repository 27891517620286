import { Image, Typography } from 'antd';

export const StartScreen = () => {
	return (
		<>
			<Typography.Title level={4}>Внимание</Typography.Title>

			<Typography.Paragraph>Для работы в системе пожалуйста разрешите следующее:</Typography.Paragraph>

			<div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', marginTop: 35 }}>
				<Image src={`/images/notification.png`} preview={false} width={350} />

				<Typography.Text mark style={{ marginTop: 20 }}>
					После разрешение подождите 15-20 секунд.
				</Typography.Text>
			</div>
		</>
	);
};
