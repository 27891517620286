import { action, makeObservable, observable } from 'mobx';

import { WorkWeekDataStore } from './work-week-data.store';
import { API } from '../../core';
import { WorkWeek } from '../../interfaces/work-week';

export class WorkWeekStore implements WorkWeek {
	@observable objects = new WorkWeekDataStore(null);
	@observable volumes = new WorkWeekDataStore(null);
	@observable tasks = new WorkWeekDataStore(null);

	constructor() {
		makeObservable(this);
	}

	@action.bound
	async getList() {
		try {
			const { objects, volumes, tasks } = await API.request<WorkWeek>(`work-week`);

			this.objects = new WorkWeekDataStore(objects);
			this.volumes = new WorkWeekDataStore(volumes);
			this.tasks = new WorkWeekDataStore(tasks);
		} catch (e) {
			console.error(`Error in method WorkWeekStore.getList : `, e);
		}
	}
}
