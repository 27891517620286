import { useEffect, useState } from 'react';

import { BellOutlined, EyeOutlined, MinusOutlined, SoundOutlined } from '@ant-design/icons';
import { Button, Empty, List, Switch, Typography } from 'antd';
import { observer } from 'mobx-react-lite';

import { useStores } from '../../../../stores';
import { Widget, WidgetTitleIcon } from '../../../common';

export const WidgetLastUpdate = observer(() => {
	const { audioStore, notificationsStore, itemsStore } = useStores();
	const [isMute, setIsMute] = useState(audioStore.currentMuteState);

	// Последнее уведомление
	const { lastNotification } = notificationsStore;

	useEffect(() => {
		audioStore.setMute(isMute);
	}, [isMute]);

	const handleOnToggle = () => setIsMute((prevState) => !prevState);

	const handleOnShowItem = async () => {
		// Читаем уведомление
		if (lastNotification && !lastNotification.is_show) {
			await lastNotification?.read();
		}

		const item_id = lastNotification?.payload.id;

		if (item_id) {
			itemsStore.selected.setId(Number(lastNotification?.payload.id));
			await itemsStore.selected.get();
			itemsStore.selected.viewer.show();
		}
	};

	return (
		<Widget
			style={{ height: 197 }}
			title={
				<WidgetTitleIcon>
					<BellOutlined /> Последние уведомления
				</WidgetTitleIcon>
			}
			extra={[
				<Switch
					key={'switch-hide'}
					checked={!isMute}
					checkedChildren={<SoundOutlined />}
					unCheckedChildren={<MinusOutlined />}
					onChange={handleOnToggle}
				/>,
			]}
		>
			{!lastNotification && <Empty description={''} imageStyle={{ height: 60 }} />}

			{lastNotification && (
				<List
					itemLayout={'horizontal'}
					size={'small'}
					dataSource={[lastNotification]}
					renderItem={() => {
						return (
							<List.Item
								actions={[
									<Button key={'view'} size={'small'} icon={<EyeOutlined />} onClick={handleOnShowItem}></Button>,
								]}
							>
								<List.Item.Meta
									title={lastNotification?.title}
									description={
										<Typography.Paragraph ellipsis={{ rows: 1, tooltip: '' }}>
											{lastNotification.description}
										</Typography.Paragraph>
									}
								/>
							</List.Item>
						);
					}}
				/>
			)}
		</Widget>
	);
});
