import { observer } from 'mobx-react-lite';

import { LeftSide } from './LeftSide/LeftSide';
import { Container } from './Messenger.styled';
import { RightSide } from './RightSide/RightSide';

export const Messenger = observer(() => {
	return (
		<>
			<Container>
				<LeftSide />
				<RightSide />
			</Container>
		</>
	);
});
