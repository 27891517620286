export enum UsersTypeId {
	Employees = 1,
	Contractors = 2,
}

export enum UsersType {
	Employees = 'Сотрудники',
	Contractors = 'Контрагенты',
}

export const UsersTypeMapping = {
	1: UsersType.Employees,
	2: UsersType.Contractors,
};
