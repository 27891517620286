import { notification } from 'antd';
import { action, makeObservable, observable } from 'mobx';

import { MethodsRequest } from '../../constants';
import { API } from '../../core';
import { ErrorAPI } from '../../helpers';
import { User } from '../../interfaces';
import { AuthLoginRequest, AuthRecoveryPassowrdRequest } from '../../requests';
import { AuthLoginResponse, CoreResponse } from '../../responses';
import { DrawerStore } from '../common/drawer.store';
import { UserStore } from '../users/user.store';

export class AuthStore {
	static PATH = 'auth';

	@observable isAuth = false;
	@observable isLoading = false;

	@observable current = new UserStore(null);
	@observable drawer = new DrawerStore();

	constructor() {
		makeObservable(this);
	}

	@action.bound
	setIsAuth(value: boolean) {
		this.isAuth = value;
	}

	@action.bound
	setIsLoading(value: boolean) {
		this.isLoading = value;
	}

	@action.bound
	setCurrent(values: User) {
		this.current = new UserStore(values);
	}

	@action.bound
	async login(values: AuthLoginRequest) {
		this.setIsLoading(true);

		try {
			const { access_token, user } = await API.request<AuthLoginResponse>(`${AuthStore.PATH}/login`, {
				method: MethodsRequest.Post,
				body: API.getFormData(values),
			});

			// Сохраняем токен
			API.saveToken = access_token;
			// Записываем пользователя
			this.setCurrent(user);
			// Авторизовываем пользователя
			this.setIsAuth(true);
		} catch (e: any) {
			notification.error({
				message: 'Ошибка',
				description: e.errors.join('<br/>'),
			});
		} finally {
			this.setIsLoading(false);
		}
	}

	@action.bound
	async recoveryPassword(values: AuthRecoveryPassowrdRequest) {
		try {
			this.setIsLoading(true);

			await API.request<CoreResponse<null>>(`auth/recovery-password`, {
				method: MethodsRequest.Post,
				body: API.getFormData(values),
			});

			notification.success({
				message: 'Пароль отправлен',
				description: 'Проверьте почтовый ящик',
			});
		} catch (e: any) {
			notification.error({
				message: 'Ошибка',
				description: e.errors,
			});
		} finally {
			this.setIsLoading(false);
		}
	}

	@action.bound
	async check(showError = true) {
		try {
			// Проверка существование токена
			if (!API.tokenValue) {
				return false;
			}

			const { data } = await API.request<CoreResponse<User>>(`${AuthStore.PATH}/check`);

			// Заполняем пользователя
			this.setCurrent(data);

			// Авторизовываем
			this.setIsAuth(true);
		} catch (e) {
			if (showError) {
				await ErrorAPI('check', e);
			}
		}
	}

	@action.bound
	logout() {
		API.removeToken();
		this.setIsAuth(false);
	}
}
