import { FormInstance, Table } from 'antd';
import { observer } from 'mobx-react-lite';

import { useColumns } from './ItemProjectTaskGenerateTable.columns';
import { ItemVolumeGenerateStore } from '../../../../../../stores/item-volume-generate';
import { ItemVolumeTaskStore } from '../../../../../../stores/item-volume-task';

interface ItemProjectTaskGenerateTableProps {
	form: FormInstance;
	itemVolumeGenerate: ItemVolumeGenerateStore;
	data: ItemVolumeTaskStore[];
	volumeIndex: number;
}

export const ItemProjectTaskGenerateTable = observer(
	({ itemVolumeGenerate, data, volumeIndex, form }: ItemProjectTaskGenerateTableProps) => {
		return (
			<>
				<Table
					rowKey={(record) => record.id}
					columns={useColumns(itemVolumeGenerate, volumeIndex, form)}
					dataSource={data}
					pagination={false}
					onRow={() => {
						return {};
					}}
				/>
			</>
		);
	},
);
