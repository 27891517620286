import dayjs from 'dayjs';
import { action, computed, makeObservable, observable } from 'mobx';

import { ItemReportFile } from '../../interfaces/item';
import { CrudStore } from '../common/crud.store';

export class ItemReportFileStore extends CrudStore<ItemReportFile> implements ItemReportFile {
	PATH = 'item-report-files';

	@observable item_report_id = 0;
	@observable user_id = 0;
	@observable name = '';
	@observable file: string | File = '';
	@observable is_delete = false;
	@observable created_at = dayjs();
	@observable updated_at = dayjs();

	constructor(initialData: ItemReportFile | null) {
		super();
		makeObservable(this);

		if (initialData) {
			this.fillStore(initialData);
		}
	}

	@computed
	get fullPath(): string {
		return `${process.env.REACT_APP_CORE_FILE_URL}${this.file}`;
	}

	@action
	fillStore(data: ItemReportFile) {
		const { id, item_report_id, user_id, name, file, is_delete, created_at, updated_at } = data;

		this.id = id;
		this.item_report_id = item_report_id;
		this.user_id = user_id;
		this.name = name;
		this.file = file;
		this.is_delete = is_delete;
		this.created_at = dayjs(created_at);
		this.updated_at = dayjs(updated_at);
	}
}
