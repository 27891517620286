export const ITEM_TABS = {
	OBJECT: {
		DATA: {
			key: 'information',
			value: 'Общая информация',
		},
		CONTRACTORS: {
			key: 'constructors',
			value: 'Контрагенты',
		},
		FILES: {
			key: 'files',
			value: 'Файлы',
		},
		RELATIONS: {
			key: 'relations',
			value: 'Связи',
		},
	},
	VOLUME: {
		DATA: {
			key: 'information',
			value: 'Информация тома',
		},
		FILES: {
			key: 'files',
			value: 'Файлы',
		},
		TRIGGERS: {
			key: 'triggers',
			value: 'Триггеры',
		},
	},
	TASK: {
		DATA: {
			key: 'information',
			value: 'Информация задачи',
		},
		FILES: {
			key: 'files',
			value: 'Файлы',
		},
		TRIGGERS: {
			key: 'triggers',
			value: 'Триггеры',
		},
	},
};
