import { action, makeObservable, observable } from 'mobx';

import { UserPhone } from '../../interfaces/user';

export class UserPhoneStore implements UserPhone {
	@observable id = 0;
	@observable phone = '';
	@observable name = '';

	constructor(initialData: UserPhone | null) {
		makeObservable(this);

		if (initialData) {
			this.fillStore(initialData);
		}
	}

	@action
	fillStore(data: UserPhone) {
		const { id, phone, name } = data;

		this.id = id;
		this.phone = phone;
		this.name = name;
	}
}
