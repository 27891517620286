import { Layout } from 'antd';
import styled from 'styled-components';

export const ContentExtra = styled(Layout.Content)`
	position: relative;
	display: flex;
	flex-direction: column;
	margin-left: 25px;
	min-width: 800px;
`;
