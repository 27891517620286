import { arrayMove } from '@dnd-kit/sortable';
import { action, computed, makeObservable, observable } from 'mobx';

import { PaginationStore } from './pagination.store';
import { MethodsRequest } from '../../constants';
import { API } from '../../core';
import { PayloadParamsI, TableI } from '../../interfaces';
import { CorePaginationResponse } from '../../responses';

export class TableStore<T, C> {
	@observable isLoading = false;
	@observable list: C[] = [];
	@observable pagination: PaginationStore = new PaginationStore(null, this);
	@observable filters: PayloadParamsI = {};
	@observable isDirty = false;

	public getPayloadParams: () => PayloadParamsI;
	// eslint-disable-next-line no-unused-vars
	public getListItemStore: (data: T) => C;

	public url: string;

	constructor({ getListItemStore, url, getPayloadParams = () => ({}) }: TableI<T, C>) {
		makeObservable(this);

		this.url = url;
		this.getListItemStore = getListItemStore;
		this.getPayloadParams = getPayloadParams;
	}

	@computed
	get isEmpty(): boolean {
		return this.list.length === 0;
	}

	@action.bound
	setIsDirty(value = true) {
		this.isDirty = value;
	}

	@action.bound
	setIsLoading(value: boolean) {
		this.isLoading = value;
	}

	@action.bound
	async getList(query_search = '') {
		this.setIsDirty();
		this.setIsLoading(true);
		let completeQueryParams = '';
		const dataParams = this.getPayloadParams();
		const filtersParams = this.filters;

		try {
			if (dataParams) {
				completeQueryParams =
					`&` +
					Object.keys(dataParams)
						.map((key) => key + '=' + dataParams[key])
						.join('&');
			}

			if (filtersParams) {
				completeQueryParams +=
					`&` +
					Object.keys(filtersParams)
						.map((key) => {
							const item = filtersParams[key];

							// Если фильтр массив
							if (Array.isArray(item)) {
								return item
									.map((item) => {
										return `${key}[]=${item}`;
									})
									.join('&');
							} else {
								return `${key}=${filtersParams[key]}`;
							}
						})
						.join('&');
			}

			if (query_search.length > 0) {
				completeQueryParams += `&query_search=${query_search}`;
			}

			const { data, pagination } = await API.request<CorePaginationResponse<T>>(
				`${this.url}?page=${this.pagination.current_page}${completeQueryParams}`,
			);
			this.setList(data);

			// Если пагинация есть
			if (pagination) {
				this.pagination.fillStore(pagination);

				// Проверка на посленюю страницу
				if (pagination.total_pages < this.pagination.current_page) {
					await this.pagination.setCurrentPage(pagination.total_pages);
				}
			}
		} catch (e) {
			console.error(`Error in method ${this.url} : `, e);
		} finally {
			this.setIsLoading(false);
		}
	}

	@action.bound
	setList(data: T[]) {
		this.list = data.map(this.getListItemStore);
	}

	@action.bound
	clear() {
		this.setList([]);
		this.pagination.reset();
	}

	@action.bound
	deleteById(id: number) {
		this.list = this.list.filter((item: any) => item.id !== id);
	}

	@action.bound
	addFilter(name: string, value: string | number | Array<number | string> = '') {
		if (!value && value !== 0) {
			delete this.filters[name];
			return;
		}

		this.filters[name] = value;
	}

	@action.bound
	addFilterArray(name: string, value: Array<unknown>) {
		this.filters[name] = value;
	}

	@action.bound
	deleteFilter(name: string) {
		delete this.filters[name];
	}

	@action.bound
	clearFilter() {
		this.filters = {};
	}

	@action.bound
	async changePosition(values: any) {
		try {
			if (values?.activeId !== values?.overId) {
				const { activeId, overId } = values;

				// Обновляем позиции на клиенте
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				const activeIndex = this.list.findIndex((i) => i?.id === activeId);
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				const overIndex = this.list.findIndex((i) => i?.id === overId);

				this.list = arrayMove(this.list, activeIndex, overIndex);

				await API.request(`${this.url}/update-sort`, {
					method: MethodsRequest.Post,
					body: API.getFormData({
						...values,
						...{
							_method: MethodsRequest.Put,
						},
					}),
				});
			}
		} catch (e) {
			console.error(`Error in method  changePosition : `, e);
		}
	}
}
