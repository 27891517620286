import { ChangeEvent, useState } from 'react';

import { Button, Col, Descriptions, Divider, Input, Popconfirm, Row, Tag } from 'antd';
import { observer } from 'mobx-react-lite';

import { useStores } from '../../../../../stores';

export const Information = observer(() => {
	const { userDashboardStore, authStore } = useStores();
	const { volumes, objects } = userDashboardStore;
	const { viewDetail, tableUser } = volumes;
	const { data: viewVolume } = viewDetail;

	const [responsibleAnswer, setResponsibleAnswer] = useState('');

	const handleOnConfirm = async () => {
		try {
			await viewVolume.update({
				confirmation_by_responsible: true,
			});

			await tableUser.getList();
		} catch {
			/* empty */
		}
	};

	const handleOnShowObject = async () => {
		const object_id = viewVolume.object_id;

		if (object_id) {
			objects.viewDetail.data.setId(object_id);
			await objects.viewDetail.data.get();
			objects.viewDetail.modal.open();
		}
	};

	const handleOnConfirmCreator = async () => {
		try {
			await viewVolume.update({
				confirmation_by_creator: true,
			});

			await tableUser.getList();
		} catch {
			/* empty */
		}
	};

	const handleOnChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
		setResponsibleAnswer(e.currentTarget.value);
	};

	return (
		<>
			<Descriptions
				layout={'vertical'}
				column={3}
				title={`Объект : ${viewVolume.object.title}`}
				extra={[
					<Button key={'view-object'} onClick={handleOnShowObject}>
						Просмотреть объект
					</Button>,
				]}
			>
				<Descriptions.Item label={'Автор тома'}>{viewVolume.creator.shortFullName}</Descriptions.Item>
				<Descriptions.Item label={'Исполнитель'}>{viewVolume.responsible.shortFullName}</Descriptions.Item>
				<Descriptions.Item label={'Шифр тома'}>
					{viewVolume.object.cipher}-{viewVolume.cipher}
				</Descriptions.Item>

				<Descriptions.Item label={'Начало работ'}>{viewVolume.startDateFormatted}</Descriptions.Item>
				<Descriptions.Item label={'Окончание работ'}>{viewVolume.endDateFormatted}</Descriptions.Item>
				<Descriptions.Item label={'Фактическое завершение работ'}>
					{viewVolume.actualEndDateView} <Divider type={'vertical'} /> (Отклонение {viewVolume.deviationDays})
				</Descriptions.Item>
			</Descriptions>

			{viewVolume.responsible_id === authStore.current.id &&
				viewVolume.confirmation_by_responsible &&
				!viewVolume.confirmation_by_creator && (
					<Descriptions layout={'vertical'} column={1}>
						<Descriptions.Item label={'Информация о выполнении объекта'}>
							{viewVolume.responsible_answer}
						</Descriptions.Item>
					</Descriptions>
				)}

			{viewVolume.responsible_id === authStore.current.id &&
				viewVolume.confirmation_by_responsible &&
				!viewVolume.confirmation_by_creator &&
				viewVolume.creator_id !== authStore.current.id && (
					<Row>
						<Col span={24}>
							<Tag color={'gold'}>Отправлен на проверку {viewVolume.creator.shortFullName}</Tag>
						</Col>
					</Row>
				)}

			{viewVolume.responsible_id === authStore.current.id &&
				!viewVolume.confirmation_by_responsible &&
				!viewVolume.confirmation_by_creator && (
					<Row>
						<Col span={24} style={{ marginBottom: 15, marginTop: 40 }}>
							<Input.TextArea
								autoSize
								value={responsibleAnswer}
								style={{ width: '100%' }}
								placeholder={'Информация о выполнении объекта'}
								onChange={handleOnChange}
							/>
						</Col>
						<Col span={24}>
							<Popconfirm
								title={'Подтверждаете выполнение?'}
								onConfirm={handleOnConfirm}
								disabled={responsibleAnswer.length === 0}
							>
								<Button type={'primary'} disabled={responsibleAnswer.length === 0}>
									Объект выполнен
								</Button>
							</Popconfirm>
						</Col>
					</Row>
				)}

			{viewVolume.creator_id === authStore.current.id &&
				viewVolume.confirmation_by_responsible &&
				!viewVolume.confirmation_by_creator && (
					<Row>
						<Col span={24}>
							<Popconfirm title={'Подтверждаете выполнение?'} onConfirm={handleOnConfirmCreator}>
								<Button type={'primary'}>Объект выполнен</Button>
							</Popconfirm>
						</Col>
					</Row>
				)}
		</>
	);
});
