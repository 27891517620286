import styled from 'styled-components';

export const Container = styled.div`
	display: grid;
	grid-template-columns: auto min-content;
	column-gap: 15px;
	max-height: 150px;
`;

export const Field = styled.div``;

export const Actions = styled.div`
	margin-top: auto;
`;
