import { action, computed, makeObservable, observable } from 'mobx';

import { RoleStore } from './role.store';
import { API } from '../../core';
import { ErrorAPI } from '../../helpers';
import { PayloadParamsI, Role } from '../../interfaces';
import { CorePaginationResponse } from '../../responses';
import { AllStore } from '../common/all.store';
import { ModalStore } from '../common/modal.store';
import { TableStore } from '../common/table.store';

export class RolesStore {
	PATH = 'roles';

	@observable modal = new ModalStore();
	@observable list: RoleStore[] = [];
	@observable table = new TableStore({
		url: 'roles',
		getListItemStore: this.getListItemStore,
		getPayloadParams: this.getPayloadParams.bind(this),
	});
	@observable all = new AllStore<Role, RoleStore>({
		url: 'roles',
		getListItemStore: this.getListItemStore,
		getPayloadParams: this.getPayloadParams.bind(this),
	});
	@observable selected = new RoleStore(null);

	constructor() {
		makeObservable(this);
	}

	@computed
	get forSelectBox() {
		return this.all.list.map((role) => ({
			label: role.name,
			value: String(role.id),
		}));
	}

	@computed
	get listNotCEO() {
		return this.all.list.filter((role) => ![1, 2].includes(role.id));
	}

	@action.bound
	getListItemStore(value: Role): RoleStore {
		return new RoleStore(value);
	}

	@action.bound
	getPayloadParams(): PayloadParamsI {
		return {};
	}

	@action.bound
	async getList() {
		try {
			const { data } = await API.request<CorePaginationResponse<Role>>(`${this.PATH}`);
			this.setList(data);
		} catch (e) {
			ErrorAPI('getList', e);
		}
	}

	@action.bound
	setList(values: Role[]) {
		this.list = (values || []).map((role) => new RoleStore(role));
	}

	@action.bound
	clearSelected() {
		this.selected = new RoleStore(null);
	}

	@action.bound
	setSelected(value: RoleStore) {
		this.selected = value;
	}
}
