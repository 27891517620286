import { useEffect } from 'react';

import { PlusCircleOutlined } from '@ant-design/icons';
import { Button, Divider, Radio, RadioChangeEvent, Tooltip, Typography } from 'antd';
import { observer } from 'mobx-react-lite';

import { useStores } from '../../../../stores';
import { BlockTitle, ContainerBody, ContainerBodyPadding } from '../../../common';
import { RightSideExtra } from '../../../common/ContainerBody/ContainerBody.styled';
import { ItemProjectDocumentationModal } from '../../../ui';
import { ProjectDocumentationTable } from '../../../ui/Tables/CabinetTables/ProjectDocumentationTable/ProjectDocumentationTable';

export const ProjectDocumentationsPage = observer(() => {
	const { itemProjectDocumentsStore, itemObjectTypesStore, rolesStore } = useStores();

	useEffect(() => {
		(async () => {
			// Получаем типы
			await itemObjectTypesStore.getList();

			// Получаем проектную документацию
			await itemProjectDocumentsStore.table.getList();

			// Получаем роли
			await rolesStore.getList();
		})();
	}, [itemProjectDocumentsStore.current_item_object_type_id]);

	const handleOnShowModal = () => {
		itemProjectDocumentsStore.detail.data.setItemObjectTypeId(itemProjectDocumentsStore.current_item_object_type_id);
		itemProjectDocumentsStore.detail.modal.open();
		// Хак чтобы была доступна форма создание проектной документации
		itemProjectDocumentsStore.detail.data.setIsDataLoaded(true);
	};

	const handleOnChangeType = (e: RadioChangeEvent) => {
		const { value } = e.target;
		itemProjectDocumentsStore.setCurrentItemObjectTypeId(value);
	};

	return (
		<>
			<ContainerBody>
				<ContainerBodyPadding>
					<BlockTitle>
						<Typography.Title level={2}>Проектная документация</Typography.Title>

						<RightSideExtra>
							<Tooltip title={`Тип сетей`}>
								<Radio.Group
									value={itemProjectDocumentsStore.current_item_object_type_id}
									optionType={'button'}
									options={itemObjectTypesStore.radioButtons}
									buttonStyle={'solid'}
									onChange={handleOnChangeType}
								/>
							</Tooltip>

							<Divider type={'vertical'} />

							<Button.Group>
								<Button type={'primary'} icon={<PlusCircleOutlined />} onClick={handleOnShowModal}>
									Создать
								</Button>
							</Button.Group>
						</RightSideExtra>
					</BlockTitle>

					<ProjectDocumentationTable />
				</ContainerBodyPadding>
			</ContainerBody>

			<ItemProjectDocumentationModal />
		</>
	);
});
