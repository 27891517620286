import { TabsProps } from 'antd';
import { action, computed, makeObservable, observable } from 'mobx';

import { ObjectTypeStore } from './object-type.store';
import { LOCAL_STORAGE_ITEMS } from '../../constants';
import { API } from '../../core';
import { ErrorAPI } from '../../helpers';
import { ObjectTypeI } from '../../interfaces';
import { CoreResponse } from '../../responses';

export class ObjectTypesStore {
	@observable list: ObjectTypeStore[] = [];

	constructor() {
		makeObservable(this);
	}

	@action.bound
	setList(values: ObjectTypeI[]) {
		this.list = (values || []).map((value) => new ObjectTypeStore(value));
	}

	@action.bound
	async getList() {
		try {
			const { data } = await API.request<CoreResponse<ObjectTypeI[]>>(`object-types`);
			this.setList(data);
		} catch (e) {
			ErrorAPI('getList', e);
		}
	}

	saveLocalStorage(value: string) {
		localStorage.setItem(LOCAL_STORAGE_ITEMS.OBJECT_TYPE_TAB, value);
	}

	@computed
	get selectedType(): string {
		return this.activeTab;
	}

	@computed
	get itemsTab(): TabsProps['items'] {
		const items: TabsProps['items'] = [];

		this.list.forEach((objectType) => {
			if (items) {
				items.push({
					key: String(objectType.id),
					label: objectType.name,
					children: null,
				});
			}
		});

		return items;
	}

	@computed
	get activeTab(): string {
		const objectType = this.list.at(0) || new ObjectTypeStore(null);
		const memoryLocalStorage = localStorage.getItem(LOCAL_STORAGE_ITEMS.OBJECT_TYPE_TAB);

		return memoryLocalStorage ? memoryLocalStorage : String(objectType.id) || '';
	}
}
