import { Checkbox, Col, DatePicker, Form, FormInstance, Input, Row, Select } from 'antd';
import FormItem from 'antd/es/form/FormItem';
import { observer } from 'mobx-react-lite';

import { useStores } from '../../../../../../stores';
import { ObjectDetailStore } from '../../../../../../stores/objects/object-detail.store';
import { VolumeStore } from '../../../../../../stores/volume/volume.store';

interface InformationVolumeTabProps {
	form: FormInstance;
	volumeStore: VolumeStore;
	objectStore: ObjectDetailStore;
}

export const InformationVolumeTab = observer(({ form, volumeStore, objectStore }: InformationVolumeTabProps) => {
	const { authStore, responsiblesStore } = useStores();

	return (
		<>
			<Form.Item name={'object_id'} hidden>
				<Input />
			</Form.Item>

			<FormItem name={'creator_id'} hidden>
				<Input type={'hidden'} />
			</FormItem>

			<Row gutter={15}>
				<Col span={12}>
					<FormItem label={'Автор тома'}>{authStore.current.shortFullName}</FormItem>
				</Col>

				<Col span={12}>
					<FormItem label={'Исполнитель'} name={'responsible_id'} rules={[{ required: true }]}>
						<Select placeholder={'Выберите исполнителя'}>
							{responsiblesStore.list.map((responsible) => (
								<Select.Option key={responsible.id} value={responsible.id}>
									{responsible.shortNameWithRole}
								</Select.Option>
							))}
						</Select>
					</FormItem>
				</Col>
			</Row>

			<Row gutter={15}>
				<Col span={12}>
					<FormItem label={'Наименование тома'} name={'name_volume'} rules={[{ required: true }]}>
						<Input placeholder={'Введите наименование тома'} />
					</FormItem>
				</Col>

				<Col span={12}>
					<FormItem label={'Шифр тома'} name={'cipher'} rules={[{ required: true }]}>
						<Input addonBefore={`${objectStore.cipher}-`} placeholder={'Введите шифр тома'} />
					</FormItem>
				</Col>
			</Row>

			<Row gutter={15}>
				<Col span={12}>
					<FormItem shouldUpdate>
						{() => {
							const objectStartDate = objectStore.start_date;
							const objectEndDate = objectStore.end_date;

							return (
								<FormItem
									label={'Начало работ'}
									name={'start_date'}
									rules={[
										{ required: true },
										{
											validator: async (_, volumeStartDate) => {
												if (volumeStartDate && objectStartDate) {
													return Promise.resolve();
												} else {
													return Promise.resolve();
												}
											},
										},
									]}
								>
									<DatePicker
										format={'DD MMMM YYYY'}
										disabledDate={(current) => {
											if (objectStartDate && current && objectEndDate) {
												return (
													(current && current <= objectStartDate.startOf('day')) ||
													current >= objectEndDate.endOf('day')
												);
											} else {
												return false;
											}
										}}
									/>
								</FormItem>
							);
						}}
					</FormItem>
				</Col>

				<Col span={12}>
					<FormItem shouldUpdate>
						{() => {
							const objectStartDate = objectStore.start_date;
							const objectEndDate = objectStore.end_date;
							const fieldStartDate = form.getFieldValue('start_date');

							return (
								<FormItem
									label={'Окончание работ'}
									name={'end_date'}
									rules={[
										{ required: true },
										{
											validator: async (_, volumeEndDate) => {
												if (volumeEndDate && objectEndDate) {
													return Promise.resolve();
												} else {
													return Promise.resolve();
												}
											},
										},
									]}
								>
									<DatePicker
										format={'DD MMMM YYYY'}
										disabledDate={(current) => {
											if (fieldStartDate && current && objectEndDate) {
												if (objectStartDate && current && objectEndDate) {
													return (
														(current && current <= objectStartDate.startOf('day')) ||
														current >= objectEndDate.endOf('day') ||
														current <= fieldStartDate
													);
												} else {
													return false;
												}
											} else {
												return false;
											}
										}}
									/>
								</FormItem>
							);
						}}
					</FormItem>
				</Col>
			</Row>

			{!volumeStore.isNewStore && (
				<>
					<Row gutter={15}>
						<Col span={12}>
							<FormItem label={'Фактическое завершение работ'} name={'actual_end_date'}>
								<DatePicker disabled={volumeStore.isNewStore} format={'DD MMMM YYYY'} />
							</FormItem>
						</Col>

						<Col span={12}>
							<FormItem label={'Отклонение от даты завершения'}>{volumeStore.deviationDays}</FormItem>
						</Col>
					</Row>

					<Row gutter={15}>
						<Col span={12}>
							<FormItem
								label={'Подтверждение выполнения исполнителя'}
								valuePropName={'checked'}
								name={'confirmation_by_responsible'}
							>
								<Checkbox disabled={volumeStore.isNewStore}></Checkbox>
							</FormItem>
						</Col>

						<Col span={12}>
							<FormItem label={'Подтверждение автора тома'} valuePropName={'checked'} name={'confirmation_by_creator'}>
								<Checkbox disabled={volumeStore.isNewStore}></Checkbox>
							</FormItem>
						</Col>
					</Row>
				</>
			)}
		</>
	);
});
