import { CopyOutlined, EditOutlined, HolderOutlined } from '@ant-design/icons';
import { Button, Dropdown, MenuProps } from 'antd';
import { ColumnsType } from 'antd/es/table';

import { ItemDropDownKey } from '../../../../../constants';
import { MenuInfoAntD } from '../../../../../interfaces';
import { useStores } from '../../../../../stores';
import { ItemProjectDocumentListStore } from '../../../../../stores/item-project-documents';

export const useProjectDocumentationTableColumns = (): ColumnsType<ItemProjectDocumentListStore> => {
	const { itemProjectDocumentsStore } = useStores();

	const items: MenuProps['items'] = [
		{
			key: ItemDropDownKey.Edit,
			label: (
				<Button type={'link'} icon={<EditOutlined />}>
					Редактировать
				</Button>
			),
		},
		{
			key: ItemDropDownKey.Clone,
			label: (
				<Button type={'link'} icon={<CopyOutlined />}>
					Дублировать
				</Button>
			),
		},
	];

	/*const handleOnSortUpdate = async (row: ItemProjectDocumentStore, sort: number) => {
    // Обновление сортировки
    await row.update({
      sort: sort,
    });

    // Обновление текущего списка
    await itemProjectDocumentsStore.table.getList();
  };*/

	/*	const handleOnDelete = async (row: ItemProjectDocumentStore) => {
      // Удаление проекта
      await row.delete(false);

      // Обновление текущего списка
      await itemProjectDocumentsStore.table.getList();
    };*/

	const handleOnClick = async (e: MenuInfoAntD, row: ItemProjectDocumentListStore) => {
		if (e.key === ItemDropDownKey.Edit) {
			// Устанавливаем ID
			itemProjectDocumentsStore.detail.data.setId(row.id);

			// Загружаем данные
			await itemProjectDocumentsStore.detail.data.get();

			// Открывае модальное окно
			await itemProjectDocumentsStore.detail.modal.open();
			return;
		}

		if (e.key === ItemDropDownKey.Clone) {
			itemProjectDocumentsStore.setIsClone(true);

			// Дублирование
			await row.clone();

			// Обновление списка
			await itemProjectDocumentsStore.table.getList();

			itemProjectDocumentsStore.setIsClone(false);
			return;
		}
	};

	return [
		{
			title: 'Название',
			align: 'center',
			dataIndex: 'name',
			ellipsis: true,
			width: 300,
			render: (value) => value,
		},
		{
			title: 'Описание',
			align: 'center',
			dataIndex: 'description',
			ellipsis: true,
			render: (value) => value,
		},
		{
			title: 'Количество томов',
			align: 'center',
			width: 200,
			render: (_, row) => row.item_volume_generates_count,
		},
		{
			title: 'Дата создания',
			align: 'center',
			width: 200,
			render: (_, row) => row.createdAtFormatted,
		},
		{
			title: 'Дата обновления',
			align: 'center',
			width: 200,
			render: (_, row) => row.updatedAtFormatted,
		},
		{
			title: '',
			align: 'center',
			width: 100,
			render: (_, row) => {
				return (
					<Dropdown
						menu={{
							onClick: (e) => handleOnClick(e, row),
							items,
						}}
						trigger={['click']}
					>
						<Button
							icon={<HolderOutlined />}
							type={'default'}
							style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', margin: '0 auto' }}
						/>
					</Dropdown>
				);
			},
		},
	];
};
