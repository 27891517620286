import { Modal, Tabs, TabsProps } from 'antd';
import { observer } from 'mobx-react-lite';

import { Contractors } from './Contractors/Contractors';
import { Files } from './Files/Files';
import { Information } from './Information/Information';
import { OBJECT_TABS } from '../../../../constants';
import { useStores } from '../../../../stores';

export const ObjectDetailModal = observer(() => {
	const { userDashboardStore } = useStores();
	const { objects } = userDashboardStore;
	const { viewDetail } = objects;
	const { data } = viewDetail;

	const itemTabs: TabsProps['items'] = [
		{
			key: OBJECT_TABS.MAIN,
			label: 'Основная информация',
			children: <Information />,
		},
		{
			key: OBJECT_TABS.CONTRACTORS,
			label: 'Контрагенты',
			children: <Contractors />,
		},
		{
			key: OBJECT_TABS.FILES,
			label: 'Файлы',
			children: <Files />,
		},
	];

	return (
		<Modal
			forceRender
			centered
			width={1200}
			styles={{
				body: {
					height: 600,
					overflowY: 'auto',
				},
			}}
			open={viewDetail.modal.isShow}
			title={`Просмотр объекта : ${data.title}`}
			onCancel={viewDetail.modal.close}
			okButtonProps={{ hidden: true }}
			cancelText={'Закрыть'}
		>
			<Tabs defaultActiveKey={OBJECT_TABS.MAIN} items={itemTabs} />
		</Modal>
	);
});
