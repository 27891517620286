import { useEffect } from 'react';

import { observer } from 'mobx-react-lite';
import { Outlet, useLocation } from 'react-router-dom';

import { Container } from './BaseLayout.styled';
import { useStores } from '../../../stores';
import { LoginPage } from '../../pages';

export const BaseLayout = observer(() => {
	const { authStore } = useStores();

	const location = useLocation();

	useEffect(() => {
		if (authStore.isAuth && location.pathname === '/') {
			window.location.href = '/cabinet';
		}
	}, [authStore.isAuth, location.pathname]);

	return (
		<Container>
			{!authStore.isAuth && <LoginPage />}
			{authStore.isAuth && <Outlet />}
		</Container>
	);
});

export default BaseLayout;
