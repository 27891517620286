import { ChangeEvent, useEffect, useState } from 'react';

import { Avatar, Input, List, Switch } from 'antd';
import { observer } from 'mobx-react-lite';

import { useStores } from '../../../../../stores';
import { ObjectNoteStore } from '../../../../../stores/object-note/object-note.store';

interface NoteItemProps {
	note: ObjectNoteStore;
}

export const NoteItem = observer(({ note, ...props }: NoteItemProps) => {
	const {
		authStore: { current: currentUser },
	} = useStores();

	const [isEditable, setIsEditable] = useState(false);
	const [isChangeContent, setIsChangeContent] = useState(false);

	const handleOnChangeIsEditable = (isCheck: boolean) => setIsEditable(isCheck);

	const handleOnChangeContent = (e: ChangeEvent<HTMLTextAreaElement>) => {
		const { value } = e.currentTarget;
		note.setContent(value);
		setIsChangeContent(true);
	};

	useEffect(() => {
		if (!isEditable && isChangeContent) {
			(async () => {
				await note.update({
					content: note.content,
				});
			})();
		}
	}, [isEditable, isChangeContent]);

	return (
		<List.Item
			{...props}
			key={note.id}
			actions={[
				note.user.id === currentUser.id ? (
					<>
						<Switch
							unCheckedChildren={'Редактировать'}
							checkedChildren={'Сохранить изменения'}
							onChange={handleOnChangeIsEditable}
						/>
					</>
				) : null,
			]}
		>
			<List.Item.Meta
				avatar={<Avatar src={note.user.fullPathAvatar} size={'large'} />}
				title={`От ${note.user.shortFullName}`}
				description={note.createdAtFormatted}
			/>

			{isEditable ? (
				<Input.TextArea autoSize value={note.content} onChange={handleOnChangeContent} />
			) : (
				<div style={{ whiteSpace: 'pre-wrap', width: 'calc(100% - 250px)' }}>{note.content}</div>
			)}
		</List.Item>
	);
});
