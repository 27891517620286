import styled from 'styled-components';

export const UserListOnlineContainer = styled.div`
	display: flex;
	flex-direction: column;
	row-gap: 10px;
	user-select: none;
`;

export const UserListOnlineRow = styled.div`
	display: flex;
	align-items: center;
	column-gap: 10px;
`;

export const UserListOnlineButton = styled.button`
	margin-left: auto;
	background: transparent;
	padding: 0;
	border: 0;
	cursor: pointer;
	transition: all 0.2s;

	&:active {
		transform: scale(0.9);
	}
`;
