import { ItemStore, useStores } from '../stores';

export const useBlockedItemHook = (item: ItemStore): Array<boolean> => {
	// Стор авторизации
	const { authStore } = useStores();

	// Проверка на блокировку
	const isBlocked = authStore.current.id !== item.user_blocked_id && item.is_blocked;

	return [isBlocked];
};
