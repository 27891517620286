import { useEffect, useRef } from 'react';

import { DeleteOutlined, MinusCircleOutlined } from '@ant-design/icons';
import { Button, Col, Divider, Form, Input, Popconfirm, Row, Select, Upload, UploadProps } from 'antd';
import { UploadChangeParam } from 'antd/es/upload';
import { observer } from 'mobx-react-lite';

import { useStores } from '../../../../../stores';
import { ObjectContractorFileStore } from '../../../../../stores/object-contractor/object-contractor-file.store';

interface ContractorItemProps {
	keyOrder: number;
	name: number;
	remove: (index: number) => void;
}

export const ContractorItem = observer(({ keyOrder, name, remove, ...restField }: ContractorItemProps) => {
	const { objectsStore, contractorsStore } = useStores();
	const { selected: currentObjectStore } = objectsStore;
	const uploadRef = useRef<UploadProps>(null);

	const files = currentObjectStore.contractors[keyOrder] ? currentObjectStore.contractors[keyOrder].files : [];

	useEffect(() => {
		if (uploadRef.current) {
			uploadRef.current.fileList = [];
		}
	}, [files]);

	const handleOnAddFiles = () => {
		return false;
	};

	const handleNormFile = (e: UploadChangeParam) => {
		return e.fileList;
	};

	const handleOnDelete = async (fileStore: ObjectContractorFileStore) => {
		await fileStore.delete();
		currentObjectStore.contractors[keyOrder].deleteFile(fileStore.id);
	};

	return (
		<Row gutter={15} key={keyOrder}>
			<Col span={22}>
				<Form.Item {...restField} name={[name, 'id']} hidden>
					<Input />
				</Form.Item>
			</Col>
			<Col span={22}>
				<Form.Item {...restField} label={'Контрагент'} name={[name, 'contractor_id']} rules={[{ required: true }]}>
					<Select showSearch optionFilterProp="children">
						{contractorsStore.all.list.map((contractor) => {
							return (
								<Select.Option key={contractor.id} value={contractor.id}>
									{contractor.company_name} ({contractor.inn})
								</Select.Option>
							);
						})}
					</Select>
				</Form.Item>
			</Col>
			<Col span={2} style={{ textAlign: 'right' }}>
				<Button
					style={{ marginTop: 30 }}
					icon={<MinusCircleOutlined />}
					type={'primary'}
					danger
					onClick={() => remove(name)}
				/>
			</Col>
			<Col span={24}>
				<Form.Item
					{...restField}
					label={'Предмет договора'}
					name={[name, 'subject_contract']}
					rules={[{ required: true }]}
				>
					<Input.TextArea autoSize />
				</Form.Item>
			</Col>
			<Col span={24}>
				<Form.Item
					{...restField}
					label={'Файлы'}
					name={[name, 'files_upload']}
					valuePropName={'fileList'}
					getValueFromEvent={handleNormFile}
				>
					<Upload beforeUpload={handleOnAddFiles} multiple ref={uploadRef}>
						<Button>Добавить файлы</Button>
					</Upload>
				</Form.Item>
			</Col>
			<Col span={24}>
				<Form.Item {...restField} label={'Загруженные файлы'}>
					{files.map((file) => {
						return (
							<div key={file.id} style={{ display: 'flex', columnGap: 15, alignItems: 'center', marginBottom: 10 }}>
								<Popconfirm title={'Уверены что хотите удалить?'} onConfirm={() => handleOnDelete(file)}>
									<Button icon={<DeleteOutlined />} type={'primary'} danger />
								</Popconfirm>
								<a href={file.fullPath} target={'_blank'} rel="noreferrer">
									{file.origin_name}
								</a>
								<span>Загружен в {file.formattedCreatedAt}</span>
							</div>
						);
					})}
				</Form.Item>
			</Col>
			<Col span={24}>
				<Divider />
			</Col>
		</Row>
	);
});
