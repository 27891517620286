import { action, computed, makeObservable, observable } from 'mobx';

import { getFirstChar, makeAbbr } from '../../helpers';
import { UserShort } from '../../interfaces';
import { RoleShortStore } from '../role';

export class UserShortStore implements UserShort {
	@observable id = 0;
	@observable email = '';
	@observable first_name = '';
	@observable last_name = '';
	@observable patronymic = '';
	@observable avatar = '';
	@observable role = new RoleShortStore(null);
	@observable contractor = null;

	constructor(initialData: UserShort | null) {
		makeObservable(this);

		if (initialData) {
			this.fillStore(initialData);
		}
	}

	@computed
	get isExist() {
		return this.id !== 0;
	}

	@computed
	get initials() {
		return makeAbbr(`${this.first_name} ${this.patronymic}`);
	}

	@computed
	get shortFullName() {
		const name = getFirstChar(this.first_name);
		const patronymic = getFirstChar(this.patronymic);
		const complete = `${this.last_name}${name ? ' ' : ''}${name}${patronymic ? '.' : ''}${patronymic}.`;
		return complete || this.email;
	}

	@computed
	get shortNameWithRole() {
		return this.shortFullName ? `${this.shortFullName} (${this.roleName})` : '';
	}

	@computed
	get roleName() {
		return this.role.name;
	}

	@computed
	get isContractor() {
		return Boolean(this.contractor);
	}

	@action
	fillStore(data: UserShort) {
		const { id, email, first_name, last_name, patronymic, avatar, role, contractor } = data;

		this.id = id;
		this.email = email;
		this.first_name = first_name;
		this.last_name = last_name;
		this.patronymic = patronymic;
		this.avatar = avatar;
		this.role = new RoleShortStore(role);
		this.contractor = contractor;
	}
}
