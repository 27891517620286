import { Col, FormInstance, Row } from 'antd';
import { observer } from 'mobx-react-lite';

import { OBJECT_FILES_BLOCK } from '../../../../../constants';
import { useStores } from '../../../../../stores';
import { FileUploadBlock } from '../../../../common';

interface FilesProps {
	form: FormInstance;
}

export const Files = observer(({ form }: FilesProps) => {
	const { objectsStore } = useStores();
	const { selected: currentObjectStore } = objectsStore;

	console.log(form);

	return (
		<>
			<Row gutter={15} style={{ marginBottom: 15 }}>
				<Col span={12}>
					<FileUploadBlock
						nameField={OBJECT_FILES_BLOCK.TECH_FILES}
						label={'Техническая документация'}
						list={currentObjectStore.techFiles.list}
					/>
				</Col>
				<Col span={12}>
					<FileUploadBlock
						nameField={OBJECT_FILES_BLOCK.ADMIN_FILES}
						label={'Административная документация'}
						list={currentObjectStore.adminFiles.list}
					/>
				</Col>
			</Row>
			<Row>
				<Col span={12}>
					<FileUploadBlock
						nameField={OBJECT_FILES_BLOCK.TECH_TASK_FILES}
						label={'Задания на проектирование (ТЗ)'}
						list={currentObjectStore.techTaskFiles.list}
					/>
				</Col>
			</Row>
		</>
	);
});
