import { useEffect, useRef, useState } from 'react';

import { Typography } from 'antd';
import dayjs from 'dayjs';
import L from 'leaflet';
import { observer } from 'mobx-react-lite';
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet';

import MarkerSVG from './marker.svg';
import { GeolocationNotification, WsMessageI, WsTypeEvent } from '../../../../interfaces';
import { useStores } from '../../../../stores';
import { BlockTitle, ContainerBody, ContainerBodyPadding } from '../../../common';

const MarkerIcon = L.icon({
	iconUrl: MarkerSVG,
	iconSize: [40, 40],
	iconAnchor: [12, 12],
	popupAnchor: [0, 0],
});

export const MapWorkPage = observer(() => {
	const { websocketStore } = useStores();

	const mapContainerRef = useRef<HTMLDivElement>(null);
	const [isLoad, setIsLoad] = useState(false);

	const [markers, setMarkers] = useState<Array<GeolocationNotification>>([]);

	let intervalID = 0;

	useEffect(() => {
		preloaderMap();

		// Подписываемся на событие получения координат
		websocketStore.addCallback({ event: WsTypeEvent.geolocationNotify, callback: handleOnGeolocationEvent });
	}, []);

	const handleOnGeolocationEvent = (message: WsMessageI<GeolocationNotification>) => {
		setMarkers((prevState) => {
			const findIndex = prevState.findIndex((marker) => marker.fullName === message.payload.fullName);

			if (findIndex !== -1) {
				prevState[findIndex] = message.payload;
			} else {
				prevState.push(message.payload);
			}

			return [...prevState];
		});
	};

	const preloaderMap = () => {
		let iteration = 0;

		intervalID = window.setInterval(() => {
			if (iteration >= 3) {
				clearInterval(intervalID);
			}

			if (mapContainerRef.current) {
				const findContainer = mapContainerRef.current.getElementsByClassName('leaflet-attribution-flag');

				if (findContainer && findContainer.length > 0) {
					if (findContainer[0].parentNode) {
						const findSVG = findContainer[0];
						findContainer[0].parentNode.removeChild(findSVG);
						clearInterval(intervalID);
						setIsLoad(() => true);
					}
				}
			}

			iteration++;
		}, 200);
	};

	return (
		<>
			<ContainerBody>
				<ContainerBodyPadding>
					<BlockTitle>
						<Typography.Title level={2}>Рабочая карта</Typography.Title>
					</BlockTitle>

					<div
						style={{ height: '100%', width: '100%', display: 'flex', opacity: isLoad ? 1 : 0 }}
						ref={mapContainerRef}
					>
						<MapContainer
							center={[55.7858444, 49.1909959]}
							zoom={16}
							scrollWheelZoom={false}
							style={{ width: '100%', height: '100%', flex: 1, borderRadius: 20 }}
						>
							<TileLayer
								attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
								url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
							/>

							{markers.map((marker) => (
								<Marker
									key={`marker-${marker.fullName}`}
									position={{
										lat: marker.latitude,
										lng: marker.longitude,
									}}
									icon={MarkerIcon}
								>
									<Popup>
										<span>
											{marker.fullName} {dayjs(marker.datetime).format('HH:mm DD MMM YYYY')}
										</span>
									</Popup>
								</Marker>
							))}
						</MapContainer>
					</div>
				</ContainerBodyPadding>
			</ContainerBody>
		</>
	);
});
