import { useEffect } from 'react';

import { FormInstance, Tabs, TabsProps } from 'antd';
import { observer } from 'mobx-react-lite';

import { Information } from './Information/Information';
import { ITEMS_TABS } from '../../../../../../constants';
import { useStores } from '../../../../../../stores';
import { ItemStore } from '../../../../../../stores/item/item.store';

interface ItemObjectProps {
	form: FormInstance;
	itemStore: ItemStore;
}

export const ItemObject = observer(({ form, itemStore }: ItemObjectProps) => {
	const { itemStatusesStore, itemProjectDocumentsStore } = useStores();

	const itemTabs: TabsProps['items'] = [
		{
			key: ITEMS_TABS.MAIN,
			label: 'Основная информация',
			children: <Information form={form} itemStore={itemStore} />,
		},
		{
			key: ITEMS_TABS.CONTRACTORS,
			label: 'Контрагенты',
			children: <div></div>,
			disabled: itemStore.isNewStore,
		},
		{
			key: ITEMS_TABS.FILES,
			label: 'Файлы',
			children: <div></div>,
			disabled: itemStore.isNewStore,
		},
	];

	// Поля объекта
	const fields = {
		id: itemStore.id,
		parent_id: itemStore.parent_id,
		creator_id: itemStore.creator_id,
		responsible_id: itemStore.responsible_id,
		item_type_id: itemStore.item_type_id,
		item_status_id: itemStore.item_status_id,
		item_object_type_id: itemStore.item_object_type_id,
		order_number: itemStore.order_number,
		current_year: itemStore.current_year,
		title: itemStore.title,
		price: itemStore.price,
		contract_number: itemStore.contract_number,
		description: itemStore.description,
		content: itemStore.content,
		cipher: itemStore.cipher,
		start_date: itemStore.start_date,
		end_date: itemStore.end_date,
		actual_date: itemStore.actual_date,
		is_subcontract: itemStore.is_subcontract,
		is_delete: itemStore.is_delete,
		custom_status: itemStore.custom_status,
	};

	useEffect(() => {
		(async () => {
			//await itemObjectTypesStore.getList();
			//await responsiblesStore.getAll();
		})();
	}, []);

	// Если изменили тип объекта
	useEffect(() => {
		(async () => {
			if (itemStore.item_object_type_id) {
				itemProjectDocumentsStore.setCurrentItemObjectTypeId(itemStore.item_object_type_id);
				await itemStatusesStore.getList(itemStore.item_object_type_id);
				await itemProjectDocumentsStore.table.getList();
			}
		})();
	}, [itemStore.item_object_type_id]);

	// Заполняем данными
	useEffect(() => {
		form.setFieldsValue({
			...fields,
		});
	}, [itemStore.id, itemStore.item_object_type_id]);

	return (
		<>
			{itemStore.trigger_workflows.map((triggerWorkflow) => {
				return <div key={triggerWorkflow.id}>{triggerWorkflow.id}</div>;
			})}
			<Tabs items={itemTabs} defaultActiveKey={ITEMS_TABS.MAIN} />
		</>
	);
});
