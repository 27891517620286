import { motion } from 'framer-motion';
import { rgba } from 'polished';
import styled, { css } from 'styled-components';

import { COLORS, fontMixin } from '../../../stylesheets';

interface DragZoneUploadContainerProps {
	isHidden: boolean;
}

export const DragZoneUploadContainer = styled.div<DragZoneUploadContainerProps>`
	display: flex;
	flex-direction: column;
	flex: 1;

	${(props) =>
		props.isHidden &&
		css`
			align-items: center;
		`}
`;

export const DragZoneUploadNotification = styled(motion.div)`
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background: ${rgba(COLORS.BLUE_100, 0.1)};
	border-radius: 20px;
	z-index: 100;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	color: ${COLORS.BLUE_100};
`;

export const DragZoneUploadTitle = styled.div`
	display: flex;
	${fontMixin(22, 500)};
`;

export const DragZoneUploadProgress = styled(motion.div)`
	display: flex;
`;
