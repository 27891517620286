import { Fragment } from 'react';

import { DeleteOutlined } from '@ant-design/icons';
import { Button, Checkbox, Col, Form, FormInstance, FormListFieldData, Input, Row, Select } from 'antd';
import { useWatch } from 'antd/es/form/Form';
import { observer } from 'mobx-react-lite';
import { v4 as uuidv4 } from 'uuid';

import { TriggerList } from './TriggerList/TriggerList';
import { ItemVolumeGenerate } from '../../../../../../../../interfaces/item-volume-generate';
import { useStores } from '../../../../../../../../stores';

interface TaskItemProps {
	orderNumber: number;
	volumeIndex: number;
	form: FormInstance;
	field: FormListFieldData;
	onRemove: (key: number) => void;
}

export const TaskItem = observer(({ orderNumber, volumeIndex, form, field, onRemove }: TaskItemProps) => {
	const { rolesStore } = useStores();

	const item_volume: ItemVolumeGenerate = useWatch(['item_volume_generates', volumeIndex], form) || {};

	// Текущий таск
	const currentTaskData = useWatch(['item_volume_generates', volumeIndex, 'item_volume_tasks', field.name], form) || {};

	const getTitleVolume = () => {
		return `${item_volume?.number_section} | ${item_volume?.nomination}`;
	};

	const getTitleTask = () => {
		return `Задача ${field.name + 1}`;
	};

	return (
		<Fragment key={field.name}>
			<Row gutter={15}>
				{/** Скрытые поля */}
				<Form.Item name={[field.name, 'inside_uuid']} hidden initialValue={uuidv4()}>
					<Input />
				</Form.Item>

				<Col span={24}>
					<div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 15 }}>
						<span style={{ fontWeight: 700, fontSize: 18 }}>{getTitleTask()}</span>
						<span>
							<Button
								shape={'circle'}
								icon={<DeleteOutlined />}
								type={'primary'}
								danger
								style={{ paddingBottom: 2 }}
								onClick={() => onRemove(field.name)}
							/>
						</span>
					</div>
				</Col>

				<Col span={16}>
					<Form.Item
						label={'Исполнитель задачи (Роль)'}
						name={[field.name, 'role_id']}
						rules={[
							{
								required: true,
								message: `Пожалуйста, выберите Исполнитель задачи (Роль). ${getTitleTask()} Тома : ${getTitleVolume()}`,
							},
						]}
					>
						<Select
							options={rolesStore.forSelectBox}
							allowClear
							showSearch
							filterOption={(inputValue, option) => {
								return String(option?.label ?? '')
									.toLowerCase()
									.includes(inputValue.toLowerCase());
							}}
						/>
					</Form.Item>
				</Col>

				<Col span={8}>
					<Form.Item
						label={'Завершающая задача'}
						name={[field.name, 'is_finish']}
						valuePropName="checked"
						initialValue={false}
						tooltip={`При выборе опции, когда будет завершена задача, то автоматически том переходит в статус Рассмотрения`}
					>
						<Checkbox />
					</Form.Item>
				</Col>

				<Col span={24}>
					<Form.Item
						label={'Название задачи'}
						name={[field.name, 'title']}
						rules={[{ required: true }]}
						initialValue={''}
					>
						<Input />
					</Form.Item>
				</Col>

				<Col span={24}>
					<Form.Item
						label={'Описание задачи'}
						name={[field.name, 'description']}
						rules={[{ required: true }]}
						initialValue={''}
					>
						<Input.TextArea autoSize={{ minRows: 3 }} />
					</Form.Item>
				</Col>
			</Row>

			<TriggerList form={form} volumeIndex={volumeIndex} taskIndex={field.name} />
		</Fragment>
	);
});
