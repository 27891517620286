import { useEffect } from 'react';

import { UserAddOutlined } from '@ant-design/icons';
import { Button, Typography } from 'antd';
import { observer } from 'mobx-react-lite';

import { useStores } from '../../../../stores';
import { BlockTitle, ContainerBody, ContainerBodyPadding } from '../../../common';
import { ContractorDrawer } from '../../../ui';
import { ContractorsTable } from '../../../ui/Tables/ContractorsTable/ContractorsTable';

export const ContractorsPage = observer(() => {
	const { contractorsStore } = useStores();

	useEffect(() => {
		(async () => {
			await contractorsStore.table.getList();
		})();
	}, []);

	const handleOnShow = () => {
		contractorsStore.drawer.show();
	};

	return (
		<>
			<ContainerBody>
				<ContainerBodyPadding>
					<BlockTitle>
						<Typography.Title level={2}>Контрагенты</Typography.Title>

						<Button.Group>
							<Button type={'primary'} icon={<UserAddOutlined />} onClick={handleOnShow}>
								Добавить
							</Button>
						</Button.Group>
					</BlockTitle>

					<ContractorsTable />
				</ContainerBodyPadding>
			</ContainerBody>

			<ContractorDrawer />
		</>
	);
});
