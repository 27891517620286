import { action, makeObservable, observable } from 'mobx';

import { AgreementPrivateContractorDetailStore } from './agreement-private-contractor-detail.store';
import { AgreementPrivateContractorFileStore } from './agreement-private-contractor-file.store';
import { AgreementPrivateDetailStore } from './agreement-private-detail.store';
import { MethodsRequest } from '../../constants';
import { API } from '../../core';
import { ErrorAPI } from '../../helpers';
import { AgreementPrivateContractor, BoolNull, NumberNull } from '../../interfaces';
import { CoreResponse } from '../../responses';
import { FileStore } from '../file/file.store';

export class AgreementPrivateContractorStore implements AgreementPrivateContractor {
	@observable id = 0;
	@observable agreement_file_template_id: NumberNull = null;
	@observable hash_link = '';
	@observable comment = '';
	@observable approve: BoolNull = null;
	@observable send_email: BoolNull = null;
	@observable files: AgreementPrivateContractorFileStore[] = [];
	@observable contractor = new AgreementPrivateContractorDetailStore(null);
	@observable agreement = new AgreementPrivateDetailStore(null);
	@observable template_file = new FileStore(null);

	constructor() {
		makeObservable(this);
	}

	@action.bound
	setHashLink(value: string) {
		this.hash_link = value;
	}

	@action.bound
	async getByHash() {
		try {
			const { data } = await API.request<CoreResponse<AgreementPrivateContractor>>(
				`agreement-private-contractor/${this.hash_link}`,
			);

			// Записываем данные
			this.fillStore(data);
		} catch (e) {
			ErrorAPI('getByHash', e);
		}
	}

	@action.bound
	async uploadFiles(files: FileList, agreement_contractor_id: number) {
		try {
			await API.request(`agreement-contractor-files`, {
				method: MethodsRequest.Post,
				body: API.getFormData({ files: files, agreement_contractor_id }),
			});
		} catch (e) {
			ErrorAPI('uploadFiles', e);
		}
	}

	@action
	fillStore(data: AgreementPrivateContractor) {
		const {
			id,
			agreement_file_template_id,
			hash_link,
			comment,
			approve,
			send_email,
			files,
			contractor,
			agreement,
			template_file,
		} = data;

		this.id = id;
		this.agreement_file_template_id = agreement_file_template_id;
		this.hash_link = hash_link;
		this.comment = comment;
		this.approve = approve;
		this.send_email = send_email;
		this.files = (files || []).map((file) => new AgreementPrivateContractorFileStore(file));
		this.contractor = new AgreementPrivateContractorDetailStore(contractor);
		this.agreement = new AgreementPrivateDetailStore(agreement);
		this.template_file = new FileStore(template_file);
	}
}
