import { useEffect, useState } from 'react';

import { Badge, Form, FormInstance, Tabs, TabsProps } from 'antd';
import { useWatch } from 'antd/es/form/Form';
import { observer } from 'mobx-react-lite';

import { FilesTab } from './Tabs/FilesTab/FilesTab';
import { InformationTab } from './Tabs/InformationTab/InformationTab';
import { TriggersTab } from './Tabs/TriggersTab';
import { ITEM_TABS } from '../../../../../constants';
import { ItemStore, useStores } from '../../../../../stores';

interface ItemTaskFormProps {
	isBlockedItem: boolean;
	form: FormInstance;
	onSend: (values: any) => void;
	onError: () => void;
	onChangeForm: () => void;
}

export const ItemTaskForm = observer(({ isBlockedItem, form, onSend, onError, onChangeForm }: ItemTaskFormProps) => {
	const { itemsStore, usersStore } = useStores();
	const { selected: currentTaskItem } = itemsStore; // Текущий таб

	const [currentTab, setCurrentTab] = useState<string>(ITEM_TABS.TASK.DATA.key);

	// Слушаем поле триггер
	const triggerFieldWatcher = useWatch('trigger_workflows', form) || [];

	useEffect(() => {
		(async () => {
			await usersStore.all.getList();
		})();
	}, []);

	// Инит данных и их обновление от side effect
	useEffect(() => {
		if (currentTaskItem.drawer.isShow) {
			updateFields();
		}
	}, [currentTaskItem.drawer.isShow, currentTaskItem.id]);

	// Табы
	const itemTabs: TabsProps['items'] = [
		{
			key: ITEM_TABS.TASK.DATA.key,
			label: ITEM_TABS.TASK.DATA.value,
			children: <InformationTab form={form} />,
			forceRender: true,
		},
		{
			key: ITEM_TABS.TASK.FILES.key,
			label: ITEM_TABS.TASK.FILES.value,
			children: <FilesTab form={form} />,
			forceRender: true,
		},
		{
			key: ITEM_TABS.TASK.TRIGGERS.key,
			label: (
				<>
					{ITEM_TABS.TASK.TRIGGERS.value} <Badge count={triggerFieldWatcher.length} />
				</>
			),
			children: <TriggersTab form={form} currentTask={currentTaskItem} />,
			forceRender: true,
		},
	];

	// Поля тома
	const getFields = (currentItem: ItemStore) => ({
		id: currentItem.id,
		parent_id: currentItem.parent_volume?.id,
		item_type_id: currentItem.item_type_id,
		item_object_type_id: currentItem.parent_volume?.item_object_type_id,
		creator_id: currentItem.creator_id || undefined,
		responsible_id: currentItem.responsible_id || undefined,
		title: currentItem.title,
		cipher: currentItem.cipher,
		start_date: currentItem.start_date,
		end_date: currentItem.end_date,
		actual_date: currentItem.actual_date || undefined,
		content: currentItem.content,
		deadline_execute_date: currentItem.created_at.clone().add(3, 'day'),
		is_finish: currentItem.is_finish,
		trigger_workflows: currentItem.trigger_workflows.map((trigger) => {
			return trigger;
		}),
	});

	// Обновление полей
	const updateFields = () => {
		form.setFieldsValue(getFields(currentTaskItem));
	};

	// Смена табов
	const handleOnChangeTab = (keyTab: string) => {
		setCurrentTab(keyTab);
	};

	return (
		<Form
			disabled={isBlockedItem}
			name={'form-task'}
			form={form}
			layout={'vertical'}
			autoComplete="off"
			onFinish={async (values) => {
				// Отправка данных
				await onSend(values);
				//updateFields();
			}}
			onFinishFailed={onError}
			onValuesChange={onChangeForm}
		>
			<Tabs activeKey={currentTab} items={itemTabs} onChange={handleOnChangeTab} />
		</Form>
	);
});
