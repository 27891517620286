import { Empty } from 'antd';

import { Body, Container } from './StartScreen.styled';

export const StartScreen = () => {
	return (
		<Container>
			<Body>
				<Empty description={'Выберите или создайте новый чат'} image={Empty.PRESENTED_IMAGE_SIMPLE} />
			</Body>
		</Container>
	);
};
