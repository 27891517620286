import { action, computed, makeObservable, observable } from 'mobx';

import { ItemLocalStatus } from '../../interfaces/item';

export class ItemLocalStatusStore implements ItemLocalStatus {
	@observable id = 0;
	@observable status = '';
	@observable description = '';

	constructor(initialData: ItemLocalStatus | null) {
		makeObservable(this);

		if (initialData) {
			this.fillStore(initialData);
		}
	}

	@computed
	get isNew() {
		return this.id === 1;
	}

	@computed
	get isCheck() {
		return this.id === 3;
	}

	@computed
	get isReturn() {
		return this.id === 5;
	}

	@action
	fillStore(data: ItemLocalStatus) {
		const { id, status, description } = data;

		this.id = id;
		this.status = status;
		this.description = description;
	}
}
