import { useEffect } from 'react';

import { Tree } from 'antd';
import { observer } from 'mobx-react-lite';

import { ItemTypes } from '../../../../../constants/item-types';
import { ItemStore, useStores } from '../../../../../stores';

interface TreeTabProps {
	itemStore: ItemStore;
}

export const TreeTab = observer(({ itemStore }: TreeTabProps) => {
	const { itemsStore } = useStores();
	const { selectedTree } = itemsStore;

	useEffect(() => {
		(async () => {
			//await itemStore.getHistory();
		})();
	}, [itemStore.id]);

	const handleOnShow = async (_: any, data: any) => {
		// Открываем окно с подробной информацией
		selectedTree.modal.open();

		// Получаем данные по текущему
		selectedTree.setId(data.key);

		await selectedTree.get();
	};

	const getExpanded = (): Array<number> => {
		if (itemStore.item_type_id === ItemTypes.tasks) {
			return [itemStore.parent_object?.id || 0, itemStore.parent_volume?.id || 0, itemStore.id];
		} else if (itemStore.item_type_id === ItemTypes.volume) {
			return [itemStore.parent_object?.id || 0, itemStore.id];
		} else {
			return [itemStore.id];
		}
	};

	return (
		<>
			<Tree
				key={itemStore.id}
				treeData={itemStore.historyTree}
				onDoubleClick={handleOnShow}
				defaultSelectedKeys={[itemStore.id]}
				defaultExpandedKeys={[...getExpanded()]}
			/>
		</>
	);
});
