import { action, computed, makeObservable, observable } from 'mobx';

import notificationWAV from './sound/notification.wav';
import { AudioConst } from '../../constants';

export class AudioStore {
	@observable notification: HTMLAudioElement;

	constructor() {
		makeObservable(this);

		this.notification = new Audio(notificationWAV);
	}

	@action.bound
	setMute(value: boolean) {
		this.notification.muted = value;
		localStorage.setItem(AudioConst.isMuteNotification, String(value));
	}

	@computed
	get currentMuteState() {
		const currentMute = localStorage.getItem(AudioConst.isMuteNotification);

		if (!currentMute) {
			return false;
		}

		if (currentMute === 'false') {
			return false;
		}

		return Boolean(currentMute);
	}
}
