import { action, makeObservable, observable } from 'mobx';

import { ObjectAdminFileStore } from './object-admin-file.store';
import { ObjectFileI } from '../../interfaces/object/object-file.interface';

export class ObjectAdminFilesStore {
	@observable list: ObjectAdminFileStore[] = [];

	constructor() {
		makeObservable(this);
	}

	@action.bound
	setList(values: ObjectFileI[]) {
		this.list = (values || []).map((file) => new ObjectAdminFileStore(file));
	}

	@action.bound
	deleteFromList(id: number) {
		this.list = this.list.filter((file) => file.id !== id);
	}
}
