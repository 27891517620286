import dayjs from 'dayjs';
import { action, computed, makeObservable, observable } from 'mobx';

import { API } from '../../core';
import { AgreementFileTemplate } from '../../interfaces';
import { CrudStore } from '../common/crud.store';

export class AgreementFileTemplateStore extends CrudStore<AgreementFileTemplate> implements AgreementFileTemplate {
	PATH = 'agreement-file-templates';

	@observable user_id = 0;
	@observable origin_name = '';
	@observable file_name = '';
	@observable size_file = 0;
	@observable is_delete = false;
	@observable created_at = dayjs();
	@observable updated_at = dayjs();

	constructor(initialData: AgreementFileTemplate | null) {
		super();

		makeObservable(this);

		if (initialData) {
			this.fillStore(initialData);
		}
	}

	@computed
	get downloadPath() {
		return `${API.getStoragePath}${this.file_name}`;
	}

	@action
	fillStore(data: AgreementFileTemplate) {
		const { id, user_id, origin_name, file_name, size_file, is_delete, created_at, updated_at } = data;

		this.id = id;
		this.user_id = user_id;
		this.origin_name = origin_name;
		this.file_name = file_name;
		this.size_file = size_file;
		this.is_delete = is_delete;
		this.created_at = dayjs(created_at);
		this.updated_at = dayjs(updated_at);
	}
}
