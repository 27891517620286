import { useEffect } from 'react';

import { PlusCircleOutlined } from '@ant-design/icons';
import { Button, Typography } from 'antd';
import { observer } from 'mobx-react-lite';

import { useStores } from '../../../../stores';
import { BlockTitle, ContainerBody, ContainerBodyPadding } from '../../../common';
import { AgreementDrawer, AgreementTable } from '../../../ui';

interface AgreementsPageProps {
	test?: boolean;
}

export const AgreementsPage = observer(({ test }: AgreementsPageProps) => {
	const { agreementsStore, contractorsStore } = useStores();

	useEffect(() => {
		(async () => {
			await agreementsStore.table.getList();

			await contractorsStore.all.getList();
		})();
	}, []);

	const handleOnShow = () => agreementsStore.drawer.show();

	return (
		<>
			<ContainerBody>
				<ContainerBodyPadding>
					<BlockTitle>
						<Typography.Title level={2}>Договора</Typography.Title>

						<Button.Group>
							<Button type={'primary'} icon={<PlusCircleOutlined />} onClick={handleOnShow}>
								Создать
							</Button>
						</Button.Group>
					</BlockTitle>

					<AgreementTable />
				</ContainerBodyPadding>
			</ContainerBody>

			<AgreementDrawer />
		</>
	);
});
