import { Card } from 'antd';
import styled from 'styled-components';

import { COLORS } from '../../../stylesheets';

export const WidgetExtra = styled(Card)`
	box-shadow: 0 5px 10px 2px rgba(0, 0, 0, 0.1);
	height: 100%;
	border-radius: 20px;

	.ant-card-head {
		background: ${COLORS.SILVER_LIGHT};
	}
`;

export const WidgetTitleIcon = styled.div`
	color: ${COLORS.GRAY_80};

	&:has(svg) {
		svg {
			color: ${COLORS.BLUE_100};
			margin-right: 8px;
		}
	}
`;
