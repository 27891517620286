import { action, computed, observable } from 'mobx';

import { StringNull, TriggerWorkflow } from '../../interfaces';
import { CrudStore } from '../common/crud.store';
import { ItemStore } from '../item';

export class TriggerWorkflowStore extends CrudStore<TriggerWorkflow> implements TriggerWorkflow {
	PATH = 'trigger-workflow';

	@observable item_id = 0;
	@observable condition = '';
	@observable title = '';
	@observable description = '';
	@observable relation_inside_uuid: StringNull = null;
	@observable relation_inside_uuid_no: StringNull = null;
	@observable is_done = false;
	@observable trigger_item = new ItemStore(null);

	constructor(initialData: TriggerWorkflow | null) {
		super();

		if (initialData) {
			this.fillStore(initialData);
		}
	}

	@computed
	get isConfirmModal() {
		return this.condition === 'go_to_task_with_notification';
	}

	@action
	fillStore(data: TriggerWorkflow) {
		const {
			id,
			item_id,
			condition,
			title,
			description,
			relation_inside_uuid,
			relation_inside_uuid_no,
			is_done,
			trigger_item,
		} = data;

		this.id = id;
		this.item_id = item_id;
		this.condition = condition;
		this.title = title;
		this.description = description;
		this.relation_inside_uuid = relation_inside_uuid;
		this.relation_inside_uuid_no = relation_inside_uuid_no;
		this.is_done = is_done;
		this.trigger_item = new ItemStore(trigger_item);
	}
}
