import { action, computed, makeObservable, observable } from 'mobx';

import { DialogMessageStore } from './dialog-message.store';
import { MethodsRequest } from '../../constants';
import { API } from '../../core';
import { ErrorAPI } from '../../helpers';
import { Dialog, Message } from '../../interfaces';
import { RootStore } from '../index';
import { UserStore } from '../users/user.store';

export class DialogStore implements Dialog {
	rootStore: RootStore;

	@observable id = 0;
	@observable creator_id = 0;
	@observable from_user_id = 0;
	@observable to_user_id = 0;
	@observable name = '';
	@observable from_user = new UserStore(null);
	@observable to_user = new UserStore(null);
	@observable members: Array<UserStore> = [];
	@observable messages: Array<DialogMessageStore> = [];

	constructor(initialData: Dialog | null, rootStore: RootStore) {
		makeObservable(this);

		this.rootStore = rootStore;

		if (initialData) {
			this.fillStore(initialData);
		}
	}

	@computed
	get toUserID() {
		return this.from_user_id !== this.rootStore.authStore.current.id ? this.from_user_id : this.to_user_id;
	}

	@computed
	get lastMessage() {
		return this.messages[this.messages.length - 1];
	}

	@action.bound
	async create(values: Pick<Dialog, 'creator_id' | 'from_user_id' | 'to_user_id'>) {
		try {
			const data = await API.request<Dialog>(`dialogs`, {
				method: MethodsRequest.Post,
				body: API.getFormData(values),
			});

			this.fillStore(data);
		} catch (e) {
			ErrorAPI('create', e);
		}
	}

	@action.bound
	async sendMessage(values: Pick<Message, 'message' | 'from_user_id' | 'to_user_id'>) {
		try {
			const messageStore = new DialogMessageStore(null);

			// Отправляем сообщение
			await messageStore.send({
				...values,
				dialog_id: this.id,
			});

			// Добавляем сообщение
			this.addMessage(messageStore);
		} catch (e) {
			ErrorAPI('sendMessage', e);
		}
	}

	@action.bound
	addMessage(value: DialogMessageStore) {
		this.messages.push(value);
	}

	@action.bound
	createAddMessage(value: Message) {
		this.addMessage(new DialogMessageStore(value));
	}

	@action
	fillStore(data: Dialog) {
		const { id, name, creator_id, from_user_id, to_user_id, from_user, to_user, members, messages } = data;

		this.id = id;
		this.creator_id = creator_id;
		this.from_user_id = from_user_id;
		this.to_user_id = to_user_id;
		this.name = name;
		this.from_user = new UserStore(from_user);
		this.to_user = new UserStore(to_user);
		this.members = (members || []).map((member) => new UserStore(member));
		this.messages = (messages || []).map((message) => new DialogMessageStore(message));
	}
}
