import styled from 'styled-components';

export const SalaryContainer = styled.div`
	display: grid;
	grid-template-columns: auto 1fr;
	column-gap: 15px;
	height: 92px;
`;

export const SalaryLeftSide = styled.div``;

export const SalaryRightSide = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
`;

export const SalarySum = styled.div`
	font-size: 35px;

	.salary {
		font-weight: 700;
	}

	span {
		font-weight: 400;
	}
`;
