export const BASE_RULE_FRAME_MOTION = {
	initial: {
		opacity: 0,
	},
	animate: {
		opacity: 1,
	},
	transition: {
		duration: 0.3,
	},
	exit: {
		opacity: 0,
	},
};
