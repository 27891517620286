import { action, computed, makeObservable, observable } from 'mobx';

import { TriggerStore } from './trigger.store';
import { API } from '../../core';
import { ErrorAPI } from '../../helpers';
import { Trigger } from '../../interfaces/triggers';
import { CoreResponse } from '../../responses';

export class TriggersStore {
	PATH = 'triggers';

	@observable list: TriggerStore[] = [];

	constructor() {
		makeObservable(this);
	}

	@action.bound
	async getList() {
		try {
			const { data } = await API.request<CoreResponse<Trigger[]>>(`${this.PATH}`);

			// Записываем
			this.setList(data);
		} catch (e) {
			ErrorAPI('getList', e);
		}
	}

	@action.bound
	setList(values: Trigger[]) {
		this.list = (values || []).map((trigger) => new TriggerStore(trigger));
	}

	@computed
	get selectBox() {
		return this.list.map((trigger) => ({
			label: trigger.name,
			value: trigger.id,
		}));
	}
}
