import { action, makeObservable, observable } from 'mobx';

import { ModalStore } from './modal.store';

export class DetailStore<T> {
	@observable modal = new ModalStore();
	@observable data: T;

	constructor(data: T) {
		makeObservable(this);
		this.data = data;
	}

	@action.bound
	setData(data: T) {
		this.data = data;
	}
}
