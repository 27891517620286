import { Layout } from 'antd';
import styled from 'styled-components';

import { COLORS } from '../../../../stylesheets';

export const SiderExtra = styled(Layout.Sider)`
	background: ${COLORS.White} !important;
	border-radius: 20px;
	box-shadow: 0 5px 10px 2px rgba(0, 0, 0, 0.1);
	padding: 20px 15px;

	> div {
		display: flex;
		flex-direction: column;
	}
`;

export const Logotype = styled.img`
	display: flex;
	width: 250px;
	height: 75px;
	margin: 0 auto;
	border-radius: 10px;
	cursor: pointer;
`;
