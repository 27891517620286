export enum ItemObjectTypes {
	DISTRIBUTION_NETWORKS = 1,
	MAIN_NETWORKS = 2,
}

export const ItemObjectTypesMapping = {
	[ItemObjectTypes.DISTRIBUTION_NETWORKS]: 'Распределительные сети',
	[ItemObjectTypes.MAIN_NETWORKS]: 'Основные сети',
};

export const ItemObjectTypesMappingShort = {
	[ItemObjectTypes.DISTRIBUTION_NETWORKS]: 'РС',
	[ItemObjectTypes.MAIN_NETWORKS]: 'ОС',
};
