import { Fragment } from 'react';

import { UserAddOutlined } from '@ant-design/icons';
import { Button, Col, Form, Row } from 'antd';
import { observer } from 'mobx-react-lite';

import { ContractorItem } from './ContractorItem';

export const Contractors = observer(() => {
	return (
		<>
			<Row gutter={15}>
				<Col span={24}>
					<Form.List name={'contractors'}>
						{(fields, { add, remove }) => {
							return (
								<Fragment key={'contractors_list'}>
									<Row>
										<Col span={8} offset={8}>
											<Form.Item>
												<Button type="dashed" onClick={() => add()} block icon={<UserAddOutlined />}>
													Добавить
												</Button>
											</Form.Item>
										</Col>
									</Row>

									{fields.map(({ key, name, ...restField }) => {
										return <ContractorItem key={key} keyOrder={name} name={name} remove={remove} {...restField} />;
									})}
								</Fragment>
							);
						}}
					</Form.List>
				</Col>
			</Row>
		</>
	);
});
