import { PlusOutlined } from '@ant-design/icons';
import { Button, Col, Form, Row } from 'antd';
import { observer } from 'mobx-react-lite';

import { TaskList } from './TaskList/TaskList';
import { useStores } from '../../../../../../stores';

export const TasksTab = observer(() => {
	const { objectsStore } = useStores();
	const { selected: currentObject } = objectsStore;
	const { volumes } = currentObject;
	const { selected: currentVolume } = volumes;
	const { tasks } = currentVolume;

	const handleOnShowTask = () => {
		tasks.modal.open();
	};

	return (
		<>
			<Row gutter={15}>
				<Col span={24}>
					<Form.Item>
						<Button type={'dashed'} icon={<PlusOutlined />} onClick={handleOnShowTask}>
							Добавить задачу
						</Button>
					</Form.Item>
				</Col>
				<Col span={24}>
					<Form.Item label={'Список задач'}>
						<TaskList />
					</Form.Item>
				</Col>
			</Row>
		</>
	);
});
