import dayjs, { Dayjs } from 'dayjs';
import { action, computed, makeObservable, observable } from 'mobx';

import { TaskI } from '../../interfaces';
import { CrudStore } from '../common/crud.store';
import { TaskFilesStore } from '../task-file/task-files.store';
import { UserDetailStore } from '../users/user-detail.store';

export class TaskStore extends CrudStore<TaskI> implements TaskI {
	PATH = 'tasks';

	@observable creator_id: number | undefined = undefined;
	@observable creator = new UserDetailStore(null);
	@observable responsible_id: number | undefined = undefined;
	@observable responsible = new UserDetailStore(null);
	@observable volume_id = 0;
	@observable title = '';
	@observable content = '';
	@observable start_date: Dayjs | undefined = undefined;
	@observable end_date: Dayjs | undefined = undefined;
	@observable confirmation_by_responsible = false;
	@observable confirmation_by_creator = false;
	@observable responsible_id_show = false;
	@observable confirmation_id_show = false;
	@observable responsible_answer = '';
	@observable is_delete = false;

	@observable files = new TaskFilesStore();

	constructor(initialData: TaskI | null) {
		super();
		makeObservable(this);

		if (initialData) {
			this.fillStore(initialData);
		}
	}

	@action.bound
	setVolumeId(value: number) {
		this.volume_id = value;
	}

	@computed
	get startDateFormatted() {
		if (this.start_date) return this.start_date.format('DD MMMM YYYY');

		return undefined;
	}

	@computed
	get endDateFormatted() {
		if (this.end_date) return this.end_date.format('DD MMMM YYYY');

		return undefined;
	}

	@action
	fillStore(data: TaskI) {
		const {
			id,
			creator_id,
			creator,
			responsible_id,
			responsible,
			volume_id,
			title,
			content,
			start_date,
			end_date,
			confirmation_by_responsible,
			confirmation_by_creator,
			responsible_id_show,
			confirmation_id_show,
			responsible_answer,
			is_delete,

			filesList,
		} = data;

		this.id = id;
		this.creator_id = creator_id;
		this.creator = new UserDetailStore(creator);
		this.responsible_id = responsible_id;
		this.responsible = new UserDetailStore(responsible);
		this.volume_id = volume_id;
		this.title = title;
		this.content = content;
		this.start_date = dayjs(start_date);
		this.end_date = dayjs(end_date);
		this.confirmation_by_responsible = confirmation_by_responsible;
		this.confirmation_by_creator = confirmation_by_creator;
		this.responsible_id_show = responsible_id_show;
		this.confirmation_id_show = confirmation_id_show;
		this.responsible_answer = responsible_answer;
		this.is_delete = is_delete;

		if (Array.isArray(filesList)) {
			this.files.setList(filesList);
		}
	}
}
