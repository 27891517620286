import { action, makeObservable, observable } from 'mobx';

import { RoleShort } from '../../interfaces/role/role-short.interface';

export class RoleShortStore implements RoleShort {
	@observable id = 0;
	@observable name = '';

	constructor(initialData: RoleShort | null) {
		makeObservable(this);

		if (initialData) {
			this.fillStore(initialData);
		}
	}

	@action
	fillStore(data: RoleShort) {
		const { id, name } = data;

		this.id = id;
		this.name = name;
	}
}
