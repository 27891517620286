import { ReactNode } from 'react';

import { CardProps } from 'antd';

import { WidgetExtra, WidgetTitleIcon } from './Widget.styled';

interface WidgetProps extends CardProps {
	children?: ReactNode;
}

export const Widget = ({ children = null, ...props }: WidgetProps) => {
	return <WidgetExtra {...props}>{children}</WidgetExtra>;
};

export { WidgetTitleIcon };
