import styled, { css } from 'styled-components';

import { COLORS, fontMixin } from '../../../../../../stylesheets';
import { AvatarExtra } from '../../../../../layouts/CabinetLayout/Sider/UserBlock/UserBlock.styled';

export const Container = styled.div`
	grid-row: 1/3;
`;

export const ScrollContainer = styled.div`
	height: calc(100vh - 320px);
	overflow-y: auto;
	background: ${COLORS.TABLE_TD};
	border-radius: 20px;
	padding: 25px;
`;

interface ItemProps {
	fromMe: boolean;
}

export const Item = styled.div<ItemProps>`
	display: grid;
	grid-template-columns: 1fr min-content;
	margin-left: 50px;
	align-items: flex-end;
	margin-bottom: 20px;

	${AvatarExtra} {
		cursor: default;
		margin-bottom: 0;

		&:active {
			transform: none;
		}
	}

	${(props) =>
		!props.fromMe &&
		css`
			grid-template-columns: min-content 1fr;
			margin-left: auto;
			margin-right: 50px;

			${AvatarExtra} {
				order: -1;
			}

			${Date} {
				text-align: left;
				margin-right: 0;
				margin-left: 30px;
			}

			${Text} {
				margin-right: auto;
				margin-left: 20px;
				background: ${COLORS.SILVER_40};
				color: ${COLORS.GRAY_80};

				&:before {
					position: absolute;
					content: '';
					height: 16px;
					border-bottom-left-radius: 0;
					border-bottom-right-radius: 0.8rem 0.7rem;
					border-right: 1rem solid ${COLORS.SILVER_40};
					right: auto;
					left: -0.35rem;
					transform: translate(0, -0.1rem);
					bottom: 0;
				}

				&:after {
					position: absolute;
					content: '';
					height: 16px;
					background-color: #fff;
					border-bottom-left-radius: 0;
					border-bottom-right-radius: 8px;
					right: auto;
					left: 20px;
					transform: translate(-30px, -2px);
					width: 10px;
					bottom: 0;
				}
			}
		`}
`;

export const Body = styled.div``;

export const Text = styled.div`
	position: relative;
	line-height: 20px;
	background: ${COLORS.BLUE_100};
	color: ${COLORS.White};
	border-radius: 20px;
	padding: 10px 15px;
	margin-right: 20px;
	white-space: break-spaces;

	&:before {
		position: absolute;
		content: '';
		height: 16px;
		border-bottom-left-radius: 0.8rem 0.7rem;
		border-right: 1rem solid ${COLORS.BLUE_100};
		right: -0.35rem;
		transform: translate(0, -0.1rem);
		bottom: 0;
	}

	&:after {
		position: absolute;
		content: '';
		height: 16px;
		background-color: #fff;
		border-bottom-left-radius: 8px;
		right: -40px;
		transform: translate(-30px, -2px);
		width: 10px;
		bottom: 0;
	}
`;

export const Date = styled.div`
	${fontMixin(13, 300)};
	text-align: right;
	margin-right: 30px;
	margin-bottom: 5px;
	color: ${COLORS.GRAY_60};
`;
