import { observer } from 'mobx-react-lite';

import { CreateChat } from './CreateChat/CreateChat';
import { Dialogs } from './Dialogs/Dialogs';
import { Container } from './LeftSide.styled';
import { SearchMessage } from './SearchMessage/SearchMessage';

export const LeftSide = observer(() => {
	return (
		<Container>
			<SearchMessage />
			<CreateChat />
			<Dialogs />
		</Container>
	);
});
