import styled from 'styled-components';

import { COLORS } from '../../../../../stylesheets';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1;
	border-right: 1px solid ${COLORS.SILVER_80};
	padding-right: 20px;
`;
