import React, { Suspense } from 'react';

import { observer } from 'mobx-react-lite';
import { Outlet } from 'react-router-dom';

import { Container, LayoutExtra } from './CabinetLayout.styled';
import { Content } from './Content/Content';
import { Sider } from './Sider/Sider';
import { BASE_RULE_FRAME_MOTION } from '../../../constants';
import { ItemDrawer, ItemViewDrawer, ProfileDrawer } from '../../ui';

export const CabinetLayout = observer(() => {
	//const [isConnect] = useGeolocation();

	/*useEffect(() => {
    navigator.geolocation.getCurrentPosition((position) => {
      console.log('position', position);
    });

    navigator.permissions.query({ name: 'geolocation' }).then((result) => {
      console.log(result.state);
    });
  }, []);*/

	//console.log('isConnect', isConnect);

	/*if (!isConnect) {
    return <TurnOnGeolocation />;
  }*/

	return (
		<>
			<Container {...BASE_RULE_FRAME_MOTION}>
				<LayoutExtra hasSider>
					<Sider />

					<Content>
						<Suspense fallback={<p>Loading</p>}>
							<Outlet />
						</Suspense>
					</Content>
				</LayoutExtra>
			</Container>

			<ProfileDrawer />
			<ItemDrawer />
			<ItemViewDrawer />
		</>
	);
});
