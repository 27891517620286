import { makeObservable } from 'mobx';

import { ObjectFileStore } from './object-file.store';
import { ObjectFileI } from '../../interfaces/object/object-file.interface';

export class ObjectTechTaskFileStore extends ObjectFileStore {
	PATH = 'object-tech-task-files';

	constructor(initialData: ObjectFileI) {
		super(initialData);
		makeObservable(this);
	}
}
