import { Form, Modal } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { observer } from 'mobx-react-lite';

import { FactoryItem } from './FactoryItem/FactoryItem';
import { ItemTypes } from '../../../../constants/item-types';
import { Item } from '../../../../interfaces/item';
import { useStores } from '../../../../stores';
import { ItemStore } from '../../../../stores/item/item.store';

interface ItemModalProps {
	itemStore: ItemStore;
}

export const ItemModal = observer(({ itemStore }: ItemModalProps) => {
	const { itemsStore } = useStores();

	const [form] = useForm();

	const getNameItem = () => {
		switch (itemStore.item_type_id) {
			case ItemTypes.object:
				return `объекта`;
			case ItemTypes.volume:
				return `тома`;
			case ItemTypes.tasks:
				return `задачи`;
			default:
				return `не определно`;
		}
	};

	const getTitle = () => {
		if (itemStore.isNewStore) {
			return `Создание ${getNameItem()}`;
		} else {
			return `Редактирование ${getNameItem()}`;
		}
	};

	const getSuccessButton = () => {
		return itemStore.isNewStore ? `Создать` : 'Обновить';
	};

	const getNameForm = () => {
		return itemStore.isNewStore ? `create` : 'update';
	};

	const handleOnSend = () => {
		form.submit();
	};

	const handleOnSave = async (values: Item) => {
		if (itemStore.isNewStore) {
			values.item_type_id = itemStore.item_type_id;
			await itemStore.create(values);
		}

		// Обновление таблицы
		await itemsStore.table.getList();
	};

	return (
		<Modal
			open={itemsStore.modal.isShow}
			title={getTitle()}
			onOk={handleOnSend}
			onCancel={itemsStore.modal.close}
			okText={getSuccessButton()}
			width={1000}
			styles={{
				body: {
					height: 700,
				},
			}}
			confirmLoading={itemStore.isLoading}
		>
			<Form form={form} name={getNameForm()} layout={'vertical'} autoComplete="off" onFinish={handleOnSave}>
				<FactoryItem form={form} itemStore={itemStore} />
			</Form>
		</Modal>
	);
});
