import { DeleteOutlined } from '@ant-design/icons';
import { Button, Col, Form, FormInstance, Row, Select } from 'antd';
import { useWatch } from 'antd/es/form/Form';

import { TriggerGoToTask } from './TriggerSelectItems/TriggerGoToTask';
import { TriggerGoToTaskWithNotification } from './TriggerSelectItems/TriggerGoToTaskWithNotification';
import { TriggerSelectItems } from '../../../../../../../../../constants';

interface TriggerItemProps {
	form: FormInstance;
	volumeIndex: number;
	taskFieldNumber: number;
	triggerItemNumber: number;
	onDeleteTriggerItem: (id: number) => void;
}

export const TriggerItem = ({
	form,
	volumeIndex,
	taskFieldNumber,
	triggerItemNumber,
	onDeleteTriggerItem,
}: TriggerItemProps) => {
	// Информация по полям текущей задачи
	const currentTask = useWatch(['item_volume_generates', volumeIndex, 'item_volume_tasks', taskFieldNumber], form);

	const conditionField = useWatch(
		[
			'item_volume_generates',
			volumeIndex,
			'item_volume_tasks',
			taskFieldNumber,
			'triggers',
			triggerItemNumber,
			'condition',
		],
		form,
	);

	const listTasks = useWatch(['item_volume_generates', volumeIndex, 'item_volume_tasks'], form) || [];
	const listVolumes = useWatch(['item_volume_generates'], form) || [];

	return (
		<>
			<Row>
				<Col span={24} style={{ display: 'flex', justifyContent: 'space-between', columnGap: 25 }}>
					<div style={{ flex: 1 }}>
						<Form.Item name={[triggerItemNumber, 'condition']}>
							<Select placeholder={'Условие'} style={{ width: '100%' }} allowClear>
								<Select.Option value={TriggerSelectItems.GoToTaskWithNotification}>
									Показать уведомление и направить на задачу
								</Select.Option>
								<Select.Option value={TriggerSelectItems.GoToTask}>Направить на задачу</Select.Option>
							</Select>
						</Form.Item>
					</div>

					<div>
						<Button
							danger
							type="primary"
							icon={<DeleteOutlined />}
							onClick={() => onDeleteTriggerItem(triggerItemNumber)}
						/>
					</div>
				</Col>
			</Row>

			{conditionField && (
				<>
					<TriggerGoToTaskWithNotification
						conditionField={conditionField}
						triggerItemNumber={triggerItemNumber}
						listVolumes={listVolumes}
						currentTask={currentTask}
					/>

					<TriggerGoToTask
						conditionField={conditionField}
						triggerItemNumber={triggerItemNumber}
						listVolumes={listVolumes}
						currentTask={currentTask}
					/>
				</>
			)}
		</>
	);
};
