import { ChangeEvent, useState } from 'react';

import { Button, Input, List, Space } from 'antd';
import { observer } from 'mobx-react-lite';

import { NoteItem } from './NoteItem';
import { ObjectNoteI } from '../../../../../interfaces';
import { useStores } from '../../../../../stores';
import { ObjectNoteStore } from '../../../../../stores/object-note/object-note.store';

export const Notes = observer(() => {
	const { objectsStore } = useStores();
	const { selected: currentObject } = objectsStore;

	const [content, setContent] = useState('');
	const [loading, setLoading] = useState(false);

	const handleOnSetContent = (e: ChangeEvent<HTMLTextAreaElement>) => {
		const { value } = e.currentTarget;
		setContent(() => value);
	};

	const handleOnSave = async () => {
		setLoading(true);
		const noteStore = new ObjectNoteStore(null);
		try {
			// Создаем заметку
			await noteStore.create({
				object_id: currentObject.id,
				content,
			} as ObjectNoteI);

			// Добавляем созданную заметку
			currentObject.notes.addStart(noteStore);

			// Очищаем поле ввода
			setContent('');
		} catch {
			/* empty */
		} finally {
			setLoading(false);
		}
	};

	return (
		<>
			<Space.Compact block>
				<Input.TextArea placeholder={'Введите сообщение'} autoSize onChange={handleOnSetContent} value={content} />
				<Button style={{ marginLeft: 15 }} type={'primary'} onClick={handleOnSave} loading={loading}>
					Добавить
				</Button>
			</Space.Compact>

			<List
				pagination={{
					position: 'top',
					pageSize: 4,
					style: {
						textAlign: 'center',
					},
				}}
				dataSource={currentObject.notes.list}
				renderItem={(note) => {
					return <NoteItem key={note.id} note={note} />;
				}}
			/>
		</>
	);
});
