import { makeObservable } from 'mobx';

import { User } from '../../interfaces';
import { UserStore } from '../users/user.store';

export class ResponsibleStore extends UserStore implements User {
	constructor(initialData: User | null) {
		super(initialData);
		makeObservable(this);
	}
}
