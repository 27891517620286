import { action, computed, makeObservable, observable } from 'mobx';

import { getFirstChar } from '../../helpers';
import { UserOnlineItem } from '../../interfaces';

export class UserOnlineItemStore implements UserOnlineItem {
	@observable id = 0;
	@observable email = '';
	@observable role_id = 0;
	@observable first_name = '';
	@observable last_name = '';
	@observable patronymic = '';
	@observable avatar = '';

	constructor(initialData: UserOnlineItem | null) {
		makeObservable(this);

		if (initialData) {
			this.fillStore(initialData);
		}
	}

	@computed
	get shortFullName() {
		const name = getFirstChar(this.first_name || '');
		const patronymic = getFirstChar(this.patronymic || '');
		const complete = `${this.last_name || ''}${name ? ' ' : ''}${name}${patronymic ? '.' : ''}${patronymic}.`;
		return complete || this.email;
	}

	@computed
	get avatarFullPath(): string {
		let complete = 'avatars/default.png';

		if (this.avatar !== 'avatars/default.png') {
			complete = `${process.env.REACT_APP_CORE_FILE_URL}${this.avatar}`;
		}

		return complete;
	}

	@action
	fillStore(data: UserOnlineItem) {
		const { id, email, role_id, first_name, last_name, patronymic, avatar } = data;

		this.id = id;
		this.email = email;
		this.role_id = role_id;
		this.first_name = first_name;
		this.last_name = last_name;
		this.patronymic = patronymic;
		this.avatar = avatar;
	}
}
