import { useRef, useState } from 'react';

import { UploadOutlined } from '@ant-design/icons';
import { Button, Form, FormInstance, Popover, Space, Tag, Upload } from 'antd';
import { RcFile } from 'antd/es/upload/interface';
import { UploadRef } from 'antd/es/upload/Upload';

import { Container, CountUploadFile, Icon, UploadText } from './UploadFilesBlock.styled';
import { makeLongString, numWords } from '../../../helpers';

interface UploadFilesBlockProps {
	form: FormInstance;
	label: string;
	nameList: string | Array<string | number>;
	height?: number;
}

export const UploadFilesBlock = ({ label, nameList, height = 200 }: UploadFilesBlockProps) => {
	const [files, setFiles] = useState<RcFile[]>([]);
	const [keyUpload, setKeyUpload] = useState('');

	const uploadContainerRef = useRef<UploadRef>(null);
	const containerInformationRef = useRef<HTMLDivElement>(null);

	const getFile = () => {
		return files;
	};

	const handleOnDragOver = () => {
		containerInformationRef?.current?.classList.add('drag-over');
	};

	const handleOnDragLeave = () => {
		containerInformationRef?.current?.classList.remove('drag-over');
	};

	const handleOnChangeUpload = (file: RcFile): boolean => {
		setFiles((prevState) => {
			const list: RcFile[] = [...prevState];

			list.push(file);

			return [...list];
		});

		return false;
	};

	const handleOnClearFiles = () => setFiles([]);

	const handleOnDeleteFile = (file: RcFile) => {
		setFiles((prevState) => {
			const list = [...prevState];
			const findIndex = list.findIndex((itemFile) => itemFile.uid === file.uid);

			if (findIndex !== -1) {
				// Удаляем из списка загрузки
				if (uploadContainerRef.current) {
					uploadContainerRef.current.fileList.splice(findIndex, 1);
				}

				list.splice(findIndex, 1);
			}

			return [...list];
		});
	};

	const getListFiles = () => {
		return (
			<Space direction={'vertical'} size={[0, 10]}>
				{files.map((file) => (
					<Tag key={file.uid} closable onClose={() => handleOnDeleteFile(file)}>
						{file.name}
					</Tag>
				))}
			</Space>
		);
	};

	const handleOnCloseList = (open: boolean) => {
		if (!open) {
			setKeyUpload(makeLongString(10));
		}
	};

	return (
		<Form.Item
			name={nameList}
			label={label}
			valuePropName="fileList"
			getValueFromEvent={getFile}
			onReset={handleOnClearFiles}
		>
			<Upload
				ref={uploadContainerRef}
				key={keyUpload}
				beforeUpload={handleOnChangeUpload}
				multiple
				showUploadList={false}
				onRemove={(file) => console.log(file)}
			>
				<Container
					style={{ height }}
					ref={containerInformationRef}
					onDragOver={handleOnDragOver}
					onDragLeave={handleOnDragLeave}
					onDrop={handleOnDragLeave}
				>
					<Icon>
						<UploadOutlined />
					</Icon>

					{files.length === 0 && (
						<UploadText>
							Нажмите или перенесите файл
							<br /> для загрузки
						</UploadText>
					)}

					<CountUploadFile>
						{files.length} {numWords(files.length, ['файл', 'файла', 'файлов'])}
					</CountUploadFile>

					{files.length > 0 && (
						<>
							<Popover trigger={['click']} content={getListFiles()} onOpenChange={handleOnCloseList}>
								<Button type={'dashed'} onClick={(e) => e.stopPropagation()}>
									Просмотреть список
								</Button>
							</Popover>
						</>
					)}
				</Container>
			</Upload>
		</Form.Item>
	);
};
