import { useRef } from 'react';

import { Checkbox, DatePicker, Form, FormInstance, Input, InputNumber, Select, Tooltip, Typography } from 'antd';
import { RangePickerProps } from 'antd/es/date-picker';
import { useWatch } from 'antd/es/form/Form';
import { ColumnsType } from 'antd/es/table';
import dayjs, { Dayjs } from 'dayjs';

import { DATE_FORMAT, Forms } from '../../../../../../constants';
import { useStores } from '../../../../../../stores';
import { ItemVolumeGenerateStore } from '../../../../../../stores/item-volume-generate';
import { ItemVolumeTaskStore } from '../../../../../../stores/item-volume-task';

export const useColumns = (
	itemVolumeGenerate: ItemVolumeGenerateStore,
	volumeIndex: number,
	form: FormInstance,
): ColumnsType<ItemVolumeTaskStore> => {
	const { employeesStore } = useStores();

	// Слушаем изменение тома
	const volumeData = useWatch([Forms.ProjectGenerate.BaseForm, volumeIndex]) || [];

	// Предыдущее значение исполнителя тома
	const prevResponsibleId = useRef({
		responsible_id: 0,
	});

	// Получаем конечную дату для задач
	let startDateVolume: null | Dayjs = null;
	let endDateVolume: null | Dayjs = null;

	if (volumeData && volumeData.volume_dates) {
		startDateVolume = volumeData.volume_dates[0];
		endDateVolume = volumeData.volume_dates[1];
	}

	// Блокирубщие даты
	const disabledDate: RangePickerProps['disabledDate'] = (current) => {
		if (startDateVolume && endDateVolume) {
			return startDateVolume.isAfter(current) || endDateVolume.isBefore(current);
		}

		return false;
	};

	// Связи
	const tasks = useWatch([Forms.ProjectGenerate.BaseForm, volumeIndex, Forms.ProjectGenerate.Tasks]) || [];

	// Если в томе изменили ответсвенного то меняем его во всех задачах
	if (
		volumeData.responsible_id &&
		prevResponsibleId.current.responsible_id !== volumeData.responsible_id &&
		Array.isArray(volumeData.tasks)
	) {
		// Устанавливаем исполнителя
		prevResponsibleId.current.responsible_id = volumeData.responsible_id;

		// Проходим по всем задачам
		volumeData.tasks.forEach((_: any, key: number) => {
			form.setFieldValue(
				[Forms.ProjectGenerate.BaseForm, volumeIndex, Forms.ProjectGenerate.Tasks, key, 'creator_id'],
				volumeData.responsible_id,
			);
		});
	}

	return [
		{
			title: 'Завершающая',
			dataIndex: 'is_finish',
			align: 'center',
			render: (value, _, index) => {
				return (
					<Form.Item
						name={[
							Forms.ProjectGenerate.BaseForm,
							volumeIndex,
							Forms.ProjectGenerate.Tasks,
							index,
							Forms.ProjectGenerate.Fields.IsFinish,
						]}
						valuePropName={'checked'}
					>
						<Checkbox />
					</Form.Item>
				);
			},
		},
		{
			title: 'Название задачи',
			dataIndex: 'title',
			ellipsis: true,
			align: 'center',
			render: (_, record, index) => {
				return (
					<>
						{/** Дополнительно скрытые поля */}
						<Form.Item
							name={[
								Forms.ProjectGenerate.BaseForm,
								volumeIndex,
								Forms.ProjectGenerate.Tasks,
								index,
								Forms.ProjectGenerate.Fields.InsideUUID,
							]}
							hidden
						>
							<Input />
						</Form.Item>

						<Form.Item
							name={[
								Forms.ProjectGenerate.BaseForm,
								volumeIndex,
								Forms.ProjectGenerate.Tasks,
								index,
								Forms.ProjectGenerate.Fields.Title,
							]}
							hidden
						>
							<Input />
						</Form.Item>

						<Tooltip title={record.title} placement={'bottomLeft'}>
							{record.title}
						</Tooltip>
					</>
				);
			},
		},
		{
			title: 'Описание задачи',
			dataIndex: 'description',
			ellipsis: true,
			align: 'center',
			render: (_, record, index) => {
				return (
					<>
						<Form.Item
							name={[
								Forms.ProjectGenerate.BaseForm,
								volumeIndex,
								Forms.ProjectGenerate.Tasks,
								index,
								Forms.ProjectGenerate.Fields.Description,
							]}
							hidden
						>
							<Input />
						</Form.Item>

						<Tooltip title={record.description} placement={'bottomLeft'}>
							{record.description}
						</Tooltip>
					</>
				);
			},
		},
		{
			title: <>Количество дней</>,
			align: 'center',
			width: 200,
			render: (_, record, index) => {
				// Вычисляем максимальное кол-во ддней
				let countMaxDays = 0;

				// Проверяем указана ли дата старта
				const startDateTask: Dayjs | null = tasks[index]?.start_date || null;

				if (startDateTask && startDateTask instanceof dayjs) {
					if (startDateTask && endDateVolume) {
						countMaxDays = endDateVolume.diff(startDateTask, 'day');
					}
				} else if (startDateVolume && endDateVolume) {
					countMaxDays = endDateVolume.diff(startDateVolume, 'day');
				}

				return (
					<>
						<Tooltip title={`Максимальное количество дней : ${countMaxDays}`}>
							<Form.Item
								name={[
									Forms.ProjectGenerate.BaseForm,
									volumeIndex,
									Forms.ProjectGenerate.Tasks,
									index,
									Forms.ProjectGenerate.Fields.CountDay,
								]}
							>
								<InputNumber min={1} max={countMaxDays} defaultValue={1} width={50} disabled={!startDateVolume} />
							</Form.Item>
						</Tooltip>
					</>
				);
			},
		},
		{
			title: 'Дата начала',
			align: 'center',
			width: 200,
			render: (_, record, index) => {
				return (
					<>
						<Form.Item
							name={[
								Forms.ProjectGenerate.BaseForm,
								volumeIndex,
								Forms.ProjectGenerate.Tasks,
								index,
								Forms.ProjectGenerate.Fields.StartDate,
							]}
						>
							<DatePicker format={DATE_FORMAT.FULL} disabledDate={disabledDate} disabled={!startDateVolume} />
						</Form.Item>
					</>
				);
			},
		},
		{
			title: 'Итоговая дата',
			align: 'center',
			width: 200,
			render: (_, record, index) => {
				// Проверяем указана ли дата старта
				const startDateTask: Dayjs | null = tasks[index]?.start_date || null;
				const countDayTask: number = tasks[index]?.count_day || 0;

				if (startDateTask && startDateTask instanceof dayjs) {
					return <div>{startDateTask.clone().add(countDayTask, 'day').format(DATE_FORMAT.FULL)}</div>;
				}

				const countDay = tasks[index]?.count_day || 0;

				return (
					<>
						<Typography.Text>
							{startDateVolume ? startDateVolume.clone().add(countDay, 'day').format(DATE_FORMAT.FULL) : '-'}
						</Typography.Text>
					</>
				);
			},
		},
		{
			title: 'Ответсвенный',
			align: 'center',
			width: 200,
			render: (_, record, index) => {
				return (
					<>
						<Form.Item
							name={[
								Forms.ProjectGenerate.BaseForm,
								volumeIndex,
								Forms.ProjectGenerate.Tasks,
								index,
								Forms.ProjectGenerate.Fields.CreatorId,
							]}
							rules={[{ required: true, message: 'Выберите ответсвенного' }]}
						>
							<Select
								style={{ width: 180 }}
								placeholder={'Выберите ответсвенного'}
								showSearch
								filterOption={(inputValue, option) => {
									return String(option?.children ?? '')
										.toLowerCase()
										.includes(inputValue.toLowerCase());
								}}
							>
								{employeesStore.all.list
									.filter((employee) => employee.role.id === Number(itemVolumeGenerate.payload.role_id))
									.map((resultItem) => {
										return (
											<Select.Option key={resultItem.id} value={resultItem.id}>
												{resultItem.shortFullName} ({resultItem.roleName})
											</Select.Option>
										);
									})}
							</Select>
						</Form.Item>
					</>
				);
			},
		},
		{
			title: 'Исполнитель',
			align: 'center',
			width: 200,
			render: (_, itemVolumeTaskStore, index) => {
				return (
					<>
						<Form.Item
							name={[
								Forms.ProjectGenerate.BaseForm,
								volumeIndex,
								Forms.ProjectGenerate.Tasks,
								index,
								Forms.ProjectGenerate.Fields.ResponsibleId,
							]}
							rules={[{ required: true, message: 'Выберите исполнителя' }]}
						>
							<Select
								style={{ width: 180 }}
								placeholder={'Выберите исполнителя'}
								showSearch
								filterOption={(inputValue, option) => {
									return String(option?.children ?? '')
										.toLowerCase()
										.includes(inputValue.toLowerCase());
								}}
								onChange={(value) => itemVolumeTaskStore.setResponsibleId(value)}
							>
								{employeesStore.all.list
									.filter((employee) => employee.role.id === itemVolumeTaskStore.role_id)
									.map((resultItem) => {
										return (
											<Select.Option key={resultItem.id} value={resultItem.id}>
												{resultItem.shortFullName} ({resultItem.roleName})
											</Select.Option>
										);
									})}
							</Select>
						</Form.Item>

						<Form.List
							name={[
								Forms.ProjectGenerate.BaseForm,
								volumeIndex,
								Forms.ProjectGenerate.Tasks,
								index,
								Forms.ProjectGenerate.Fields.Triggers,
							]}
						>
							{(fields, operation, meta) => {
								return (
									<div>
										{fields.map((field, index) => {
											return (
												<>
													<Form.Item name={[field.name, 'condition']} hidden>
														<Input />
													</Form.Item>
													<Form.Item name={[field.name, 'title']} hidden>
														<Input />
													</Form.Item>
													<Form.Item name={[field.name, 'description']} hidden>
														<Input />
													</Form.Item>
													<Form.Item name={[field.name, 'relation_inside_uuid']} hidden>
														<Input />
													</Form.Item>
													<Form.Item name={[field.name, 'relation_inside_uuid_no']} hidden>
														<Input />
													</Form.Item>
												</>
											);
										})}
									</div>
								);
							}}
						</Form.List>
					</>
				);
			},
		},
	];
};
