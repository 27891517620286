import React, { HTMLAttributes } from 'react';

import { MenuOutlined } from '@ant-design/icons';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

interface RowProps extends HTMLAttributes<HTMLTableRowElement> {
	'data-row-key': string;
}

export const ItemTaskRowTable = (props: RowProps) => {
	const { attributes, listeners, setActivatorNodeRef, setNodeRef, transform, transition, isDragging } = useSortable({
		id: props['data-row-key'],
	});

	const style: React.CSSProperties = {
		...props.style,
		transform: CSS.Transform.toString(transform && { ...transform, scaleY: 1 }),
		transition,
		...(isDragging ? { position: 'relative', zIndex: 9999 } : {}),
	};

	return (
		<tr {...props} ref={setNodeRef} style={style} {...attributes}>
			{React.Children.map(props.children, (child) => {
				if ((child as React.ReactElement).key === 'sort-dnd') {
					return React.cloneElement(child as React.ReactElement, {
						children: (
							<MenuOutlined ref={setActivatorNodeRef} style={{ touchAction: 'none', cursor: 'move' }} {...listeners} />
						),
					});
				}
				return child;
			})}
		</tr>
	);
};
