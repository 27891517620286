import styled from 'styled-components';

import { COLORS } from '../../../../../stylesheets';

export const ContainerUpload = styled.div`
	cursor: pointer;
	width: 100%;
	height: 100px;
	border: 1px dashed #d9d9d9;
	box-shadow: 0 2px 0 rgba(0, 0, 0, 0.02);
	border-radius: 6px;
	display: flex;
	column-gap: 15px;
	align-items: center;
	justify-content: center;
	transition: all 0.2s;
	padding-inline: 20px;
	user-select: none;

	&:hover {
		border-color: ${COLORS.BLUE_80};
		color: ${COLORS.BLUE_80};
	}
`;

export const ContainerInputFile = styled.input`
	display: none;
`;
