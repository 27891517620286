import { action, computed, makeObservable, observable } from 'mobx';

import { EmployeeStore } from './employee.store';
import { User } from '../../interfaces';
import { AllStore } from '../common/all.store';
import { DrawerStore } from '../common/drawer.store';
import { TableStore } from '../common/table.store';

export class EmployeesStore {
	@observable drawer = new DrawerStore();
	@observable table = new TableStore({
		url: 'employees',
		getListItemStore: this.getListItemStore,
		getPayloadParams: this.getPayloadParams,
	});
	@observable all = new AllStore({
		url: 'employees',
		getListItemStore: this.getListItemStore,
	});
	@observable selected = new EmployeeStore(null);

	constructor() {
		makeObservable(this);
	}

	@action.bound
	private getListItemStore(value: User): EmployeeStore {
		return new EmployeeStore(value);
	}

	@action.bound
	setSelected(value: EmployeeStore) {
		this.selected = value;
	}

	@action.bound
	clearSelected() {
		this.selected = new EmployeeStore(null);
	}

	@action.bound
	private getPayloadParams() {
		return {};
	}

	selectBoxChat(userID: number) {
		return computed(() => {
			const prepareData: { [key: string]: Array<EmployeeStore> } = {};

			this.all.list.forEach((user) => {
				if (userID === user.id) {
					return false;
				}

				if (!prepareData[user.roleName]) {
					prepareData[user.roleName] = [];
				}

				prepareData[user.roleName].push(user);
			});

			return Object.entries(prepareData).map((item) => {
				const [key, values] = item;

				return {
					label: key,
					options: values.map((employee) => ({
						label: employee.shortFullName,
						value: employee.id,
					})),
				};
			});
		}).get();
	}
}
