import { Spin, Table } from 'antd';
import { observer } from 'mobx-react-lite';

import { useProjectDocumentationTableColumns } from './ProjectDocumentationTable.columns';
import { useStores } from '../../../../../stores';

export const ProjectDocumentationTable = observer(() => {
	const { itemProjectDocumentsStore } = useStores();

	return (
		<>
			<Spin spinning={itemProjectDocumentsStore.isClone} tip={'Клонирование проектной документации'}>
				<Table
					rowKey={'id'}
					columns={useProjectDocumentationTableColumns()}
					dataSource={itemProjectDocumentsStore.table.list}
					onRow={(row) => {
						return {
							onDoubleClick: async () => {
								// Открывае модальное окно
								await itemProjectDocumentsStore.detail.modal.open();

								// Устанавливаем ID
								itemProjectDocumentsStore.detail.data.setId(row.id);

								// Загружаем данные
								await itemProjectDocumentsStore.detail.data.get();
							},
						};
					}}
				/>
			</Spin>
		</>
	);
});
