import styled from 'styled-components';

import { COLORS } from '../../../../stylesheets';

export const Container = styled.div`
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%) translateZ(0) scale(1, 1);
	width: 600px;
	height: 350px;
	background: ${COLORS.White};
	border: 1px solid ${COLORS.BLUE_100};
	border-radius: 20px;
	box-shadow: 0 0 10px 5px rgba(34, 60, 80, 0.04);
	padding: 25px;
`;

export const Loading = styled.div`
	display: flex;
	flex-direction: column;
	row-gap: 20px;
	width: 100%;
	height: 100%;
	align-items: center;
	justify-content: center;
	color: ${COLORS.GRAY_80};
`;
