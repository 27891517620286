import { action, makeObservable, observable } from 'mobx';

import { API } from '../../core';
import { AllI, ListParamsI, PayloadParamsI } from '../../interfaces';
import { CoreResponse } from '../../responses';

export class AllStore<T, C> {
	@observable isLoading = false;
	@observable list: C[] = [];
	@observable filters: PayloadParamsI = {};

	public url: string;

	// eslint-disable-next-line no-unused-vars
	public getListItemStore: (data: T) => C;

	public getPayloadParams: () => PayloadParamsI;

	constructor({ getListItemStore, getPayloadParams = () => ({}), url }: AllI<T, C>) {
		makeObservable(this);

		this.url = url;
		this.getListItemStore = getListItemStore;
		this.getPayloadParams = getPayloadParams;
	}

	@action.bound
	setIsLoading(value: boolean) {
		this.isLoading = value;
	}

	@action.bound
	setList(data: T[]) {
		this.list = data.map(this.getListItemStore);
	}

	@action.bound
	async getList(query_search = '') {
		this.setIsLoading(true);

		const dataParams = this.getPayloadParams();
		const filtersParams = this.filters;
		let completeQueryParams = '';

		if (dataParams) {
			completeQueryParams =
				`&` +
				Object.keys(dataParams)
					.map((key) => key + '=' + dataParams[key])
					.join('&');
		}

		try {
			if (filtersParams) {
				completeQueryParams +=
					`&` +
					Object.keys(filtersParams)
						.map((key) => {
							const item = filtersParams[key];

							// Если фильтр массив
							if (Array.isArray(item)) {
								return item
									.map((item) => {
										return `${key}[]=${item}`;
									})
									.join('&');
							} else {
								return `${key}=${filtersParams[key]}`;
							}
						})
						.join('&');
			}

			if (query_search.length > 0) {
				completeQueryParams += `&query_search=${query_search}`;
			}

			try {
				const { data } = await API.request<CoreResponse<T[]>>(`${this.url}/all?${completeQueryParams}`);
				this.setList(data);
			} catch (e) {
				console.error(`Error in method AllStore.getAll : `, e);
			} finally {
				this.setIsLoading(false);
			}
		} catch {
			/* empty */
		}
	}

	@action.bound
	addFilter(name: string, value: string | number | Array<number | string> = '') {
		if (!value && value !== 0) {
			delete this.filters[name];
			return;
		}

		this.filters[name] = value;
	}

	@action.bound
	addFilterArray(name: string, value: Array<unknown>) {
		this.filters[name] = value;
	}

	@action.bound
	deleteFilter(name: string) {
		delete this.filters[name];
	}

	@action.bound
	clearFilter() {
		this.filters = {};
	}
}
