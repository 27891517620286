import dayjs from 'dayjs';
import { action, makeObservable, observable } from 'mobx';

import { MethodsRequest } from '../../constants';
import { API } from '../../core';
import { ItemTaskRequest } from '../../interfaces/item';
import { UserShortStore } from '../users';

export class ItemTaskRequestStore implements ItemTaskRequest {
	@observable id = 0;
	@observable item_id = 0;
	@observable responsible_id = 0;
	@observable created_at = dayjs();
	@observable updated_at = dayjs();
	@observable responsible = new UserShortStore(null);

	constructor(initialData: ItemTaskRequest | null) {
		makeObservable(this);

		if (initialData) {
			this.fillStore(initialData);
		}
	}

	@action.bound
	setItemId(value: number) {
		this.item_id = value;
	}

	@action.bound
	async send() {
		try {
			await API.request(`item-task-requests`, {
				method: MethodsRequest.Post,
				body: API.getFormData({
					item_id: this.item_id,
				}),
			});
		} catch (e) {
			console.error(`Error in method send : `, e);
		}
	}

	@action
	fillStore(data: ItemTaskRequest) {
		const { id, item_id, responsible_id, created_at, updated_at, responsible } = data;

		this.id = id;
		this.item_id = item_id;
		this.responsible_id = responsible_id;
		this.created_at = dayjs(created_at);
		this.updated_at = dayjs(updated_at);
		this.responsible = new UserShortStore(responsible);
	}
}
