import dayjs from 'dayjs';
import { action, observable } from 'mobx';

import { ItemContractorFileStore } from './item-contractor-file.store';
import { ItemContractor } from '../../interfaces/item';
import { CrudStore } from '../common/crud.store';
import { ContractorStore } from '../contractors/contractor.store';

export class ItemContractorStore extends CrudStore<ItemContractor> implements ItemContractor {
	@observable item_id = 0;
	@observable contractor_id = 0;
	@observable subject_contract = '';
	@observable created_at = dayjs();
	@observable updated_at = dayjs();
	@observable files: Array<ItemContractorFileStore> = [];
	@observable contractor = new ContractorStore(null);

	constructor(initialData: ItemContractor | null) {
		super();
		if (initialData) {
			this.fillStore(initialData);
		}
	}

	@action
	fillStore(data: ItemContractor) {
		const { id, item_id, contractor_id, subject_contract, created_at, updated_at, files, contractor } = data;

		this.id = id;
		this.item_id = item_id;
		this.contractor_id = contractor_id;
		this.subject_contract = subject_contract;
		this.created_at = dayjs(created_at);
		this.updated_at = dayjs(updated_at);
		this.files = (files || []).map((file) => new ItemContractorFileStore(file));
		this.contractor = new ContractorStore(contractor);
	}
}
