import { Col, Form, Input, Row, Select } from 'antd';
import { observer } from 'mobx-react-lite';

import { TriggerSelectItems } from '../../../../../../../../../constants';
import { TaskList } from '../../../../../../../../../interfaces';
import { useStores } from '../../../../../../../../../stores';

interface TriggerGoToTaskWithNotificationActionProps {
	conditionField: string;
	triggerItemNumber: number;
}

export const TriggerGoToTaskWithNotificationAction = observer(
	({ conditionField, triggerItemNumber }: TriggerGoToTaskWithNotificationActionProps) => {
		const { taskListStore, itemsStore } = useStores();
		const { selected } = itemsStore;

		if (conditionField !== TriggerSelectItems.GoToTaskWithNotification) {
			return null;
		}

		return (
			<Row gutter={15}>
				<Col span={24}>
					<Form.Item label={'Заголовок'} name={[triggerItemNumber, 'title']}>
						<Input placeholder={'Введите заголовок'} />
					</Form.Item>
				</Col>

				<Col span={24}>
					<Form.Item label={'Текст сообщения'} name={[triggerItemNumber, 'description']}>
						<Input.TextArea autoSize={{ minRows: 3 }} placeholder={'Введите текст сообщения'} />
					</Form.Item>
				</Col>

				<Col span={12}>
					<Form.Item label={'Ответ Да - Связать с задачей'} name={[triggerItemNumber, 'relation_inside_uuid']}>
						<Select
							showSearch
							filterOption={(inputValue, option) => {
								return String(option?.children ?? '')
									.toLowerCase()
									.includes(inputValue.toLowerCase());
							}}
						>
							{taskListStore.list.map((taskItem: TaskList) => {
								if (selected.id === taskItem.id) {
									return null;
								}

								return (
									<Select.Option key={taskItem?.inside_uuid} value={taskItem?.inside_uuid}>
										{taskItem?.title || taskItem?.inside_uuid}
									</Select.Option>
								);
							})}
						</Select>
					</Form.Item>
				</Col>

				<Col span={12}>
					<Form.Item label={'Ответ Нет - Связать с задачей'} name={[triggerItemNumber, 'relation_inside_uuid_no']}>
						<Select
							showSearch
							filterOption={(inputValue, option) => {
								return String(option?.children ?? '')
									.toLowerCase()
									.includes(inputValue.toLowerCase());
							}}
						>
							{taskListStore.list.map((taskItem: TaskList) => {
								if (selected.id === taskItem.id) {
									return null;
								}

								return (
									<Select.Option key={taskItem?.inside_uuid} value={taskItem?.inside_uuid}>
										{taskItem?.title || taskItem?.inside_uuid}
									</Select.Option>
								);
							})}
						</Select>
					</Form.Item>
				</Col>
			</Row>
		);
	},
);
