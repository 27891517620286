import { observer } from 'mobx-react-lite';

import { DialogEmpty } from './DialogEmpty';
import { DialogItem } from './DialogItem';
import { Container } from './Dialogs.styled';
import { useStores } from '../../../../../../stores';

export const Dialogs = observer(() => {
	const { dialogsStore } = useStores();

	return (
		<Container>
			{dialogsStore.empty && <DialogEmpty />}

			{dialogsStore.list.map((dialog) => {
				return <DialogItem key={dialog.id} dialog={dialog} />;
			})}
		</Container>
	);
});
