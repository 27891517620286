import { Fragment, useEffect, useState } from 'react';

import { DeleteOutlined, MinusCircleOutlined, PhoneOutlined, SaveOutlined } from '@ant-design/icons';
import { Button, Checkbox, Col, DatePicker, Divider, Drawer, Form, Input, Popconfirm, Row, Select, Space } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { MaskedInput } from 'antd-mask-input';
import dayjs from 'dayjs';
import { observer } from 'mobx-react-lite';

import { checkDateIsValid } from '../../../../helpers';
import { User } from '../../../../interfaces';
import { useStores } from '../../../../stores';

export const EmployeesDrawer = observer(() => {
	const { employeesStore, rolesStore } = useStores();
	const { selected: selectedEmployees } = employeesStore;
	const [form] = useForm();

	const [isShowChangePassword, setIsShowChangePassword] = useState(false);

	const getTitle = () => {
		if (selectedEmployees.id) {
			return `Редактирование сотрудника ${selectedEmployees.id} | ${selectedEmployees.shortFullName}`;
		}
		return `Добавление сотрудника`;
	};

	const getButton = () => {
		if (selectedEmployees.id) {
			return `Обновить`;
		}
		return `Добавить`;
	};

	const filedNames = {
		last_name: selectedEmployees.last_name,
		first_name: selectedEmployees.first_name,
		patronymic: selectedEmployees.patronymic,
		email: selectedEmployees.email,
		birthday: checkDateIsValid(selectedEmployees.birthday) ? selectedEmployees.birthday : undefined,
		post: selectedEmployees.post,
		role_id: selectedEmployees.role.id || undefined,
		phones: selectedEmployees.phones,
	};

	// Заполняем
	useEffect(() => {
		// Очищаем форму
		form.resetFields();

		// Записываем данные
		form.setFieldsValue({
			...filedNames,
		});
	}, [selectedEmployees.id]);

	const handleOnSave = async (values: User) => {
		try {
			// Сохраняем или обновляем
			if (selectedEmployees.id) {
				await employeesStore.selected.update(values);
			} else {
				// Добавление
				await employeesStore.selected.create(values);
				// Закрываем drawer
				employeesStore.drawer.close();
				// Очищаем выбранный стор
				employeesStore.clearSelected();
				// Очищаем форму
				form.resetFields();
			}

			// Обновляем список
			await employeesStore.table.getList();
		} catch {
			/* empty */
		}
	};

	const handleOnSend = () => form.submit();

	const handleOnClose = () => {
		// Закрываем окно
		employeesStore.drawer.close();
		// Очистка стора
		employeesStore.clearSelected();
		// Очищаем форму
		form.resetFields();
	};

	const handleOnDelete = async () => {
		// Удаление
		await selectedEmployees.delete(false);
		// Закрываем окно
		employeesStore.drawer.close();
		// Очистка стора
		employeesStore.clearSelected();
		// Очищаем форму
		form.resetFields();
		// Обновляем список
		await employeesStore.table.getList();
	};

	return (
		<Drawer
			forceRender
			open={employeesStore.drawer.isShow}
			width={800}
			title={getTitle()}
			onClose={handleOnClose}
			extra={[
				<Fragment key={'actions'}>
					<Space size={15}>
						{selectedEmployees.isNotNewStore && (
							<Popconfirm title={`Удалить?`} onConfirm={handleOnDelete}>
								<Button type={'primary'} icon={<DeleteOutlined />} danger>
									Удалить
								</Button>
							</Popconfirm>
						)}

						<Button
							key={'create'}
							htmlType={'submit'}
							icon={<SaveOutlined />}
							type={'primary'}
							onClick={handleOnSend}
							loading={employeesStore.selected.isLoading}
						>
							{getButton()}
						</Button>
					</Space>
				</Fragment>,
			]}
		>
			<Form form={form} layout={'vertical'} onFinish={handleOnSave}>
				<Row gutter={15} style={{ marginBottom: 15 }}>
					<Col span={8}>
						<Form.Item name={'last_name'} label={'Фамилия'} rules={[{ required: true }]}>
							<Input />
						</Form.Item>
					</Col>

					<Col span={8}>
						<Form.Item name={'first_name'} label={'Имя'} rules={[{ required: true }]}>
							<Input />
						</Form.Item>
					</Col>

					<Col span={8}>
						<Form.Item name={'patronymic'} label={'Отчество'} rules={[{ required: true }]}>
							<Input />
						</Form.Item>
					</Col>
				</Row>

				<Row gutter={15} style={{ marginBottom: 15 }}>
					<Col span={8}>
						<Form.Item
							name={'email'}
							label={'Email'}
							rules={[{ required: selectedEmployees.isNewStore, type: 'email' }]}
						>
							<Input disabled={selectedEmployees.isNotNewStore} />
						</Form.Item>
					</Col>

					<Col span={8}>
						<Form.Item name={'birthday'} label={'Дата рождения'} rules={[{ required: true }]}>
							<DatePicker
								style={{ width: '100%' }}
								format={'DD.MM.YYYY'}
								disabledDate={(current) => current && current > dayjs().endOf('day')}
							/>
						</Form.Item>
					</Col>

					<Col span={8}>
						<Form.Item name={'role_id'} label={'Должность'} rules={[{ required: true }]}>
							<Select>
								{rolesStore.listNotCEO.map((role) => {
									return (
										<Select.Option key={role.id} value={role.id}>
											{role.name}
										</Select.Option>
									);
								})}
							</Select>
						</Form.Item>
					</Col>
				</Row>

				<Divider />

				<>
					<Row gutter={15}>
						{selectedEmployees.isNotNewStore && (
							<Col span={24} style={{ marginBottom: 15 }}>
								<Checkbox
									checked={isShowChangePassword}
									onChange={(checked) => setIsShowChangePassword(checked.target.checked)}
								>
									Сменить пароль
								</Checkbox>
							</Col>
						)}

						<Col span={12}>
							<Form.Item
								name={'password'}
								label={'Пароль'}
								rules={[{ required: selectedEmployees.isNotNewStore ? isShowChangePassword : true }]}
							>
								<Input.Password disabled={selectedEmployees.isNotNewStore ? !isShowChangePassword : false} />
							</Form.Item>
						</Col>

						<Col span={12}>
							<Form.Item
								name={'password_confirmation'}
								label={'Повтор пароля'}
								dependencies={['password']}
								rules={[
									{ required: selectedEmployees.isNotNewStore ? isShowChangePassword : true },
									({ getFieldValue }) => ({
										validator(_, value) {
											if (!value || getFieldValue('password') === value) {
												return Promise.resolve();
											}
											return Promise.reject(new Error('Пароли должны совпадать'));
										},
									}),
								]}
							>
								<Input.Password disabled={selectedEmployees.isNotNewStore ? !isShowChangePassword : false} />
							</Form.Item>
						</Col>
					</Row>

					<Divider />
				</>

				<Row gutter={15} style={{ marginBottom: 15 }}>
					<Col span={24}>
						<Form.List name={'phones'}>
							{(fields, { add, remove }) => {
								return (
									<>
										<Row>
											<Col span={8} offset={8}>
												<Form.Item>
													<Button type="dashed" onClick={() => add()} block icon={<PhoneOutlined />}>
														Добавить телефон
													</Button>
												</Form.Item>
											</Col>
										</Row>

										{fields.map(({ key, name, ...restField }) => (
											<Row gutter={15} key={key}>
												<Col span={10}>
													<Form.Item
														{...restField}
														label={'Назначение телефона'}
														name={[name, 'name']}
														rules={[{ required: true }]}
													>
														<Input />
													</Form.Item>
												</Col>
												<Col span={10}>
													<Form.Item
														{...restField}
														label={'Телефон'}
														name={[name, 'phone']}
														rules={[{ required: true }]}
													>
														<MaskedInput mask={`+7 (000) 000 00 00`} />
													</Form.Item>
												</Col>
												<Col span={2}>
													<Button
														style={{ marginTop: 30 }}
														icon={<MinusCircleOutlined />}
														type={'primary'}
														danger
														onClick={() => remove(name)}
													/>
												</Col>
											</Row>
										))}
									</>
								);
							}}
						</Form.List>
					</Col>
				</Row>
			</Form>
		</Drawer>
	);
});
