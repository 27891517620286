import { action, makeObservable, observable } from 'mobx';

export class FilterStore {
	@observable isLoading = false;
	@observable query = '';
	@observable isDirty = false;

	constructor() {
		makeObservable(this);
	}

	@action.bound
	setIsDirty(value = true) {
		this.isDirty = value;
	}

	@action.bound
	setIsLoading(value = false) {
		this.isLoading = value;
	}

	@action.bound
	setQuery(value: string) {
		this.query = value;
	}
}
