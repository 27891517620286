import React from 'react';

import { FormInstance, Table } from 'antd';
import { observer } from 'mobx-react-lite';

import { useColumns } from './ItemProjectGenerateTable.columns';
import { ItemProjectTaskGenerateTable } from './ItemProjectTaskGenerateTable/ItemProjectTaskGenerateTable';
import { ItemVolumeGenerateStore } from '../../../../../stores/item-volume-generate';

interface ItemProjectGenerateTableProps {
	index: number;
	form: FormInstance;
	selectedRowKeys?: React.Key[];
	onSelectedRowKeys?: (values: React.Key[]) => void;
	data: ItemVolumeGenerateStore[];
}

export const ItemProjectGenerateTable = observer(({ index, form, data }: ItemProjectGenerateTableProps) => {
	return (
		<>
			<Table
				key={index}
				rowKey={(row) => row.id}
				expandable={{
					defaultExpandAllRows: true,
					expandedRowRender: (itemVolumeGenerate: ItemVolumeGenerateStore, index) => (
						<ItemProjectTaskGenerateTable
							form={form}
							itemVolumeGenerate={itemVolumeGenerate}
							data={itemVolumeGenerate.item_volume_tasks}
							volumeIndex={index}
						/>
					),
				}}
				dataSource={data}
				columns={useColumns(data)}
				pagination={false}
			/>
		</>
	);
});
