import dayjs from 'dayjs';
import { action, makeObservable, observable } from 'mobx';

import { AgreementPrivateItemFileStore } from './agreement-private-item-file.store';
import { AgreementPrivateItem } from '../../interfaces/agreement-private-contractor/agreement-private-item.interface';

export class AgreementPrivateItemStore implements AgreementPrivateItem {
	@observable id = 0;
	@observable title = '';
	@observable cipher = '';
	@observable start_date = dayjs();
	@observable end_date = dayjs();
	@observable files: AgreementPrivateItemFileStore[] = [];

	constructor(initialData: AgreementPrivateItem | null) {
		makeObservable(this);

		if (initialData) {
			this.fillStore(initialData);
		}
	}

	@action
	fillStore(data: AgreementPrivateItem) {
		const { id, title, cipher, start_date, end_date, files } = data;

		this.id = id;
		this.title = title;
		this.cipher = cipher;
		this.start_date = dayjs(start_date);
		this.end_date = dayjs(end_date);
		this.files = (files || []).map((file) => new AgreementPrivateItemFileStore(file));
	}
}
