import { motion } from 'framer-motion';
import styled from 'styled-components';

import { COLORS } from '../../../../stylesheets';

export const Container = styled(motion.div)`
	position: absolute;
	top: 50%;
	left: 50%;
	width: 400px;
	transform: translate(-50%, -50%);
	background: ${COLORS.White};
	border: 1px solid ${COLORS.BLUE_40};
	border-radius: 12px;
	display: grid;
	box-shadow: 0 0 10px 5px rgba(34, 60, 80, 0.04);
	padding: 25px;
	grid-template-rows: 80px 1fr;
	gap: 20px;
`;
