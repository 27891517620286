import { useEffect, useRef } from 'react';

import { Empty } from 'antd';
import { observer } from 'mobx-react-lite';

import { MessageItem } from './Messageitem';
import { Container, ScrollContainer } from './MessageList.styled';
import { Message, WsTypeEvent } from '../../../../../../interfaces';
import { useStores } from '../../../../../../stores';
import { DialogMessageStore } from '../../../../../../stores/dialogs/dialog-message.store';
import { UserStore } from '../../../../../../stores/users';

export const MessageList = observer(() => {
	const {
		authStore: { current: currentUser },
		dialogsStore: { selected: currentDialog },
		websocketStore,
	} = useStores();

	const scrollContainerRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		websocketStore.addCallback<Message>({
			event: WsTypeEvent.chatMessage,
			callback: (data) => {
				currentDialog?.createAddMessage(data.payload);
			},
		});
	}, []);

	useEffect(() => {
		if (scrollContainerRef.current) {
			scrollContainerRef.current.scrollTo({
				behavior: 'smooth',
				top: scrollContainerRef.current.scrollHeight,
			});
		}
	}, [currentDialog?.messages.length]);

	const getUser = (message: DialogMessageStore): UserStore => {
		if (!currentDialog) {
			return currentUser;
		}

		if (currentDialog.creator_id === currentUser.id) {
			if (message.from_user_id === currentUser.id) {
				return currentUser;
			} else {
				return currentDialog.to_user;
			}
		} else {
			if (message.from_user_id === currentUser.id) {
				return currentUser;
			} else if (message.from_user_id !== currentUser.id) {
				return currentDialog.from_user;
			}
			if (message.to_user_id === currentUser.id) {
				return currentUser;
			} else if (message.to_user_id !== currentUser.id) {
				return currentDialog.to_user;
			}
		}

		return currentUser;
	};

	return (
		<Container>
			<ScrollContainer ref={scrollContainerRef}>
				{currentDialog?.messages.length === 0 && (
					<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={'Нет сообщений'} />
				)}

				{currentDialog?.messages.map((messageStore) => {
					return (
						<MessageItem
							key={messageStore.id}
							fromMe={messageStore.from_user_id === currentUser.id}
							date={messageStore.created_at}
							user={getUser(messageStore)}
							message={messageStore.message}
						/>
					);
				})}
			</ScrollContainer>
		</Container>
	);
});
