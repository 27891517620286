import dayjs from 'dayjs';
import { action, computed, makeObservable, observable } from 'mobx';
import { v4 } from 'uuid';

import { MethodsRequest } from '../../constants';
import { API } from '../../core';
import { ErrorAPI } from '../../helpers';
import { Message } from '../../interfaces';

export class DialogMessageStore implements Message {
	static PATH = 'dialog-messages';

	@observable id: number | string = 0;
	@observable dialog_id = 0;
	@observable from_user_id = 0;
	@observable to_user_id = 0;
	@observable message = '';
	@observable created_at = dayjs();

	constructor(initialData: Message | null) {
		makeObservable(this);

		if (initialData) {
			this.fillStore(initialData);
		}
	}

	@computed
	get fromMyMessage(): boolean {
		return true;
	}

	@action.bound
	async send(values: Pick<Message, 'message' | 'dialog_id' | 'from_user_id' | 'to_user_id'>) {
		try {
			const data = await API.request<Message>(DialogMessageStore.PATH, {
				method: MethodsRequest.Post,
				body: API.getFormData(values),
			});

			this.fillStore(data);
		} catch (e) {
			ErrorAPI('send', e);
		}
	}

	@action
	fillStore(data: Message) {
		const { id, dialog_id, from_user_id, to_user_id, message, created_at } = data;

		this.id = id || v4();
		this.dialog_id = dialog_id;
		this.from_user_id = from_user_id;
		this.to_user_id = to_user_id;
		this.message = message;
		this.created_at = dayjs(created_at);
	}
}
