import { useEffect } from 'react';

import { PlusOutlined } from '@ant-design/icons';
import { Alert, Button, Form, FormInstance, Typography } from 'antd';
import { observer } from 'mobx-react-lite';

import { TriggerItem } from './TriggerItem';
import { ItemStore, useStores } from '../../../../../../../stores';

interface TriggersTabProps {
	form: FormInstance;
	currentTask: ItemStore;
}

export const TriggersTab = observer(({ form, currentTask }: TriggersTabProps) => {
	const { taskListStore } = useStores();

	useEffect(() => {
		(async () => {
			// Получаем список всех тасков по этому объекту
			if (currentTask.parent_object?.id) {
				await taskListStore.getAllByObjectId(currentTask.parent_object?.id);
			}
		})();
	}, [currentTask.parent_object?.id]);

	return (
		<>
			<Form.List name={['trigger_workflows']} initialValue={[]}>
				{(triggersFields, { add: onAddTrigger, remove: onDeleteTrigger }) => (
					<>
						<div
							style={{
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'space-between',
								marginBottom: 25,
							}}
						>
							<span>
								<Typography.Title level={5}>Триггер после выполнения</Typography.Title>
							</span>

							<span>
								<Button
									type={'primary'}
									icon={<PlusOutlined />}
									onClick={() => {
										onAddTrigger();
									}}
								>
									Добавить триггер
								</Button>
							</span>
						</div>

						{triggersFields.length === 0 && (
							<Alert showIcon type="info" description={`У задачи нет ни одного триггера`} />
						)}

						{triggersFields.map((triggerField) => {
							return (
								<TriggerItem
									key={triggerField.name}
									field={triggerField}
									form={form}
									onDeleteTriggerItem={onDeleteTrigger}
								/>
							);
						})}
					</>
				)}
			</Form.List>
		</>
	);
});
