import { observer } from 'mobx-react-lite';

import { ChooseUser } from './ChooseUser/ChooseUser';
import { MessageList } from './MessageList/MessageList';
import { Container } from './RightSide.styled';
import { StartScreen } from './StartScreen/StartScreen';
import { WriteMessage } from './WriteMessage/WriteMessage';
import { useStores } from '../../../../../stores';

export const RightSide = observer(() => {
	const { dialogsStore } = useStores();

	return (
		<>
			<Container>
				{dialogsStore.notSelected && <StartScreen />}

				{dialogsStore.selected && dialogsStore.selected.id !== 0 && (
					<>
						<MessageList />
						<WriteMessage />
					</>
				)}

				{dialogsStore.selected && !dialogsStore.selected.id && (
					<>
						<ChooseUser />
					</>
				)}
			</Container>
		</>
	);
});
