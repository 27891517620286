import { FilePdfOutlined } from '@ant-design/icons';
import { notification, UploadFile } from 'antd';
import dayjs, { Dayjs } from 'dayjs';

import { ItemTypes, ItemTypesDrawerMapping } from '../constants/item-types';
import { Item } from '../interfaces/item';
import { ContractorStore } from '../stores/contractors/contractor.store';
import { UserItemStore } from '../stores/user-items';
import { UserShortStore } from '../stores/users';
import { UserStore } from '../stores/users/user.store';

export const ErrorAPI = (nameMethod: string, e: any) => {
	console.error(`Error in method ${nameMethod} : `, e);
	console.log('e.errors', e.errors);
	const errors = e.errors && Array.isArray(e.errors) ? e.errors.join('<br/>') : 'Обратитесь к администратору';

	// Вывод информации об ошибке
	notification.error({
		message: 'Ошибка',
		description: <span dangerouslySetInnerHTML={{ __html: errors }} />,
		duration: 5,
	});

	throw e;
};

export const getFirstChar = (value: string): string => {
	return value?.length ? value[0] : '';
};

export const checkDateIsValid = (value: Dayjs) => {
	return value.isValid() && value.format('YYYY-MM-DD') !== '1899-11-30';
};

export const formatPhoneNumber = (phone: string) => {
	//Filter only numbers from the input
	const cleaned = ('' + phone).replace(/\D/g, '');

	//Check if the input is of correct
	const match = cleaned.match(/^(7|)?(\d{3})(\d{3})(\d{2})(\d{2})$/);

	if (match) {
		//Remove the matched extension code
		//Change this to format for any country code.
		const intlCode = match[1] ? '+7 ' : '';
		return [intlCode, '(', match[2], ') ', match[3], '-', match[4], '-', match[5]].join('');
	}

	return null;
};

export const formattedPrice = (value: number | string | undefined) =>
	String(value)
		.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
		.replace(',', '.') + ' ₽';

export const getTitle = (name = 'не указан', isCreate = true, nameEdit = ''): string => {
	if (isCreate) return `Создать ${name}`;

	return `Редактирование ${name} : ${nameEdit}`;
};

export const getButtonName = (isCreate = true): string => {
	if (isCreate) return `Создать`;
	return `Обновить`;
};

export const getIconByFile = (extension: string) => {
	switch (extension) {
		case 'application/pdf':
			return <FilePdfOutlined />;
		default:
			return null;
	}
};

export const checkIsOverdue = (date_end: Dayjs): boolean => {
	return dayjs().isAfter(date_end, 'day');
};

export const numWords = (value: number, words: Array<string>) => {
	value = Math.abs(value) % 100;
	const num = value % 10;
	if (value > 10 && value < 20) return words[2];
	if (num > 1 && num < 5) return words[1];
	if (num === 1) return words[0];
	return words[2];
};

export const checkStartDay = (values: { [key: string]: any }) => {
	Object.keys(values).forEach((key) => {
		if (values[key] instanceof dayjs) {
			values[key] = values[key].startOf('day');
		}
	});

	return values;
};

export const makeAbbr = (words: string) => {
	return words
		.split(' ')
		.reduce((prevVal, curWord) => prevVal + (curWord ? curWord[0] : ''), '')
		.toUpperCase();
};

export const makeLongString = (length: number) => {
	let result = '';
	const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
	const charactersLength = characters.length;
	for (let i = 0; i < length; i++) {
		result += characters.charAt(Math.floor(Math.random() * charactersLength));
	}
	return result;
};

export const checkDateOrNull = (date: Dayjs | null | undefined) => {
	return date === null || typeof date === undefined ? undefined : dayjs(date);
};

export const getExtensionsFile = (fileName: string): string => {
	const result = fileName.split('.').pop();
	return result ? result.toLowerCase() : '';
};

export const isViewFileBrowser = (extensionFile: string): boolean => {
	return ['png', 'jpeg', 'jpg', 'pdf'].includes(extensionFile);
};

export const checkIsViewFile = (fileName: string) => {
	return isViewFileBrowser(getExtensionsFile(fileName));
};

export const getRandom = (length = 10) => {
	return Math.floor(Math.pow(10, length - 1) + Math.random() * 9 * Math.pow(10, length - 1));
};

export const getGreetings = () => {
	const hours = new Date().getHours();

	if (hours >= 5 && hours < 11) {
		return `Доброе утро`;
	}

	if (hours >= 11 && hours < 16) {
		return `Добрый день`;
	}

	if (hours >= 16 && hours < 23) {
		return `Добрый вечер`;
	}

	return `Доброй ночи`;
};

export const getInformationUser = (user: UserStore | ContractorStore | UserShortStore) => {
	return (
		<div>
			{user.shortFullName} {user.roleName && `(${user.roleName})`}
		</div>
	);
};

export const prepareFilesForUpload = <T,>(dataForSend: T, keyFile: keyof T, files: UploadFile[]) => {
	if (dataForSend) {
		dataForSend[keyFile] = files.map((file) => file.originFileObj) as any;
	}
};

export const getTypeItem = (type_id: ItemTypes) => {
	return ItemTypesDrawerMapping[type_id];
};

export const generateNotificationChangeStatus = (data: Item) => {
	switch (data.item_local_status_id) {
		case 1: {
			return `Изменился статус у ${getTypeItem(data.item_type_id)} на создан`;
		}
		case 2: {
			return `Изменился статус у ${getTypeItem(data.item_type_id)} в работе`;
		}
		case 3: {
			return `Изменился статус у ${getTypeItem(data.item_type_id)} на рассмотрении`;
		}
		case 4: {
			return `Изменился статус у ${getTypeItem(data.item_type_id)} подтвержден`;
		}
		case 5: {
			return `Изменился статус у ${getTypeItem(data.item_type_id)} на доработку`;
		}
		default: {
			return `Статус не найден`;
		}
	}
};

export const generateNotificationAssignItem = (data: Item) => {
	return `Назначена ${getTypeItem(data.item_type_id)}`;
};

export const getColorLocalStatus = (row: UserItemStore) => {
	switch (row.item_local_status_id) {
		case 1:
			return 'magenta';
		case 2:
			return 'volcano';
		case 3:
			return 'orange';
		case 4:
			return 'gold';
		case 5:
			return 'red';
		default:
			return 'magenta';
	}
};

export const saveProjectGenerateLocalStorage = (value: string, id_project_generate: number) => {
	localStorage.setItem(`item-project-generate-${id_project_generate}`, value);
};

const mutationDate = (data: any) => {
	// Проходим по всем ключам где есть в названии date и оборачиваем значение в dayjs
	if (Array.isArray(data)) {
		data.forEach((volume: any, index: number) => {
			// Проходимся по ключам
			for (const [key, value] of Object.entries(volume)) {
				if (key.includes(`date`)) {
					if (Array.isArray(value)) {
						data[index][key] = value.map(dayjs);
					} else {
						if (value) {
							// eslint-disable-next-line @typescript-eslint/ban-ts-comment
							// @ts-ignore
							data[index][key] = dayjs(value);
						}
					}
				}

				// Для задач
				if (key === 'tasks') {
					mutationDate(data[index][key]);
				}
			}
		});
	}
};

export const findProjectGenerateLocalStorage = (id_project_generate: number) => {
	const findData = localStorage.getItem(`item-project-generate-${id_project_generate}`);

	if (findData) {
		const jsonData = JSON.parse(findData);

		if (jsonData['items']) {
			mutationDate(jsonData['items']);
			return jsonData['items'];
		} else {
			console.warn(`Not key items in json`);
		}

		return jsonData;
	}

	return null;
};

export const deleteProjectGenerateLocalStorage = (id_project_generate: number) => {
	localStorage.removeItem(`item-project-generate-${id_project_generate}`);
};

export const getIconCurrency = (code: string) => {
	const findCurrency = new Intl.NumberFormat('ru-RU', {
		style: 'currency',
		currencyDisplay: 'symbol',
		currency: code,
	})
		.formatToParts(0)
		.find((x) => x.type === 'currency');

	if (findCurrency) {
		return findCurrency.value;
	}

	return '-';
};

export const niceBytes = (x: number) => {
	const units = ['bytes', 'KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];

	let l = 0,
		n = parseInt(String(x), 10) || 0;

	while (n >= 1024 && ++l) {
		n = n / 1024;
	}

	return n.toFixed(n < 10 && l > 0 ? 1 : 0) + ' ' + units[l];
};

export { IconEditItem } from './IconEditItem';
