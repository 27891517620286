import React from 'react';

import { configure } from 'mobx';

import { AgreementFileTemplatesStore } from './agreement-file-templates';
import { AgreementPrivateContractorStore } from './agreement-private-contractor';
import { AgreementStatusesStore } from './agreement-statuses';
import { AgreementStore } from './agreements/agreement.store';
import { AgreementsStore } from './agreements/agreements.store';
import { AudioStore } from './audio';
import { AuthStore } from './auth/auth.store';
import { ContractorFileStore } from './contractors/contractor-file.store';
import { ContractorsStore } from './contractors/contractors.store';
import { CurrenciesStore } from './currencies';
import { DialogsStore } from './dialogs/dialogs.store';
import { EmployeesStore } from './employees/employees.store';
import { GenerateVolumesStore } from './generate-volumes/generate-volumes.store';
import { GeolocationStore } from './geolocation';
import { ItemLocalStatusesStore, ItemStore, ItemTypesStore } from './item';
import { ItemObjectTypesStore } from './item/item-object-types.store';
import { ItemsStore } from './item/items.store';
import { ItemDisabledStore } from './item-disabled/item-disabled.store';
import { ItemGenerateVolumeStore } from './item-generate-volume/item-generate-volume.store';
import { ItemProjectDocumentsStore } from './item-project-documents';
import { ItemProjectGenerateStore } from './item-project-generate/item-project-generate.store';
import { ItemStatusesStore } from './item-statuses/item-statuses.store';
import { NotificationsStore } from './notifications/notifications.store';
import { ObjectStatusesStore } from './object-status/object-statuses.store';
import { ObjectTypesStore } from './objects/object-types.store';
import { ObjectsStore } from './objects/objects.store';
import { PermissionsStore } from './permissions/permissions.store';
import { ProjectDocumentationsStore } from './project-documentation/project-documentations.store';
import { ResponsiblesStore } from './responsibles/responsibles.store';
import { RolesStore } from './role/roles.store';
import { TasksStore } from './task/tasks.store';
import { TaskExchangeStore } from './task-exchange';
import { TaskListStore } from './task-list/task-list.store';
import { TourStore } from './tour';
import { TriggerExecuteEventsStore, TriggersStore } from './triggers';
import { UserDashboardStore } from './user-dashboard/user-dashboard.store';
import { UserItemsStore } from './user-items';
import { UserOnlineStore } from './user-online';
import { UserCountStore, UsersStore } from './users';
import { VolumesStore } from './volume/volumes.store';
import { WebsocketStore } from './websocket/websocket.store';
import { WorkWeekStore } from './work-week';

configure({
	enforceActions: 'never',
});

export class RootStore {
	public authStore: AuthStore;

	public userCountStore: UserCountStore;
	public usersStore: UsersStore;

	public objectsStore: ObjectsStore;
	public volumesStore: VolumesStore;
	public tasksStore: TasksStore;
	public employeesStore: EmployeesStore;
	public rolesStore: RolesStore;
	public contractorsStore: ContractorsStore;
	public responsiblesStore: ResponsiblesStore;
	public objectTypesStore: ObjectTypesStore;
	public websocketStore: WebsocketStore;
	public notificationsStore: NotificationsStore;
	public userDashboardStore: UserDashboardStore;
	public permissionsStore: PermissionsStore;
	public objectStatusesStore: ObjectStatusesStore;
	public compositionProjectDocumentationsStore: ProjectDocumentationsStore;
	public generateVolumesStore: GenerateVolumesStore;
	public itemGenerateVolumeStore: ItemGenerateVolumeStore;
	public itemProjectGenerateStore: ItemProjectGenerateStore;

	public itemStore: ItemStore;
	public itemsStore: ItemsStore;
	public itemObjectTypesStore: ItemObjectTypesStore;
	public itemStatusesStore: ItemStatusesStore;
	public itemProjectDocumentsStore: ItemProjectDocumentsStore;
	public itemLocalStatusesStore: ItemLocalStatusesStore;
	public itemTypesStore: ItemTypesStore;

	public workWeekStore: WorkWeekStore;

	public userItemsStore: UserItemsStore;

	public triggersStore: TriggersStore;
	public triggerExecuteEventsStore: TriggerExecuteEventsStore;

	public dialogsStore: DialogsStore;

	public geolocationStore: GeolocationStore;

	public audioStore: AudioStore;

	public tourStore: TourStore;

	public taskExchangeStore: TaskExchangeStore;

	public taskListStore: TaskListStore;

	// Договора
	public agreementsStore: AgreementsStore;
	public agreementStore: AgreementStore;

	// Статусов договора
	public agreementStatusesStore: AgreementStatusesStore;

	// Валюты
	public currenciesStore: CurrenciesStore;

	// Файлы с шаблонными договорами
	public agreementFileTemplatesStore: AgreementFileTemplatesStore;

	// Количество пользователей онлайн
	public userOnlineStore: UserOnlineStore;

	public agreementPrivateContractorStore: AgreementPrivateContractorStore;

	// Блокированные документы
	public itemDisabledStore: ItemDisabledStore;

	constructor() {
		this.authStore = new AuthStore();

		this.userCountStore = new UserCountStore();
		this.usersStore = new UsersStore();

		this.objectsStore = new ObjectsStore();
		this.volumesStore = new VolumesStore();
		this.tasksStore = new TasksStore();
		this.employeesStore = new EmployeesStore();
		this.rolesStore = new RolesStore();
		this.contractorsStore = new ContractorsStore();
		this.responsiblesStore = new ResponsiblesStore();
		this.objectTypesStore = new ObjectTypesStore();
		this.websocketStore = new WebsocketStore(this);
		this.notificationsStore = new NotificationsStore();
		this.userDashboardStore = new UserDashboardStore();
		this.permissionsStore = new PermissionsStore();
		this.objectStatusesStore = new ObjectStatusesStore(this);
		this.compositionProjectDocumentationsStore = new ProjectDocumentationsStore(this);
		this.generateVolumesStore = new GenerateVolumesStore();

		this.itemStore = new ItemStore(null);
		this.itemsStore = new ItemsStore();
		this.itemObjectTypesStore = new ItemObjectTypesStore();
		this.itemStatusesStore = new ItemStatusesStore(this);
		this.itemProjectDocumentsStore = new ItemProjectDocumentsStore();
		this.itemGenerateVolumeStore = new ItemGenerateVolumeStore();
		this.itemLocalStatusesStore = new ItemLocalStatusesStore();
		this.itemTypesStore = new ItemTypesStore();

		this.itemProjectGenerateStore = new ItemProjectGenerateStore();

		this.workWeekStore = new WorkWeekStore();

		this.userItemsStore = new UserItemsStore();

		this.triggersStore = new TriggersStore();
		this.triggerExecuteEventsStore = new TriggerExecuteEventsStore();

		this.dialogsStore = new DialogsStore(this);

		this.geolocationStore = new GeolocationStore();

		this.audioStore = new AudioStore();

		this.tourStore = new TourStore();

		this.taskExchangeStore = new TaskExchangeStore();

		this.taskListStore = new TaskListStore();

		// Instance договоров
		this.agreementsStore = new AgreementsStore();
		this.agreementStore = new AgreementStore(null);

		// Instance статусов договора
		this.agreementStatusesStore = new AgreementStatusesStore();

		// Instance валют
		this.currenciesStore = new CurrenciesStore();

		// Instance файл шаблонных договоров
		this.agreementFileTemplatesStore = new AgreementFileTemplatesStore();

		// Instance кол-во пользователей онлайн
		this.userOnlineStore = new UserOnlineStore();

		// Instance agreementPrivateContractorStore
		this.agreementPrivateContractorStore = new AgreementPrivateContractorStore();

		// Instance заблокированных документов
		this.itemDisabledStore = new ItemDisabledStore();
	}
}

let store: RootStore;

const StoreContext = React.createContext(new RootStore());

export const useStores = (): RootStore => React.useContext(StoreContext);

export const RootStoreProvider = ({ children }: { children: React.ReactNode }) => {
	const root = store ?? new RootStore();
	return <StoreContext.Provider value={root}>{children}</StoreContext.Provider>;
};

export {
	AuthStore,
	ContractorFileStore,
	UserOnlineStore,
	UsersStore,
	ContractorsStore,
	TriggersStore,
	UserDashboardStore,
	CurrenciesStore,
	UserItemsStore,
	ItemTypesStore,
	ItemStore,
	ObjectTypesStore,
	ObjectsStore,
	ItemsStore,
	ObjectStatusesStore,
	AgreementStatusesStore,
	AgreementsStore,
	AgreementStore,
	ItemStatusesStore,
	ItemLocalStatusesStore,
	ItemObjectTypesStore,
	UserCountStore,
	TourStore,
};

export * from './item';
