import { Table } from 'antd';
import { observer } from 'mobx-react-lite';

import { TaskItemTable } from './TaskItemTable';
import { useVolumeItemsTableColumns } from './VolumeItemsTable.columns';
import { ItemStore, useStores } from '../../../../../../stores';

interface VolumeItemsTableProps {
	items: ItemStore[];
}

export const VolumeItemsTable = observer(({ items }: VolumeItemsTableProps) => {
	const { itemsStore } = useStores();

	return (
		<Table
			rowKey={(row) => row.id}
			pagination={false}
			columns={useVolumeItemsTableColumns()}
			dataSource={items}
			expandable={{
				rowExpandable: (row) => (row?.children_list || []).length > 0,
				expandedRowRender: (record) => {
					return <TaskItemTable items={record.children_list || []} />;
				},
			}}
			footer={() => <div style={{ height: 23 }} />}
			onRow={(row) => {
				return {
					onDoubleClick: async () => {
						// Показываем окно
						itemsStore.selected.viewer.show();

						// Устанавливаем ID
						itemsStore.selected.setId(row.id);

						// Получаем инфомацию
						await itemsStore.selected.get();
					},
				};
			}}
		/>
	);
});
