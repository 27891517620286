import { useEffect, useState } from 'react';

import { EyeInvisibleOutlined, EyeOutlined, WalletOutlined } from '@ant-design/icons';
import { Skeleton, Switch, Typography } from 'antd';
import CountUp from 'react-countup';

import { SalaryContainer, SalaryLeftSide, SalaryRightSide, SalarySum } from './WidgetSalary.styled';
import { Widget, WidgetTitleIcon } from '../../../common';

const itemsSalary = [
	{
		key: 'day',
		label: 'За день',
		value: 5276,
	},
	{
		key: 'week',
		label: 'За неделю',
		value: 19855,
	},
	{
		key: 'month',
		label: 'За месяц',
		value: 87215,
	},
];

export const WidgetSalary = () => {
	const [isHideSalary, setIsHideSalary] = useState(true);
	const [salary, setSalary] = useState(87215);
	const [beforeSalary] = useState(0);

	const handleOnToggle = () => setIsHideSalary((prevState) => !prevState);

	useEffect(() => {
		setSalary(itemsSalary[0].value);
	}, []);

	return (
		<Widget
			title={
				<WidgetTitleIcon>
					<WalletOutlined /> Кошелек
				</WidgetTitleIcon>
			}
			extra={[
				<Switch
					key={'switch-hide'}
					checked={!isHideSalary}
					checkedChildren={<EyeOutlined />}
					unCheckedChildren={<EyeInvisibleOutlined />}
					onChange={handleOnToggle}
				/>,
			]}
		>
			<SalaryContainer>
				<SalaryLeftSide></SalaryLeftSide>

				<SalaryRightSide>
					<Skeleton active loading={isHideSalary} paragraph={false}>
						<SalarySum>
							<CountUp
								start={beforeSalary}
								end={salary}
								separator={' '}
								decimal={','}
								decimals={2}
								className={'salary'}
							/>{' '}
							<span>&#8381;</span>
						</SalarySum>

						<Typography.Text type={'danger'} strong>
							Цифры тестовые.
						</Typography.Text>
					</Skeleton>
				</SalaryRightSide>
			</SalaryContainer>
		</Widget>
	);
};
