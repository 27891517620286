import { action, makeObservable, observable } from 'mobx';

import { VolumeFileStore } from './volume-file.store';
import { VolumeFileI } from '../../interfaces';

export class VolumeFilesStore {
	@observable list: VolumeFileStore[] = [];

	constructor() {
		makeObservable(this);
	}

	@action.bound
	setList(values: VolumeFileI[]) {
		this.list = (values || []).map((file) => new VolumeFileStore(file));
	}

	@action.bound
	addFile(value: VolumeFileStore) {
		this.list.push(value);
	}

	@action.bound
	deleteFile(id: number) {
		this.list = this.list.filter((file) => file.id !== id);
	}
}
