import { UploadOutlined } from '@ant-design/icons';
import { Form, Upload } from 'antd';

import { Container } from './FileUploadBlock.styled';
import { FileStore } from '../../../stores/file/file.store';
import { FileButtons } from '../FileButtons/FileButtons';

interface FileUploadBlockProps {
	label: string;
	nameField: string;
	list?: FileStore[];
}

export const FileUploadBlock = ({ label, nameField, list }: FileUploadBlockProps) => {
	/*const handleOnDelete = async (file: FileStore) => {
    try {
      await file.delete();
    } catch (e) {
      ErrorAPI('handleOnDelete', e);
    }
  };*/

	return (
		<>
			<Form.Item label={label} name={nameField} valuePropName={'fileList'} getValueFromEvent={(e) => e.fileList}>
				<Upload beforeUpload={() => false} multiple>
					<Container>
						<UploadOutlined /> Выбрать файлы или переместить сюда
					</Container>
				</Upload>
			</Form.Item>

			{(list || []).map((file) => {
				return (
					<div key={file.id} style={{ display: 'flex', columnGap: 15, alignItems: 'center', marginBottom: 10 }}>
						<FileButtons file={file} />
					</div>
				);
			})}
		</>
	);
};
