import { Fragment, useEffect, useState } from 'react';

import { DeleteOutlined, SaveOutlined } from '@ant-design/icons';
import { Button, Checkbox, Col, DatePicker, Divider, Drawer, Form, Input, Popconfirm, Row, Space } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { observer } from 'mobx-react-lite';

import { getButtonName, getTitle } from '../../../../helpers';
import { Contractor } from '../../../../interfaces';
import { useStores } from '../../../../stores';
import { UserFileStore } from '../../../../stores/users';
import { UploadFilesBlock, UserPhones } from '../../../common';
import { UploadedUserBlock } from '../../../common/UploadedUserBlock/UploadedUserBlock';

export const ContractorDrawer = observer(() => {
	const { contractorsStore } = useStores();
	const { selected: selectedContractor } = contractorsStore;
	const [form] = useForm();
	const [verified, setVerified] = useState(selectedContractor.verified);

	const filedNames = {
		company_name: selectedContractor.company_name,
		ceo: selectedContractor.ceo,
		registration_date: selectedContractor.registration_date,
		inn: selectedContractor.inn,
		email: selectedContractor.email,
		legal_address: selectedContractor.legal_address,
		verified: selectedContractor.verified,
		active_passed: selectedContractor.active_passed,
		phones: selectedContractor.phones,
	};

	// Заполняем
	useEffect(() => {
		form.setFieldsValue({
			...filedNames,
		});
	}, [selectedContractor.id, selectedContractor.company_name]);

	// Очищаем форму
	useEffect(() => {
		if (!contractorsStore.drawer.isShow) {
			form.resetFields();
			contractorsStore.clearSelected();
			setVerified(false);
			form.setFieldsValue({
				...filedNames,
			});
		}
	}, [contractorsStore.drawer.isShow]);

	const handleOnSave = async (values: Contractor) => {
		try {
			if (selectedContractor.isNotNewStore) {
				await selectedContractor.update(values);
			} else {
				await selectedContractor.create(values);
				contractorsStore.drawer.close();
			}

			// Чистим файлы для загрузки
			form.resetFields(['files']);

			// Обновляем список
			await contractorsStore.table.getList();
		} catch (e) {
			/* empty */
		}
	};

	const handleOnSend = () => form.submit();

	const handleOnDelete = async () => {
		try {
			// Удаляем контрагента
			await selectedContractor.delete(false);
			// Закрываем окно
			contractorsStore.drawer.close();
			// Обновляем список
			await contractorsStore.table.getList();
		} catch (e) {
			/* empty */
		}
	};

	const handleOnDeleteFile = async (fileStore: UserFileStore) => {
		try {
			await fileStore.delete(false);
			selectedContractor.deleteFromFiles(fileStore.id);
		} catch (e) {
			/* empty */
		}
	};

	return (
		<>
			<Drawer
				loading={contractorsStore.selected.isLoading}
				forceRender
				open={contractorsStore.drawer.isShow}
				width={800}
				title={getTitle('контрагента', contractorsStore.selected.isNewStore, contractorsStore.selected.company_name)}
				onClose={() => {
					contractorsStore.drawer.close();
					contractorsStore.clearSelected();
					form.resetFields();
				}}
				extra={[
					<Fragment key={'actions'}>
						<Space size={15}>
							{selectedContractor.isNotNewStore && (
								<Popconfirm title={`Удалить?`} onConfirm={handleOnDelete}>
									<Button
										key={'delete'}
										htmlType={'submit'}
										icon={<DeleteOutlined />}
										type={'primary'}
										danger
										loading={contractorsStore.selected.isLoading}
									>
										Удалить
									</Button>
								</Popconfirm>
							)}

							<Button
								key={'create'}
								htmlType={'submit'}
								icon={<SaveOutlined />}
								type={'primary'}
								onClick={handleOnSend}
								loading={contractorsStore.selected.isLoading}
							>
								{getButtonName(contractorsStore.selected.isNewStore)}
							</Button>
						</Space>
					</Fragment>,
				]}
			>
				<Form form={form} layout={'vertical'} onFinish={handleOnSave}>
					<Row gutter={15}>
						<Col span={12}>
							<Form.Item name={'company_name'} label={'Название компании'} rules={[{ required: true }]}>
								<Input />
							</Form.Item>
						</Col>

						<Col span={12}>
							<Form.Item name={'ceo'} label={'Директор'} rules={[{ required: true }]}>
								<Input />
							</Form.Item>
						</Col>
					</Row>

					<Row gutter={15}>
						<Col span={8}>
							<Form.Item name={'registration_date'} label={'Дата регистрации'}>
								<DatePicker format={'DD MMMM YYYY'} />
							</Form.Item>
						</Col>

						<Col span={8}>
							<Form.Item name={'inn'} label={'ИНН'} rules={[{ required: selectedContractor.isNewStore }]}>
								<Input disabled={selectedContractor.isNotNewStore} />
							</Form.Item>
						</Col>

						<Col span={8}>
							<Form.Item name={'email'} label={'Email'} rules={[{ required: true }]}>
								<Input />
							</Form.Item>
						</Col>
					</Row>

					<Row gutter={15}>
						<Col span={24}>
							<Form.Item name={'legal_address'} label={'Юридический адрес'} rules={[{ required: true }]}>
								<Input.TextArea autoSize />
							</Form.Item>
						</Col>
					</Row>

					<Row gutter={15}>
						<Col span={12}>
							<Form.Item name={'verified'} label={'Верифицирован'} valuePropName={'checked'}>
								<Checkbox onChange={(e) => setVerified(e.target.checked)} />
							</Form.Item>
						</Col>

						<Col span={12}>
							<Form.Item
								name={'active_passed'}
								label={'Верифицирован до'}
								rules={[
									{
										required: verified,
									},
								]}
							>
								<DatePicker format={'DD MMMM YYYY'} disabled={!verified} />
							</Form.Item>
						</Col>
					</Row>

					<Divider />
					<UserPhones />
					<Divider />

					<Row gutter={15}>
						<Col span={12}>
							<UploadFilesBlock form={form} label={'Файлы'} nameList={'files'} />
						</Col>

						<Col span={12}>
							<UploadedUserBlock files={selectedContractor.files} onDelete={handleOnDeleteFile} />
						</Col>
					</Row>
				</Form>
			</Drawer>
		</>
	);
});
