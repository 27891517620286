import { EyeOutlined } from '@ant-design/icons';
import { Button, List, Tooltip } from 'antd';
import { observer } from 'mobx-react-lite';

import { useStores } from '../../../../../stores';
import { TaskStore } from '../../../../../stores/task/task.store';

export const Tasks = observer(() => {
	const { userDashboardStore } = useStores();
	const { volumes, tasks } = userDashboardStore;
	const { viewDetail } = volumes;
	const { data: viewVolume } = viewDetail;

	const handleOnShowTask = async (task: TaskStore) => {
		const { viewDetail } = tasks;
		viewDetail.setData(task);
		await viewDetail.data.get();
		viewDetail.modal.open();
	};

	return (
		<>
			<List
				itemLayout={'horizontal'}
				dataSource={viewVolume.tasks.list}
				renderItem={(task) => {
					const description = (
						<span
							dangerouslySetInnerHTML={{
								__html: `
								${task.content}
								<br/><br/>
								${task.startDateFormatted} - ${task.endDateFormatted} | Исполнитель : ${task.responsible.shortFullName}
`,
							}}
						/>
					);
					return (
						<List.Item
							key={task.id}
							actions={[
								<Tooltip key={'view-task'} title={`Просмотреть задачу ${task.title}`}>
									<Button icon={<EyeOutlined />} onClick={() => handleOnShowTask(task)} />
								</Tooltip>,
							]}
						>
							<List.Item.Meta title={`${task.title}`} description={description} />
						</List.Item>
					);
				}}
			/>
		</>
	);
});
