import { action, makeObservable, observable } from 'mobx';

import { ItemStatusShort } from '../../interfaces/item';

export class ItemStatusShortStore implements ItemStatusShort {
	@observable id = 0;
	@observable name = '';

	constructor(initialData: ItemStatusShort | null) {
		makeObservable(this);

		if (initialData) {
			this.fillStore(initialData);
		}
	}

	@action
	fillStore(data: ItemStatusShort) {
		const { id, name } = data;

		this.id = id;
		this.name = name;
	}
}
