import { action, computed, makeObservable, observable } from 'mobx';

import { DialogStore } from './dialog.store';
import { API } from '../../core';
import { ErrorAPI } from '../../helpers';
import { Dialog } from '../../interfaces';
import { CoreResponse } from '../../responses';
import { RootStore } from '../index';

export class DialogsStore {
	static PATH = 'dialogs';

	rootStore: RootStore;

	@observable selected: null | DialogStore = null;
	@observable list: Array<DialogStore> = [];

	constructor(rootStore: RootStore) {
		makeObservable(this);

		this.rootStore = rootStore;
	}

	@action.bound
	async getList() {
		try {
			const { data } = await API.request<CoreResponse<Dialog[]>>(`${DialogsStore.PATH}`);
			this.setList(data);
		} catch (e) {
			ErrorAPI('DialogsStore', e);
		}
	}

	@action.bound
	createNew() {
		this.setSelected(new DialogStore(null, this.rootStore));
	}

	@action.bound
	setList(values: Dialog[]) {
		this.list = (values || []).map((dialog) => new DialogStore(dialog, this.rootStore));
	}

	@action.bound
	setSelected(value: DialogStore) {
		this.selected = value;
	}

	@computed
	get notSelected() {
		return !this.selected;
	}

	@computed
	get empty() {
		return this.list.length === 0;
	}
}
