import { action, makeObservable, observable } from 'mobx';

import { DRAWER_WINDOW } from '../../constants';

export class DrawerStore {
	@observable isShow = DRAWER_WINDOW.CLOSE;
	@observable isError = false;

	constructor() {
		makeObservable(this);
	}

	@action.bound
	setIsShow(value: boolean) {
		this.isShow = value;
	}

	@action.bound
	show() {
		this.setIsShow(DRAWER_WINDOW.OPEN);
	}

	@action.bound
	close() {
		this.setIsShow(DRAWER_WINDOW.CLOSE);
	}

	@action.bound
	setIsError(value: boolean) {
		this.isError = value;
	}
}
