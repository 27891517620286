import styled from 'styled-components';

export const Container = styled.div`
	display: grid;
	grid-template-columns: 1fr minmax(100px, auto);
	grid-auto-flow: column;
	column-gap: 20px;
	justify-content: right;
	align-items: center;
	margin: 15px 0 25px;
`;
