import { useState } from 'react';

import { AuthForm } from '../../../constants';
import { LoginForm, RecoverPasswordForm } from '../../ui';

export const LoginPage = () => {
	const [currentForm, setCurrentForm] = useState<AuthForm>(AuthForm.LOGIN);

	const handleOnChangeForm = (value: AuthForm) => {
		setCurrentForm(value);
	};

	if (currentForm === AuthForm.LOGIN) {
		return <LoginForm onChangeForm={handleOnChangeForm} />;
	}

	if (currentForm === AuthForm.RECOVER) {
		return <RecoverPasswordForm onChangeForm={handleOnChangeForm} />;
	}
	return null;
};

export default LoginPage;
