export const Forms = {
	ProjectGenerate: {
		BaseForm: 'items',
		Tasks: 'tasks',
		Fields: {
			NumberSection: 'number_section',
			Nomination: 'nomination',
			VolumeDates: 'volume_dates',
			Note: 'note',
			IsFinish: 'is_finish',
			CountDay: 'count_day',
			Title: 'title',
			Description: 'description',
			ResponsibleId: 'responsible_id',
			CreatorId: 'creator_id',
			StartDate: 'start_date',
			EndDate: 'end_date',
			Triggers: 'triggers',
			InsideUUID: 'inside_uuid',
		},
	},
};
