import { action, computed, makeObservable, observable } from 'mobx';

import { ObjectStatusStore } from './object-status.store';
import { API } from '../../core';
import { ErrorAPI } from '../../helpers';
import { ObjectStatusI } from '../../interfaces';
import { CoreResponse } from '../../responses';
import { RootStore } from '../index';

export class ObjectStatusesStore {
	private rootStore: RootStore;

	@observable list: ObjectStatusStore[] = [];

	constructor(rootStore: RootStore) {
		makeObservable(this);
		this.rootStore = rootStore;
	}

	@action.bound
	async getList() {
		try {
			const { data } = await API.request<CoreResponse<ObjectStatusI[]>>(`object-status`);
			this.setList(data);
		} catch (e) {
			ErrorAPI('getList', e);
		}
	}

	@action.bound
	setList(values: ObjectStatusI[]) {
		this.list = (values || []).map((status) => new ObjectStatusStore(status));
	}

	@computed
	get selectBoxList() {
		const { object_type_id, isNewStore } = this.rootStore.objectsStore.selected;
		return this.list
			.filter((workObjectStatus) => workObjectStatus.object_type_id === object_type_id)
			.map((workObjectStatus, index) => ({
				disabled: index !== 0 && isNewStore,
				title: workObjectStatus.name,
				value: workObjectStatus.id,
				children: (workObjectStatus.children || []).map((subChild) => ({
					title: subChild.name,
					value: subChild.id,
					disabled: isNewStore,
				})),
			}));
	}
}
