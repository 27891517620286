import { action, makeObservable, observable } from 'mobx';

import { Item } from '../../interfaces/item';
import { TableStore } from '../common/table.store';
import { UserItemStore } from '../user-items';

export class TaskExchangeStore {
	@observable table = new TableStore({
		url: 'task-exchange',
		getListItemStore: this.getListItemStore,
		getPayloadParams: this.getPayloadParams.bind(this),
	});

	constructor() {
		makeObservable(this);
	}

	@action.bound
	getListItemStore(value: Item): UserItemStore {
		return new UserItemStore(value);
	}

	@action.bound
	getPayloadParams() {
		return {};
	}
}
