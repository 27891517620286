import { IdcardOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Upload } from 'antd';
import { RcFile } from 'antd/es/upload/interface';
import { observer } from 'mobx-react-lite';

import { AvatarExtra, Container, UserInitials, UserPost } from './UserBlock.styled';
import { useStores } from '../../../../../stores';

export const UserBlock = observer(() => {
	const { authStore } = useStores();
	const { current } = authStore;

	const handleOnEditProfile = () => {
		authStore.drawer.show();
	};

	const handleOnUpload = async (file: RcFile) => {
		await authStore.current.uploadAvatar({
			file: file,
		});

		return false;
	};

	return (
		<Container>
			<Upload beforeUpload={handleOnUpload} showUploadList={false}>
				<AvatarExtra icon={<UserOutlined />} size={120} src={current.avatarFullPath} />
			</Upload>

			<UserInitials>{current.shortFullName}</UserInitials>
			<UserPost>{current.roleName}</UserPost>

			<Button type={'link'} icon={<IdcardOutlined />} onClick={handleOnEditProfile}>
				Профиль
			</Button>
		</Container>
	);
});
