import { Badge, FormInstance, Tabs, TabsProps } from 'antd';
import { useWatch } from 'antd/es/form/Form';
import { observer } from 'mobx-react-lite';

import { InformationTab, TasksTab } from './Tabs';

interface VolumeItemProps {
	form: FormInstance;
	volumeIndex: number;
}

export const VolumeItem = observer(({ form, volumeIndex }: VolumeItemProps) => {
	const item_volume_tasks = useWatch(['item_volume_generates', volumeIndex, 'item_volume_tasks'], form) || [];

	const items: TabsProps['items'] = [
		{
			key: 'information',
			label: 'Информация о томе',
			children: <InformationTab form={form} volumeIndex={volumeIndex} />,
		},
		{
			key: 'tasks',
			label: (
				<>
					Задачи
					<Badge count={item_volume_tasks.length} style={{ marginLeft: 5 }} />
				</>
			),
			children: <TasksTab form={form} volumeIndex={volumeIndex} />,
		},
	];

	return <Tabs items={items} />;
});
