import { UnlockOutlined } from '@ant-design/icons';
import { Button, Form, Input } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { observer } from 'mobx-react-lite';

import { Container } from './RecoverPasswordForm.styled';
import { AuthForm, BASE_RULE_FRAME_MOTION } from '../../../../constants';
import { AuthRecoveryPassowrdRequest } from '../../../../requests';
import { useStores } from '../../../../stores';
import { Logotype } from '../LoginForm/LoginForm.styled';

interface RecoverPasswordFormProps {
	onChangeForm: (value: AuthForm) => void;
}

export const RecoverPasswordForm = observer(({ onChangeForm }: RecoverPasswordFormProps) => {
	const { authStore } = useStores();
	const [form] = useForm();

	const handleOnFinish = async (values: AuthRecoveryPassowrdRequest) => {
		try {
			// Отправлчяем запрос
			await authStore.recoveryPassword(values);

			// Очищаем форму
			form.resetFields();
		} catch (e) {
			/* empty */
		}
	};

	const handleOnToLoginPage = () => {
		onChangeForm(AuthForm.LOGIN);
	};

	return (
		<Container {...BASE_RULE_FRAME_MOTION}>
			<Logotype src={'/images/logotype.png'} />

			<Form
				layout={'vertical'}
				form={form}
				initialValues={{
					email: '',
					password: '',
				}}
				onFinish={handleOnFinish}
			>
				<Form.Item name={'email'} label={'Email'} rules={[{ required: true, type: 'email' }]}>
					<Input placeholder={'Введите email'} />
				</Form.Item>

				<Form.Item style={{ textAlign: 'center', marginBottom: 10 }}>
					<Button htmlType={'submit'} type={'primary'} icon={<UnlockOutlined />} loading={authStore.isLoading}>
						Восстановить пароль
					</Button>
				</Form.Item>

				<Form.Item style={{ textAlign: 'center' }}>
					<Button type={'link'} onClick={handleOnToLoginPage}>
						Войти в систему
					</Button>
				</Form.Item>
			</Form>
		</Container>
	);
});
