import { Col, DatePicker, Form, FormInstance, Input, Row, Select } from 'antd';
import FormItem from 'antd/es/form/FormItem';
import { observer } from 'mobx-react-lite';

import { useStores } from '../../../../../stores';

interface InformationProps {
	form: FormInstance;
}

export const Information = observer(({ form }: InformationProps) => {
	const { objectsStore, responsiblesStore } = useStores();
	const { selected: currentObject } = objectsStore;
	const { volumes } = currentObject;
	const { selected: currentVolume } = volumes;

	return (
		<>
			<Form.Item name={'volume_id'} hidden>
				<Input />
			</Form.Item>

			<Row gutter={15}>
				<Col span={8}>
					<FormItem label={'Исполнитель'} name={'responsible_id'}>
						<Select>
							{responsiblesStore.list.map((user) => {
								return (
									<Select.Option key={user.id} value={user.id}>
										{user.shortNameWithRole}
									</Select.Option>
								);
							})}
						</Select>
					</FormItem>
				</Col>

				<Col span={8}>
					<FormItem shouldUpdate>
						{() => {
							const volumeStartDate = currentVolume.start_date;
							const volumeEndDate = currentVolume.end_date;

							return (
								<Form.Item
									label={'Начало работ'}
									name={'start_date'}
									rules={[
										{ required: true },
										{
											validator: async () => {
												if (volumeStartDate) {
													return Promise.resolve();
												} else {
													return Promise.resolve();
												}
											},
										},
									]}
								>
									<DatePicker
										format={'DD MMMM YYYY'}
										disabledDate={(current) => {
											if (volumeStartDate && current && volumeEndDate) {
												return (current && current <= volumeStartDate) || current >= volumeEndDate.endOf('day');
											} else {
												return false;
											}
										}}
									/>
								</Form.Item>
							);
						}}
					</FormItem>
				</Col>

				<Col span={8}>
					<FormItem shouldUpdate style={{ margin: 0 }}>
						{() => {
							const fieldStartDate = form.getFieldValue('start_date');
							const volumeEndDate = currentVolume.end_date;

							return (
								<Form.Item label={'Окончание работ'} name={'end_date'} rules={[{ required: true }]}>
									<DatePicker
										picker={'date'}
										format={'DD MMMM YYYY'}
										disabledDate={(current) => {
											if (fieldStartDate && current && volumeEndDate) {
												return (current && current <= fieldStartDate) || current > volumeEndDate.endOf('day');
											} else {
												return false;
											}
										}}
									/>
								</Form.Item>
							);
						}}
					</FormItem>
				</Col>
			</Row>

			<Row gutter={15}>
				<Col span={24}>
					<Form.Item label={'Название задачи'} name={'title'} rules={[{ required: true }]}>
						<Input />
					</Form.Item>
				</Col>
			</Row>

			<Row gutter={15}>
				<Col span={24}>
					<Form.Item label={'Описание задачи'} name={'content'} rules={[{ required: true }]}>
						<Input.TextArea autoSize />
					</Form.Item>
				</Col>
			</Row>
		</>
	);
});
