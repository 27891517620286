import { DeleteOutlined, DownloadOutlined, EyeOutlined } from '@ant-design/icons';
import { Button, Divider } from 'antd';

import { Container, DateUploaded, InformationFile, NameFile } from './FileButtons.styled';
import { checkIsViewFile } from '../../../helpers';
import { FileStore } from '../../../stores/file/file.store';

interface FileButtonsProps {
	file: FileStore;
	readOnly?: boolean;
}

export const FileButtons = ({ file, readOnly = false }: FileButtonsProps) => {
	const handleOnViewFile = () => {
		window.open(file.fullPath, '_blank');
	};

	const handleOnDownload = async () => {
		const data = await fetch(file.fullPath);
		const blob = await data.blob();
		const objectUrl = URL.createObjectURL(blob);

		const link = document.createElement('a');

		link.setAttribute('href', objectUrl);
		link.setAttribute('download', file.origin_name);
		link.style.display = 'none';

		document.body.appendChild(link);

		link.click();

		document.body.removeChild(link);
	};

	const generateButtons = () => {
		const buttons: Array<React.ReactNode> = [];

		// Добавляем просмотр
		if (checkIsViewFile(file.origin_name)) {
			buttons.push(<Button key={'view-file'} icon={<EyeOutlined />} type={'dashed'} onClick={handleOnViewFile} />);
		}

		// Добавляем скачивание
		buttons.push(
			<Button key={'download-file'} icon={<DownloadOutlined />} type={'primary'} onClick={handleOnDownload} />,
		);

		// Удаление
		if (!readOnly) {
			buttons.push(<Button key={'delete-file'} icon={<DeleteOutlined />} type={'primary'} danger />);
		}

		return buttons;
	};

	return (
		<Container>
			<InformationFile>
				<NameFile>{file.origin_name}</NameFile>
				<DateUploaded>
					{file.formattedCreatedAt} <Divider type={'vertical'} />
				</DateUploaded>
			</InformationFile>
			<Button.Group style={{ marginRight: 10 }}>{generateButtons()}</Button.Group>
		</Container>
	);
};
