import { useEffect } from 'react';

import { Form, Input, Modal, Tree, TreeProps } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { observer } from 'mobx-react-lite';

import { getButtonName, getTitle } from '../../../../helpers';
import { Role } from '../../../../interfaces';
import { useStores } from '../../../../stores';
import { RoleStore } from '../../../../stores/role/role.store';

export const RoleModal = observer(() => {
	const [form] = useForm();
	const { rolesStore, permissionsStore } = useStores();
	const { selected: currentRole } = rolesStore;

	const fields = {
		name: currentRole.name,
	};

	useEffect(() => {
		(async () => {
			await permissionsStore.getList();
		})();
	}, []);

	// Заполняем данными
	useEffect(() => {
		form.setFieldsValue({
			...fields,
		});
	}, [currentRole.id, currentRole.name]);

	const handleOnClose = () => {
		rolesStore.clearSelected();
		rolesStore.modal.close();
	};

	const handleOnSubmit = () => {
		form.submit();
	};

	const handleOnSend = async (value: Pick<RoleStore, 'name' | 'permissionsList'>) => {
		// Записваем выбранные доступы для роли
		value.permissionsList = currentRole.permissionsList;

		if (currentRole.isNewStore) {
			await currentRole.create(value as Role);
			await rolesStore.table.getList();
			handleOnClose();
		} else {
			await currentRole.update(value as Role);
			await rolesStore.table.getList();
		}

		// Закрываем окно
		//rolesStore.modal.close();
	};

	//const onSelect: TreeProps['onSelect'] = () => {};

	const onCheck: TreeProps['onCheck'] = (checkedKeys) => {
		if (Array.isArray(checkedKeys)) {
			currentRole.togglePermission(checkedKeys.map((id) => String(id)));
		}
	};

	return (
		<Modal
			forceRender
			open={rolesStore.modal.isShow}
			centered
			width={1000}
			styles={{
				body: {
					height: 600,
					overflowY: 'auto',
				},
			}}
			onCancel={handleOnClose}
			onOk={handleOnSubmit}
			okText={getButtonName(currentRole.isNewStore)}
			title={getTitle('должности', currentRole.isNewStore, currentRole.name)}
		>
			<Form name={'role'} layout={'vertical'} form={form} onFinish={handleOnSend}>
				<Form.Item label={'Название должности'} name={'name'} rules={[{ required: true }]}>
					<Input />
				</Form.Item>
				<Form.Item label={'Доступы'}>
					<Tree
						checkable
						treeData={permissionsStore.transferList}
						checkedKeys={currentRole.permissionsList}
						onCheck={onCheck}
					/>
				</Form.Item>
			</Form>
		</Modal>
	);
});
