import React, { useCallback, useEffect, useRef, useState } from 'react';

import { ArrowDownOutlined, ArrowUpOutlined, CheckOutlined, NodeIndexOutlined } from '@ant-design/icons';
import { Button, Col, Divider, Radio, RadioChangeEvent, Row, Tour, Typography } from 'antd';
import { debounce } from 'debounce';
import { observer } from 'mobx-react-lite';

import { FilterItemBy } from '../../../../interfaces';
import { useStores } from '../../../../stores';
import { ContainerBody, ContainerBodyPadding, GlobalSearch } from '../../../common';
import { ExtraActionsBlock, TwoColumnsContainer } from '../../../common/ContainerBody/ContainerBody.styled';
import { DashboardTable, WidgetGreetings, WidgetLastUpdate, WidgetSalary } from '../../../ui';

export const DashboardPage = observer(() => {
	const { userItemsStore, workWeekStore, itemLocalStatusesStore, itemTypesStore, authStore, itemObjectTypesStore } =
		useStores();

	const [isOpen, setOpen] = useState(false);

	const ref1 = useRef(null);
	const ref2 = useRef(null);
	const ref3 = useRef(null);

	useEffect(() => {
		userItemsStore.setFilterBy(FilterItemBy.ForMe);

		(async () => {
			await workWeekStore.getList();
			await itemLocalStatusesStore.getList();
			await itemTypesStore.getList();
			await itemObjectTypesStore.getList();
		})();
	}, []);

	const steps = [
		{
			title: 'Заголовок',
			description: 'Краткое описание функциональности данного блока',
			target: () => ref1.current,
		},
		{
			title: 'Заголовок для индивидуального элемента',
			description: 'Краткое описание функциональности данного блока',
			target: () => ref2.current,
		},
	];

	// Запрос на бэе
	const handelOnRequest = async () => await userItemsStore.all.getList(userItemsStore.filter.query);

	// При выходе со страницы чистим все фильтры
	useEffect(() => {
		return () => {
			userItemsStore.table.clearFilter();
		};
	}, []);

	// Поиск по назначению
	useEffect(() => {
		(async () => {
			await handelOnRequest();
		})();
	}, [userItemsStore.filter_by]);

	// Глобальный поиск
	useEffect(() => {
		if (userItemsStore.filter.query.length > 0) {
			handleOnSearch();
			return;
		}

		if (userItemsStore.filter.query.length === 0 && userItemsStore.filter.isDirty) {
			handleOnSearch();
		}
	}, [userItemsStore.filter.query]);

	const handleOnSearch = useCallback(
		debounce(() => {
			(async () => {
				await handelOnRequest();
			})();
		}, 300),
		[],
	);

	const handleOnChangeFilter = (e: RadioChangeEvent) => {
		userItemsStore.setFilterBy(e.target.value);
	};

	return (
		<>
			<Row gutter={[25, 15]}>
				<Col span={7} sm={24} xxl={8}>
					<WidgetGreetings />
				</Col>
				<Col span={7} sm={12} xxl={8}>
					<WidgetSalary />
				</Col>
				<Col span={7} sm={12} xxl={8}>
					<WidgetLastUpdate />
				</Col>
			</Row>

			<div style={{ height: 25 }} />

			<ContainerBody>
				<ContainerBodyPadding>
					<TwoColumnsContainer>
						<Typography.Title level={2}>Рабочий стол</Typography.Title>

						<ExtraActionsBlock>
							<Button
								onClick={() => {
									setOpen(true);
								}}
							>
								Тур
							</Button>

							<Divider type={'vertical'} />

							<Radio.Group
								ref={ref1}
								optionType={'button'}
								buttonStyle={'solid'}
								value={userItemsStore.filter_by}
								onChange={handleOnChangeFilter}
							>
								<Radio value={FilterItemBy.ForMe}>
									<ArrowDownOutlined /> Входящие
								</Radio>
								<Radio value={FilterItemBy.FromMe}>
									<ArrowUpOutlined /> Исходящие
								</Radio>
								<Radio value={FilterItemBy.Complete}>
									<CheckOutlined /> Выполненые
								</Radio>

								{authStore.current.role.isAdmin && (
									<Radio value={FilterItemBy.TaskExchange}>
										<NodeIndexOutlined /> В бирже задач
									</Radio>
								)}
							</Radio.Group>
						</ExtraActionsBlock>
					</TwoColumnsContainer>

					<div ref={ref2}>
						<GlobalSearch filterStore={userItemsStore.filter} />
					</div>

					<div ref={ref3}>
						<DashboardTable />
					</div>
				</ContainerBodyPadding>
			</ContainerBody>

			<Tour
				open={isOpen}
				onClose={() => setOpen(false)}
				steps={steps}
				indicatorsRender={(current, total) => (
					<span>
						{current + 1} / {total}
					</span>
				)}
			/>
		</>
	);
});
