import { action, makeObservable, observable } from 'mobx';

export class ModalStore {
	@observable isShow = false;

	constructor() {
		makeObservable(this);
	}

	@action.bound
	open() {
		this.isShow = true;
	}

	@action.bound
	close() {
		this.isShow = false;
	}
}
