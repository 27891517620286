import { action, makeObservable, observable } from 'mobx';

import { ItemObjectType } from '../../interfaces/item';
import { CrudStore } from '../common/crud.store';

export class ItemObjectTypeStore extends CrudStore<ItemObjectType> implements ItemObjectType {
	@observable name = '';
	@observable slug = '';
	@observable description = '';

	constructor(initialData: ItemObjectType | null) {
		super();
		makeObservable(this);

		if (initialData) {
			this.fillStore(initialData);
		}
	}

	@action
	fillStore(data: ItemObjectType) {
		const { id, name, slug, description } = data;

		this.id = id;
		this.name = name;
		this.slug = slug;
		this.description = description;
	}
}
