import { action, makeObservable, observable } from 'mobx';

export class UserCountStore {
	@observable count = 0;

	constructor() {
		makeObservable(this);
	}

	@action.bound
	setCount(value: number) {
		this.count = value;
	}
}
