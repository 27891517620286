import { DeleteOutlined } from '@ant-design/icons';
import { Button, Col, Divider, Form, FormInstance, FormListFieldData, Row, Select } from 'antd';
import { useWatch } from 'antd/es/form/Form';
import { observer } from 'mobx-react-lite';

import { TriggerGoToTaskAction } from './Actions';
import { TriggerGoToTaskWithNotificationAction } from './Actions/TriggerGoToTaskWithNotificationAction';
import { TriggerSelectItems } from '../../../../../../../constants';

interface TriggerItemProps {
	field: FormListFieldData;
	form: FormInstance;
	onDeleteTriggerItem: (id: number) => void;
}

export const TriggerItem = observer(({ field, form, onDeleteTriggerItem }: TriggerItemProps) => {
	// Ватчер на поле выбора варианта
	const conditionFieldWatch = useWatch(['trigger_workflows', field.name, 'condition'], form);

	return (
		<>
			<Row>
				<Col span={24} style={{ display: 'flex', justifyContent: 'space-between', columnGap: 25 }}>
					<div style={{ flex: 1 }}>
						<Form.Item name={[field.name, 'condition']}>
							<Select placeholder={'Условие'} style={{ width: '100%' }} allowClear>
								<Select.Option value={TriggerSelectItems.GoToTaskWithNotification}>
									Показать уведомление и направить на задачу
								</Select.Option>
								<Select.Option value={TriggerSelectItems.GoToTask}>Направить на задачу</Select.Option>
							</Select>
						</Form.Item>
					</div>

					<div>
						<Button danger type="primary" icon={<DeleteOutlined />} onClick={() => onDeleteTriggerItem(field.name)} />
					</div>
				</Col>
			</Row>
			{conditionFieldWatch && (
				<>
					<TriggerGoToTaskAction form={form} conditionField={conditionFieldWatch} triggerItemNumber={field.name} />
					<TriggerGoToTaskWithNotificationAction conditionField={conditionFieldWatch} triggerItemNumber={field.name} />
				</>
			)}
			{conditionFieldWatch && (
				<>
					{/*<TriggerGoToTaskWithNotification
            conditionField={conditionFieldWatch}
            triggerItemNumber={field.name}
            listVolumes={listVolumes}
            currentTask={currentTask}
          />

          <TriggerGoToTask
            conditionField={conditionFieldWatch}
            triggerItemNumber={field.name}
            listVolumes={listVolumes}
            currentTask={currentTask}
          />*/}
				</>
			)}
			<Divider />
		</>
	);
});
