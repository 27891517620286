import { MinusCircleOutlined } from '@ant-design/icons';
import { Col, Form, FormListFieldData, Input, InputNumber, Popconfirm, Row } from 'antd';
import { MaskedInput } from 'antd-mask-input';

interface UserPhoneItemProps {
	field: FormListFieldData;
	onRemove: (item: number) => void;
}

export const UserPhoneItem = ({ field, onRemove }: UserPhoneItemProps) => {
	return (
		<Row gutter={15}>
			<Form.Item hidden name={[field.name, 'id']} initialValue={0}>
				<InputNumber />
			</Form.Item>

			<Col span={10}>
				<Form.Item label="Название" name={[field.name, 'name']} initialValue={''} rules={[{ required: true }]}>
					<Input />
				</Form.Item>
			</Col>

			<Col span={10}>
				<Form.Item label="Номер телефона" name={[field.name, 'phone']} initialValue={''} rules={[{ required: true }]}>
					<MaskedInput mask={'+7 (000) 000 00 00'} />
				</Form.Item>
			</Col>

			<Col span={4} style={{ display: 'flex', alignItems: 'center' }}>
				<Popconfirm title={'Удалить телефон?'} onConfirm={() => onRemove(field.name)}>
					<MinusCircleOutlined style={{ marginTop: 10 }} />
				</Popconfirm>
			</Col>
		</Row>
	);
};
