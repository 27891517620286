import dayjs from 'dayjs';
import { action, computed, makeObservable, observable } from 'mobx';

import { AgreementPrivateContractorFile } from '../../interfaces';

export class AgreementPrivateContractorFileStore implements AgreementPrivateContractorFile {
	@observable id = 0;
	@observable agreement_contractor_id = 0;
	@observable origin_name = '';
	@observable file_name = '';
	@observable size_file = 0;
	@observable is_delete = false;
	@observable created_at = dayjs();
	@observable updated_at = dayjs();

	constructor(initialData: AgreementPrivateContractorFile | null) {
		makeObservable(this);

		if (initialData) {
			this.fillStore(initialData);
		}
	}

	@computed
	get fullPath(): string {
		return `${process.env.REACT_APP_CORE_FILE_URL}${this.file_name}`;
	}

	@action
	fillStore(data: AgreementPrivateContractorFile) {
		const { id, agreement_contractor_id, origin_name, file_name, size_file, is_delete, created_at, updated_at } = data;

		this.id = id;
		this.agreement_contractor_id = agreement_contractor_id;
		this.origin_name = origin_name;
		this.file_name = file_name;
		this.size_file = size_file;
		this.is_delete = is_delete;
		this.created_at = dayjs(created_at);
		this.updated_at = dayjs(updated_at);
	}
}
