import { FormInstance } from 'antd';
import { observer } from 'mobx-react-lite';

import { useStores } from '../../../../../stores';
import { FileUploadBlock } from '../../../../common';

interface FilesProps {
	form: FormInstance;
}

export const Files = observer(({ form }: FilesProps) => {
	const { objectsStore } = useStores();
	const { selected: currentObject } = objectsStore;
	const { volumes } = currentObject;
	const { selected: currentVolume } = volumes;
	const { tasks } = currentVolume;
	const { selected: currentTask } = tasks;

	console.log(form);

	/*const handleOnDelete = async (taskFile: TaskFileStore) => {
    try {
      await taskFile.delete();
      currentTask.files.deleteFile(taskFile);
    } catch {
      /!* empty *!/
    }
  };*/

	return (
		<>
			<FileUploadBlock label={'Загрузка файлов'} nameField={'files'} list={currentTask.files.list} />
		</>
	);
});
