import { Col, Row, Statistic, Typography } from 'antd';
import { observer } from 'mobx-react-lite';

import { getGreetings } from '../../../../helpers';
import { useStores } from '../../../../stores';
import { Widget } from '../../../common';

export const WidgetGreetings = observer(() => {
	const { authStore, workWeekStore } = useStores();
	const { current } = authStore;

	return (
		<Widget>
			<Typography.Title level={3}>
				{getGreetings()}, {current.first_name}.
			</Typography.Title>

			<Typography.Title level={5}>На этой неделе необходимо завершить</Typography.Title>

			<Row>
				<Col span={8}>
					<Statistic
						title={'Объектов'}
						value={workWeekStore.objects.complete}
						suffix={`/ ${workWeekStore.objects.total}`}
					/>
				</Col>
				<Col span={8}>
					<Statistic
						title={'Томов'}
						value={workWeekStore.volumes.complete}
						suffix={`/ ${workWeekStore.volumes.total}`}
					/>
				</Col>
				<Col span={8}>
					<Statistic title={'Задач'} value={workWeekStore.tasks.complete} suffix={`/ ${workWeekStore.tasks.total}`} />
				</Col>
			</Row>
		</Widget>
	);
});
