import { useState } from 'react';

import { PlusOutlined } from '@ant-design/icons';
import { Button, Col, Form, FormInstance, Row } from 'antd';
import { Observer, observer } from 'mobx-react-lite';

import { VolumesList } from './VolumesList';
import { CollapseItem } from '../../../../../../interfaces';
import { ItemVolumeGenerateStore } from '../../../../../../stores/item-volume-generate';

interface VolumesProps {
	form: FormInstance;
}

export const Volumes = observer(({ form }: VolumesProps) => {
	// Наблюдаем список томов
	const volumeListWatch: ItemVolumeGenerateStore[] = Form.useWatch(['item_volume_generates'], form);

	// Текущая вкладка тома
	const [currentCollapse, setCurrentCollapse] = useState<CollapseItem>('');

	// Добавление еще одного тома
	const handleOnOpenLastVolume = () => {
		const lastVolumeItem = String(Object.keys(volumeListWatch).length);

		setCurrentCollapse([lastVolumeItem]);
	};

	return (
		<Observer>
			{() => {
				return (
					<Form.List name={'item_volume_generates'}>
						{(fields, { add: onAddVolume, remove: onRemoveVolume }) => {
							return (
								<>
									<Row>
										<Col span={4} offset={10}>
											<Form.Item>
												<Button
													type="primary"
													onClick={() => {
														onAddVolume();
														handleOnOpenLastVolume();
													}}
													block
													icon={<PlusOutlined />}
												>
													Добавить том
												</Button>
											</Form.Item>
										</Col>
									</Row>

									<VolumesList
										form={form}
										fields={fields}
										onRemove={onRemoveVolume}
										currentCollapse={currentCollapse}
										onSetCurrentCollapse={setCurrentCollapse}
									/>
								</>
							);
						}}
					</Form.List>
				);
			}}
		</Observer>
	);
});
