import { useEffect } from 'react';

import { Form, Modal, Tabs, TabsProps } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { observer } from 'mobx-react-lite';

import { Information } from './Information/Information';
import { Volumes } from './Volumes/Volumes';
import { PROJECT_DOCUMENTATION_TABS } from '../../../../constants';
import { ErrorAPI, getButtonName, getTitle } from '../../../../helpers';
import { ItemProjectDocument } from '../../../../interfaces/item-project-document';
import { useStores } from '../../../../stores';

export const ProjectDocumentationModal = observer(() => {
	const { itemProjectDocumentsStore } = useStores();
	const { detail } = itemProjectDocumentsStore;

	const [form] = useForm();

	const itemTabs: TabsProps['items'] = [
		{
			key: PROJECT_DOCUMENTATION_TABS.MAIN,
			label: 'Основная информация',
			children: <Information form={form} />,
		},
		{
			key: PROJECT_DOCUMENTATION_TABS.VOLUMES,
			label: 'Тома',
			children: <Volumes form={form} />,
		},
	];

	// Поля
	const fields = {
		id: detail.data.id,
		item_object_type_id: detail.data.item_object_type_id,
		sort: detail.data.isNewStore ? itemProjectDocumentsStore.lastSort : detail.data.sort,
		name: detail.data.name,
		description: detail.data.description,
		item_volume_generates: detail.data.formListData,
	};

	// Очистка стора
	useEffect(() => {
		if (!detail.modal.isShow) {
			itemProjectDocumentsStore.clearDetail();
		}
	}, [detail.modal.isShow]);

	// Заполнение данных
	useEffect(() => {
		if (detail.modal.isShow) {
			form.setFieldsValue({
				...fields,
			});
		}
	}, [detail.data.id, detail.data.item_object_type_id, detail.modal.isShow]);

	const handleOnSend = async (values: ItemProjectDocument) => {
		try {
			if (detail.data.isNewStore) {
				await detail.data.create(values);

				// Закрываем окно
				detail.modal.close();
			} else {
				await detail.data.update(values);
			}

			// Обновление списка
			await itemProjectDocumentsStore.table.getList();
		} catch (e) {
			ErrorAPI('handleOnSend', e);
		}
	};

	return (
		<>
			<Modal
				forceRender
				title={getTitle('проектной документации', detail.data.isNewStore)}
				open={detail.modal.isShow}
				onOk={form.submit}
				onCancel={detail.modal.close}
				width={1200}
				styles={{
					body: {
						height: 700,
						overflowY: 'auto',
						overflowX: 'hidden',
					},
				}}
				okText={getButtonName(detail.data.isNewStore)}
			>
				<Form form={form} layout={'vertical'} autoComplete="off" onFinish={handleOnSend}>
					<Tabs items={itemTabs} />
				</Form>
			</Modal>
		</>
	);
});
