import { PlusOutlined } from '@ant-design/icons';
import { Alert, Button, Form } from 'antd';

import { UserPhoneItem } from './UserPhoneItem';

export const UserPhones = () => {
	return (
		<Form.List name="phones">
			{(fields, { add: onAddPhone, remove: onRemovePhone }) => (
				<>
					<Form.Item>
						<Button type="dashed" onClick={() => onAddPhone()} block icon={<PlusOutlined />}>
							Добавить телефон
						</Button>
					</Form.Item>

					{fields.length === 0 && <Alert type={'info'} message={`Телефон не указан`} showIcon />}

					{fields.map((field) => {
						return <UserPhoneItem key={field.name} field={field} onRemove={onRemovePhone} />;
					})}
				</>
			)}
		</Form.List>
	);
};
