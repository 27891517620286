import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Divider, Popconfirm, Tooltip } from 'antd';
import { ColumnsType } from 'antd/es/table';

import { useStores } from '../../../../stores';
import { RoleStore } from '../../../../stores/role/role.store';

export const useColumns = (): ColumnsType<RoleStore> => {
	const { rolesStore } = useStores();

	return [
		{
			title: 'ID',
			align: 'center',
			dataIndex: 'id',
			width: 100,
		},
		{
			title: 'Название должности',
			align: 'left',
			dataIndex: 'name',
		},
		{
			title: 'Дата создания',
			align: 'center',
			render: (_, record) => {
				return record.created_at ? record.created_at.format(`DD MMMM YYYY HH:mm:ss`) : null;
			},
		},
		{
			title: 'Дата обновления',
			align: 'center',
			render: (_, record) => {
				return record.updated_at ? record.updated_at.format(`DD MMMM YYYY HH:mm:ss`) : null;
			},
		},
		{
			title: '',
			align: 'center',
			render: (_, record) => {
				return (
					<>
						<Tooltip title="Редактировать должность">
							<Button
								icon={<EditOutlined />}
								onClick={() => {
									rolesStore.setSelected(record);
									rolesStore.modal.open();
								}}
							/>
						</Tooltip>

						<Divider type={'vertical'} />
						<Tooltip title={'Удалить должность'}>
							<Popconfirm
								title={`Удалить ${record.name}?`}
								onConfirm={async () => {
									await record.delete(false);
									rolesStore.table.deleteById(record.id);
								}}
							>
								<Button icon={<DeleteOutlined />} type={'primary'} danger />
							</Popconfirm>
						</Tooltip>
					</>
				);
			},
		},
	];
};
