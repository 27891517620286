import dayjs from 'dayjs';
import { action, computed, makeObservable, observable } from 'mobx';

import { ObjectContractorFileI } from '../../interfaces/object-contractor/object-contractor-file.interface';
import { CrudStore } from '../common/crud.store';

export class ObjectContractorFileStore extends CrudStore<ObjectContractorFileI> implements ObjectContractorFileI {
	PATH = 'object-contractor-files';

	@observable work_object_contractor_id = 0;
	@observable contractor_id = 0;
	@observable name = '';
	@observable origin_name = '';
	@observable file_name = '';
	@observable is_delete = false;
	@observable created_at = dayjs();
	@observable updated_at = dayjs();

	constructor(initialData: ObjectContractorFileI | null) {
		super();
		makeObservable(this);

		if (initialData) {
			this.fillStore(initialData);
		}
	}

	@computed
	get fullPath(): string {
		return `${process.env.REACT_APP_CORE_FILE_URL}${this.file_name}`;
	}

	@computed
	get formattedCreatedAt(): string {
		return this.created_at.format('HH:mm DD MMMM YYYY');
	}

	@action
	fillStore(data: ObjectContractorFileI) {
		const {
			id,
			work_object_contractor_id,
			contractor_id,
			name,
			origin_name,
			file_name,
			is_delete,
			created_at,
			updated_at,
		} = data;

		this.id = id;
		this.work_object_contractor_id = work_object_contractor_id;
		this.contractor_id = contractor_id;
		this.name = name;
		this.origin_name = origin_name;
		this.file_name = file_name;
		this.is_delete = is_delete;
		this.created_at = dayjs(created_at);
		this.updated_at = dayjs(updated_at);
	}
}
