import { Col, Form, FormInstance, Row, Select } from 'antd';
import { observer } from 'mobx-react-lite';

import { TriggerSelectItems } from '../../../../../../../../../constants';
import { TaskList } from '../../../../../../../../../interfaces';
import { useStores } from '../../../../../../../../../stores';

interface TriggerGoToTaskActionProps {
	form: FormInstance;
	conditionField: string;
	triggerItemNumber: number;
}

export const TriggerGoToTaskAction = observer(({ conditionField, triggerItemNumber }: TriggerGoToTaskActionProps) => {
	const { taskListStore, itemsStore } = useStores();
	const { selected } = itemsStore;

	if (conditionField !== TriggerSelectItems.GoToTask) {
		return null;
	}

	return (
		<Row gutter={15}>
			<Col span={24}>
				<Form.Item label={'Связать с задачей'} name={[triggerItemNumber, 'relation_inside_uuid']}>
					<Select
						showSearch
						filterOption={(inputValue, option) => {
							return String(option?.children ?? '')
								.toLowerCase()
								.includes(inputValue.toLowerCase());
						}}
					>
						{taskListStore.list.map((taskItem: TaskList) => {
							if (selected.id === taskItem.id) {
								return null;
							}

							return (
								<Select.Option key={taskItem?.inside_uuid} value={taskItem?.inside_uuid}>
									{taskItem?.title || taskItem?.inside_uuid}
								</Select.Option>
							);
						})}
					</Select>
				</Form.Item>
			</Col>
		</Row>
	);
});
