import { action, makeObservable, observable } from 'mobx';

import { ObjectNoteI } from './object-note.interface';
import { ObjectNoteStore } from '../../stores/object-note/object-note.store';

export class ObjectNotesStore {
	@observable list: ObjectNoteStore[] = [];

	constructor() {
		makeObservable(this);
	}

	@action.bound
	setList(values: ObjectNoteI[]) {
		this.list = (values || []).map((note) => new ObjectNoteStore(note));
	}

	@action.bound
	addStart(value: ObjectNoteStore) {
		const list = [...this.list];
		list.unshift(value);
		this.list = list.map((note) => new ObjectNoteStore(note));
	}
}
