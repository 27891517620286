import { Modal, Tabs, TabsProps } from 'antd';
import { observer } from 'mobx-react-lite';

import { Files } from './Files/Files';
import { Information } from './Information/Information';
import { TASK_TABS } from '../../../../constants';
import { useStores } from '../../../../stores';

export const TaskDetailModal = observer(() => {
	const { userDashboardStore } = useStores();
	const { tasks } = userDashboardStore;
	const { viewDetail } = tasks;
	const { data: viewTask } = viewDetail;

	const itemTabs: TabsProps['items'] = [
		{
			key: TASK_TABS.INFORMATION,
			label: 'Информация',
			children: <Information />,
		},
		{
			key: TASK_TABS.FILES,
			label: 'Файлы',
			children: <Files />,
		},
	];

	const handleOnClose = () => {
		viewDetail.modal.close();
	};

	return (
		<>
			<Modal
				open={viewDetail.modal.isShow}
				forceRender
				centered
				title={`Просмотр задачи : ${viewTask.title}`}
				width={1200}
				styles={{
					body: {
						height: 600,
					},
				}}
				okButtonProps={{ hidden: true }}
				cancelText={'Закрыть'}
				onCancel={handleOnClose}
			>
				<Tabs defaultActiveKey={TASK_TABS.INFORMATION} items={itemTabs} />
			</Modal>
		</>
	);
});
