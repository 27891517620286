import dayjs, { Dayjs } from 'dayjs';
import { action, computed, makeObservable, observable } from 'mobx';

import { ObjectNoteI } from '../../interfaces';
import { CrudStore } from '../common/crud.store';
import { UserDetailStore } from '../users/user-detail.store';

export class ObjectNoteStore extends CrudStore<ObjectNoteI> implements ObjectNoteI {
	PATH = 'object-notes';

	@observable object_id = 0;
	@observable content = '';
	@observable user = new UserDetailStore(null);
	@observable created_at: Dayjs | undefined = undefined;

	constructor(initialData: ObjectNoteI | null) {
		super();
		makeObservable(this);

		if (initialData) {
			this.fillStore(initialData);
		}
	}

	@action.bound
	setContent(value: string) {
		this.content = value;
	}

	@computed
	get createdAtFormatted() {
		if (this.created_at) return this.created_at.format('HH:mm:ss DD MMMM YYYY');

		return undefined;
	}

	@action
	fillStore(data: ObjectNoteI) {
		const { id, object_id, content, user, created_at } = data;

		this.id = id;
		this.object_id = object_id;
		this.content = content;
		this.user = new UserDetailStore(user);
		this.created_at = dayjs(created_at);
	}
}
