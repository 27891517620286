import { useEffect } from 'react';

import {
	Checkbox,
	Col,
	DatePicker,
	Form,
	FormInstance,
	Input,
	InputNumber,
	Radio,
	RadioChangeEvent,
	Row,
	Select,
	TreeSelect,
} from 'antd';
import { observer } from 'mobx-react-lite';

import { formattedPrice } from '../../../../../../../helpers';
import { useStores } from '../../../../../../../stores';

interface InformationTabProps {
	form: FormInstance;
}

export const InformationTab = observer(({ form }: InformationTabProps) => {
	const {
		itemsStore: { selected: currentItem },
		itemObjectTypesStore,
		itemStatusesStore,
		authStore: { current: currentUser },
		responsiblesStore,
	} = useStores();

	useEffect(() => {
		(async () => {
			//await itemObjectTypesStore.getList();
		})();
	}, []);

	const handleOnChangeType = (e: RadioChangeEvent) => {
		const { value } = e.target;
		currentItem.setItemObjectType(value);
	};

	return (
		<>
			<Form.Item name={'item_type_id'} hidden>
				<Input type={'hidden'} />
			</Form.Item>

			<Row gutter={15}>
				<Col span={12}>
					<Form.Item name={'item_object_type_id'} label={'Тип объекта'} rules={[{ required: true }]}>
						<Radio.Group
							disabled={currentItem.isNotNewStore}
							value={currentItem.item_object_type_id}
							buttonStyle={'solid'}
							onChange={handleOnChangeType}
							options={itemObjectTypesStore.radioButtons}
							style={{ width: '100%', display: 'flex', textAlign: 'center' }}
						/>
					</Form.Item>
				</Col>

				<Col span={12}>
					<Form.Item name={'is_draft'} label={'Черновик'} valuePropName={'checked'}>
						<Checkbox />
					</Form.Item>
				</Col>
			</Row>

			<Row gutter={15}>
				<Col span={12}>
					<Form.Item shouldUpdate label={'Статус'} name={'item_status_id'} rules={[{ required: true }]}>
						<TreeSelect treeData={itemStatusesStore.selectBoxList} />
					</Form.Item>
				</Col>

				<Col span={12}>
					<Form.Item label={'Шифр'}>
						{currentItem.isNewStore ? `Будет сгенерирован автоматически` : currentItem.cipher}
					</Form.Item>
				</Col>
			</Row>

			<Row gutter={15}>
				<Col span={12}>
					<Form.Item label={'Ответственный объекта'} name={'creator_id'}>
						<Select
							placeholder={'Выберите ответсвенного'}
							showSearch
							filterOption={(inputValue, option) => {
								return String(option?.children ?? '')
									.toLowerCase()
									.includes(inputValue.toLowerCase());
							}}
						>
							{responsiblesStore.list.map((responsible) => {
								return (
									<Select.Option key={responsible.id} value={responsible.id}>
										{responsible.shortFullName}
									</Select.Option>
								);
							})}
						</Select>
					</Form.Item>
				</Col>

				<Col span={12}>
					<Form.Item label={'Исполнитель объекта'} name={'responsible_id'}>
						<Select
							placeholder={'Выберите исполнителя'}
							showSearch
							filterOption={(inputValue, option) => {
								return String(option?.children ?? '')
									.toLowerCase()
									.includes(inputValue.toLowerCase());
							}}
						>
							{responsiblesStore.list.map((responsible) => {
								return (
									<Select.Option key={responsible.id} value={responsible.id}>
										{responsible.shortFullName}
									</Select.Option>
								);
							})}
						</Select>
					</Form.Item>
				</Col>
			</Row>

			<Row gutter={15}>
				<Col span={24}>
					<Form.Item label={'Титул проекта'} name={'title'} rules={[{ required: true }]}>
						<Input />
					</Form.Item>
				</Col>
			</Row>

			<Row gutter={15}>
				<Col span={12}>
					<Form.Item label={'Номер договора'} name={'contract_number'}>
						<Input />
					</Form.Item>
				</Col>
				<Col span={12}>
					<Form.Item label={'Стоимость'} name={'price'} rules={[{ required: true }]}>
						<InputNumber
							precision={2}
							decimalSeparator={','}
							min={0}
							formatter={(value) => `${formattedPrice(value)}`}
						/>
					</Form.Item>
				</Col>
			</Row>

			<Row gutter={15}>
				<Col span={12}>
					<Form.Item label={'Дата начала проектирования'} name={'start_date'} rules={[{ required: true }]}>
						<DatePicker format={'DD MMMM YYYY'} />
					</Form.Item>
				</Col>
				<Col span={12}>
					<Form.Item shouldUpdate style={{ margin: 0 }}>
						{() => {
							const fieldStartDate = form.getFieldValue('start_date');
							return (
								<Form.Item
									label={'Дата окончания проектирования'}
									name={'end_date'}
									rules={[
										{ required: true },
										{
											validator: async (_, workEndDate) => {
												if (workEndDate) {
													if (workEndDate.diff(fieldStartDate, 'days') <= 0) {
														return Promise.reject('Окончание работы < чем начало работ');
													}

													return Promise.resolve();
												} else {
													return Promise.resolve();
												}
											},
										},
									]}
								>
									<DatePicker
										format={'DD MMMM YYYY'}
										disabledDate={(current) => {
											if (fieldStartDate && current) {
												return current && current < fieldStartDate.endOf('day');
											} else {
												return false;
											}
										}}
									/>
								</Form.Item>
							);
						}}
					</Form.Item>
				</Col>
			</Row>
		</>
	);
});
