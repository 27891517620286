import styled from 'styled-components';

export const Container = styled.hr`
	display: flex;
	margin-bottom: 24px;
	width: 100%;
	border: 0;
	height: 2px;
	background: rgba(0, 0, 0, 0.1);
`;
