import { Col, Form, Input, Row, Select } from 'antd';

import { TriggerSelectItems } from '../../../../../../../../../../../constants';

interface TriggerGoToTaskWithNotificationProps {
	conditionField: string;
	triggerItemNumber: number;
	listVolumes: Array<any>;
	currentTask: any;
}

export const TriggerGoToTaskWithNotification = ({
	conditionField,
	currentTask,
	triggerItemNumber,
	listVolumes,
}: TriggerGoToTaskWithNotificationProps) => {
	if (conditionField !== TriggerSelectItems.GoToTaskWithNotification) {
		return null;
	}

	return (
		<Row gutter={15}>
			<Col span={24}>
				<Form.Item label={'Заголовок'} name={[triggerItemNumber, 'title']}>
					<Input placeholder={'Введите заголовок'} />
				</Form.Item>
			</Col>

			<Col span={24}>
				<Form.Item label={'Текст сообщения'} name={[triggerItemNumber, 'description']}>
					<Input.TextArea autoSize={{ minRows: 3 }} placeholder={'Введите текст сообщения'} />
				</Form.Item>
			</Col>

			<Col span={24}>
				<Form.Item label={'Ответ Да - Связать с задачей'} name={[triggerItemNumber, 'relation_inside_uuid']}>
					<Select>
						{listVolumes.map((volumeItem: any) => {
							return (volumeItem?.item_volume_tasks || []).map((taskItem: any) => {
								if (currentTask?.inside_uuid === taskItem?.inside_uuid) {
									return null;
								}

								return (
									<Select.Option key={taskItem?.inside_uuid} value={taskItem?.inside_uuid}>
										{volumeItem?.number_section} {volumeItem?.nomination} | {taskItem?.title || taskItem?.inside_uuid}
									</Select.Option>
								);
							});
						})}
					</Select>
				</Form.Item>
			</Col>

			<Col span={24}>
				<Form.Item label={'Ответ Нет - Связать с задачей'} name={[triggerItemNumber, 'relation_inside_uuid_no']}>
					<Select>
						{listVolumes.map((volumeItem: any) => {
							return (volumeItem?.item_volume_tasks || []).map((taskItem: any) => {
								if (currentTask?.inside_uuid === taskItem?.inside_uuid) {
									return null;
								}

								return (
									<Select.Option key={taskItem?.inside_uuid} value={taskItem?.inside_uuid}>
										{volumeItem?.number_section} {volumeItem?.nomination} | {taskItem?.title || taskItem?.inside_uuid}
									</Select.Option>
								);
							});
						})}
					</Select>
				</Form.Item>
			</Col>
		</Row>
	);
};
