import { useEffect, useState } from 'react';

import { Form, FormInstance, Tabs, TabsProps } from 'antd';
import { observer } from 'mobx-react-lite';

import { FilesTab } from './Tabs/FilesTab/FilesTab';
import { InformationTab } from './Tabs/InformationTab/InformationTab';
import { ITEM_TABS } from '../../../../../constants';
import { ItemStore, useStores } from '../../../../../stores';

interface ItemVolumeFormProps {
	isBlockedItem: boolean;
	form: FormInstance;
	onSend: (values: any) => void;
	onError: () => void;
	onChangeForm: () => void;
}

export const ItemVolumeForm = observer(
	({ isBlockedItem, form, onSend, onError, onChangeForm }: ItemVolumeFormProps) => {
		const { itemsStore, usersStore } = useStores();
		const { selected: currentVolumeItem } = itemsStore;

		// Текущий таб
		const [currentTab, setCurrentTab] = useState<string>(ITEM_TABS.VOLUME.DATA.key);

		// Табы
		const itemTabs: TabsProps['items'] = [
			{
				key: ITEM_TABS.VOLUME.DATA.key,
				label: ITEM_TABS.VOLUME.DATA.value,
				children: <InformationTab form={form} />,
				forceRender: true,
			},
			{
				key: ITEM_TABS.VOLUME.FILES.key,
				label: ITEM_TABS.VOLUME.FILES.value,
				children: <FilesTab form={form} />,
				forceRender: true,
			},
			/*{
      key: ITEM_TABS.VOLUME.TRIGGERS.key,
      label: ITEM_TABS.VOLUME.TRIGGERS.value,
      children: <TriggerTab form={form} />,
      forceRender: true,
      disabled: currentVolumeItem.isNewStore,
    },*/
		];

		useEffect(() => {
			(async () => {
				await usersStore.all.getList();
			})();
		}, []);

		// Инит данных и их обновление от side effect
		useEffect(() => {
			if (currentVolumeItem.drawer.isShow) {
				updateFields();
			}
		}, [currentVolumeItem.drawer.isShow, currentVolumeItem.id]);

		// Поля тома
		const getFields = (currentItem: ItemStore) => ({
			id: currentItem.id,
			parent_id: currentItem.parent_object?.id,
			item_type_id: currentItem.item_type_id,
			item_object_type_id: currentItem.parent_object?.item_object_type_id,
			responsible_id: currentItem.responsible_id || undefined,
			title: currentItem.title,
			cipher: currentItem.cipher,
			start_date: currentItem.start_date,
			end_date: currentItem.end_date,
			actual_date: currentItem.actual_date || undefined,
			triggers: currentItem.triggers.map((trigger) => {
				return {
					trigger_id: trigger.trigger_id,
					trigger_execute_event_id: trigger.trigger_execute_event_id,
					value: trigger.value,
					payload: {
						title: trigger.payload.title,
						content: trigger.payload.content,
						responsible_id: trigger.payload.responsible_id,
						start_date: trigger.payload.start_date,
						end_date: trigger.payload.end_date,
					},
				};
			}),
		});

		// Обновление полей
		const updateFields = () => {
			form.setFieldsValue(getFields(currentVolumeItem));
		};

		// Смена табов
		const handleOnChangeTab = (keyTab: string) => {
			setCurrentTab(keyTab);
		};

		return (
			<>
				<Form
					disabled={isBlockedItem}
					name={'form-volume'}
					form={form}
					layout={'vertical'}
					autoComplete="off"
					onFinish={async (values) => {
						await onSend(values);
						updateFields();
					}}
					onFinishFailed={onError}
					onValuesChange={onChangeForm}
				>
					<Tabs activeKey={currentTab} items={itemTabs} onChange={handleOnChangeTab} />
				</Form>
			</>
		);
	},
);
