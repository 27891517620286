import styled from 'styled-components';

export const Container = styled.div`
	.ant-form-item {
		margin-bottom: 0;
	}

	.ant-table-expanded-row .ant-pagination {
		margin-top: 45px;
	}
`;
