import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Button, List, Popconfirm } from 'antd';
import { observer } from 'mobx-react-lite';

import { useStores } from '../../../../../../../stores';
import { TaskStore } from '../../../../../../../stores/task/task.store';

export const TaskList = observer(() => {
	const { objectsStore } = useStores();
	const { selected: currentObject } = objectsStore;
	const { volumes } = currentObject;
	const { selected: currentVolume } = volumes;
	const { tasks } = currentVolume;

	const handleOnEdit = (taskStore: TaskStore) => {
		tasks.setSelected(taskStore);
		tasks.modal.open();
	};

	const handleOnDelete = async (task: TaskStore) => {
		try {
			await task.delete();
			currentVolume.tasks.deleteTask(task);
		} catch {
			/* empty */
		}
	};

	/*const handleOnShowTask = (task: TaskStore) => {
    tasksStore.viewDetail.setData(task);
    tasksStore.viewDetail.modal.open();
  };*/

	return (
		<List
			itemLayout={'horizontal'}
			dataSource={tasks.list}
			renderItem={(task: TaskStore) => (
				<List.Item
					actions={[
						<Popconfirm
							key={'delete-task'}
							title={'Уверены что хотите удалить?'}
							onConfirm={() => handleOnDelete(task)}
						>
							<Button type={'primary'} danger icon={<DeleteOutlined />} />
						</Popconfirm>,
						/*<Button key={'view-task'} icon={<EyeOutlined />} onClick={() => handleOnShowTask(task)} />,*/
						<Button key={'edit-task'} type={'primary'} icon={<EditOutlined />} onClick={() => handleOnEdit(task)} />,
					]}
				>
					<List.Item.Meta
						title={task.title}
						description={`${task.startDateFormatted} - ${task.endDateFormatted} | Автор : ${task.creator.shortFullName} | Исполнитель : ${task.responsible.shortFullName} `}
					/>
				</List.Item>
			)}
		/>
	);
});
