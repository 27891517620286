import styled from 'styled-components';

export const UserInformationContainer = styled.div`
	display: flex;
`;

export const Body = styled.div`
	display: flex;
	column-gap: 15px;
	align-items: center;
`;

export const Block = styled.div`
	display: flex;
	flex-direction: column;
	cursor: pointer;
	row-gap: 5px;
`;

export const FullName = styled.div`
	font-size: 16px;
	font-weight: 500;
`;

export const Post = styled.div`
	font-size: 12px;
	font-weight: 300;
`;
