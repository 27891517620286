import { Descriptions, Divider } from 'antd';
import { observer } from 'mobx-react-lite';

import { numWords } from '../../../../../../helpers';
import { ItemStore } from '../../../../../../stores';
import { CompleteBlock } from '../../CompleteBlock/CompleteBlock';
import { ConfirmBlock } from '../../ConfirmBlock/ConfirmBlock';
import { FilesBlock } from '../../FilesBlock/FilesBlock';
import { ResultBlock } from '../../ResultBlock/ResultBlock';
import { ItemObjectView } from '../ItemObjectView/ItemObjectView';

interface ItemVolumeViewProps {
	itemVolume: ItemStore;
	isShort?: boolean;
}

export const ItemVolumeView = observer(({ itemVolume, isShort = false }: ItemVolumeViewProps) => {
	const { parent_object: itemObject } = itemVolume;

	return (
		<>
			<Descriptions title={'Основная информация'} layout={'vertical'} column={2} bordered size={'small'}>
				<Descriptions.Item label={'Отвественный тома'}>{itemVolume.creator.shortFullName}</Descriptions.Item>
				<Descriptions.Item label={'Исполнитель тома'}>
					{itemVolume.responsible.isContractor
						? itemVolume.responsible.contractor.shortFullName
						: itemVolume.responsible.shortFullName}
				</Descriptions.Item>

				<Descriptions.Item label={'Наименование тома'}>{itemVolume.title}</Descriptions.Item>
				<Descriptions.Item label={'Шифр тома'}>
					{itemVolume.parent_object?.cipher}-{itemVolume.cipher}
				</Descriptions.Item>

				<Descriptions.Item label={'Начало работ'}>{itemVolume.startDateFormatted}</Descriptions.Item>
				<Descriptions.Item label={'Окончание работ'}>{itemVolume.endDateFormatted}</Descriptions.Item>

				<Descriptions.Item label={'Фактическое завершение работ'}>
					{itemVolume.actualDateFormatted || '-'}
				</Descriptions.Item>
				<Descriptions.Item label={'Отклонение от даты завершения'}>
					{itemVolume.deviation_days} {numWords(itemVolume.deviation_days, ['день', 'дня', 'дней'])}
				</Descriptions.Item>
			</Descriptions>

			<FilesBlock itemStore={itemVolume} />

			<ResultBlock itemStore={itemVolume} />
			<ConfirmBlock itemStore={itemVolume} />
			<CompleteBlock itemStore={itemVolume} />

			{itemObject && !isShort && (
				<>
					<Divider />
					<ItemObjectView itemObject={itemObject} forHistory />
				</>
			)}
		</>
	);
});
