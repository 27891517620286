import dayjs from 'dayjs';
import { action, makeObservable, observable } from 'mobx';

import { API } from '../../core';
import { ErrorAPI } from '../../helpers';
import { Agreement, DayJsNull, NumberNull } from '../../interfaces';
import { AgreementContractorStore } from '../agreement-contractor';
import { AgreementStatusStore } from '../agreement-statuses';
import { CrudStore } from '../common/crud.store';
import { CurrencyStore } from '../currencies';

export class AgreementStore extends CrudStore<Agreement> implements Agreement {
	PATH = 'agreements';

	@observable item_id: NumberNull = null;
	@observable creator_id: NumberNull = null;
	@observable status_id = 1;
	@observable currency_id = 1;
	@observable system_number = '';
	@observable title = '';
	@observable price = 0;
	@observable start_date: DayJsNull = null;
	@observable end_date: DayJsNull = null;
	@observable comment = '';
	@observable status = new AgreementStatusStore(null);
	@observable currency = new CurrencyStore(null);
	@observable contractors: Array<AgreementContractorStore> = [];

	// System field
	@observable cleanForm = true;

	constructor(initialData: Agreement | null) {
		super();

		makeObservable(this);

		if (initialData) {
			this.fillStore(initialData);
		}
	}

	@action.bound
	setCleanForm(value: boolean) {
		this.cleanForm = value;
	}

	@action.bound
	async setEmails() {
		try {
			await API.request(`agreement-email/${this.id}`);
		} catch (e) {
			ErrorAPI('setEmails', e);
		}
	}

	@action
	fillStore(data: Agreement) {
		const {
			id,
			item_id,
			creator_id,
			currency_id,
			system_number,
			title,
			price,
			start_date,
			end_date,
			comment,
			status,
			currency,
			contractors,
		} = data;

		try {
			this.id = id;
			this.item_id = item_id;
			this.creator_id = creator_id;
			this.currency_id = currency_id;
			this.system_number = system_number;
			this.title = title;
			this.price = price;
			this.start_date = dayjs(start_date);
			this.end_date = dayjs(end_date);
			this.comment = comment;
			this.status = new AgreementStatusStore(status);
			this.currency = new CurrencyStore(currency);
			this.contractors = (contractors || []).map((contractor) => new AgreementContractorStore(contractor));
		} catch (e) {
			console.error(`ERRROR e`, e);
		}
	}
}
