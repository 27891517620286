import { Dayjs } from 'dayjs';
import { action, computed, makeObservable, observable } from 'mobx';

import { API } from '../../core';
import { getFirstChar } from '../../helpers';
import { UserDetailSmall } from '../../interfaces';
import { ContractorStore } from '../contractors/contractor.store';
import { RoleDetailSmallStore } from '../role/role-detail-small.store';

export class UserDetailStore implements UserDetailSmall {
	@observable id = 0;
	@observable email = '';
	@observable first_name = '';
	@observable last_name = '';
	@observable patronymic = '';
	@observable birthday: Dayjs | null = null;
	@observable avatar = '';
	@observable role: RoleDetailSmallStore = new RoleDetailSmallStore(null);
	@observable contractor = new ContractorStore(null);

	constructor(initialData: UserDetailSmall | null) {
		makeObservable(this);

		if (initialData) {
			this.fillStore(initialData);
		}
	}

	@computed
	get shortFullName() {
		const name = getFirstChar(this.first_name);
		const patronymic = getFirstChar(this.patronymic);
		const complete = `${this.last_name}${name ? '.' : ''}${name}${patronymic ? '.' : ''}${patronymic}`;
		return complete || this.email || 'Не указан';
	}

	@computed
	get fullPathAvatar() {
		if (this.avatar === 'avatars/default.png') {
			return `/${this.avatar}`;
		}

		if (this.avatar) {
			return `${API.getStoragePath}${this.avatar}`;
		}

		return undefined;
	}

	@computed
	get isContractor() {
		return Boolean(this.contractor.id);
	}

	@action
	fillStore(data: UserDetailSmall) {
		const { id, email, first_name, last_name, patronymic, birthday, avatar, role, contractor } = data;

		this.id = id;
		this.email = email;
		this.first_name = first_name;
		this.last_name = last_name;
		this.patronymic = patronymic;
		this.birthday = birthday;
		this.avatar = avatar;
		this.role = new RoleDetailSmallStore(role);
		this.contractor = new ContractorStore(contractor);
	}
}
