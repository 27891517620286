import { action, makeObservable, observable } from 'mobx';

import { ItemVolumeTaskTrigger } from '../../interfaces';
import { CrudStore } from '../common/crud.store';

export class ItemVolumeTaskTriggerStore extends CrudStore<ItemVolumeTaskTrigger> implements ItemVolumeTaskTrigger {
	@observable item_task_generate_id = 0;
	@observable condition = '';
	@observable relation_inside_uuid = '';
	@observable relation_inside_uuid_no = '';
	@observable title = '';
	@observable description = '';

	constructor(initialData: ItemVolumeTaskTrigger | null) {
		super();
		makeObservable(this);

		if (initialData) {
			this.fillStore(initialData);
		}
	}

	@action
	fillStore(data: ItemVolumeTaskTrigger) {
		const { id, item_task_generate_id, condition, relation_inside_uuid, relation_inside_uuid_no, title, description } =
			data;

		this.id = id;
		this.item_task_generate_id = item_task_generate_id;
		this.condition = condition;
		this.relation_inside_uuid = relation_inside_uuid;
		this.relation_inside_uuid_no = relation_inside_uuid_no;
		this.title = title;
		this.description = description;
	}
}
