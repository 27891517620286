import { Col, FormInstance, Row } from 'antd';
import { observer } from 'mobx-react-lite';

import { ItemTypeGroupFiles } from '../../../../../../../constants';
import { useStores } from '../../../../../../../stores';
import { UserFileStore } from '../../../../../../../stores/users';
import { UploadFilesBlock } from '../../../../../../common';
import { UploadedUserBlock } from '../../../../../../common/UploadedUserBlock/UploadedUserBlock';

interface FilesTabProps {
	form: FormInstance;
}

export const FilesTab = observer(({ form }: FilesTabProps) => {
	const {
		itemsStore: { selected: currentItemTask },
	} = useStores();

	const handleOnDelete = async (file: UserFileStore) => {
		await file.delete(false);
	};

	return (
		<Row gutter={15}>
			<Col span={12}>
				<UploadFilesBlock
					form={form}
					label={'Файлы для загрузки'}
					nameList={ItemTypeGroupFiles.TechnicalDocumentation}
				/>
			</Col>

			<Col span={12}>
				<UploadedUserBlock
					files={currentItemTask.getFiles(ItemTypeGroupFiles.TechnicalDocumentation)}
					onDelete={handleOnDelete}
				/>
			</Col>
		</Row>
	);
});
