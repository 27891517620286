import { action, computed, makeObservable, observable } from 'mobx';

import { ItemProjectDocumentListStore } from './item-project-document-list.store';
import { ItemProjectDocumentStore } from './item-project-document.store';
import { ItemObjectTypes } from '../../constants';
import { PayloadParamsI } from '../../interfaces';
import { ItemProjectDocumentList } from '../../interfaces/item-project-document';
import { AllStore } from '../common/all.store';
import { DetailStore } from '../common/detail.store';
import { TableStore } from '../common/table.store';

export class ItemProjectDocumentsStore {
	@observable detail = new DetailStore<ItemProjectDocumentStore>(new ItemProjectDocumentStore(null));
	@observable table = new TableStore<ItemProjectDocumentList, ItemProjectDocumentListStore>({
		url: 'item-project-documents',
		getListItemStore: this.getListItemStore,
		getPayloadParams: this.getPayloadParams.bind(this),
	});
	@observable all = new AllStore<ItemProjectDocumentList, ItemProjectDocumentListStore>({
		url: 'item-project-documents',
		getListItemStore: this.getListItemStore,
		getPayloadParams: this.getPayloadParams.bind(this),
	});

	@observable current_item_object_type_id = ItemObjectTypes.DISTRIBUTION_NETWORKS;

	@observable isClone = false;

	constructor() {
		makeObservable(this);
	}

	@computed
	get nameItemObjectType(): string {
		switch (this.current_item_object_type_id) {
			case ItemObjectTypes.DISTRIBUTION_NETWORKS:
				return `Распределительные сети`;
			case ItemObjectTypes.MAIN_NETWORKS:
				return `Основные сети`;
			default:
				return 'Не определено';
		}
	}

	@computed
	get lastSort() {
		return this.table.list[this.table.list.length - 1]?.sort + 1 || 1;
	}

	@computed
	get selectBox() {
		return this.all.list.map((item) => ({
			label: item.name,
			value: item.id,
		}));
	}

	@action.bound
	setIsClone(value: boolean) {
		this.isClone = value;
	}

	@action.bound
	setCurrentItemObjectTypeId(value: ItemObjectTypes) {
		this.current_item_object_type_id = value;
	}

	@action.bound
	getListItemStore(value: ItemProjectDocumentList): ItemProjectDocumentListStore {
		return new ItemProjectDocumentListStore(value);
	}

	@action.bound
	getPayloadParams(): PayloadParamsI {
		return {
			item_object_type_id: this.current_item_object_type_id,
		};
	}

	@action.bound
	clearDetail() {
		this.detail = new DetailStore<ItemProjectDocumentStore>(new ItemProjectDocumentStore(null));
	}

	// Получение выбранной проектной документации
	getSelectedById(id: number | undefined): null | undefined | ItemProjectDocumentListStore {
		if (!id) {
			return null;
		}

		return computed(() => {
			return this.all.list.find((item) => item.id === id);
		}).get();
	}

	// Получение подготовленных данных для отправки на генерацию объектов
	getDataForFormById(id: number | undefined): null | any {
		if (!id) {
			return null;
		}

		return computed(() => {
			return this.all.list
				.find((item) => item.id === id)
				?.item_volume_generates.map((item_volume_generate) => {
					return {
						nomination: item_volume_generate.nomination,
						note: item_volume_generate.note,
						number_section: item_volume_generate.number_section,
						range_dates: [undefined, undefined],
						is_finish: item_volume_generate?.payload?.is_finish || false,
						tasks: item_volume_generate.item_volume_tasks.map((task) => {
							return {
								inside_uuid: task.inside_uuid,
								responsible_id: task.responsible_id,
								title: task.title,
								description: task.description,
								is_finish: task.is_finish,
								count_day: task.count_day,
								triggers: task.task_triggers,
								/*triggers: task.task_triggers.map((trigger) => {
                  return {
                    condition: trigger.condition,
                    title: trigger.title,
                    description: trigger.description,
                    relation_inside_uuid: trigger.relation_inside_uuid,
                    relation_inside_uuid_no: trigger.relation_inside_uuid_no,
                  };
                }),*/
							};
						}),
					};
				});
		}).get();
	}
}
