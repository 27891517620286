import dayjs, { Dayjs } from 'dayjs';
import { action, computed, makeObservable, observable } from 'mobx';

import { Contractor } from '../../interfaces';
import { CrudStore } from '../common/crud.store';
import { UserFileStore } from '../users/user-file.store';
import { UserPhoneStore } from '../users/user-phone.store';

export class ContractorStore extends CrudStore<Contractor> implements Contractor {
	PATH = 'contractors';

	@observable ceo = '';
	@observable company_name = '';
	@observable registration_date: Dayjs | undefined = undefined;
	@observable inn = '';
	@observable email = '';
	@observable legal_address = '';
	@observable verified = false;
	@observable active_passed: Dayjs | undefined = undefined;
	@observable files: UserFileStore[] = [];
	@observable created_at = dayjs();
	@observable updated_at = dayjs();
	@observable phones: UserPhoneStore[] = [];

	constructor(initialData: Contractor | null) {
		super();
		makeObservable(this);
		if (initialData) {
			this.fillStore(initialData);
		}
	}

	@computed
	get shortFullName() {
		return `${this.ceo} (${this.company_name})`;
	}

	@computed
	get roleName() {
		return ``;
	}

	@computed
	get registrationDateFormatted() {
		return this.registration_date ? this.registration_date.format('DD MMMM YYYY') : 'Не указан';
	}

	@action.bound
	deleteFromFiles(id: number) {
		let files = [...this.files];
		files = files.filter((file) => file.id !== id);
		this.files = files;
	}

	@action
	fillStore(data: Contractor) {
		const {
			id,
			ceo,
			company_name,
			registration_date,
			inn,
			email,
			legal_address,
			verified,
			active_passed,
			files,
			created_at,
			updated_at,
			phones,
		} = data;

		this.id = id;
		this.ceo = ceo;
		this.company_name = company_name;
		this.registration_date = registration_date ? dayjs(registration_date) : undefined;
		this.inn = inn;
		this.email = email;
		this.legal_address = legal_address;
		this.verified = verified;
		this.active_passed = active_passed ? dayjs(active_passed) : undefined;
		this.files = (files || []).map((file) => new UserFileStore(file));
		this.created_at = dayjs(created_at);
		this.updated_at = dayjs(updated_at);
		this.phones = (phones || []).map((phone) => new UserPhoneStore(phone));
	}
}
