import dayjs from 'dayjs';
import { action, observable } from 'mobx';

import { ItemTypeGroupFiles } from '../../constants';
import { ItemFile } from '../../interfaces/item';
import { UserFileStore } from '../users/user-file.store';

export class ItemFileStore extends UserFileStore implements ItemFile {
	PATH = 'item-file';

	@observable item_id = 0;
	@observable group = ItemTypeGroupFiles.TechnicalDocumentation;

	constructor(initialData: ItemFile | null) {
		super(initialData);

		if (initialData) {
			this.fillStore(initialData);
		}

		this.fillStore = action(this.fillStore.bind(this));
	}

	fillStore(data: ItemFile) {
		const { id, user_id, item_id, name, file, group, is_delete, created_at, updated_at } = data;

		this.id = id;
		this.user_id = user_id;
		this.item_id = item_id;
		this.name = name;
		this.file = file;
		this.group = group;
		this.is_delete = is_delete;
		this.created_at = dayjs(created_at);
		this.updated_at = dayjs(updated_at);
	}
}
