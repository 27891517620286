import { Col, Form, FormInstance, Input, InputNumber, Row, Typography } from 'antd';
import { observer } from 'mobx-react-lite';

import { useStores } from '../../../../../stores';

interface InformationProps {
	form: FormInstance;
}

export const Information = observer(({ form }: InformationProps) => {
	const { itemProjectDocumentsStore } = useStores();

	console.log(form);

	return (
		<>
			<Row gutter={15}>
				<Col span={24}>
					<Form.Item name={'item_object_type_id'} hidden>
						<Input />
					</Form.Item>
				</Col>
			</Row>

			<Row gutter={15}>
				<Col span={24}>
					<Form.Item label={'Тип сетей'}>
						<Typography.Text strong>{itemProjectDocumentsStore.detail.data.nameItemObjectType}</Typography.Text>
					</Form.Item>
				</Col>
			</Row>

			<Row gutter={15}>
				<Col span={4}>
					<Form.Item label={'Позиция'} name={'sort'} rules={[{ required: true }]}>
						<InputNumber />
					</Form.Item>
				</Col>
				<Col span={20}>
					<Form.Item label={'Название проектной документации'} name={'name'} rules={[{ required: true }]}>
						<Input placeholder={'Введите название'} />
					</Form.Item>
				</Col>
			</Row>

			<Row gutter={15}>
				<Col span={24}>
					<Form.Item label={'Описание'} name={'description'}>
						<Input.TextArea placeholder={'Введите описание'} autoSize={{ minRows: 3, maxRows: 10 }} />
					</Form.Item>
				</Col>
			</Row>
		</>
	);
});
