import { IssuesCloseOutlined, SearchOutlined } from '@ant-design/icons';
import { Avatar, Button, Checkbox, DatePicker, Input, Popover, Tag, Tooltip, Typography } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { Observer } from 'mobx-react-lite';

import { ItemObjectTypesMapping, ItemObjectTypesMappingShort, NameDays } from '../../../../../constants';
import { ItemTypes, ItemTypesDrawerMapping } from '../../../../../constants/item-types';
import { getInformationUser, numWords } from '../../../../../helpers';
import { useStores } from '../../../../../stores';
import { UserItemStore } from '../../../../../stores/user-items';
import { COLORS } from '../../../../../stylesheets';
import { AvatarExtra } from '../../../../layouts/CabinetLayout/Sider/UserBlock/UserBlock.styled';
import { Text } from '../../../Other/Messenger/RightSide/MessageList/MessageList.styled';

export const useColumns = (): ColumnsType<UserItemStore> => {
	const { userItemsStore, itemLocalStatusesStore, itemTypesStore, itemObjectTypesStore } = useStores();

	const getType = (row: UserItemStore) => {
		return ItemTypesDrawerMapping[row.item_type_id];
	};

	const getColorType = (row: UserItemStore) => {
		switch (row.item_type_id) {
			case ItemTypes.object:
				return 'cyan';
			case ItemTypes.volume:
				return 'geekblue';
			case ItemTypes.tasks:
				return 'purple';
			default:
				return 'magenta';
		}
	};

	const getColorLocalStatus = (row: UserItemStore) => {
		switch (row.item_local_status_id) {
			case 1:
				return 'magenta';
			case 2:
				return 'volcano';
			case 3:
				return 'orange';
			case 4:
				return 'gold';
			case 5:
				return 'red';
			default:
				return 'magenta';
		}
	};

	const getObjectType = (row: UserItemStore) => {
		if (row.item_type_id === ItemTypes.object && row.item_object_type_id) {
			return ItemObjectTypesMappingShort[row.item_object_type_id];
		}

		if (row?.item_object_type_id && [ItemTypes.volume, ItemTypes.tasks].includes(row.item_type_id)) {
			return ItemObjectTypesMappingShort[row.item_object_type_id];
		}
	};

	const getObjectTypeTitle = (row: UserItemStore) => {
		if (row.item_type_id === ItemTypes.object && row.item_object_type_id) {
			return ItemObjectTypesMapping[row.item_object_type_id];
		}

		if (row?.parent_object?.item_object_type_id && [ItemTypes.volume, ItemTypes.tasks].includes(row.item_type_id)) {
			return ItemObjectTypesMapping[row.parent_object.item_object_type_id];
		}
	};

	const getCipher = (row: UserItemStore) => {
		if ([ItemTypes.object, ItemTypes.volume].includes(row.item_type_id)) {
			return row.cipher;
		}

		return '-';
	};

	return [
		{
			title: 'Тип сетей',
			align: 'center',
			width: 140,
			render: (_, row) => (
				<Tooltip placement={'top'} title={getObjectTypeTitle(row)}>
					<Tag color={COLORS.BLUE_100}>{getObjectType(row)}</Tag>
				</Tooltip>
			),
			filterDropdown: ({ setSelectedKeys, confirm, selectedKeys }) => {
				return (
					<div>
						{itemObjectTypesStore.list.map((itemObjectTypeStore) => {
							return (
								<div key={itemObjectTypeStore.id}>
									<Checkbox
										value={itemObjectTypeStore.id}
										checked={selectedKeys.includes(itemObjectTypeStore.id)}
										onChange={(e) => {
											const { value } = e.target;
											const findIndex = selectedKeys.findIndex((item) => item === value);

											// Записываем или удаляем выбранный вариант
											if (findIndex === -1) {
												const complete = [...selectedKeys, ...[value]] as Array<number>;
												setSelectedKeys(complete);

												// Итоговый вариант записываем в фильтры
												userItemsStore.table.addFilter('item_object_type_id', complete);
											} else {
												selectedKeys.splice(findIndex, 1);
												setSelectedKeys([...selectedKeys]);

												// Итоговый вариант записываем в фильтры
												userItemsStore.table.addFilter('item_object_type_id', selectedKeys as Array<number>);
											}
										}}
									>
										{itemObjectTypeStore.name}
									</Checkbox>
								</div>
							);
						})}

						<Button
							type={'primary'}
							style={{ width: '100%', marginTop: 20 }}
							onClick={async () => {
								confirm();
							}}
						>
							Найти
						</Button>
					</div>
				);
			},
		},
		{
			title: 'Шифр',
			align: 'center',
			width: 150,
			render: (_, row) => (
				<Typography.Title style={{ color: COLORS.BLUE_100, fontSize: 12, margin: 0 }} ellipsis={true}>
					{getCipher(row)}
				</Typography.Title>
			),
			filterIcon: () => {
				return <SearchOutlined />;
			},
			filterDropdown: ({ selectedKeys, setSelectedKeys, confirm }) => {
				return (
					<Input
						autoFocus
						placeholder={'Минимум 3 символа'}
						name={'cipher'}
						allowClear
						value={selectedKeys[0] as string}
						onChange={async (e) => {
							const { value, name } = e.target;
							setSelectedKeys(value ? [value] : []);
							userItemsStore.table.addFilter(name, value);

							if (value.length > 0 && value.length < 3) {
								return false;
							}
						}}
						onBlur={() => confirm()}
					/>
				);
			},
		},
		{
			title: 'Статус',
			align: 'center',
			width: 150,
			filterDropdown: ({ setSelectedKeys, confirm, selectedKeys }) => {
				return (
					<div>
						{itemLocalStatusesStore.list.map((itemLocalStatus) => {
							return (
								<div key={itemLocalStatus.id}>
									<Checkbox
										value={itemLocalStatus.id}
										checked={selectedKeys.includes(itemLocalStatus.id)}
										onChange={(e) => {
											const { value } = e.target;
											const findIndex = selectedKeys.findIndex((item) => item === value);

											// Записываем или удаляем выбранный вариант
											if (findIndex === -1) {
												const complete = [...selectedKeys, ...[value]] as Array<number>;
												setSelectedKeys(complete);

												// Итоговый вариант записываем в фильтры
												userItemsStore.table.addFilter('item_local_status', complete);
											} else {
												selectedKeys.splice(findIndex, 1);
												setSelectedKeys([...selectedKeys]);

												// Итоговый вариант записываем в фильтры
												userItemsStore.table.addFilter('item_local_status', selectedKeys as Array<number>);
											}
										}}
									>
										{itemLocalStatus.status}
									</Checkbox>
								</div>
							);
						})}

						<Button
							type={'primary'}
							style={{ width: '100%', marginTop: 20 }}
							onClick={async () => {
								confirm();
							}}
						>
							Найти
						</Button>
					</div>
				);
			},
			render: (_, row) => (
				<Observer>
					{() => {
						return <Tag color={getColorLocalStatus(row)}>{row.localStatus}</Tag>;
					}}
				</Observer>
			),
		},
		{
			title: 'Название',
			ellipsis: true,
			width: 400,
			render: (_, row) => (
				<>
					{row.parent_object?.id ? (
						<>
							<div style={{ display: 'flex', textAlign: 'left' }}>
								<Typography.Text strong style={{ fontSize: 12 }} ellipsis={{ tooltip: row.parent_object.title }}>
									Объект : {row.parent_object.title}
								</Typography.Text>
							</div>
						</>
					) : null}
					{row.is_finish && (
						<Tooltip title={`Закрывающая задача`}>
							<Tag icon={<IssuesCloseOutlined />} />
						</Tooltip>
					)}{' '}
					{row.title}
				</>
			),
			filterIcon: () => {
				return <SearchOutlined />;
			},
			filterDropdown: ({ selectedKeys, setSelectedKeys, confirm }) => {
				return (
					<Input
						autoFocus
						placeholder={'Минимум 3 символа'}
						name={'title'}
						allowClear
						value={selectedKeys[0] as string}
						onChange={async (e) => {
							const { value, name } = e.target;
							setSelectedKeys(value ? [value] : []);
							userItemsStore.table.addFilter(name, value);

							if (value.length > 0 && value.length < 3) {
								return false;
							}
						}}
						onBlur={() => confirm()}
					/>
				);
			},
		},

		{
			title: 'Участники',
			align: 'center',
			width: 150,
			render: (_, row) => {
				return (
					<Observer>
						{() => {
							return (
								<Avatar.Group>
									{row.creator.isExist && (
										<Popover title={'Ответственный'} content={getInformationUser(row.creator)}>
											<AvatarExtra style={{ background: COLORS.BLUE_80 }}>{row.creator.initials}</AvatarExtra>
										</Popover>
									)}

									{row.responsible.isExist && (
										<Popover
											title={'Исполнитель'}
											content={
												row.responsible.isContractor
													? getInformationUser(row.responsible.contractor)
													: getInformationUser(row.responsible)
											}
										>
											<AvatarExtra style={{ background: COLORS.BLUE_100 }}>
												{row.responsible.isContractor
													? row.responsible.contractor.company_name
													: row.responsible.initials}
											</AvatarExtra>
										</Popover>
									)}
								</Avatar.Group>
							);
						}}
					</Observer>
				);
			},
		},
		{
			title: 'Начало / Заершение',
			align: 'center',
			width: 300,
			render: (_, row) => {
				return (
					<Observer>
						{() => {
							return (
								<span>
									{row.start_date?.format('DD MMMM YYYY')} - {row.end_date?.format('DD MMMM YYYY')}
								</span>
							);
						}}
					</Observer>
				);
			},
			filterDropdown: ({ setSelectedKeys, confirm }) => {
				return (
					<div>
						<DatePicker.RangePicker
							picker={'date'}
							format={'DD MMM YYYY'}
							onChange={(e) => {
								if (Array.isArray(e)) {
									const [dateStart, dateEnd] = e;

									if (dateStart && dateEnd) {
										setSelectedKeys([dateStart.toISOString(), dateEnd.toISOString()]);

										userItemsStore.table.addFilter('date_range', [dateStart.toISOString(), dateEnd.toISOString()]);
									}
								} else {
									setSelectedKeys([]);
									userItemsStore.table.addFilter('date_range', '');
								}
							}}
						/>

						<Button
							type={'primary'}
							style={{ width: '100%', marginTop: 20 }}
							onClick={async () => {
								confirm();
							}}
						>
							Найти
						</Button>
					</div>
				);
			},
		},
		{
			title: 'Отклонение',
			align: 'center',
			width: 150,
			render: (_, row) => {
				return (
					<Observer>
						{() => {
							return (
								<span>
									{row.deviation_days} {numWords(row.deviation_days, NameDays)}
								</span>
							);
						}}
					</Observer>
				);
			},
		},
		// Рендрим только для тех у кого есть право редатировать
		/*{
      title: 'Действия',
      align: 'center',
      width: 100,
      render: (_, row) => {
        return (
          <Dropdown
            menu={{
              onClick: (e) => {
                e.domEvent.stopPropagation();
                handleOnClick(e, row);
              },
              items,
            }}
            trigger={['click']}
          >
            <Button
              icon={<HolderOutlined />}
              type={'default'}
              style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', margin: '0 auto' }}
              onClick={(e) => e.stopPropagation()}
            />
          </Dropdown>
        );
      },
    },*/
	];
};
