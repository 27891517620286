import { EditOutlined, HolderOutlined } from '@ant-design/icons';
import { Button, Dropdown, MenuProps, Typography } from 'antd';
import { ColumnsType } from 'antd/es/table';

import { ItemDropDownKey } from '../../../../../constants';
import { formattedPrice } from '../../../../../helpers';
import { MenuInfoAntD } from '../../../../../interfaces';
import { useStores } from '../../../../../stores';
import { AgreementStore } from '../../../../../stores/agreements';
import { COLORS } from '../../../../../stylesheets';

export const useColumns = (): ColumnsType<AgreementStore> => {
	const { agreementsStore } = useStores();

	const items: MenuProps['items'] = [
		{
			key: ItemDropDownKey.Edit,
			label: (
				<Button type={'link'} icon={<EditOutlined />}>
					Редактировать
				</Button>
			),
		},
	];

	const handleOnClick = async (e: MenuInfoAntD, row: AgreementStore) => {
		if (e.key === ItemDropDownKey.Edit) {
			// Очищаем выбранный объект
			agreementsStore.clearSelected();

			// Устанавливаем ID объекта
			agreementsStore.selected.setId(row.id);

			// Получаем информацию
			await agreementsStore.selected.get();

			// Открваем окно
			agreementsStore.drawer.show();

			return;
		}
	};

	return [
		{
			title: 'Статус',
			align: 'center',
			width: 150,
			render: (_, record) => {
				return (
					<Typography.Title style={{ color: COLORS.BLUE_100, fontSize: 12 }} ellipsis={true}>
						{record.status.name}
					</Typography.Title>
				);
			},
		},
		{
			dataIndex: 'title',
			title: 'Титул объекта',
			align: 'center',
		},
		{
			title: 'Сумма',
			align: 'center',
			render: (_, record) => {
				return <>{formattedPrice(record.price)}</>;
			},
		},
		{
			title: 'Даты выполнения',
			align: 'center',
			render: (_, record) => {
				return (
					<>
						{record.start_date?.format('D MMMM YYYY')} - {record.end_date?.format('D MMMM YYYY')}
					</>
				);
			},
		},
		{
			title: 'Контрагенты',
			align: 'center',
			render: (_, record) => {
				return <>{record.contractors.length}</>;
			},
		},
		{
			title: 'Действия',
			align: 'center',
			width: 100,
			render: (_, row) => {
				return (
					<Dropdown
						menu={{
							onClick: (e) => handleOnClick(e, row),
							items,
						}}
						trigger={['click']}
					>
						<Button
							icon={<HolderOutlined />}
							type={'default'}
							style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', margin: '0 auto' }}
						/>
					</Dropdown>
				);
			},
		},
	];
};
