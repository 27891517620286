import type { DataNode } from 'antd/es/tree';
import { action, computed, makeObservable, observable } from 'mobx';

import { PermissionStore } from './permission.store';
import { API } from '../../core';
import { ErrorAPI } from '../../helpers';
import { PermissionI } from '../../interfaces/permission';
import { CoreResponse } from '../../responses';

export class PermissionsStore {
	@observable isLoading = false;
	@observable list: PermissionStore[] = [];

	constructor() {
		makeObservable(this);
	}

	@action.bound
	setIsLoading(value: boolean) {
		this.isLoading = value;
	}

	@action.bound
	setList(values: PermissionI[]) {
		this.list = (values || []).map((permission) => new PermissionStore(permission));
	}

	@action.bound
	async getList() {
		this.setIsLoading(true);

		try {
			const { data } = await API.request<CoreResponse<PermissionI[]>>(`permissions`);
			this.setList(data);
		} catch (e) {
			ErrorAPI('getList', e);
		} finally {
			this.setIsLoading(false);
		}
	}

	@computed
	get selectBoxPermissions() {
		return this.list.map((permission) => ({
			label: permission.description,
			options: permission.children.map((subPermission) => ({
				label: subPermission.description,
				value: subPermission.id,
			})),
		}));
	}

	@computed
	get transferList(): DataNode[] {
		return this.list.map((permission) => {
			return {
				key: String(permission.id),
				title: permission.description,
				children: permission.children.map((childPermission) => ({
					key: String(childPermission.id),
					title: childPermission.description,
				})),
			};
		});
	}
}
