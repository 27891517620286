import { action, makeObservable, observable } from 'mobx';

import { UserOnlineItemStore } from './user-online-item.store';
import { UserOnlineItem } from '../../interfaces';

export class UserOnlineStore {
	@observable count = 0;
	@observable list: UserOnlineItemStore[] = [];

	constructor() {
		makeObservable(this);
	}

	@action.bound
	setCount(value: number) {
		this.count = value;
	}

	@action.bound
	setList(values: UserOnlineItem[]) {
		this.list = (values || []).map((userOnline) => new UserOnlineItemStore(userOnline));
	}
}
