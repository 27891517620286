import { Dayjs } from 'dayjs';

export interface Notification {
	id: number;
	user_id: number;
	title: string;
	content: string;
	payload: NotificationPayload;
	is_show: boolean;
	type: NotificationType;
	created_at: Dayjs;
	updated_at: Dayjs;
}

export interface NotificationPayload {
	[key: string]: string | number;
}

export enum NotificationType {
	Item = 'item',
	System = 'system',
}
