import { useEffect } from 'react';

import { Typography } from 'antd';
import { observer } from 'mobx-react-lite';

import { useStores } from '../../../../stores';
import { BlockTitle, ContainerBody, ContainerBodyPadding } from '../../../common';
import { ItemsTable } from '../../../ui';

export const ObjectsDeletePage = observer(() => {
	const { itemsStore, itemObjectTypesStore, itemStatusesStore, triggersStore, triggerExecuteEventsStore } = useStores();
	const { selected: createItem } = itemsStore;

	useEffect(() => {
		(async () => {
			itemsStore.setIsDelete(1);
			//await itemObjectTypesStore.getList();
			//await triggersStore.getList();
			//await triggerExecuteEventsStore.getList();
		})();
	}, []);

	useEffect(() => {
		(async () => {
			await itemsStore.table.getList();
		})();
	}, [itemsStore.item_object_type_id]);

	useEffect(() => {
		(async () => {
			if (createItem.item_object_type_id) {
				await itemStatusesStore.getList(createItem.item_object_type_id);
			}
		})();
	}, [createItem.item_object_type_id]);

	/*const handleOnShow = () => {
    // Устанавливаем выбранный тип сетей
    createItem.setItemObjectType(itemsStore.item_object_type_id);

    // Устанавливаем тип объект
    createItem.setTypeObject();

    // Открываем окно
    createItem.drawer.show();
  };*/

	return (
		<>
			<ContainerBody>
				<ContainerBodyPadding>
					<BlockTitle>
						<Typography.Title level={2}>Удаленные объекты</Typography.Title>
					</BlockTitle>

					<ItemsTable />
				</ContainerBodyPadding>
			</ContainerBody>
		</>
	);
});
