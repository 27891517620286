import { ChangeEvent, useState } from 'react';

import { Button, Col, Descriptions, Input, Popconfirm, Row, Tag } from 'antd';
import { observer } from 'mobx-react-lite';

import { useStores } from '../../../../../stores';

export const Information = observer(() => {
	const { userDashboardStore, authStore } = useStores();
	const { tasks } = userDashboardStore;
	const { viewDetail, tableUser } = tasks;
	const { data: viewTask } = viewDetail;

	const [responsibleAnswer, setResponsibleAnswer] = useState('');

	const handleOnConfirm = async () => {
		try {
			await viewTask.update({
				confirmation_by_responsible: true,
				responsible_answer: responsibleAnswer,
			});

			await tableUser.getList();
		} catch {
			/* empty */
		}
	};

	const handleOnConfirmCreator = async () => {
		try {
			await viewTask.update({
				confirmation_by_creator: true,
			});

			await tableUser.getList();
		} catch {
			/* empty */
		}
	};

	const handleOnChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
		setResponsibleAnswer(e.currentTarget.value);
	};

	console.log();

	return (
		<>
			<Descriptions layout={'vertical'} column={4}>
				<Descriptions.Item label={'Автор'}>{viewTask.creator.shortFullName}</Descriptions.Item>
				<Descriptions.Item label={'Исполнитель'}>{viewTask.responsible.shortFullName}</Descriptions.Item>
				<Descriptions.Item label={'Начало работ'}>{viewTask.startDateFormatted}</Descriptions.Item>
				<Descriptions.Item label={'Окончание работ'}>{viewTask.endDateFormatted}</Descriptions.Item>
			</Descriptions>

			<Descriptions layout={'vertical'} column={1}>
				<Descriptions.Item label={'Название задачи'}>{viewTask.title}</Descriptions.Item>
			</Descriptions>

			<Descriptions layout={'vertical'} column={1}>
				<Descriptions.Item label={'Описание задачи'}>{viewTask.content}</Descriptions.Item>
			</Descriptions>

			{([viewTask.responsible_id, viewTask.creator_id].includes(authStore.current.id) &&
				viewTask.confirmation_by_responsible &&
				!viewTask.confirmation_by_creator && (
					<Descriptions layout={'vertical'} column={1}>
						<Descriptions.Item label={'Информация о выполнении задачи'}>
							{viewTask.responsible_answer}
						</Descriptions.Item>
					</Descriptions>
				)) ||
				null}

			{(viewTask.responsible_id === authStore.current.id &&
				viewTask.confirmation_by_responsible &&
				!viewTask.confirmation_by_creator &&
				viewTask.creator_id !== authStore.current.id && (
					<Row>
						<Col span={24}>
							<Tag color={'gold'}>Отправлен на проверку {viewTask.creator.shortFullName}</Tag>
						</Col>
					</Row>
				)) ||
				null}

			{(viewTask.responsible_id === authStore.current.id &&
				!viewTask.confirmation_by_responsible &&
				!viewTask.confirmation_by_creator && (
					<Row>
						<Col span={24} style={{ marginBottom: 15, marginTop: 40 }}>
							<Input.TextArea
								autoSize
								value={responsibleAnswer}
								style={{ width: '100%' }}
								placeholder={'Информация о выполнении задачи'}
								onChange={handleOnChange}
							/>
						</Col>
						<Col span={24}>
							<Popconfirm
								title={'Подтверждаете выполнение?'}
								onConfirm={handleOnConfirm}
								disabled={responsibleAnswer.length === 0}
							>
								<Button type={'primary'} disabled={responsibleAnswer.length === 0}>
									Задача выполнена
								</Button>
							</Popconfirm>
						</Col>
					</Row>
				)) ||
				null}

			{(viewTask.creator_id === authStore.current.id &&
				viewTask.confirmation_by_responsible &&
				!viewTask.confirmation_by_creator && (
					<Row>
						<Col span={24}>
							<Popconfirm title={'Подтверждаете выполнение?'} onConfirm={handleOnConfirmCreator}>
								<Button type={'primary'}>Задача выполнена</Button>
							</Popconfirm>
						</Col>
					</Row>
				)) ||
				null}
		</>
	);
});
