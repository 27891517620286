import { LogoutOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import dayjs from 'dayjs';
import { observer } from 'mobx-react-lite';

import { Container, Version } from './Exit.styled';
import { useStores } from '../../../../../stores';
import { StatusConnection } from '../../../../common';

export const Exit = observer(() => {
	const { authStore, websocketStore } = useStores();

	const handleOnExit = async () => {
		authStore.logout();

		websocketStore.close();
	};

	return (
		<>
			<Container>
				<StatusConnection />
				<Button type={'primary'} danger icon={<LogoutOutlined />} onClick={handleOnExit}>
					Выйти
				</Button>
				<Version>Версия от {dayjs('2024-08-19').format('D MMMM YYYY')}</Version>
			</Container>
		</>
	);
});
