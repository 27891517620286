import { darken, rgba } from 'polished';

const BLUE_MAIN = '#0086CB';

export const COLORS = {
	White: '#fff',
	BLUE_100: BLUE_MAIN,
	BLUE_80: rgba(BLUE_MAIN, 0.8),
	BLUE_60: rgba(BLUE_MAIN, 0.6),
	BLUE_40: rgba(BLUE_MAIN, 0.4),
	BLUE_20: rgba(BLUE_MAIN, 0.2),
	BLUE_10: rgba(BLUE_MAIN, 0.1),
	BLUE_05: rgba(BLUE_MAIN, 0.05),
	SILVER_LIGHT: rgba(0, 0, 0, 0.02),

	GRAY_80: rgba(0, 0, 0, 0.8),
	GRAY_60: rgba(0, 0, 0, 0.6),
	GRAY_40: rgba(0, 0, 0, 0.4),
	GRAY_20: rgba(0, 0, 0, 0.2),
	GRAY_10: rgba(0, 0, 0, 0.1),

	TABLE_TD: '#fafafa',

	SILVER_20: '#f5f5f5',
	SILVER_40: '#DCDCDC',
	SILVER_80: darken(0.05, '#f5f5f5'),
};
