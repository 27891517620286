import { Checkbox, Col, Form, FormInstance, Input, Row, Select } from 'antd';
import { useWatch } from 'antd/es/form/Form';

import { ItemVolumeGenerate } from '../../../../../../../../interfaces/item-volume-generate';
import { useStores } from '../../../../../../../../stores';

interface InformationProps {
	form: FormInstance;
	volumeIndex: number;
}

export const InformationTab = ({ volumeIndex, form }: InformationProps) => {
	const { rolesStore } = useStores();
	const item_volume: ItemVolumeGenerate = useWatch(['item_volume_generates', volumeIndex], form) || {};

	const getTitleVolume = () => {
		return `${item_volume?.number_section} | ${item_volume?.nomination || 'Не указан'}`;
	};

	return (
		<Row gutter={15}>
			<Col span={12}>
				<Form.Item
					label={'№ раздела. подраздела, части'}
					initialValue={''}
					name={[volumeIndex, 'number_section']}
					rules={[
						{
							required: true,
							message: `Пожалуйста, введите № раздела. подраздела, части в Томе : ${getTitleVolume()}`,
						},
					]}
				>
					<Input style={{ textAlign: 'center' }} />
				</Form.Item>
			</Col>

			<Col span={12}>
				<Form.Item
					label={'Обозначение'}
					initialValue={''}
					name={[volumeIndex, 'nomination']}
					rules={[{ required: true, message: `Пожалуйста, введите Обозначение в Томе : ${getTitleVolume()}` }]}
				>
					<Input />
				</Form.Item>
			</Col>

			<Col span={12}>
				<Form.Item
					label={'Исполнитель (должность)'}
					name={[volumeIndex, 'payload', 'role_id']}
					rules={[{ required: true, message: `Пожалуйста, выберите исполнителя для тома` }]}
				>
					<Select options={rolesStore.forSelectBox} allowClear />
				</Form.Item>
			</Col>

			<Col span={12}>
				<Form.Item
					label={'Завершающий том'}
					name={[volumeIndex, 'payload', 'is_finish']}
					valuePropName="checked"
					initialValue={false}
					tooltip={`При выборе опции, когда будет завершен том, то автоматически объект переходит в статус Подтвержден`}
				>
					<Checkbox />
				</Form.Item>
			</Col>

			<Col span={24}>
				<Form.Item
					label={'Наименование'}
					initialValue={''}
					name={[volumeIndex, 'note']}
					style={{ marginBottom: 0 }}
					rules={[{ required: true, message: `Пожалуйста, введите Наименование в Томе : ${getTitleVolume()}` }]}
				>
					<Input.TextArea autoSize={{ minRows: 3, maxRows: 6 }} />
				</Form.Item>
			</Col>
		</Row>
	);
};
