import {
	Col,
	DatePicker,
	Form,
	FormInstance,
	Input,
	InputNumber,
	Radio,
	RadioChangeEvent,
	Row,
	Select,
	TreeSelect,
} from 'antd';
import { observer } from 'mobx-react-lite';

import { ItemObjectTypes } from '../../../../../../../constants';
import { useStores } from '../../../../../../../stores';
import { ItemStore } from '../../../../../../../stores/item/item.store';

interface InformationProps {
	form: FormInstance;
	itemStore: ItemStore;
}

export const Information = observer(({ form, itemStore }: InformationProps) => {
	const { itemObjectTypesStore, itemStatusesStore, authStore, responsiblesStore, itemProjectDocumentsStore } =
		useStores();

	const handleOnChangeItemObjectType = (e: RadioChangeEvent) => {
		const { value } = e.target;
		itemStore.setItemObjectType(value as ItemObjectTypes);
	};

	return (
		<>
			<Row gutter={15}>
				<Col span={24}>
					<Form.Item label={'Тип объекта'} name={'item_object_type_id'} rules={[{ required: true }]}>
						<Radio.Group
							buttonStyle={'solid'}
							onChange={handleOnChangeItemObjectType}
							style={{ width: '100%', display: 'flex', textAlign: 'center' }}
						>
							{itemObjectTypesStore.list.map((itemObjectType) => {
								return (
									<Radio.Button key={itemObjectType.id} value={itemObjectType.id} style={{ width: '100%' }}>
										{itemObjectType.name}
									</Radio.Button>
								);
							})}
						</Radio.Group>
					</Form.Item>
				</Col>
			</Row>

			<Row gutter={15}>
				<Col span={12}>
					<Form.Item shouldUpdate style={{ margin: 0 }}>
						{() => {
							const ItemObjectTypeId = form.getFieldValue('item_object_type_id');
							return (
								<Form.Item
									label={'Состав проектной документации'}
									name={'item_project_document_id'}
									rules={[{ required: [ItemObjectTypes.MAIN_NETWORKS].includes(ItemObjectTypeId) }]}
								>
									<Select placeholder={'Выберите составную документацию'}>
										{itemProjectDocumentsStore.table.list.map((item) => {
											return (
												<Select.Option key={item.id} value={item.id}>
													{item.name}
												</Select.Option>
											);
										})}
									</Select>
								</Form.Item>
							);
						}}
					</Form.Item>
				</Col>

				<Col span={12}>
					<Form.Item label={'Статус'} name={'item_status_id'} rules={[{ required: true }]}>
						<TreeSelect
							style={{ width: '100%' }}
							placeholder={'Выберите статус'}
							treeData={itemStatusesStore.selectBoxList}
						/>
					</Form.Item>
				</Col>
			</Row>

			<Row gutter={15}>
				<Col span={12}>
					<Form.Item label={'Автор объекта'}>
						(<strong>Вы</strong>) {authStore.current.shortFullName}
					</Form.Item>
				</Col>

				<Col span={12}>
					<Form.Item label={'Ответственный по объекту'} name={'responsible_id'}>
						<Select
							placeholder={'Выберите ответсвенного'}
							showSearch
							filterOption={(inputValue, option) => {
								return String(option?.children ?? '')
									.toLowerCase()
									.includes(inputValue.toLowerCase());
							}}
						>
							{responsiblesStore.list.map((responsible) => {
								return (
									<Select.Option key={responsible.id} value={responsible.id}>
										{responsible.shortFullName}
									</Select.Option>
								);
							})}
						</Select>
					</Form.Item>
				</Col>
			</Row>

			<Row gutter={15}>
				<Col span={24}>
					<Form.Item label={'Титул проекта'} name={'title'} rules={[{ required: true }]}>
						<Input />
					</Form.Item>
				</Col>
			</Row>

			<Row gutter={15}>
				<Col span={12}>
					<Form.Item label={'Номер договора'} name={'contract_number'}>
						<Input />
					</Form.Item>
				</Col>
				<Col span={12}>
					<Form.Item label={'Стоимость'} name={'price'} rules={[{ required: true }]}>
						<InputNumber
							precision={2}
							decimalSeparator={','}
							min={0}
							formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ').replace(',', '.')}
						/>
					</Form.Item>
				</Col>
			</Row>

			<Row gutter={15}>
				<Col span={12}>
					<Form.Item label={'Дата начала проектирование'} name={'start_date'} rules={[{ required: true }]}>
						<DatePicker format={'DD MMMM YYYY'} />
					</Form.Item>
				</Col>
				<Col span={12}>
					<Form.Item shouldUpdate style={{ margin: 0 }}>
						{() => {
							const fieldStartDate = form.getFieldValue('start_date');
							return (
								<Form.Item
									label={'Дата окончания проектирования'}
									name={'end_date'}
									rules={[
										{ required: true },
										{
											validator: async (_, workEndDate) => {
												if (workEndDate) {
													if (workEndDate.diff(fieldStartDate, 'days') <= 0) {
														return Promise.reject('Окончание работы < чем начало работ');
													}

													return Promise.resolve();
												} else {
													return Promise.resolve();
												}
											},
										},
									]}
								>
									<DatePicker
										format={'DD MMMM YYYY'}
										disabledDate={(current) => {
											if (fieldStartDate && current) {
												return current && current < fieldStartDate.endOf('day');
											} else {
												return false;
											}
										}}
									/>
								</Form.Item>
							);
						}}
					</Form.Item>
				</Col>
			</Row>
		</>
	);
});
