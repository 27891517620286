import { useEffect } from 'react';

import { EyeOutlined } from '@ant-design/icons';
import { Button, Divider, List, Tag, Tooltip } from 'antd';
import { observer } from 'mobx-react-lite';

import { useStores } from '../../../../stores';
import { ObjectDetailStore } from '../../../../stores/objects/object-detail.store';
import { COLORS } from '../../../../stylesheets';
import { CardWidget, Status } from '../../../common';

export const ObjectWidget = observer(() => {
	const { userDashboardStore } = useStores();
	const { objects } = userDashboardStore;
	const { tableUser } = objects;

	useEffect(() => {
		(async () => {
			await tableUser.getList();
		})();
	}, []);

	const handleOnShow = async (objectStore: ObjectDetailStore) => {
		objects.viewDetail.setData(objectStore);
		await objects.viewDetail.data.get();
		objects.viewDetail.modal.open();
	};

	return (
		<>
			<CardWidget title={'Объекты'} tableStore={tableUser}>
				<List
					loading={tableUser.isLoading}
					dataSource={tableUser.list}
					renderItem={(objectStore: ObjectDetailStore) => {
						return (
							<List.Item
								key={objectStore.id}
								actions={[
									<Button
										key={'show'}
										type={'primary'}
										icon={<EyeOutlined />}
										onClick={() => handleOnShow(objectStore)}
									/>,
								]}
							>
								<List.Item.Meta
									avatar={null}
									title={
										<span style={{ display: 'flex', alignItems: 'center' }}>
											<Status
												confirmation_by_responsible={objectStore.confirmation_by_responsible}
												confirmation_by_creator={objectStore.confirmation_by_creator}
											/>

											<Tooltip title={objectStore.object_type.name}>
												<Tag color={COLORS.BLUE_100}>{objectStore.abbr}</Tag>
											</Tooltip>

											<span style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>
												<Tooltip title={objectStore.title}>
													{objectStore.cipher}
													<Divider type={'vertical'} />
													{objectStore.title}
												</Tooltip>
											</span>
										</span>
									}
									description={
										<>
											{objectStore.startDateFormatted} - {objectStore.endDateFormatted}{' '}
										</>
									}
								/>
							</List.Item>
						);
					}}
				/>
			</CardWidget>
		</>
	);
});
