import dayjs, { Dayjs } from 'dayjs';
import { action, makeObservable, observable } from 'mobx';

import { ObjectTypeStore } from './object-type.store';
import { OBJECT_TYPES } from '../../constants';
import { checkDateOrNull } from '../../helpers';
import { ObjectDetailI } from '../../interfaces';
import { ObjectContractorStore } from '../object-contractor/object-contractor.store';
import { ObjectStatusStore } from '../object-status/object-status.store';
import { ProjectDocumentationStore } from '../project-documentation/project-documentation.store';
import { UserDetailStore } from '../users/user-detail.store';

export class ObjectDetailFlatStore implements ObjectDetailI {
	@observable id = 0;
	@observable creator_id = 0;
	@observable creator = new UserDetailStore(null);
	@observable responsible_id: number | undefined = undefined;
	@observable project_documentation_id: number | undefined = undefined;
	@observable object_type_id: OBJECT_TYPES = OBJECT_TYPES.DISTRIBUTION_NETWORKS;
	@observable object_type = new ObjectTypeStore(null);
	@observable object_status_id: number | undefined = undefined;
	@observable object_status = new ObjectStatusStore(null);
	@observable title = '';
	@observable price = 0;
	@observable contract_number = '';
	@observable description = '';
	@observable cipher = '';
	@observable start_date: Dayjs | undefined = undefined;
	@observable end_date: Dayjs | undefined = undefined;
	@observable actual_date: Dayjs | null | undefined = undefined;
	@observable subcontracting = false;
	@observable confirmation_by_responsible = false;
	@observable confirmation_by_creator = false;
	@observable confirmation_by_responsible_date: Dayjs | undefined = undefined;
	@observable confirmation_by_creator_date: Dayjs | undefined = undefined;
	@observable responsible_answer = '';
	@observable is_delete = false;
	@observable responsible_show = false;
	@observable responsible: UserDetailStore | null = null;
	@observable volumes_count = 0;
	@observable volumes_executed_count = 0;
	@observable contractors: ObjectContractorStore[] = [];
	@observable project_documentation = new ProjectDocumentationStore(null);

	constructor(initialData: ObjectDetailI | null) {
		makeObservable(this);
		if (initialData) {
			this.fillStore(initialData);
		}
	}

	@action
	fillStore(data: ObjectDetailI) {
		const {
			id,
			creator_id,
			creator,
			responsible_id,
			project_documentation_id,
			object_type_id,
			object_type,
			object_status_id,
			object_status,
			title,
			price,
			contract_number,
			description,
			cipher,
			start_date,
			end_date,
			actual_date,
			subcontracting,
			confirmation_by_responsible,
			confirmation_by_creator,
			confirmation_by_responsible_date,
			confirmation_by_creator_date,
			responsible_answer,
			is_delete,
			responsible_show,
			responsible,
			volumes_count,
			volumes_executed_count,
			contractors,
			project_documentation,
		} = data;

		this.id = id;
		this.creator_id = creator_id;
		this.creator = new UserDetailStore(creator);
		this.responsible_id = responsible_id || undefined;
		this.project_documentation_id = project_documentation_id;
		this.object_type_id = object_type_id;
		this.object_type = new ObjectTypeStore(object_type);
		this.object_status_id = object_status_id;
		this.object_status = new ObjectStatusStore(object_status);
		this.title = title;
		this.price = price;
		this.contract_number = contract_number;
		this.description = description;
		this.cipher = cipher;
		this.start_date = start_date ? dayjs(start_date) : undefined;
		this.end_date = end_date ? dayjs(end_date) : undefined;
		this.actual_date = checkDateOrNull(actual_date);
		this.subcontracting = subcontracting;
		this.confirmation_by_responsible = confirmation_by_responsible;
		this.confirmation_by_creator = confirmation_by_creator;
		this.confirmation_by_responsible_date = checkDateOrNull(confirmation_by_responsible_date);
		this.confirmation_by_creator_date = checkDateOrNull(confirmation_by_creator_date);
		this.responsible_answer = responsible_answer;
		this.is_delete = is_delete;
		this.responsible_show = responsible_show;
		this.responsible = responsible ? new UserDetailStore(responsible) : null;
		this.volumes_count = volumes_count;
		this.volumes_executed_count = volumes_executed_count;
		this.contractors = (contractors || []).map((contractor) => new ObjectContractorStore(contractor));
		this.project_documentation = new ProjectDocumentationStore(project_documentation);
	}
}
