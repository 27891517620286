import { ReactNode } from 'react';

import { Container } from './BlockTitle.styled';

interface BlockTitleProps {
	children?: ReactNode;
}

export const BlockTitle = ({ children = null }: BlockTitleProps) => {
	return <Container>{children}</Container>;
};
